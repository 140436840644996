import { FC } from "react";

import ReactSlider, { ReactSliderProps } from "react-slider";

import cn from "../../../utils/cn";

import styles from "./Slider.module.css";

interface ISliderProps extends ReactSliderProps {
	min?: number;
	max?: number;
	step?: number;
	value: number;
	maxLabel?: string;
	minLabel?: string;
	minLabelDetails?: string;
	maxLabelDetails?: string;
	//TODO: erreur dans le composant BusinessInformationFilter
	renderSliderLabel: (value: number, maxLabel: string) => string;
	onChange?: (fieldName: any, value: any) => void;
	className?: string;
}

export const Slider: FC<ISliderProps> = ({
	min = 0,
	max = 100,
	onChange,
	step = 10,
	value,
	maxLabel = "",
	minLabel = "",
	minLabelDetails = "",
	maxLabelDetails = "",
	renderSliderLabel,
	className = "",
}) => (
	<div className={styles.wrapper}>
		<ReactSlider
			className="default-slider"
			min={min}
			max={max}
			step={step}
			value={value === null ? 100 : value}
			thumbClassName="default-slider-thumb"
			trackClassName="default-slider-track"
			thumbActiveClassName="default-slider-thumb-active"
			onChange={onChange}
			renderThumb={(props) => (
				// eslint-disable-next-line react/jsx-props-no-spreading
				<div {...props}>
					<div className={cn([styles.tooltip, `${styles[className]}`])}>
						{renderSliderLabel(value, maxLabel)}
					</div>
				</div>
			)}
		/>
		<span className={cn([styles.start, styles.label])}>{`${minLabel} ${minLabelDetails}`}</span>
		<span className={cn([styles.end, styles.label])}>{`${maxLabel} ${maxLabelDetails}`}</span>
	</div>
);
