// @ts-nocheck
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useTitle } from "react-use";

import { format, getUnixTime } from "date-fns";
import localeFr from "date-fns/locale/fr";

// Services
import { getUnifiedTempWorker } from "../../../../services/missionFollowUpService";
import { searchClientContracts } from "../../../../services/unifiedTempWorkerService";

// Hooks
import useInterval from "../../../../hooks/useInterval";

// Templates
// Constants
import { PAGINATION_OFFSET_OPTIONS, TOOLTIP_THRESHOLD } from "../../../../constants";
// Utils
import { dateDifference } from "../../../../utils/formatDate";
import { definePageOffset } from "../../../../utils/paginationUtils";
// import PropTypes from 'prop-types';
import { PaginationV2, Picto, SelectPopover, Text, Title, Tooltip } from "@zolteam/react-ras-library";
import { ColumnListing, DateTimeRangeDisplay, PaginationSearch } from "src/components/molecules";
import { TableListing } from "src/components/organisms";

import style from "./ProfileMissionsContracts.module.css";

const DEFAULT_SEARCH_PARAMS = {
	page: 1,
	limit: 40,
};

const ProfileMissionsContracts = () => {
	const { t } = useTranslation();
	const { profileId } = useParams();
	useTitle(t("profile.clientRelationship.title"));

	const [data, setData] = useState();
	const [search, setSearch] = useState(DEFAULT_SEARCH_PARAMS);
	const [isFirstLoading, setIsFirstLoading] = useState(true);

	const setUpdatedData = (res) => {
		const updatedData = res?.items?.reduce((acc, item) => {
			let updateDiff = null;
			if (item.updatedAt === item.createdAt) {
				updateDiff = null;
			} else {
				updateDiff = dateDifference(
					getUnixTime(new Date(item.updatedAt) * 1000),
					getUnixTime(Date.now()) * 1000
				);
			}
			acc.push({ ...item, updateDiff });
			return acc;
		}, []);
		setData({ ...res, items: updatedData });
	};

	const { data: unifiedTempWorker } = useQuery(["unified-temp-worker", profileId], () =>
		getUnifiedTempWorker(profileId)
	);

	const searchClientContractsMutation = useMutation((params) => searchClientContracts(profileId, params), {
		onSuccess: (result) => {
			setUpdatedData(result?.data);
			setIsFirstLoading(false);
		},
	});

	const { isError: issearchClientContractsError, isLoading: issearchClientContractsLoading } =
		searchClientContractsMutation;

	useInterval(() => {
		setUpdatedData(data);
	}, 60000);

	const handleSearch = (sort) => {
		setSearch((prev) => {
			const searchRequest = { ...prev, ...sort };
			return searchRequest;
		});
		searchClientContractsMutation.mutate({ ...search, ...sort });
	};

	const isListLoading = () => (isFirstLoading && !issearchClientContractsError) || issearchClientContractsLoading;

	useEffect(() => {
		searchClientContractsMutation.mutate(search);
	}, []);

	return (
		<div className={style.clientRelationships}>
			<div className={style.header}>
				<Title tag="h1" fontWeight="bold" size="heading01" lineHeight="m">
					{t("profile.contract.title")}
				</Title>
				{/* Uncoment this when user story is ready */}
				{/* <div className={style.tableOptions}>
          <div className={style.filter}>
            <Picto icon="sliders" className={style.filterPicto} />
            {t('profile.contract.filterRelationships')}
          </div>
        </div> */}
			</div>
			<div className={style.clientRelationshipList}>
				<TableListing
					loading={isListLoading()}
					fullHeight
					canBeChecked={false}
					error={issearchClientContractsError}
					data={data?.items}
					handleSearch={() => {}}
					onDoubleClick={() => null}
					noDataText={t("profile.contract.listing.noResultMessage", {
						firstName: unifiedTempWorker?.data?.firstName || "",
						lastName: unifiedTempWorker?.data?.lastName || "",
					})}
					noBorder
					initialColumnSort={{
						field: "updatedAt",
						orderBy: "DESC",
					}}
					footer={
						data?.total ? (
							<>
								<PaginationV2
									previousLabel={<Picto icon="chevronLeft" />}
									nextLabel={<Picto icon="chevronRight" />}
									breakLine={
										<PaginationSearch
											onInputChange={handleSearch}
											max={Math.round(data.total / search.limit)}
										/>
									}
									currentPage={search.page}
									totalCount={data.total}
									pageSize={search.limit}
									siblingCount={2}
									onPageChange={(activePage) => handleSearch({ page: activePage })}
								/>
								<Text tag="span" size="paragraph02" color="grey">
									{t("global.pagination.resultsPagination", {
										resultStart: search.limit * search.page - search.limit + 1,
										resultEnd:
											search.limit * search.page <= data?.total
												? search.limit * search.page
												: data?.total,
										resultTotal: data?.total,
									})}
								</Text>
								<SelectPopover
									name="limit"
									options={PAGINATION_OFFSET_OPTIONS}
									onChange={(value) =>
										handleSearch({
											limit: value,
											page: definePageOffset(value, search.limit, search.page),
										})
									}
									value={
										search.limit
											? PAGINATION_OFFSET_OPTIONS.find((option) => option.value === search.limit)
											: null
									}
								/>
							</>
						) : null
					}
				>
					{/* CONTRACT NUMBER */}
					<ColumnListing
						id="contractNumber"
						name={
							<Text tag="span" size="paragraph02" color="grey">
								{t("profile.contract.listing.contractNumber")}
							</Text>
						}
						field={(elem) => ({ contractNumber: elem.contractNumber })}
						component={({ data: { contractNumber } }) => (
							<div className={style.column}>{contractNumber}</div>
						)}
						tdClassName="min-column-width"
					/>

					{/* CLIENT */}
					<ColumnListing
						id="clientName"
						name={
							<Text tag="span" size="paragraph02" color="grey">
								{t("profile.contract.listing.clientName")}
							</Text>
						}
						field={(elem) => ({ clientName: elem.clientName })}
						component={({ data: { clientName } }) => (
							<div className={style.column}>
								{clientName?.length > TOOLTIP_THRESHOLD ? (
									<Tooltip animation={false} arrow={false} content={clientName || ""}>
										<div>{`${clientName?.substring(0, TOOLTIP_THRESHOLD)}...`}</div>
									</Tooltip>
								) : (
									clientName
								)}
							</div>
						)}
						tdClassName="min-column-width"
					/>

					{/* QUALIFICATION */}
					<ColumnListing
						id="qualificationName"
						name={
							<Text tag="span" size="paragraph02" color="grey">
								{t("profile.contract.listing.qualificationName")}
							</Text>
						}
						field={(elem) => ({ qualificationName: elem.qualificationName })}
						component={({ data: { qualificationName } }) => (
							<div className={style.column}>
								{qualificationName?.length > TOOLTIP_THRESHOLD ? (
									<Tooltip animation={false} arrow={false} content={qualificationName || ""}>
										<div>{`${qualificationName?.substring(0, TOOLTIP_THRESHOLD)}...`}</div>
									</Tooltip>
								) : (
									qualificationName
								)}
							</div>
						)}
						tdClassName="min-column-width"
					/>

					{/* AGENCY */}
					<ColumnListing
						id="agency"
						name={
							<Text tag="span" size="paragraph02" color="grey">
								{t("profile.contract.listing.agencyName")}
							</Text>
						}
						field={(elem) => ({
							agencyName: elem.agencyName.replace("RAS ", ""),
						})}
						component={({ data: { agencyName } }) => (
							<div className={style.column}>
								{agencyName?.length > TOOLTIP_THRESHOLD ? (
									<Tooltip animation={false} arrow={false} content={agencyName || ""}>
										<div>{`${agencyName?.substring(0, TOOLTIP_THRESHOLD)}...`}</div>
									</Tooltip>
								) : (
									agencyName
								)}
							</div>
						)}
						tdClassName="min-column-width"
					/>

					{/* CONTRACT START DATE */}
					<ColumnListing
						id="contractStartDate"
						name={
							<Text tag="span" size="paragraph02" color="grey">
								{t("profile.contract.listing.startDate")}
							</Text>
						}
						field={(elem) => ({
							contractStartDate: elem.contractStartDate,
							startTimeAm: elem.startTimeAm,
							finishTimeAm: elem.finishTimeAm,
							startTimePm: elem.startTimePm,
							finishTimePm: elem.finishTimePm,
						})}
						component={({
							data: { contractStartDate, startTimeAm, finishTimeAm, startTimePm, finishTimePm },
						}) => (
							<DateTimeRangeDisplay
								hours={[
									{ startHour: startTimeAm, endHour: finishTimeAm },
									{ startHour: startTimePm, endHour: finishTimePm },
								]}
								date={format(new Date(contractStartDate), "dd MMM yyyy", {
									locale: localeFr,
								})}
								locked={false}
								onClick={() => {}}
							/>
						)}
						tdClassName="min-column-width"
					/>

					{/* CONTRACT END DATE */}
					<ColumnListing
						id="contractEndDate"
						name={
							<Text tag="span" size="paragraph02" color="grey">
								{t("profile.contract.listing.endDate")}
							</Text>
						}
						field={(elem) => ({ contractEndDate: elem.contractEndDate })}
						component={({ data: { contractEndDate } }) => (
							<div className={style.column}>
								{format(new Date(contractEndDate), "dd MMM yyyy", {
									locale: localeFr,
								})}
							</div>
						)}
						tdClassName="min-column-width"
					/>
				</TableListing>
			</div>
		</div>
	);
};

ProfileMissionsContracts.propTypes = {};

export default ProfileMissionsContracts;
