import { useCallback, useState } from "react";

import { debounce } from "lodash";
import { ExtractParams } from "src/types/CommandsType";

import { ExtractQuery, postOrdersExtract } from "src/services/commands/postOrderExtractService";

import { useToastContext } from "src/store/ToastContext";

// Types
import CommandsFiltersType from "../types/orderFiltersType";

import { extractFilename } from "src/utils/extractFilename";

import EXTRACT_PERIMETER from "src/constants/extractPerimeter";

const processStream = async (reader: ReadableStreamDefaultReader) => {
	const stream = new ReadableStream({
		async start(controller) {
			while (true) {
				const { done, value } = await reader.read();
				if (done) break;
				controller.enqueue(value);
			}
			controller.close();
		},
	});

	const response = new Response(stream);
	const blob = await response.blob();
	return blob;
};

const downloadFile = (url: string, filename: string) => {
	const a = document.createElement("a");
	a.href = url;
	a.download = filename;
	a.click();
	URL.revokeObjectURL(url);
};

export const useCommands = (setFilters: React.Dispatch<React.SetStateAction<CommandsFiltersType>>) => {
	const [, toastDispatch] = useToastContext();
	const [isExtractProcessing, setIsExtractProcessing] = useState(false);
	const [searchOrder, setSearchOrder] = useState("");

	const DEBOUNCE_DELAY = 250;

	const fetchAndSaveOrders = useCallback(
		async (extractQuery: ExtractQuery) => {
			try {
				// waiting for the creation of the file to be completed
				setIsExtractProcessing(true);
				const res = await postOrdersExtract(extractQuery);

				if (res.data) {
					const reader = res.data.getReader();
					const filename = extractFilename(res);

					const blob = await processStream(reader);
					const url = URL.createObjectURL(blob);

					downloadFile(url, filename);
				} else {
					toastDispatch({
						type: "ERROR",
						payload: {
							icon: "alertCircle",
							msg: "commands.extractError",
						},
					});
				}
			} catch (error) {
				toastDispatch({
					type: "ERROR",
					payload: {
						icon: "alertCircle",
						msg: error.message || "commands.extractError",
					},
				});
			} finally {
				setIsExtractProcessing(false);
			}
		},
		[toastDispatch]
	);

	const handleExtract = useCallback(
		({ extractPerimeter, format, selectedItems, filters }: ExtractParams) => {
			const extractQuery: ExtractQuery = {
				format,
				orderIds: extractPerimeter === EXTRACT_PERIMETER.SELECTED ? selectedItems : [],
				query: extractPerimeter === EXTRACT_PERIMETER.DISPLAYED ? filters : null,
			};

			fetchAndSaveOrders(extractQuery);
		},
		[fetchAndSaveOrders]
	);

	const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setSearchOrder(value);
		debouncedSetFilters(value);
	};

	const handleSearchClear = () => {
		setSearchOrder("");
		setFilters((prev: CommandsFiltersType) => ({
			...prev,
			search: null,
		}));
	};

	const debouncedSetFilters = useCallback(
		debounce((value: string) => {
			setFilters((prev: CommandsFiltersType) => ({
				...prev,
				search: value,
			}));
		}, DEBOUNCE_DELAY),
		[]
	);

	return { handleExtract, isExtractProcessing, handleSearchChange, handleSearchClear, searchOrder };
};
