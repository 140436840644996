import { FC } from "react";

import { ToggleSwitch } from "@zolteam/react-ras-library";
import { CheckboxListFilter } from "src/components/organisms";

interface ToggleRadioInputProps {
	name: string;
	label: string;
	toggled: boolean;
	radioOptions?: Record<string, boolean>;
	radioValue?: string;
	onRadioChange?: (item: string) => void;
	onChange: (toogled?: boolean) => void;
	getRadioLabel?: (type: string) => string;
	classNameLabel?: string;
	disableRadio?: boolean;
}

export const ToggleRadioInput: FC<ToggleRadioInputProps> = ({
	name,
	label,
	toggled,
	radioOptions,
	radioValue,
	onChange,
	onRadioChange,
	getRadioLabel,
	classNameLabel,
	disableRadio,
}) => {
	return (
		<>
			<ToggleSwitch name={name} id={name} onChange={() => onChange(!toggled)} toggled={toggled}>
				<label htmlFor={name} className={classNameLabel}>
					{label}
				</label>
			</ToggleSwitch>
			{toggled && !disableRadio && (
				<CheckboxListFilter
					items={radioOptions}
					type="radio"
					onChange={onRadioChange}
					getLabel={getRadioLabel}
					getValue={(type) => {
						return type === radioValue;
					}}
					listStyle={{ gap: "1rem", flexWrap: "nowrap", justifyContent: "start" }}
					orientation="horizontal"
					getId={(index) => `toggle-radio-${name}-${index}`}
					labelClassName="text-xs text-gray-500"
				/>
			)}
		</>
	);
};
