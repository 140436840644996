import i18n from "src/i18n";
import * as Yup from "yup";

import isValidEndDayHour from "src/views/Command/Configuration/forms/Rules/Validators/isValidEndDayHour";
import isValidEndHourRequired from "src/views/Command/Configuration/forms/Rules/Validators/isValidEndHourRequired";
import isValidEndNightHour from "src/views/Command/Configuration/forms/Rules/Validators/isValidEndNightHour";
import isValidSlots from "src/views/Command/Configuration/forms/Rules/Validators/isValidSlots";
import isValidStartDayHour from "src/views/Command/Configuration/forms/Rules/Validators/isValidStartDaytHour";
import isValidStartHourRequired from "src/views/Command/Configuration/forms/Rules/Validators/isValidStartHourRequired";
import isValidStartNightHour from "src/views/Command/Configuration/forms/Rules/Validators/isValidStartNightHour";
import isValidValidationTime from "src/views/Command/Configuration/forms/Rules/Validators/isValidValidationTime";

const SlotsRule = () => {
	return Yup.array().of(
		Yup.object().shape({
			startHour: Yup.string()
				.nullable()
				.test("isValidStartHourRequired", i18n.t("commands.error.startHourRequired"), isValidStartHourRequired)
				.test("isValidValidationTime", i18n.t("commands.error.wrongTimeFormat"), isValidValidationTime)
				.test("isValidDayHour", i18n.t("commands.error.wrongDayTimeFormat"), isValidStartDayHour)
				.test("isValidNightHour", i18n.t("commands.error.wrongNightTimeFormat"), isValidStartNightHour)
				.test("isValidSlots", i18n.t("commands.error.wrongDayTimeFormat"), isValidSlots),
			endHour: Yup.string()
				.nullable()
				.test("isValidEndHourRequired", i18n.t("commands.error.endHourRequired"), isValidEndHourRequired)
				.test("isValidValidationTime", i18n.t("commands.error.wrongTimeFormat"), isValidValidationTime)
				.test("isValidDayHour", i18n.t("commands.error.wrongDayTimeFormat"), isValidEndDayHour)
				.test("isValidNightHour", i18n.t("commands.error.wrongNightTimeFormat"), isValidEndNightHour)
				.test("isValidSlots", i18n.t("commands.error.wrongDayTimeFormat"), isValidSlots),
		})
	);
};

export default SlotsRule;
