// @ts-nocheck
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { useTitle } from "react-use";

import { format, getUnixTime } from "date-fns";
import localeFr from "date-fns/locale/fr";

// Services
import { searchClientRelationships } from "../../../../services/unifiedTempWorkerService";

// Hooks
import useInterval from "../../../../hooks/useInterval";

// Constants
import { PAGINATION_OFFSET_OPTIONS } from "../../../../constants";
// Utils
import cn from "../../../../utils/cn";
import { dateDifference } from "../../../../utils/formatDate";
import { definePageOffset } from "../../../../utils/paginationUtils";
// import PropTypes from 'prop-types';
import { Button, PaginationV2, Picto, SelectPopover, Tag, Text, Title, Tooltip } from "@zolteam/react-ras-library";
import { ColumnListing, PaginationSearch } from "src/components/molecules";
import { TableListing } from "src/components/organisms";

// Templates
// Hooks
import style from "./ProfileMissionsClientRelationships.module.css";

const DEFAULT_SEARCH_PARAMS = {
	page: 1,
	limit: 40,
};

const ProfileMissionsClientRelationships = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const { profileId } = useParams();
	useTitle(t("profile.clientRelationship.title"));

	const [data, setData] = useState();
	const [search, setSearch] = useState(DEFAULT_SEARCH_PARAMS);
	const [isFirstLoading, setIsFirstLoading] = useState(true);

	const setUpdatedData = (res) => {
		const updatedData = res?.items?.reduce((acc, item) => {
			let updateDiff = null;
			if (item.updatedAt === item.createdAt) {
				updateDiff = null;
			} else {
				updateDiff = dateDifference(
					getUnixTime(new Date(item.updatedAt) * 1000),
					getUnixTime(Date.now()) * 1000
				);
			}
			acc.push({ ...item, updateDiff });
			return acc;
		}, []);
		setData({ ...res, items: updatedData });
	};

	const searchClientRelationshipsMutation = useMutation((params) => searchClientRelationships(profileId, params), {
		onSuccess: (result) => {
			setUpdatedData(result?.data);
			setIsFirstLoading(false);
		},
	});

	const { isError: isSearchClientRelationshipsError, isLoading: isSearchClientRelationshipsLoading } =
		searchClientRelationshipsMutation;

	useInterval(() => {
		setUpdatedData(data);
	}, 60000);

	const handleSearch = (sort) => {
		setSearch((prev) => {
			const searchRequest = { ...prev, ...sort };
			return searchRequest;
		});
		searchClientRelationshipsMutation.mutate({ ...search, ...sort });
	};

	const isListLoading = () =>
		(isFirstLoading && !isSearchClientRelationshipsError) || isSearchClientRelationshipsLoading;

	useEffect(() => {
		searchClientRelationshipsMutation.mutate(search);
	}, []);

	const handleDoubleClick = ({ id: relationshipId }) => {
		history.push(`/profile/${profileId}/missions/client-relationship/${relationshipId}`);
	};

	const getTableRowHref = ({ id }) => `/profile/${id}/missions/client-relationship/${id}`;

	return (
		<div className={style.clientRelationships}>
			<div className={style.header}>
				<Title tag="h1" fontWeight="bold" size="heading01" lineHeight="m">
					{t("profile.clientRelationship.title")}
				</Title>
				<div className={style.tableOptions}>
					<Button
						type="button"
						onClick={() => history.push(`/profile/${profileId}/missions/client-relationship/create`)}
						color="primary"
					>
						{t("profile.clientRelationship.addRelationship")}
					</Button>
					<div className={style.filter}>
						<Picto icon="sliders" className={style.filterPicto} />
						{t("profile.clientRelationship.filterRelationships")}
					</div>
				</div>
			</div>
			<div className={style.clientRelationshipList}>
				<TableListing
					loading={isListLoading()}
					fullHeight
					canBeChecked={false}
					error={isSearchClientRelationshipsError}
					data={data?.items}
					handleSearch={() => {}}
					getTableRowHref={getTableRowHref}
					onDoubleClick={handleDoubleClick}
					noBorder
					disabledRow={(elem) => !elem.hasAccess}
					initialColumnSort={{
						field: "updatedAt",
						orderBy: "DESC",
					}}
					footer={
						data?.total ? (
							<>
								<PaginationV2
									previousLabel={<Picto icon="chevronLeft" />}
									nextLabel={<Picto icon="chevronRight" />}
									breakLine={
										<PaginationSearch
											onInputChange={handleSearch}
											max={Math.round(data.total / search.limit)}
										/>
									}
									currentPage={search.page}
									totalCount={data.total}
									pageSize={search.limit}
									siblingCount={2}
									onPageChange={(activePage) => handleSearch({ page: activePage })}
								/>
								<Text tag="span" size="paragraph02" color="grey">
									{t("global.pagination.resultsPagination", {
										resultStart: search.limit * search.page - search.limit + 1,
										resultEnd:
											search.limit * search.page <= data?.total
												? search.limit * search.page
												: data?.total,
										resultTotal: data?.total,
									})}
								</Text>
								<SelectPopover
									name="limit"
									options={PAGINATION_OFFSET_OPTIONS}
									onChange={(value) =>
										handleSearch({
											limit: value,
											page: definePageOffset(value, search.limit, search.page),
										})
									}
									value={
										search.limit
											? PAGINATION_OFFSET_OPTIONS.find((option) => option.value === search.limit)
											: null
									}
								/>
							</>
						) : null
					}
				>
					{/* STATUS */}
					<ColumnListing
						id="isAdequate"
						name={
							<Text tag="span" size="paragraph02" color="grey">
								{t("profile.clientRelationship.listing.isAdequate")}
							</Text>
						}
						field={(elem) => ({ isAdequate: elem.isAdequate })}
						component={({ data: { isAdequate } }) => (
							<div className={style.column}>
								<Tag color={isAdequate ? "ghost" : "warning"}>
									<span>
										{t(`profile.clientRelationship.${isAdequate ? "adequate" : "inadequate"}`)}
									</span>
								</Tag>
							</div>
						)}
						tdClassName="min-column-width"
					/>

					{/* AGENCE */}
					<ColumnListing
						id="agency"
						name={
							<Text tag="span" size="paragraph02" color="grey">
								{t("profile.clientRelationship.listing.agencyName")}
							</Text>
						}
						field={(elem) => ({
							agencyName: elem.agencyName.replace("RAS ", ""),
						})}
						component={({ data: { agencyName } }) => (
							<div className={style.column}>
								{agencyName?.length > 20 ? (
									<Tooltip animation={false} arrow={false} content={agencyName || ""}>
										<div>{`${agencyName?.substring(0, 20)}...`}</div>
									</Tooltip>
								) : (
									agencyName
								)}
							</div>
						)}
						tdClassName="min-column-width"
					/>

					{/* CLIENT */}
					<ColumnListing
						id="clientName"
						name={
							<Text tag="span" size="paragraph02" color="grey">
								{t("profile.clientRelationship.listing.clientName")}
							</Text>
						}
						field={(elem) => ({ clientName: elem.clientName })}
						component={({ data: { clientName } }) => (
							<div className={style.column}>
								{clientName?.length > 20 ? (
									<Tooltip animation={false} arrow={false} content={clientName || ""}>
										<div>{`${clientName?.substring(0, 20)}...`}</div>
									</Tooltip>
								) : (
									clientName
								)}
							</div>
						)}
						tdClassName="min-column-width"
					/>

					{/* CODE CLIENT */}
					<ColumnListing
						id="clientCode"
						name={
							<Text tag="span" size="paragraph02" color="grey">
								{t("profile.clientRelationship.listing.clientCode")}
							</Text>
						}
						field={(elem) => ({ clientCode: elem.clientCode })}
						component={({ data: { clientCode } }) => (
							<div className={cn([style.column, style.columnClientCode])}>{clientCode}</div>
						)}
						tdClassName="min-column-width"
					/>

					{/* INTERVENANT */}
					<ColumnListing
						id="contact"
						name={
							<Text tag="span" size="paragraph02" color="grey">
								{t("profile.clientRelationship.listing.contact")}
							</Text>
						}
						field={(elem) => ({ contact: elem.contact })}
						component={({ data: { contact } }) => (
							<div className={style.column}>
								{contact?.length > 20 ? (
									<Tooltip animation={false} arrow={false} content={contact || ""}>
										<div>{`${contact?.substring(0, 20)}...`}</div>
									</Tooltip>
								) : (
									contact
								)}
							</div>
						)}
						tdClassName="min-column-width"
					/>

					{/* MISE A JOUR */}
					<ColumnListing
						id="updatedAt"
						name={
							<Text tag="span" size="paragraph02" color="grey">
								{t("profile.clientRelationship.listing.updatedAt")}
							</Text>
						}
						field={(elem) => ({ updatedAt: elem.updatedAt })}
						component={({ data: { updatedAt } }) => (
							<div className={style.column}>
								{format(new Date(updatedAt), "dd MMM yyyy", {
									locale: localeFr,
								})}
							</div>
						)}
						tdClassName="min-column-width"
					/>

					{/* PAR */}
					<ColumnListing
						id="createdBy"
						name={
							<Text tag="span" size="paragraph02" color="grey">
								{t("profile.clientRelationship.listing.createdBy")}
							</Text>
						}
						field={(elem) => ({ createdBy: elem.createdBy })}
						component={({ data: { createdBy } }) => (
							<div className={style.column}>
								{createdBy?.length > 20 ? (
									<Tooltip animation={false} arrow={false} content={createdBy || ""}>
										<div>{`${createdBy?.substring(0, 20)}...`}</div>
									</Tooltip>
								) : (
									createdBy
								)}
							</div>
						)}
						tdClassName="min-column-width"
					/>
				</TableListing>
			</div>
		</div>
	);
};

ProfileMissionsClientRelationships.propTypes = {};

export default ProfileMissionsClientRelationships;
