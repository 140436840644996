import { FC } from "react";

import Skeleton from "react-loading-skeleton";

import { Button, Picto } from "@zolteam/react-ras-library";

import formatPhoneNumber from "src/utils/formatPhoneNumber";

interface ContactBlockProps {
	phone: string | null;
	phone2: string | null;
	email: string | null;
}

export const ContactBlock: FC<ContactBlockProps> = ({ phone, phone2, email }: any) => {
	return (
		<div className="flex flex-row gap-3">
			{phone || email ? (
				<div className="flex flex-col gap-3 w-full">
					<div className="flex flex-row gap-3 items-center w-full justify-between">
						{phone && (
							<a href={`tel:${phone}`} target="__blank" className="flex-1">
								<Button
									tabIndex="-1"
									size="s"
									color="white"
									outline
									type="tel"
									className="flex !text-white w-full"
								>
									{formatPhoneNumber(phone)}
								</Button>
							</a>
						)}
						{email && (
							<button
								className="flex"
								onClick={(e) => {
									e.preventDefault();
									window.location.href = `mailto:${email}`;
								}}
								type="button"
								aria-label="mail"
							>
								<Picto icon="mail" className="text-primary-300 w-12 h-5" />
							</button>
						)}
					</div>
					{phone2 && phone && (
						<a href={`tel:${phone}`} target="__blank">
							<Button
								tabIndex="-1"
								size="s"
								color="white"
								outline
								type="tel"
								className="flex !text-white w-full"
							>
								{formatPhoneNumber(phone2)}
							</Button>
						</a>
					)}
				</div>
			) : phone === null || email === null ? null : (
				<div className="">
					<Skeleton
						className=""
						baseColor="var(--color-neutral-700)"
						highlightColor="var(--color-neutral-600)"
						height={30}
					/>
				</div>
			)}
		</div>
	);
};
