// Style
import React, { FC } from "react";

import style from "./WideLoader.module.css";

interface IToastProps {
	message: string | React.ReactNode;
}

export const WideLoader: FC<IToastProps> = ({ message }) => (
	<div className={style.wideLoader}>
		<div className={style.wideLoaderContent}>
			<img
				className={style.wideLoaderIcon}
				src="/circle-loader.png"
				width="75"
				height="auto"
				alt="circle-loader"
			/>
			<div className={style.wideLoaderMessage}>{message}</div>
		</div>
	</div>
);
