import { FC } from "react";

import { t } from "i18next";

import { WithSideMenu, WithTopBarre } from "src/components/templates";

interface IPage403Props {
	text?: string;
}

const Page403: FC<IPage403Props> = ({ text = t("403.message") }) => {
	return (
		<WithSideMenu>
			<WithTopBarre quickEntryContent={<div>test</div>} title={""} containerClassname="container">
				{text}
			</WithTopBarre>{" "}
		</WithSideMenu>
	);
};

export default Page403;
