// @ts-nocheck

/* eslint-disable max-len */

/* eslint-disable react/jsx-no-useless-fragment */
import { useState } from "react";

import { Form, Formik } from "formik";
import { Trans, useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useMutation } from "react-query";

// Service
import { putOrderProfileNotes } from "../../../../../services/commandService";

// Store
import { useOrderProfilesContext } from "../../../../../store/OrderProfilesContext";
import useModalContentProfileCardDetails from "./hooks/useModalContentProfileCardDetails";

// Constants
import { ORDER_PROFILES_ERRORS } from "../../../../../constants";
import cn from "../../../../../utils/cn";
import formatPhoneNumber from "../../../../../utils/formatPhoneNumber";
import { Button, InfoMessage, ModalV2, Picto, Tag, Text, Textarea } from "@zolteam/react-ras-library";
// Components
import { NavigationTabs } from "src/components/molecules";

// Style
import style from "./ModalContentProfileCardDetails.module.css";

const ModalContentProfileCardDetails = ({ profile, areNotesEditingLoading, setNotesInitialValues }) => {
	const { t } = useTranslation();
	const {
		isFetching,
		firstName,
		lastName,
		avatarUrl,
		channelPreference,
		phone,
		email,
		isUnifiedTempWorkerValidated,
		notesAreConflicting,
		isEditingNote,
		isNoteSuccessDisplayed,
		isVip,
		isCdiInterim,
		tabs,
		resetNotesForm,
		dirtyNotes,
		isActivityTabActive,
		setActiveTab,
		activeTab,
		handleScroll,
		notesAreFetched,
		notesAreFetching,
		isNotesTabActive,
		isCancelModalOpen,
		setIsCancelModalOpen,
		dispatchActivityBasedOnType,
		activitesAreFetched,
		concatenatedActivities,
		isFetchingNextPage,
	} = useModalContentProfileCardDetails(profile, setNotesInitialValues);

	return (
		<div className={style.content}>
			<div className={style.header}>
				{isFetching ? (
					<Skeleton containerClassName={style.skeletonCase} height={60} width={60} borderRadius={50} />
				) : (
					<div className={style.avatarBox}>
						{avatarUrl ? (
							<img className={style.avatar} src={avatarUrl} alt="user profil" />
						) : (
							<div className={style.avatarPlaceholder}>
								<Picto icon="userDelete" />
							</div>
						)}
						{isVip && (
							<span>
								<Picto className={style.vipPicto} icon="vip" />
							</span>
						)}
					</div>
				)}
				{isFetching ? (
					<Skeleton containerClassName={style.skeletonModel} height={50} borderRadius={25} width={250} />
				) : (
					<>
						<p className={style.name}>{firstName}</p>
						<p className={style.name}>{lastName}</p>
						{isCdiInterim && (
							<Tag className={style.profilAttribute} color="primaryLight">
								CDII
							</Tag>
						)}
					</>
				)}
			</div>
			<div className={style.body}>
				{isFetching ? (
					<Skeleton containerClassName={style.skeletonLeftPart} borderRadius={25} />
				) : (
					<div className={style.leftPart}>
						<div>
							<div className={style.channelPreferenceBox}>
								<span className={style.channelPreference}>
									{t(
										"commands.profiles.modalsContent.profileDetail.activity.preferredCommunicationChannel",
										{
											communicationMedium: channelPreference || "non défini",
										}
									)}
								</span>
							</div>
						</div>
						{channelPreference === "sms" ? (
							<span className={style.phone}>{formatPhoneNumber(`+33${phone.slice(1)}` || "")}</span>
						) : null}
						{channelPreference === "email" ? <span className={style.phone}>{email || ""}</span> : null}
						{isUnifiedTempWorkerValidated ? (
							<div className={style.validatedRas}>
								<div className={style.logoMyRas}>
									<Picto icon="logoMyRasAlt" className={style.myRasLogo} />
								</div>
								<span>{t("commands.profiles.modalsContent.profileDetail.activity.verified")} </span>
							</div>
						) : null}
					</div>
				)}

				{isFetching ? (
					<Skeleton containerClassName={style.skeletonRightPart} borderRadius={25} />
				) : (
					<div className={style.rightPart}>
						<div className={style.navigationTab}>
							<NavigationTabs tabs={tabs} onTabChange={setActiveTab} activeTab={activeTab} />
						</div>
						{isNoteSuccessDisplayed && (
							<div className={style.successMessage}>
								<InfoMessage withIcon color="success">
									<span className={style.successMessageContent}>
										{t("commands.profiles.modalsContent.profileDetail.notes.success")}
									</span>
								</InfoMessage>
							</div>
						)}
						{notesAreConflicting && (
							<div className={style.notesConflict}>
								<InfoMessage withIcon color="error">
									<Trans
										i18nKey="commands.profiles.modalsContent.profileDetail.notes.versionMismatch"
										components={{
											button: (
												<button
													className={style.infoLink}
													aria-label="rafraîchir"
													type="button"
													onClick={() => {
														dispatchOrderProfiles({
															type: "SET_NOTES_ARE_CONFLICTING",
															payload: false,
														});
														queryClient.invalidateQueries([
															"getOrderProfileNotes",
															orderProfileId,
														]);
													}}
												/>
											),
										}}
									/>
								</InfoMessage>
							</div>
						)}
						{isActivityTabActive && (
							<div className={style.activitiesBox} onScroll={handleScroll}>
								{activitesAreFetched ? (
									<div>
										{concatenatedActivities?.map((activity, index) =>
											dispatchActivityBasedOnType(activity, index)
										)}
										{isFetchingNextPage && (
											<div className={style.loadingBox}>
												<span className={style.loader} />
											</div>
										)}
									</div>
								) : (
									<div>
										<div className={style.skeletonActivityItem}>
											<Skeleton
												containerClassName={style.skeletonFullWidth}
												height={50}
												borderRadius={25}
											/>
											<Skeleton
												containerClassName={style.skeletonFullWidth}
												height={15}
												borderRadius={10}
												width={100}
											/>
										</div>
										<div className={style.skeletonActivityItem}>
											<Skeleton
												containerClassName={style.skeletonFullWidth}
												height={50}
												borderRadius={25}
											/>
											<Skeleton
												containerClassName={style.skeletonFullWidth}
												height={15}
												borderRadius={10}
												width={100}
											/>
										</div>
										<div className={style.skeletonActivityItem}>
											<Skeleton
												containerClassName={style.skeletonFullWidth}
												height={50}
												borderRadius={25}
											/>
											<Skeleton
												containerClassName={style.skeletonFullWidth}
												height={15}
												borderRadius={10}
												width={100}
											/>
										</div>
									</div>
								)}
							</div>
						)}
						{isNotesTabActive && (
							<>
								{!notesAreFetched || notesAreFetching ? (
									<Skeleton
										containerClassName={cn([style.skeletonFullWidth, style.skeletonFullHeight])}
										borderRadius={25}
									/>
								) : (
									<>
										<Textarea
											className={cn([
												style.notesTextarea,
												isEditingNote ? style.notesTextareaEditing : null,
											])}
											disabled={!isEditingNote}
											name="notes"
											placeholder={t(
												"commands.profiles.modalsContent.profileDetail.notes.placeholder"
											)}
											shouldDisplayErrorMessage={false}
										/>
										<div className={style.notesActions}>
											{areNotesEditingLoading ? (
												<img
													className={style.noteLoaderIcon}
													src="/circle-loader.png"
													alt="circle-loader"
												/>
											) : (
												<>
													{isEditingNote ? (
														<span className={style.submitGroup}>
															<Button
																onClick={(e) => {
																	setIsCancelModalOpen(true);
																	e.preventDefault();
																}}
																type="button"
																color="primary"
																outline
															>
																{t(
																	"commands.profiles.modalsContent.profileDetail.notes.cancel"
																)}
															</Button>
															<Button
																disabled={!dirtyNotes}
																type="submit"
																color="primary"
															>
																{t(
																	"commands.profiles.modalsContent.profileDetail.notes.save"
																)}
															</Button>
														</span>
													) : (
														<Button
															color="primary"
															onClick={() => {
																dispatchOrderProfiles({
																	type: "SET_IS_EDITING_NOTE",
																	payload: true,
																});
																dispatchOrderProfiles({
																	type: "SET_NOTE_SUCCESS_DISPLAYED",
																	payload: false,
																});

																setTimeout(() => {
																	const textarea =
																		document.querySelector(
																			'textarea[name="notes"]'
																		);
																	if (textarea) {
																		textarea.focus();
																	}
																}, 50);
															}}
														>
															{t(
																"commands.profiles.modalsContent.profileDetail.notes.edit"
															)}
														</Button>
													)}
												</>
											)}
										</div>
									</>
								)}
							</>
						)}
					</div>
				)}
			</div>
			<ModalV2
				isDisplayed={isCancelModalOpen}
				onClose={() => setIsCancelModalOpen(false)}
				size="s"
				title={t("commands.profiles.modalsContent.profileDetail.notes.cancelModal.title")}
			>
				<div>
					<Text tag="p" size="paragraph01" lineHeight="l" className={style.modalText}>
						{t("commands.profiles.modalsContent.profileDetail.notes.cancelModal.message")}
					</Text>
					<hr className={style.separator} />
					<div className={style.modalButton}>
						<Button onClick={() => setIsCancelModalOpen(false)} color="primary" outline>
							{t("commands.profiles.modalsContent.profileDetail.notes.cancelModal.cancel")}
						</Button>
						<Button
							onClick={() => {
								dispatchOrderProfiles({ type: "SET_IS_EDITING_NOTE", payload: false });
								setIsCancelModalOpen(false);
								resetNotesForm();
								queryClient.invalidateQueries(["getOrderProfileNotes", orderProfileId]);
							}}
							color="primary"
						>
							{t("commands.profiles.modalsContent.profileDetail.notes.cancelModal.confirm")}
						</Button>
					</div>
				</div>
			</ModalV2>
		</div>
	);
};

const ModalContentProfileCardDetailsForm = ({ profile }) => {
	const [, dispatchOrderProfiles] = useOrderProfilesContext();

	const [notesInitialValues, setNotesInitialValues] = useState({ notes: "", notesVersion: "" });

	const { mutate: putOrderProfileNotesMutate, isLoading: areNotesEditingLoading } = useMutation(
		(payload) => {
			const transformedData = {
				notes: payload.data.notes || "",
				notesVersion: payload.data.notesVersion,
			};

			return putOrderProfileNotes(payload.orderProfileId, transformedData);
		},
		{
			onSuccess: (success) => {
				dispatchOrderProfiles({ type: "SET_NOTES_ARE_CONFLICTING", payload: false });
				dispatchOrderProfiles({ type: "SET_NOTE_SUCCESS_DISPLAYED", payload: true });
				setTimeout(() => {
					dispatchOrderProfiles({ type: "SET_NOTE_SUCCESS_DISPLAYED", payload: false });
				}, 3000);
				if (success.data) setNotesInitialValues(success.data);
			},
			onError: (error) => {
				if (
					error.response.status === 409 &&
					error.response.data?.message === ORDER_PROFILES_ERRORS.ERR_CO_ORDER_PROFILE_NOTES_CONFLICT
				) {
					dispatchOrderProfiles({ type: "SET_NOTES_ARE_CONFLICTING", payload: true });
				}
			},
		}
	);

	return (
		<Formik
			initialValues={notesInitialValues}
			enableReinitialize
			onSubmit={(values) => {
				dispatchOrderProfiles({ type: "SET_IS_EDITING_NOTE", payload: false });
				putOrderProfileNotesMutate({
					orderProfileId: profile.orderProfileId,
					data: {
						notes: values.notes,
						notesVersion: values.notesVersion,
					},
				});
			}}
		>
			{() => (
				<Form>
					<ModalContentProfileCardDetails
						profile={profile}
						areNotesEditingLoading={areNotesEditingLoading}
						setNotesInitialValues={setNotesInitialValues}
					/>
				</Form>
			)}
		</Formik>
	);
};

export default ModalContentProfileCardDetailsForm;
