import { t } from "i18next";

import { Tag, Text } from "@zolteam/react-ras-library";
import { ColumnListing, TableInfo } from "src/components/molecules";

import { formatDate } from "src/utils/formatDate";

import { UTW_DOC_STATUS } from "src/constants/CUnifiedTemporaryWorker";

const DocumentsColumns = () => {
	return [
		<ColumnListing
			key="type"
			id="type"
			name={
				<Text tag="span" size="paragraph02">
					{t("usersListing.type")}
				</Text>
			}
			field={(elem) => ({ type: elem.type })}
			component={({ data: { type } }) => (
				<Text tag="span" size="paragraph02" fontWeight="bold">
					{t(`usersListing.temporaryWorker.documents.types.${type}`)}
				</Text>
			)}
		/>,
		<ColumnListing
			key="name"
			id="name"
			name={
				<Text tag="span" size="paragraph02">
					{t("usersListing.name")}
				</Text>
			}
			field={(elem) => ({ name: elem.name, reference: elem.reference })}
			component={({ data: { name, reference } }) => (
				<TableInfo info={name} subInfo={reference || null} className="min-w-36" />
			)}
		/>,
		<ColumnListing
			key="deliveryDate"
			id="deliveryDate"
			name={
				<Text tag="span" size="paragraph02">
					{t("usersListing.deliveryDate")}
				</Text>
			}
			field={(elem) => ({ deliveryDate: elem.deliveryDate })}
			component={({ data: { deliveryDate } }) => {
				return <TableInfo info={deliveryDate ? formatDate(deliveryDate) : ""} className="min-w-28" />;
			}}
		/>,

		<ColumnListing
			key="expirationDate"
			id="expirationDate"
			name={
				<Text tag="span" size="paragraph02">
					{t("usersListing.expirationDate")}
				</Text>
			}
			field={(elem) => ({ expirationDate: elem.expirationDate })}
			component={({ data: { expirationDate } }) => {
				return <TableInfo info={expirationDate ? formatDate(expirationDate) : ""} className="min-w-28" />;
			}}
		/>,

		<ColumnListing
			key="status"
			id="status"
			name={
				<Text tag="span" size="paragraph02">
					{t("usersListing.status")}
				</Text>
			}
			field={(elem) => ({
				status: elem.status,
			})}
			component={({ data: { status } }) => {
				return (
					<Text
						tag="div"
						size="paragraph02"
						className={"flex flex-row gap-3 items-center justify-start whitespace-nowrap w-full min-w-32"}
					>
						{status && UTW_DOC_STATUS[status] && (
							<Tag
								color={UTW_DOC_STATUS[status]?.color}
								size={"m"}
								className={UTW_DOC_STATUS[status]?.className}
							>
								{t(`usersListing.temporaryWorker.documents.status.${UTW_DOC_STATUS[status]?.label}`)}
							</Tag>
						)}
					</Text>
				);
			}}
		/>,
	];
};

export default DocumentsColumns;
