import client from "./client";

/**
 * @description Post search activity logs user
 * @param {Object} search
 * @returns {Promise<AxiosResponse<any>>}
 */
const postActivityLogsSearch = (userId: number, search: object) =>
	client.post(`api-client-v2/activity/logs/worker/${userId}`, search);

const ActivityLogsService = {
	postActivityLogsSearch,
};

export default ActivityLogsService;
