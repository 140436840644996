import { FC, useRef, useState } from "react";

import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { NavLink, useHistory, useLocation, useParams } from "react-router-dom";
import { TApiError } from "src/types/TErrors";
import { TTemporaryWorker, TTemporaryWorkerAgency, TTemporaryWorkerProfile } from "src/types/TTemporaryWorker";

import UserTemporaryWorkerService from "src/services/userTemporaryWorkerService";

import Administrative from "./Administrative/Administrative";
import Documents from "./Documents/Documents";
import Summary from "./Summary/Summary";
import { Picto, SelectStatus, Text } from "@zolteam/react-ras-library";
import { IdBox, MyScanPicto, Tag } from "src/components/atoms";
import { ContactBlock, SuspiciousModal } from "src/components/organisms";
import { SideMenuSecondary, WithSideMenu, WithTopBarre } from "src/components/templates";

interface ITemporaryWorkerSheetHeaderProps {
	user: TTemporaryWorkerProfile | null;
	isLoading?: boolean;
}

export type TParams = {
	userId: string;
};

const TemporaryWorkerSheetHeader: FC<ITemporaryWorkerSheetHeaderProps> = ({ user, isLoading }) => {
	const { t } = useTranslation();
	const agenciesList = Object.values(user?.agencies || {}).map((agency: TTemporaryWorkerAgency) => ({
		value: agency?.userId?.toString() || null,
		label: agency.agencyName,
	}));

	const history = useHistory();
	const location = useLocation();
	const userId = useParams<TParams>()?.userId;
	const handleAgencyChange = (agency: { value: string; label: string }) => {
		// Replace userId in the current path
		const currentPath = location.pathname.replace(/\/users\/\d+/, `/users/${agency.value}`);
		history.push(currentPath);
	};
	const agency = {
		value: user?.agencies && user.agencies[userId]?.userId ? user.agencies[userId].userId?.toString() : null,
		label: user?.agencies && user.agencies[userId]?.agencyName ? user.agencies[userId].agencyName : null,
	};

	return (
		<div className="flex lg:flex-col flex-wrap gap-8">
			<div className="flex flex-row lg:flex-col  gap-3 mr-4 lg:mr-0 max-lg:items-center max-lg:flex-wrap">
				<div className="flex flex-row justify-between items-center gap-3">
					<div className="flex-1">
						<IdBox isLoading={isLoading}>{user?.uniqueId || "-"}</IdBox>
					</div>
					<button className="w-full flex flex-1 justify-end">
						<MyScanPicto />
					</button>
				</div>

				{agenciesList.length > 1 ? (
					<SelectStatus
						options={(isLoading && []) || agenciesList || []}
						value={isLoading ? null : agency}
						onChange={(agency: { value: string; label: string }) => {
							handleAgencyChange(agency);
						}}
						noOptionsMessage={() => {
							return t("commands.selectNoAgenciesFound");
						}}
						placeholder={""}
						variant="default"
					/>
				) : (
					<Tag className="flex justify-start" children={<>{agenciesList[0]?.label}</>} />
				)}

				<div className="flex flex-row justify-between items-center gap-2">
					<IdBox isLoading={isLoading}>{user?.twStatus ? t(`twStatus.${user?.twStatus}`) : "-"}</IdBox>
					<IdBox isLoading={isLoading}>{user?.tempoId || "-"}</IdBox>
				</div>
			</div>

			<>
				{isLoading ? (
					<Skeleton
						className=""
						baseColor="var(--color-neutral-700)"
						highlightColor="var(--color-neutral-600)"
						height={50}
						width={"100%"}
					/>
				) : user ? (
					<div className="flex flex-row lg:flex-col gap-2 max-lg:text-neutral-700 max-lg:text-center max-lg:items-center max-lg:flex-wrap max-lg:mr-4 ">
						<h4 className="font-medium text-xl">
							{user.firstName} {user.lastName}
						</h4>
						<h5 className="text-l font-extralight">{user.mainQualificationName}</h5>
						<ContactBlock phone={user?.phone} phone2={user?.phone2} email={user?.email} />
					</div>
				) : (
					<>-</>
				)}
			</>
		</div>
	);
};

const TemporaryWorkerSheet: FC = () => {
	const { t } = useTranslation();
	const userId = useParams<TParams>()?.userId;
	const uniqueId = useRef(null);
	const [suspiciousUTW, setSuspiciousUTW] = useState<
		| (Partial<Omit<TTemporaryWorker, "unifiedTempWorkerIdOnSuspicion">> & {
				unifiedTempWorkerIdOnSuspicion: number;
		  })
		| null
	>(null);

	const getTemporaryWorker = useQuery({
		queryKey: ["getUser", userId],
		queryFn: () =>
			UserTemporaryWorkerService.getUserTemporaryWorker(userId).then((res) => {
				uniqueId.current = res?.data?.uniqueId;
				return res;
			}),
		onError: (error: AxiosError<TApiError>) => {
			if (error.status === 422) {
				const suspiciousUTWId = error.response?.data?.errors?.unifiedTempWorkerIdOnSuspicion;
				setSuspiciousUTW({ unifiedTempWorkerIdOnSuspicion: suspiciousUTWId });
			}
		},
	});

	const { isFetching: isFetchingGetTempWorker, data: dataTempWorker, error } = getTemporaryWorker;

	const routes = [
		{
			path: `/users/:userId/synthese`,
			routeName: "synthese",
			label: t("usersListing.menu.summary"),
			component: <Summary user={error ? null : dataTempWorker?.data || null} />,
		},
		{
			path: `/users/:userId/documents`,
			routeName: "documents",
			label: t("usersListing.menu.documents"),
			component: <Documents uniqueId={uniqueId.current} />,
			permissions: ["digital.division"],
		},
		{
			path: `/users/:userId/administrative`,
			routeName: "administrative",
			label: t("usersListing.menu.administrative"),
			component: <Administrative uniqueId={uniqueId.current} />,
			permissions: ["digital.division"],
		},
		{
			path: `/users/:userId/profil-professionnel`,
			routeName: "profil-professionnel",
			label: t("usersListing.menu.profilPro"),
			component: <div>Profil pro</div>,
		},
		{
			path: `/users/:userId/missions`,
			routeName: "missions",
			label: t("usersListing.menu.missions"),
			component: <div>missions</div>,
		},
		{
			path: `/users/:userId/paie`,
			routeName: "pay",
			label: t("usersListing.menu.pay"),
			component: <div>pay</div>,
		},
		{
			path: `/users/:userId/demat`,
			routeName: "demat",
			label: t("usersListing.menu.demat"),
			component: <div>demat</div>,
		},
		{
			path: `/users/:userId/communication`,
			routeName: "communication",
			label: t("usersListing.menu.communication"),
			component: <div>communication</div>,
		},
		{
			path: `/users/:userId/formation`,
			routeName: "training",
			label: t("usersListing.menu.training"),
			component: <div>training</div>,
		},
		{
			path: `/users/:userId/accidents`,
			routeName: "workAccident",
			label: t("usersListing.menu.workAccident"),
			component: <div>workAccident</div>,
		},
	];

	return (
		<>
			<WithSideMenu>
				<WithTopBarre
					quickEntryContent={<></>}
					leftContent={
						<div style={{ display: "flex" }}>
							<NavLink
								to={"/users"}
								className={"flex flex-row items-center gap-1"}
								onKeyDown={(e) => {
									if (e.key === " " || e.key === "Enter") {
										e.preventDefault();
										(e.target as HTMLElement).click();
									}
								}}
							>
								<Picto style={{ width: "24px" }} icon="chevronLeft" />
								<Text fontWeight="normal" tag="span">
									{t("usersListing.allUsersLink")}
								</Text>
							</NavLink>
						</div>
					}
					containerClassname="flex flex-row"
				>
					<SideMenuSecondary
						routes={routes?.map((route) => {
							return {
								...route,
								navPath: route.path.replace(":userId", userId),
							};
						})}
						header={
							<TemporaryWorkerSheetHeader
								user={error ? null : dataTempWorker?.data || null}
								isLoading={isFetchingGetTempWorker}
							/>
						}
						className="max-lg:h-fit max-lg:flex-wrap max-lg:pl-0"
						classNamePanel="overflow-y-auto overflow-x-hidden flex"
						classNameHeader="max-lg:overflow-auto lg:block"
						classNameMenuItem="whitespace-nowrap"
					/>
				</WithTopBarre>
				<SuspiciousModal suspiciousUTW={suspiciousUTW} setSuspiciousUTW={setSuspiciousUTW} />
			</WithSideMenu>
		</>
	);
};

export default TemporaryWorkerSheet;
