import { FC, PropsWithChildren, ReactNode, useState } from "react";

import cn from "../../../utils/cn";
import { ModalV2 } from "@zolteam/react-ras-library";

// Style
import style from "./WithTopBarre.module.css";

interface IWithTopBarreProps extends PropsWithChildren {
	leftContent?: ReactNode;
	rightContent?: ReactNode;
	containerClassname?: string;
	quickEntryContent?: ReactNode;
	title?: ReactNode;
}

export const WithTopBarre: FC<IWithTopBarreProps> = ({
	children,
	leftContent,
	rightContent,
	quickEntryContent,
	title,
	containerClassname,
}) => {
	// Will add hook to use the global search
	const [isQuickEntryOpen, setIsQuickEntryOpen] = useState(false);

	return (
		<div className={style.withTopBarreWrapper}>
			{leftContent || rightContent ? (
				<header className={style.topBarre}>
					{leftContent && <div className={style.leftContent}>{leftContent || null}</div>}
					<div className={style.rightContent}>{rightContent || null}</div>
				</header>
			) : null}
			{title ? <div className={style.pageTitle}>{title}</div> : null}
			<main className={cn([style.withTopBarreContent, containerClassname])}>{children}</main>

			<ModalV2 isDisplayed={isQuickEntryOpen} onClose={() => setIsQuickEntryOpen(false)} size="m">
				{quickEntryContent}
			</ModalV2>
		</div>
	);
};
