// @ts-nocheck
import { useMemo } from "react";

import { NavLink, Redirect, Route, Switch, useParams } from "react-router-dom";

// Utils
import cn from "../../../utils/cn";
// Views
import ProfileMissionsClientRelationships from "./ProfileMissionsClientRelationships";
import ProfileMissionsContracts from "./ProfileMissionsContracts";
import ProfileMissionsFollowUp from "./ProfileMissionsFollowUp";
import ProfileMissionsOrders from "./ProfileMissionsOrders";

import style from "./ProfileMissions.module.css";

const ProfileMissions = () => {
	const { profileId } = useParams();

	const routes = useMemo(
		() => [
			{
				path: "/profile/:profileId/missions/orders",
				routeName: "profile-missions-orders",
				label: "Commandes",
				component: <ProfileMissionsOrders />,
				enabled: true,
			},
			{
				path: "/profile/:profileId/missions/follow-up",
				routeName: "profile-missions-follow-up",
				label: "Suivis de mission",
				component: <ProfileMissionsFollowUp />,
				enabled: true,
			},
			{
				path: "/profile/:profileId/missions/client-relationship",
				routeName: "profile-missions-client-relationship",
				label: "Relations clients",
				component: <ProfileMissionsClientRelationships />,
				enabled: true,
			},
			{
				path: "/profile/:profileId/missions/contracts",
				routeName: "profile-missions-contracts",
				label: "Contrats",
				component: <ProfileMissionsContracts />,
				enabled: true,
			},
		],
		[]
	);

	return (
		<div className={style.profileMissions}>
			<div className={style.menu}>
				{routes.map((route) => (
					<NavLink
						key={route.path}
						className={cn([style.menuItem, !route.enabled ? style.routeDisabled : null])}
						to={route.path.replace(":profileId", profileId)}
						activeClassName={style.selected}
					>
						<button type="button" key={route.path} className={style.menuItemBtn}>
							{route.label}
						</button>
					</NavLink>
				))}
			</div>
			<div className={style.content}>
				<Switch>
					<Route exact path="/profile/:profileId/missions">
						<Redirect to={`/profile/${profileId}/missions/orders`} />
					</Route>
					{routes.map((route) => (
						<Route key={route.path} exact path={`${route.path}`}>
							{route.component}
						</Route>
					))}
				</Switch>
			</div>
		</div>
	);
};

ProfileMissions.propTypes = {};

export default ProfileMissions;
