import { useEffect, useRef, useState } from "react";

const useElementOnScreen = (options: IntersectionObserverInit, callBack?: (visible: boolean) => void) => {
	const container = useRef<HTMLDivElement | null>(null);
	const [isVisible, setIsVisible] = useState(false);

	const observerCallback = (entries: IntersectionObserverEntry[]) => {
		const [entry] = entries;
		setIsVisible(entry.isIntersecting);
		callBack && callBack?.(entry.isIntersecting);
	};

	useEffect(() => {
		if (container.current) {
			const observer = new IntersectionObserver(observerCallback, options);
			observer.observe(container.current);

			return () => {
				observer?.disconnect();
			};
		}
	}, [container, options]);

	return { container, isVisible };
};

export default useElementOnScreen;
