import { TNotificationsFilters } from "src/types/TNotifications";
import { TUsersListingFilters } from "src/types/TUsersListing";

import { DEFAULT_NOTIFICATIONS_LIST_FILTERS } from "./CNotifications";
import { DEFAULT_USERS_LISTING_FILTERS } from "./CUsersListing";

type TDefaultFilters = Record<string, TNotificationsFilters | TUsersListingFilters>;

export const DEFAULT_FILTERS: TDefaultFilters = {
	notifications: DEFAULT_NOTIFICATIONS_LIST_FILTERS,
	users: DEFAULT_USERS_LISTING_FILTERS,
};
