import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import getTextWidth from "../../../utils/getTextWidth";
import { checkSuspiciousValue } from "../../../utils/suspiciousSheetUtils";
import { CheckPicto } from "../CheckPicto/CheckPicto";
import { Picto, Text, Tooltip } from "@zolteam/react-ras-library";

import style from "./CheckableInput.module.css";

interface ICheckableInputProps {
	id: string;
	disabled?: boolean;
	isCheckable?: boolean;
	textValue: string | number;
	value: boolean;
	placeholder: string;
	borderColor?: "primary" | "grey";
	initialTextValue: string | number;
	hoverPlaceholder?: string;
	onChange: (value: boolean) => void;
}

export const CheckableInput: React.FC<ICheckableInputProps> = ({
	id,
	disabled = false,
	isCheckable = true,
	textValue = "",
	value,
	placeholder,
	onChange,
	borderColor = "primary",
	initialTextValue = "",
	hoverPlaceholder = "",
}) => {
	const { t } = useTranslation();
	const [isHover, setIsHover] = useState(false);
	const [displayTooltip, setDisplayTooltip] = useState(false);

	const tooltipDissociateStyle = {
		maxWidth: "inherit",
		whiteSpace: "nowrap",
		padding: "var(--padding-s) var(--padding-m)",
	};

	const handleMouseEnter = () => {
		if (checkSuspiciousValue(initialTextValue)) {
			setIsHover(true);
		}
	};

	const handleMouseLeave = () => {
		setIsHover(false);
	};

	useEffect(() => {
		const elemWidth = getTextWidth(String(textValue), "16px DM sans", 0);
		if (elemWidth >= 244) setDisplayTooltip(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Tooltip
			placement="top"
			animation={false}
			arrow={false}
			onShow={() => !isCheckable}
			content={
				<Text tag="span" size="paragraph02" color="grey">
					{t("suspiciousSheet.isNotCheckable")}
				</Text>
			}
		>
			<div
				data-testid={id}
				onMouseLeave={handleMouseLeave}
				onMouseEnter={handleMouseEnter}
				className={`
          ${borderColor === "primary" ? style.primaryBorder : style.greyBorder} 
          ${value && !disabled && !isHover && isCheckable ? style.greenBorder : ""}
          ${disabled || isHover ? style.disabled : style.normal}
          ${!isCheckable && style.isNotCheckable}
        `}
			>
				<div className={style.textContainer}>
					<p className={checkSuspiciousValue(textValue) ? style.placeholder : style.placeholderNoValue}>
						{isHover ? hoverPlaceholder : placeholder}
					</p>
					{displayTooltip ? (
						<Tooltip
							placement="top"
							animation={false}
							arrow={false}
							tooltipBoxStyle={tooltipDissociateStyle}
							content={
								<Text tag="span" size="paragraph02" color="grey">
									{isHover ? initialTextValue : textValue}
								</Text>
							}
						>
							<p className={style.inputValue}>{isHover ? initialTextValue : textValue}</p>
						</Tooltip>
					) : (
						<p className={style.inputValue}>{isHover ? initialTextValue : textValue}</p>
					)}
				</div>
				{disabled || isHover || !isCheckable ? null : (
					<button
						data-testid={`${id}-button`}
						type="button"
						className={style.checkboxContainer}
						onClick={() => onChange(!value)}
					>
						{value ? <CheckPicto customClass="green-checkbox" /> : <span className={style.emptyCheckbox} />}
					</button>
				)}
				{isHover ? <Picto icon="info" className={style.infoPicto} /> : null}
			</div>
		</Tooltip>
	);
};
