import { FC, useState } from "react";

import { useFormikContext } from "formik";
import { t } from "i18next";

import { TAdministrativeFormValues } from "./Administrative";
import { Select } from "@zolteam/react-ras-library";
import { Field } from "src/components/molecules";

import { cntm } from "src/utils/cntm";

import { familySituationsArray } from "src/constants/CUnifiedTemporaryWorker";

import style from "./Administative.module.css";

interface ISocialSecurityInformationsProps {
	disabled?: boolean;
	name?: string;
	required?: boolean;
}

type TOptions = {
	label: string;
	value: number;
};

const FamilySituationInformations: FC<ISocialSecurityInformationsProps> = ({ disabled, name, required }) => {
	const { values, setFieldValue } = useFormikContext<TAdministrativeFormValues>();
	const [isFocused, setIsFocused] = useState<boolean>(false);

	return (
		<div className={cntm([disabled && "cursor-not-allowed", "w-full"])}>
			<div className="relative flex flex-col items-start justify-start gap-4">
				<div className={cntm(["flex flex-col gap-2 w-full", disabled && "pointer-events-none opacity-70"])}>
					<h2 className="text-xl font-medium">
						{t("usersListing.temporaryWorker.administrative.familySituation")}
					</h2>
					<div className="flex flex-col gap-2 md:flex-row [&>div]:w-full lg:[&>div]:w-[calc(25%-0.25rem)] xl:[&>div]:w-[16.5%]">
						<Select
							isSearchable={false}
							defaultOptions
							disabled={disabled}
							className={"flex-1 " + style.fields}
							options={familySituationsArray}
							showIndicator={!disabled}
							onChange={(value: TOptions) => {
								return setFieldValue(name || "familySituation", value.value);
							}}
							value={familySituationsArray.find((fs) => fs.value === values?.familySituation)}
							name={name || "familySituation"}
							label={t("usersListing.temporaryWorker.administrative.situation") + (required ? "*" : "")}
							getOptionLabel={(option: TOptions) => t(`global.familySituation.${option.label}`)}
							getOptionValue={(option: TOptions) => option.value}
							noOptionsMessage={() => t("search.noResultBis")}
							displayFormikError={false}
						/>
						<Field
							label={t("usersListing.temporaryWorker.administrative.children")}
							name="childrenQuantity"
							type="number"
							min="0"
							maxLength={99}
							max={99}
							value={values?.childrenQuantity || (isFocused ? null : 0)}
							onFocus={(e) => {
								if (!values?.childrenQuantity) {
									e.target.value = "";
									setIsFocused(true);
								}
							}}
							onBlur={(e) => {
								if (!values?.childrenQuantity) {
									e.target.value = "0";
									setIsFocused(false);
								}
							}}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
								if (parseInt(e.target.value) > 99) {
									e.preventDefault();
									return;
								} else {
									setIsFocused(false);
									setFieldValue("childrenQuantity", parseInt(e.target.value));
								}
							}}
							customErrorDisplay
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FamilySituationInformations;
