import { useState } from "react";

interface UseSelectableProps<T> {
	targetField?: keyof T;
}

const useSelectable = <T extends Record<string, any>>({ targetField = "id" }: UseSelectableProps<T>) => {
	const [selectedItems, setSelectedItems] = useState<T[typeof targetField][]>([]);

	const updateSelectionAfterFilter = (filteredData: T[]) => {
		setSelectedItems((oldSelectedItems) =>
			oldSelectedItems.filter((selectedItem) => filteredData.some((item) => item[targetField] === selectedItem))
		);
	};

	const toggleSelected = (item: T) => {
		const id = item[targetField];
		setSelectedItems((oldValue) =>
			oldValue.includes(id) ? oldValue.filter((elem) => elem !== id) : [...oldValue, id]
		);
	};

	const singleSelect = (item: T) => {
		const id = item[targetField];
		setSelectedItems([id]);
	};

	const selectAll = (data: T[]) => {
		let matchedValues = selectedItems.filter((selectedItem) => data.some((item) => selectedItem === item.id));
		if (matchedValues.length === 0) {
			setSelectedItems((prevItems) => [...prevItems, ...data.map((elem) => elem[targetField])]);
		}
		if (matchedValues.length !== 0) {
			setSelectedItems((prevItems) =>
				prevItems.filter((item) => !data.some((elem) => elem[targetField] === item))
			);
		}
	};

	const selectedValuesStatus = (data: T[]): "false" | "true" | "conflict" => {
		const matchedCount = selectedItems.filter((selectedItem) =>
			data.some((item) => selectedItem === item.id)
		).length;

		if (matchedCount === 0) return "false";
		if (matchedCount === data.length) return "true";
		return "conflict";
	};

	const clearSelection = () => {
		setSelectedItems([]);
	};

	const totalItemsSelected = selectedItems.length;

	return {
		selectedItems,
		toggleSelected,
		selectAll,
		selectedValuesStatus,
		totalItemsSelected,
		updateSelectionAfterFilter,
		singleSelect,
		clearSelection,
	};
};

export default useSelectable;
