import { useHistory } from "react-router-dom";

// Constants
import { ORDER_CREATION_MODE } from "src/constants";

const useCommandsMoreOptions = () => {
	const history = useHistory();

	const handleEditCommand = (e: React.SyntheticEvent, commandId: string) => {
		e.preventDefault();
		history.push(`/commandes/${commandId}/configuration`);
	};

	const handleDuplicateCommand = (e: React.SyntheticEvent, commandId: string) => {
		e.preventDefault();
		history.push({
			pathname: `/commandes/create/configuration`,
			state: {
				mode: ORDER_CREATION_MODE.DUPLICATE,
				duplicatedOrderId: commandId,
			},
		});
	};

	return {
		handleEditCommand,
		handleDuplicateCommand,
	};
};

export default useCommandsMoreOptions;
