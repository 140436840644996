import React from "react";

import { useFormik } from "formik";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";

// Services
import getUnifiedTempWorkerInterviewAgencies from "src/services/commands/getUnifiedTempWorkerInterviewAgenciesService";
import { AgencyInterviewData } from "src/services/commands/getUnifiedTempWorkerInterviewAgenciesService";

import ModalContentCreateUserInterviewDataFormValidation from "./ModalContentCreateUserInterviewDataFormValidation";
import { ModalContentCreateUserInterviewDataInterface, FormValues } from "./ModalContentCreateUserInterviewType";
import { Button, Title, SelectV2 } from "@zolteam/react-ras-library";

const ModalContentCreateUserInterviewData: React.FC<ModalContentCreateUserInterviewDataInterface> = ({
	onConfirmClick,
	onCancelClick,
	unifiedTempWorkerId,
}) => {
	// Hooks
	const { t } = useTranslation();

	const { values, setFieldValue, handleSubmit, isValid } = useFormik<FormValues>({
		initialValues: {
			agency: null,
		},
		onSubmit: () => {},
		validateOnMount: true,
		validationSchema: ModalContentCreateUserInterviewDataFormValidation,
	});

	// Api call
	const { data, isLoading } = useQuery(["getUnifiedTempWorkerInterviewAgencies"], () =>
		getUnifiedTempWorkerInterviewAgencies(unifiedTempWorkerId)
	);

	const agencies: AgencyInterviewData[] = data?.data || [];

	const clientOptionsTemplate = (option: AgencyInterviewData) => {
		return (
			<div>
				<p>
					{option.agencyName.replace("RAS ", "")}{" "}
					<span className="text-[12.8px] leading-4 text-neutral-500">
						{t("matching.createUserInterviewDataModal.updatedAt", {
							date: moment(option.updatedAt).format("D MMM YYYY"),
							time: moment(option.updatedAt).format("HH[h]mm"),
						})}
					</span>
				</p>
			</div>
		);
	};

	return (
		<div className="-mt-5">
			<Title className="mb-10" tag="h1" size="heading01" lineHeight="l">
				{t("matching.createUserInterviewDataModal.title")}
			</Title>

			<div className="flex flex-col gap-8 mb-10">
				<p>{t("matching.createUserInterviewDataModal.infoMessage")}</p>
				<p>{t("matching.createUserInterviewDataModal.inforMessageComplement")}</p>
			</div>
			<form onSubmit={handleSubmit}>
				<div className="mb-10">
					{!isLoading ? (
						<SelectV2
							options={agencies}
							onChange={(option: AgencyInterviewData | null) => {
								setFieldValue("agency", option);
							}}
							name="agency"
							value={values.agency}
							label={t("matching.createUserInterviewDataModal.selectLabel")}
							placeholder={t("matching.createUserInterviewDataModal.selectLabel")}
							formatOptionLabel={(option: AgencyInterviewData) => clientOptionsTemplate(option)}
							getOptionLabel={(option: AgencyInterviewData) => option}
							getOptionValue={(option: AgencyInterviewData) => option.agencyId}
						/>
					) : (
						<Skeleton count={1} borderRadius={30} height={50} />
					)}
				</div>
				<hr className="mb-10 my-10 -mx-8 text-black opacity-10" />
				<div className="flex justify-end gap-5 w-full">
					<Button type="button" color="primary" onClick={onCancelClick} outline>
						{t("matching.createUserInterviewDataModal.cancelButtonMessage")}
					</Button>
					<Button
						type="button"
						color={!isValid ? "grey" : "primary"}
						disabled={!isValid}
						onClick={() => onConfirmClick(Number(values.agency.agencyId))}
					>
						{t("matching.createUserInterviewDataModal.validationButtonMessage")}
					</Button>
				</div>
			</form>
		</div>
	);
};

export default ModalContentCreateUserInterviewData;
