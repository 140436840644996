// @ts-nocheck
import { useCallback, useEffect, useRef, useState } from "react";

import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { components } from "react-select";

// Services
import { getClientDetails, getClientQualifications, getClients } from "src/services/commandService";
import { ClientInfo, ClientQualification } from "src/services/types/commandServiceTypes";

// Hooks
import { useConfigurationContext } from "src/store/ConfigurationContext";
import useDuplication from "src/views/Command/Configuration/hooks/useDuplication";

// Constants
import { COMMAND_TYPE_INTERIM, CREATE_MODE } from "../../../../../constants";
import {
	commandInterlocutorFormDefaultValues,
	commandQualificationFormDefaultValues,
	commandWorkAddressFormDefaultValues,
} from "../commandFormDefaultValues";
// Constants
// Misc
import { ModalV2, Picto, Select, SelectV2, Textarea, Title } from "@zolteam/react-ras-library";
// Components
import { DropdownIndicator } from "src/components/atoms";
// Components
import { OptionClient, OptionQualificationTempo, TooltipMessage } from "src/components/atoms";

// Style
import cn from "src/utils/cn";
// Constants
// Misc
import parseTextWithLineBreaks from "src/utils/parseTextWithLineBreaks";

import style from "./CommandClientFormBlock.module.css";

const CommandClientFormBlock = () => {
	// Hooks
	const { values, setValues, setFieldValue, errors, touched, handleBlur, setFieldTouched } = useFormikContext();
	const { agencyId, clientId, clientOption, qualification, type } = values;
	const [, dispatchConfiguration] = useConfigurationContext();
	const { t } = useTranslation();
	const { orderId } = useParams<{ orderId: string }>();
	const customClientWorkingHour = clientOption?.customWorkingHour || null;
	const [is247InstructionsModalOpen, setIs247InstructionsModalOpen] = useState(false);
	const [clients, setClients] = useState([]);
	const [totalClients, setTotalClients] = useState(0);
	const [searchLoading, setSearchLoading] = useState(false);
	const [userTyping, setUserTyping] = useState(false);

	const { isInDuplicationMode } = useDuplication();
	const isOrderInEdition = orderId !== CREATE_MODE;

	const MAX_CLIENTS = 100;
	const MINIMUM_SEARCH_LENGTH = 3;

	// API Call

	let timeoutId: ReturnType<typeof setTimeout>;

	const handleInputChange = useCallback(
		async (value: string | undefined): Promise<ClientInfo[]> => {
			clearTimeout(timeoutId);
			return new Promise((resolve) => {
				timeoutId = setTimeout(async () => {
					if (value && value.length >= MINIMUM_SEARCH_LENGTH) {
						try {
							setSearchLoading(true);
							setUserTyping(false);
							const response = await getClients(values?.agencyId, value);
							const clients: ClientInfo[] = response.data.items;

							setSearchLoading(false);
							setClients(clients);
							setTotalClients(response.data.total);
							resolve(clients);
						} catch (error) {
							resolve([]);
						}
					} else {
						resolve([]);
					}
				}, 300);
			});
		},
		[setClients, setTotalClients, setSearchLoading, setUserTyping, values?.agencyId]
	);

	const { data: clientQualifications } = useQuery(
		["getClientQualifications", agencyId, clientId],
		() => getClientQualifications(agencyId, clientId, ""),
		{
			select: ({ data }) =>
				data.map((qualification: ClientQualification) => ({
					...qualification,
					id: qualification.clientQualificationId,
				})),
			enabled: !!(agencyId && clientId),
		}
	);

	const { data: clientData, isFetched: isClientFetched } = useQuery<AxiosResponse<ClientInfo[]>, Error>(
		["getClientDetailsForOrder", clientId],
		() => getClientDetails(clientId),
		{
			enabled: !!clientId,
			select: ({ data }) => ({
				...data,
				value: data.id,
				label: data.name,
			}),
		}
	);

	const defaultNumberWorkersOptions = Array.from({ length: 500 }, (_, i) => ({
		value: i + 1,
		label: `${i + 1}`,
	}));

	const qualificationOptionsTemplate = (option: ClientQualification) => (
		<OptionQualificationTempo
			key={option.clientQualificationId}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...option}
			id={option?.clientQualificationId?.toString()}
		/>
	);

	const handleCustomWorkingHour = (selectedOption: ClientQualification) => {
		if (selectedOption?.specificTime) {
			return selectedOption?.specificTime;
		}
		if (customClientWorkingHour) {
			return customClientWorkingHour;
		}
		return null;
	};

	const handleSelectQualification = (selectedOption: ClientQualification) => {
		setFieldTouched("qualificationOption", true);
		const missionDescription = !isOrderInEdition ? selectedOption?.missionDescription : values.missionDescription;
		const particularWorkTime = !isOrderInEdition
			? handleCustomWorkingHour(selectedOption)
			: values.particularWorkTime;
		setValues({
			...values,
			qualificationOption: selectedOption || null,
			qualification: {
				...values.qualification,
				id: selectedOption?.clientQualificationId || "",
				name: selectedOption?.name || null,
				level: selectedOption?.level || null,
				deletedAt: null,
			},
			missionDescription,
			workingTime: {
				...values.workingTime,
				particularWorkTime,
			},
		});
	};

	const handleSelectClient = useCallback(
		(selectedClient: ClientInfo) => {
			setClients([selectedClient]);
			setFieldTouched("clientOption", true);
			if (selectedClient === null) {
				setClients([]);
			}
			const periodNotWorking = !isOrderInEdition ? selectedClient?.notWorkedPeriod : values.periodNotWorking;

			dispatchConfiguration({
				type: "SET_IS_CLIENT_EDITED",
				payload: true,
			});

			setValues({
				...values,
				clientOption: selectedClient || null,
				clientId: selectedClient?.id || null,
				// Reset form fields depending on clientId
				...commandInterlocutorFormDefaultValues,
				...commandQualificationFormDefaultValues,
				...commandWorkAddressFormDefaultValues,
				workingTime: {
					...values.workingTime,
					periodNotWorking,
				},
			});
		},
		[
			dispatchConfiguration,
			setValues,
			values,
			commandInterlocutorFormDefaultValues,
			commandQualificationFormDefaultValues,
			commandWorkAddressFormDefaultValues,
		]
	);

	const handleSelectQualificationNumberWorker = (selectedNumber: { value: number; label: string }) => {
		setFieldValue("qualification.workerRequestedCount", selectedNumber.value);
	};

	const CustomMenuList = (props) => {
		const totalClientText = useMemo(() => {
			return t("commands.clientForm.totalClientResult", { totalClients, maxClients: MAX_CLIENTS });
		}, [totalClients, MAX_CLIENTS, t]);

		return (
			<div className="relative pb-10">
				<components.MenuList {...props}>{props.children}</components.MenuList>
				<hr className="text-black opacity-10" />
				<div className="absolute bottom-0 left-0 w-full p-2.5 text-center text-primary-500 leading-4">
					{totalClientText}
				</div>
			</div>
		);
	};

	const customComponents = {
		DropdownIndicator: () => <DropdownIndicator isLoading={searchLoading} />,
		...(totalClients > MAX_CLIENTS ? { MenuList: CustomMenuList } : {}),
	};

	useEffect(() => {
		if (isClientFetched) {
			setClients([clientData]);
		}
	}, [clientData, isOrderInEdition, isInDuplicationMode, setClients, isClientFetched]);

	const isInitialClientOptionSet = useRef(false);
	useEffect(() => {
		if ((isOrderInEdition || isInDuplicationMode) && isClientFetched && clients.length !== 0) {
			if (!isInitialClientOptionSet.current) {
				isInitialClientOptionSet.current = true;
				setFieldValue("clientOption", clientData);
			}
		}
	}, [clients, values, setFieldValue, clientData, isOrderInEdition, isInDuplicationMode, isClientFetched]);

	if (isInitialClientOptionSet.current === false && (isOrderInEdition || isInDuplicationMode)) {
		return (
			<div className={style.skeleton}>
				<Skeleton height={50} width={400} borderRadius={25} />
				<div className={style.skeletonQualificationBox}>
					<Skeleton height={50} width={400} borderRadius={25} />
					<Skeleton height={50} width={90} borderRadius={25} />
				</div>
				<Skeleton height={250} borderRadius={25} />
			</div>
		);
	}

	return (
		<div className={style.content}>
			<div className={style.selectBox}>
				{
					<div className={style.clientBox}>
						{isInitialClientOptionSet || !isOrderInEdition ? (
							<div className={style.clientSelectBox}>
								<SelectV2
									isSearchable
									isClearable
									options={clients}
									placeholder={t("commands.client")}
									noOptionsMessage={({ inputValue }) => {
										if (searchLoading) {
											return t("components.gouvAddressSelect.loading");
										}
										if (inputValue.length < MINIMUM_SEARCH_LENGTH) {
											return t("components.gouvAddressSelect.minimumSearchLength", {
												length: MINIMUM_SEARCH_LENGTH,
											});
										}
										if (userTyping) {
											return t("components.gouvAddressSelect.loading");
										}
										return t("components.gouvAddressSelect.noResults");
									}}
									onInputChange={(value, e) => {
										if (e.action === "input-blur") {
											setClients([]);
										}
										if (value?.length <= 2 && e.action === "input-change") {
											setClients([]);
										}
										if (value?.length >= MINIMUM_SEARCH_LENGTH) setUserTyping(true);
										handleInputChange(value);
									}}
									onChange={handleSelectClient}
									getOptionValue={(option: ClientInfo) => option.id || ""}
									className={style.select}
									formatOptionLabel={(option: ClientInfo) => (
										<OptionClient
											key={option.tempoId}
											tempoId={option.tempoId}
											name={option.name}
											city={option.city}
										/>
									)}
									name="clientOption.id"
									value={clientOption?.id || null}
									filterOption={(option) => option.value !== clientOption?.id}
									label={t("commands.client")}
									components={customComponents}
									menuPosition="fixed"
								/>
								{clientOption?.instructions247 ? (
									<Picto
										className="w-6 h-6 text-primary-500"
										icon="timeTwentyFour"
										onClick={() => setIs247InstructionsModalOpen(true)}
									/>
								) : null}
							</div>
						) : (
							<Skeleton height={50} width={400} borderRadius={25} />
						)}
					</div>
				}
				<div className={style.qualificationBox}>
					{clientId && (
						<>
							<div className={style.qualificationSelect}>
								<Select
									key={values.clientId}
									name="qualification.id"
									label={`${t("commands.qualification")} ${type === COMMAND_TYPE_INTERIM ? "*" : ""}`}
									getOptionLabel={(option: ClientQualification) => option.name}
									getOptionValue={(option: ClientQualification) => option.clientQualificationId}
									formatOptionLabel={(option: ClientQualification) =>
										qualificationOptionsTemplate(option)
									}
									options={clientQualifications}
									value={
										qualification?.deletedAt
											? qualification
											: clientQualifications?.find(
													(option: ClientQualification) =>
														option.clientQualificationId === qualification?.id
												)
									}
									noOptionsMessage={() => t("commands.selectNoQualificationFound")}
									loadingMessage={() => t("commands.selectLoadingQualification")}
									isClearable
									className={cn([style.select, qualification?.deletedAt ? "warn warnSelect" : ""])}
									onChange={handleSelectQualification}
									onBlur={handleBlur("qualification.id")}
								/>
								{errors?.qualification?.id && !!touched.qualification && (
									<TooltipMessage className={style.centerTooltip} color="error">
										{errors?.qualification?.id}
									</TooltipMessage>
								)}
								{qualification?.deletedAt && (
									<TooltipMessage className={style.centerTooltip} color="warning">
										{t("commands.deletedQualificationMessage")}
									</TooltipMessage>
								)}
							</div>
							<Select
								isSearchable
								className={style.selectNumber}
								options={defaultNumberWorkersOptions}
								onChange={handleSelectQualificationNumberWorker}
								name="qualification.workerRequestedCount"
								value={defaultNumberWorkersOptions[(qualification?.workerRequestedCount ?? 0) - 1]}
							/>
						</>
					)}
				</div>
			</div>
			{values.qualification?.id && (
				<Textarea
					className={style.textarea}
					maxLength={800}
					name="missionDescription"
					label={t("commands.notes")}
					shouldDisplayErrorMessage={false}
				/>
			)}
			<ModalV2
				size="m"
				isDisplayed={is247InstructionsModalOpen}
				onClose={() => setIs247InstructionsModalOpen(false)}
			>
				<div className="-mt-5">
					<Title className="mb-10" tag="h1" size="heading01" lineHeight="l">
						{t("commands.clientForm.247InstructionsModalTitle")}
					</Title>
					<p className="text-base font-normal leading-6 whitespace-pre-line">
						{parseTextWithLineBreaks(clientOption?.instructions247)}
					</p>
				</div>
			</ModalV2>
		</div>
	);
};

export default CommandClientFormBlock;
