// @ts-nocheck
import { useCallback, useMemo, useState } from "react";

import { useFormikContext } from "formik";
import { capitalize, omit, upperFirst } from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

// Services
import { getClientWorkAddresses, getCountries } from "../../../../../services/commandService";

// Hooks
import useDuplication from "src/views/Command/Configuration/hooks/useDuplication";

// Constants
import { COMMAND_WORK_ADDRESS_MAIN_ADDRESS_LIBELLE, CREATE_MODE, EDIT_MODE } from "../../../../../constants";
import cn from "../../../../../utils/cn";
import { commandWorkAddressFormDefaultValues } from "../commandFormDefaultValues";
// Utils
import { Select } from "@zolteam/react-ras-library";
import { GouvAddressSelect, TooltipMessage } from "src/components/atoms";
// Misc
// Components
import { Field } from "src/components/molecules";

// Hooks
// Style
import style from "./CommandLocationFormBlock.module.css";

const COUNTRY_FRANCE = "France";

const formatOptionAddress = (address, city) => {
	const addressCaracLimit = 40;
	const troncatedAddress =
		address?.length > addressCaracLimit ? `${address.substring(0, addressCaracLimit)}...` : address;

	return `${troncatedAddress || ""}${city ? `, ${city}` : ""}`;
};

const CommandLocationFormBlock = ({ isOrderFetched }) => {
	// Hooks
	const { values, setValues, handleChange, handleBlur, setFieldValue, setFieldTouched, errors } = useFormikContext();
	const { agencyId, clientId, workAddress, workAddressCountryOption } = values;
	const { isInDuplicationMode } = useDuplication();
	const { t } = useTranslation();
	const { orderId } = useParams();
	const isNewCommand = orderId === CREATE_MODE;
	const commandFormMode = isNewCommand ? CREATE_MODE : EDIT_MODE;
	const CUSTOM_ADDRESS_OPTION = {
		...omit(commandWorkAddressFormDefaultValues.workAddress, [
			"meetingAddress",
			"meetingAddressComplement",
			"meetingPostalCode",
			"meetingCity",
			"meetingCountry",
		]),
		id: null,
		libelle: t("commands.locationForm.addressSelectOtherOptionLabel"),
		country: "France",
	};

	const isReady = useMemo(() => isOrderFetched || isNewCommand, [isOrderFetched, isNewCommand]);

	// States
	const isFormDisabled = workAddress?.isClientMainAddress || workAddress?.id;
	const [formatedClientWorkAddresses, setFormattedClientWorkAddresses] = useState([]);

	// Move this to a custom hook ?
	const IS_CUSTOM_ADDRESS = useMemo(() => !workAddress?.isClientMainAddress && !workAddress?.id, [workAddress]);
	const IS_COUNTRY_FRANCE = useMemo(
		() => workAddressCountryOption?.name === COUNTRY_FRANCE,
		[workAddressCountryOption]
	);
	const showAutoCompleteLayout = useMemo(
		() => IS_CUSTOM_ADDRESS && IS_COUNTRY_FRANCE,
		[IS_CUSTOM_ADDRESS, IS_COUNTRY_FRANCE]
	);

	// API Call
	const getCountriesQuery = useQuery("getCountries", () => getCountries());

	const getCountryOptionValue = (option, countryKey) =>
		getCountriesQuery?.data?.data?.find((country) =>
			country.name.toLowerCase().includes(option?.[countryKey]?.toLowerCase())
		) || null;

	// Not really at its place in the component code but no choice here
	const handleAddressSelected = useCallback(
		(selectedOption, isInitial = false) => {
			if (!isInitial) setFieldTouched("workAddress", true);

			const countryOptionValue = getCountryOptionValue(selectedOption, "country");
			const meetingCountryOptionValue = getCountryOptionValue(selectedOption, "meetingCountry");

			const getLabel = () => {
				if (selectedOption?.isClientMainAddress) return COMMAND_WORK_ADDRESS_MAIN_ADDRESS_LIBELLE;
				if (!selectedOption?.id) return t("commands.locationForm.addressSelectOtherOptionLabel");
				if (selectedOption?.libelle) return selectedOption.libelle;
				if (selectedOption?.address) return selectedOption.address;
				if (!selectedOption.libelle) return t("commands.locationForm.unspecifiedAddressName");

				return selectedOption?.libelle;
			};

			setFieldValue("workAddress", {
				...values.workAddress,
				...selectedOption,
				libelle: getLabel(),
				longitude: selectedOption?.longitude || null,
				latitude: selectedOption?.latitude || null,
				// certain fields must be kept when switching between addresses
				comment: values.workAddress?.comment,
				geographicSector: values.workAddress?.geographicSector,
			});
			setFieldValue("workAddressCountryOption", countryOptionValue);
			setFieldValue(
				"workAddressMeetingCountryOption",
				isInitial ? meetingCountryOptionValue : values.workAddressMeetingCountryOption
			);
		},
		[values, setValues, setFieldTouched, t, getCountryOptionValue, COMMAND_WORK_ADDRESS_MAIN_ADDRESS_LIBELLE]
	);

	// API Call
	useQuery(
		["getClientWorkAddresses", agencyId, clientId, commandFormMode, isInDuplicationMode],
		() => getClientWorkAddresses(agencyId, clientId),
		{
			onSuccess: (result) => {
				// We have to do this beacause datas from tempo could be not 'clean'
				const filteredData = result.data.map((address) => {
					if (address.libelle === null) {
						const addressOption = formatOptionAddress(address.address, address.city);
						return {
							...address,
							libelle: addressOption || t("commands.locationForm.unspecifiedAddressName"),
						};
					}
					return address;
				});

				if (values.workAddress) {
					setFieldValue();
				}
				setFormattedClientWorkAddresses(filteredData);

				// set the client main address as default selected
				const clientMainAddress = result.data.find((address) => address?.isClientMainAddress);

				// If the command is in edit mode, or duplication mode, default selected should be the command work address
				// Else if the client has a main address, default selected should be the client main address
				if (commandFormMode === EDIT_MODE || isInDuplicationMode) {
					const selectedAddress =
						workAddress?.id && !workAddress?.isClientMainAddress
							? result.data.find((address) => address?.id === workAddress?.id)
							: workAddress;

					handleAddressSelected(selectedAddress, true);

					// If in creation mode, set default meeting address country if any
				} else if (clientMainAddress) {
					handleAddressSelected(clientMainAddress, true);
				}
			},
			enabled: !!(agencyId && clientId) && isReady,
		}
	);

	const handleOnCountryChange = (selectedOption) => {
		if (
			(workAddress.country === "France" && selectedOption.name !== "France") ||
			(workAddress.country !== "France" && selectedOption.name === "France")
		) {
			// It means that the user is switching between France and another country, reset address, postalCode and city
			setValues({
				...values,
				workAddress: {
					...values.workAddress,
					address: null,
					postalCode: null,
					city: null,
					country: capitalize(selectedOption?.name) || null,
					latitude: null,
					longitude: null,
				},
				workAddressCountryOption: selectedOption,
			});
		} else {
			setValues({
				...values,
				workAddress: {
					...values?.workAddress,
					country: capitalize(selectedOption?.name) || null,
				},
				workAddressCountryOption: selectedOption,
			});
		}
	};

	const capitalizeOnBlur = (e) => {
		if (e.target.value) setFieldValue(e.target.name, upperFirst(e.target.value));
		handleBlur(e);
	};

	const renderAddessComplement = useMemo(
		() => (
			<Field
				disabled={isFormDisabled}
				type="text"
				name="workAddress.addressComplement"
				id="workAddress.addressComplement"
				label={t("commands.locationForm.addressLine2Placeholder")}
				classNameContainer={
					showAutoCompleteLayout
						? style.fieldInputAutoCompleteAddressComplement
						: style.fieldInputFreeAddressComplement
				}
				onChange={handleChange("workAddress.addressComplement")}
				onBlur={capitalizeOnBlur}
			/>
		),
		[style, t, IS_COUNTRY_FRANCE, handleChange, capitalizeOnBlur, isFormDisabled]
	);

	const renderCountrySelect = useMemo(
		() => (
			<Select
				isSearchable
				disabled={isFormDisabled}
				className={cn([
					style.capitalize,
					IS_COUNTRY_FRANCE && IS_CUSTOM_ADDRESS ? style.autoCompleteCountry : style.freeEditCountry,
					isFormDisabled && style.disabledSelect,
				])}
				options={getCountriesQuery?.data?.data || []}
				onChange={handleOnCountryChange}
				showIndicator={!isFormDisabled}
				onBlur={handleBlur("workAddress.country")}
				name="workAddress.country"
				label={t("commands.locationForm.countrySelectPlaceholder")}
				getOptionLabel={(option) => option.name}
				getOptionValue={(option) => option.id}
				value={values.workAddressCountryOption}
				noOptionsMessage={() => t("commands.locationForm.countrySelectNoOptions")}
				loadingMessage={() => t("commands.locationForm.countrySelectLoading")}
			/>
		),
		[style, t, getCountriesQuery, values, handleOnCountryChange, handleBlur, isFormDisabled, IS_COUNTRY_FRANCE]
	);

	const renderGeographicSector = useMemo(
		() => (
			<div className={style.geographicSectorBox}>
				<Field
					classNameContainer={style.fieldInputGeographicSector}
					type="text"
					name="workAddress.geographicSector"
					id="workAddress.geographicSector"
					label={t("commands.locationForm.geographicSectorPlaceholder")}
					onChange={handleChange("workAddress.geographicSector")}
					onBlur={handleBlur("workAddress.geographicSector")}
				/>
				<TooltipMessage className={style.centerTooltip} arrow={false}>
					{t("commands.locationForm.geographicSectorMessage")}
				</TooltipMessage>
			</div>
		),
		[style, t, handleChange, handleBlur]
	);

	const renderAutoCompleteLayout = useMemo(
		() => (
			<>
				<GouvAddressSelect
					name="workAddressAutoComplete"
					onAddressSelect={handleAddressSelected}
					className={style.fieldAutoCompleteInputAddress}
					addressValue={values.workAddress}
				/>
				{renderAddessComplement}
				{renderCountrySelect}
			</>
		),
		[renderAddessComplement, renderCountrySelect, style, t, handleChange, capitalizeOnBlur, isFormDisabled]
	);

	const renderForeignAddressLayout = useMemo(
		() => (
			<>
				<Field
					disabled={isFormDisabled}
					type="text"
					name="workAddress.address"
					id="workAddress.address"
					label={t("commands.locationForm.addressLine1Placeholder")}
					classNameContainer={style.fieldFreeInputAddress}
					onChange={handleChange("workAddress.address")}
					onBlur={capitalizeOnBlur}
				/>
				{renderAddessComplement}
				<Field
					disabled={isFormDisabled}
					type="text"
					name="workAddress.postalCode"
					id="workAddress.postalCode"
					label={t("commands.locationForm.zipCodePlaceholder")}
					classNameContainer={cn([
						style.fieldInputpostalCode,
						errors?.workAddress?.postalCode ? style.postalCodeError : "",
					])}
					onChange={handleChange("workAddress.postalCode")}
					onBlur={handleBlur("workAddress.postalCode")}
					customErrorDisplay
				/>
				<Field
					disabled={isFormDisabled}
					type="text"
					name="workAddress.city"
					id="workAddress.city"
					label={t("commands.locationForm.cityPlaceholder")}
					className={cn([style.capitalize, style.fieldInputCity])}
					classNameContainer={cn([
						style.fieldInputCity,
						errors?.workAddress?.postalCode ? style.postalCodeError : "",
					])}
					onChange={handleChange("workAddress.city")}
					onBlur={capitalizeOnBlur}
				/>
				{renderCountrySelect}
			</>
		),
		[
			style,
			errors,
			isFormDisabled,
			getCountriesQuery,
			values,
			t,
			handleChange,
			handleBlur,
			capitalizeOnBlur,
			handleOnCountryChange,
			renderAddessComplement,
			renderCountrySelect,
		]
	);

	if (!isReady) {
		return (
			<div className={style.skeleton}>
				<div className={style.skeletonRow}>
					<Skeleton containerClassName={style.skeletonRowMainAdress} height={50} borderRadius={25} />
					<Skeleton containerClassName={style.skeletonRowSector} height={50} borderRadius={25} />
				</div>
				<div className={style.skeletonRow}>
					<Skeleton containerClassName={style.skeletonRowAdress} height={50} borderRadius={25} />
					<Skeleton containerClassName={style.skeletonRowAdressComplement} height={50} borderRadius={25} />
				</div>
				<div className={style.skeletonRow}>
					<Skeleton containerClassName={style.skeletonRowZipCode} height={50} borderRadius={25} />
					<Skeleton containerClassName={style.skeletonRowCity} height={50} borderRadius={25} />
					<Skeleton containerClassName={style.skeletonRowCountry} height={50} borderRadius={25} />
				</div>
			</div>
		);
	}

	return (
		<div className={cn([style.content, IS_COUNTRY_FRANCE ? style.autoCompleteGrid : style.freeGrid])}>
			<span className={cn([style.selectAddress])}>
				<Select
					isSearchable={false}
					options={[...(formatedClientWorkAddresses || []), CUSTOM_ADDRESS_OPTION]}
					onChange={(addressSelected) => handleAddressSelected(addressSelected)}
					name="workAddress.libelle"
					label={t("commands.locationForm.addressSelectPlaceholder")}
					getOptionLabel={(option) => option.libelle}
					getOptionValue={(option) => option.libelle}
					value={workAddress}
					isOptionSelected={(option) =>
						option.id === workAddress.id && workAddress.isClientMainAddress === option.isClientMainAddress
					}
					noOptionsMessage={() => t("commands.locationForm.addressSelectNoOptions")}
					loadingMessage={() => t("commands.locationForm.addressSelectLoading")}
				/>
				{workAddressCountryOption?.name && workAddressCountryOption.name !== COUNTRY_FRANCE && (
					<TooltipMessage className={style.centerTooltip} color="warning">
						{t("commands.locationForm.countryWarningMessage")}
					</TooltipMessage>
				)}
			</span>
			{renderGeographicSector}
			{showAutoCompleteLayout ? renderAutoCompleteLayout : renderForeignAddressLayout}
		</div>
	);
};

CommandLocationFormBlock.propTypes = {
	isOrderFetched: PropTypes.bool.isRequired,
};

export default CommandLocationFormBlock;
