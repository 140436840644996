// Types
import { CommonQualificationsApiResponse } from "src/services/commonQualificationsService";

const formatCommonQualifications = (result: AxiosResponse<CommonQualificationsApiResponse>) => {
	if (result == null) {
		return [];
	}
	return result.data.items.sort((a: { name: string }, b: { name: string }) => a.name.localeCompare(b.name));
};

export default formatCommonQualifications;
