import { FC } from "react";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { TTemporaryWorker } from "src/types";

import { ConfirmModal } from "src/components/molecules/ConfirmModal/ConfirmModal";

interface SuspiciousModalProps {
	suspiciousUTW:
		| (Partial<Omit<TTemporaryWorker, "unifiedTempWorkerIdOnSuspicion">> & {
				unifiedTempWorkerIdOnSuspicion: number;
		  })
		| null;
	setSuspiciousUTW: (
		tempWorker:
			| (Partial<Omit<TTemporaryWorker, "unifiedTempWorkerIdOnSuspicion">> & {
					unifiedTempWorkerIdOnSuspicion: number;
			  })
			| null
	) => void;
}

export const SuspiciousModal: FC<SuspiciousModalProps> = ({ suspiciousUTW, setSuspiciousUTW }) => {
	const { t } = useTranslation();
	const history = useHistory();
	const suspiciousText = t("usersListing.suspicious.list", { returnObjects: true });

	return (
		<ConfirmModal
			title={t("usersListing.suspicious.title")}
			text={
				<div>
					<span>{t("usersListing.suspicious.content")}</span>
					{Array.isArray(suspiciousText) && (
						<div className="ml-12 mt-2">
							<ul className="list-disc flex flex-col gap-1">
								{suspiciousText?.map((st: string, index: number) => <li key={index}>{st}</li>)}
							</ul>
						</div>
					)}
				</div>
			}
			isOpen={!!suspiciousUTW}
			onClose={() => setSuspiciousUTW(null)}
			onConfirm={() => history.push(`/suspicions/${suspiciousUTW?.unifiedTempWorkerIdOnSuspicion}`)}
			confirmText={t("usersListing.suspicious.confirm")}
			cancelText={t("global.leave")}
			size="s"
		/>
	);
};
