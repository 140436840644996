// Style
import { FC } from "react";

import { Picto, Text } from "@zolteam/react-ras-library";

import style from "./Toast.module.css";

interface IToastProps {
	msg: string;
	icon?: string;
}

export const Toast: FC<IToastProps> = ({ icon = "", msg }) => (
	<div className={style.container}>
		{icon ? (
			<div className={style.icon}>
				<Picto icon={icon} />
			</div>
		) : null}
		<Text tag="span" size="paragraph02" className="ml-10">
			{msg}
		</Text>
	</div>
);
