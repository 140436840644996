import React from "react";

import { useQuery } from "react-query";

import cn from "../../../utils/cn";
import { Picto } from "@zolteam/react-ras-library";

interface IAvatarProps {
	src?: string;
	title?: string;
	className?: string;
	noDefault?: boolean;
	firstName?: string;
	lastName?: string;
}

const Avatar: React.FC<IAvatarProps> = ({ src, title, className, noDefault, firstName, lastName }) => {
	const getImage = () => {
		const link = "";

		const img = src ? src : link ? link : noDefault ? "" : null;

		return {
			link: img,
			hasImage: img?.length && img !== null,
		};
	};

	const { data } = useQuery(["avatar"], () => {
		return Promise.resolve(getImage());
	});

	if (noDefault && !data?.hasImage) return null;

	if (!data?.hasImage) {
		return (
			<div className="bg-neutral-100 rounded-full p-2">
				<Picto
					icon="user"
					className={cn([
						"image",
						!!data ? "rounded-full object-cover object-center text-neutral-400" : "",
						className,
					])}
				/>
			</div>
		);
	}
	return (
		<img
			src={data?.link}
			alt={title ? title : `${firstName} ${lastName}`}
			className={cn(["image", !!data ? "rounded-full object-cover object-center" : "", className])}
		/>
	);
};

export default Avatar;
