// @ts-nocheck
/**
 * Remap the fields received from an API response to a specific object format.
 *
 * @param {Object} addressResult - an Object parsed from the address result JSON element returned by the api.
 * @param {Object} addressResult.properties - The properties object within the API response.
 * @param {string} addressResult.properties.label - The full address label.
 * @param {string} addressResult.properties.postcode - The postal code.
 * @param {string} addressResult.properties.citycode - The city name.
 * @param {string} addressResult.properties.city - The city name.
 * @param {string} addressResult.properties.context - The context string containing additional location information.
 * @param {Object} addressResult.geometry - The geometry object within the API response.
 * @param {Array<number>} addressResult.geometry.coordinates - The coordinates array containing longitude and latitude.
 * @param {string} addressComplement - The complementary address information.
 * @returns {Object} The remapped object containing the address details.
 * @returns {?string} return.address - The full address label.
 * @returns {?string} return.addressComplement - The complementary address information.
 * @returns {?string} return.postalCode - The postal code.
 * @returns {?string} return.city - The city name.
 * @returns {?string} return.country - The country name.
 * @returns {?string} return.latitude - The latitude as a string.
 * @returns {?string} return.longitude - The longitude as a string.
 */
export const remapGouvAddress = (addressResult) => {
	const {
		properties: { name, postcode, citycode, city },
		geometry: { coordinates },
	} = addressResult || {};

	const [longitude, latitude] = coordinates;

	return {
		address: name || null,
		postalCode: postcode || citycode || null,
		city: city || null,
		country: "France",
		latitude: latitude ? latitude.toString() : null,
		longitude: longitude ? longitude.toString() : null,
	};
};

/**
 * Remap the fields received from an API response to a specific object format.
 *
 * @param {Object} apiResponse - The API response object.
 * @param {Object} apiResponse.properties - The properties object within the API response.
 * @param {string} apiResponse.properties.label - The full address label.
 * @param {string} apiResponse.properties.postcode - The postal code.
 * @param {string} apiResponse.properties.citycode - The city name.
 * @param {string} apiResponse.properties.city - The city name.
 * @param {string} apiResponse.properties.context - The context string containing additional location information.
 * @param {Object} apiResponse.geometry - The geometry object within the API response.
 * @param {Array<number>} apiResponse.geometry.coordinates - The coordinates array containing longitude and latitude.
 * @param {string} addressComplement - The complementary address information.
 * @returns {Object} The remapped object containing the address details.
 * @returns {?string} return.address - The full address label.
 * @returns {?string} return.addressComplement - The complementary address information.
 * @returns {?string} return.postalCode - The postal code.
 * @returns {?string} return.city - The city name.
 * @returns {?string} return.country - The country name.
 * @returns {?string} return.latitude - The latitude as a string.
 * @returns {?string} return.longitude - The longitude as a string.
 */
export const createGouvAddressOptions = (apiResponse) => {
	if (apiResponse.length === 0) {
		return [];
	}
	return apiResponse.map((addressResult) => {
		const { name, postcode, citycode, city } = addressResult.properties || {};
		return {
			label: `${name}, ${postcode || citycode || ""} ${city}, France`,
			value: remapGouvAddress(addressResult),
		};
	});
};
