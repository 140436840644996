import { FC } from "react";

import SpinnerIcon from "../../icons/Spinner";

import styles from "./Spinner.module.css";

interface ISpinnerProps {
	size?: number;
	style?: React.CSSProperties;
	text?: string;
	className?: string;
}

export const Spinner: FC<ISpinnerProps> = ({ size = 30, style = {}, text = "", className = "" }) => (
	<div
		style={{
			width: "100%",
			textAlign: "center",
			...style,
		}}
		className={`${className}`}
	>
		<SpinnerIcon
			className={styles.loadingIcon}
			style={{
				width: size,
				height: size,
			}}
		/>
		{!!text.length && <div>{text}</div>}
	</div>
);
