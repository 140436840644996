export const MATCHING_ERRORS = {
	NO_COMMON_QUALIFICATION: "NO_COMMON_QUALIFICATION",
	DEFAULT: "DEFAULT",
	ERR_BR_MAT_0015_INVALID_WORKING_DAYS_ORDER_PERIOD: "ERR_BR_MAT_0015_INVALID_WORKING_DAYS_ORDER_PERIOD",
	ERR_BR_MAT_0016_INVALID_WORKING_HOURS_ON_ORDER: "ERR_BR_MAT_0016_INVALID_WORKING_HOURS_ON_ORDER",
};

export const ORDER_FORM_ERRORS = {
	ERR_GT_OTS_0001_TEMPO_CALL_IS_ON_ERROR: "ERR_GT_OTS_0001_TEMPO_CALL_IS_ON_ERROR",
};

export const ORDER_PROFILES_ERRORS = {
	ERR_CO_ORDER_PROFILE_NOTES_CONFLICT: "ERR_CO_ORDER_PROFILE_NOTES_CONFLICT",
};
