// @ts-nocheck
import CONFIG_EXPORT_XLSX from "src/constants/configExportXLSX";

import client from "./client";

/**
 * @description Get expired documents
 * @param {Object} params
 * @returns {Promise<AxiosResponse<any>>}
 */
const postExpiredDocumentsSearch = (params) => client.post("/api-client-v2/expired-documents/search", params);

const exportExpiredDocuments = (params) =>
	client.post("/api-client-v2/expired-documents/export", params, {
		...CONFIG_EXPORT_XLSX,
		timeout: 120000,
	});

const exportExpiredDocumentsHistory = () =>
	client.get("/api-client-v2/expired-documents/historical/export", CONFIG_EXPORT_XLSX);

const ExpiredDocumentsService = {
	postExpiredDocumentsSearch,
	exportExpiredDocuments,
	exportExpiredDocumentsHistory,
};

export default ExpiredDocumentsService;
