// @ts-nocheck
function capitalizeFirstCharacter(inputString) {
	if (!inputString) {
		return inputString;
	}
	if (inputString.length > 0) {
		if (inputString.charAt(0) !== inputString.charAt(0).toUpperCase()) {
			return inputString.charAt(0).toUpperCase() + inputString.slice(1);
		}
	}
	return inputString;
}

export default capitalizeFirstCharacter;
