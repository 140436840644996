// @ts-nocheck

/* eslint-disable react/no-array-index-key */

/* eslint-disable max-len */
import { useState } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import { format } from "date-fns";
import localeFr from "date-fns/locale/fr";

// Services
import { getMatchingTempWorkerOtherOrders } from "../../../services/commandService";

// Hooks
import { useMatchingContext } from "../../../store/MatchingContext";

import { PROFILES_STATUS } from "../../../constants";
// Utils
import cn from "../../../utils/cn";
import { Tag, ModalV2 } from "@zolteam/react-ras-library";

// Style
import style from "./MatchingProfileSectionOtherOrders.module.css";

const OrderItem = ({ order }) => {
	const { t } = useTranslation();

	const renderTag = () => {
		switch (order.orderProfileStatus) {
			case PROFILES_STATUS.CONTACTED: {
				return (
					<Tag color="primaryLight">
						<span className={cn([style.statusTag, style.primaryLightText])}>
							{t(`matching.otherOrders.statuses.${order.orderProfileStatus}`)}
						</span>
					</Tag>
				);
			}
			case PROFILES_STATUS.OFFER_ACCEPTED: {
				return (
					<Tag color="primaryLight">
						<span className={cn([style.statusTag, style.primaryLightText])}>
							{t(`matching.otherOrders.statuses.${order.orderProfileStatus}`)}
						</span>
					</Tag>
				);
			}
			case PROFILES_STATUS.OFFER_DECLINED: {
				return (
					<Tag color="primaryLight">
						<span className={cn([style.statusTag, style.primaryLightText])}>
							{t(`matching.otherOrders.statuses.${order.orderProfileStatus}`)}
						</span>
					</Tag>
				);
			}
			case PROFILES_STATUS.PROPOSED: {
				return (
					<Tag color="primaryLight">
						<span className={cn([style.statusTag, style.primaryLightText])}>
							{t(`matching.otherOrders.statuses.${order.orderProfileStatus}`)}
						</span>
					</Tag>
				);
			}
			default: {
				return null;
			}
		}
	};

	const getFormattedDate = (date) => format(new Date(date), "dd MMM yyyy", { locale: localeFr });

	return (
		<div className={style.orderItem}>
			<div className={style.orderItemLeft}>
				<div className={style.orderItemStatus}>{renderTag()}</div>
				<div className={style.orderItemClientQualif}>
					<div className={style.orderItemClient}>{order.clientName}</div>
					<div className={style.orderItemQualif}>{order.clientQualificationName}</div>
				</div>
			</div>
			<div className={style.orderItemRight}>
				<div className={style.orderItemAgency}>{order.agencyName}</div>
				<div className={style.orderItemDate}>
					{`${getFormattedDate(order?.startDate || "")} - ${getFormattedDate(order?.endDate || "")}`}
				</div>
			</div>
		</div>
	);
};

OrderItem.propTypes = {
	order: PropTypes.shape({
		orderProfileStatus: PropTypes.string.isRequired,
		clientName: PropTypes.string.isRequired,
		agencyName: PropTypes.string.isRequired,
		startDate: PropTypes.string.isRequired,
		endDate: PropTypes.string.isRequired,
		clientQualificationName: PropTypes.string.isRequired,
	}).isRequired,
};

const MatchingProfileSectionOtherOrders = () => {
	const { orderId } = useParams();
	const { t } = useTranslation();
	const [{ unifiedTempWorkerId }] = useMatchingContext();
	const [seeAllModalOpen, setSeeAllModalOpen] = useState(false);

	const {
		data: tempWorkerOtherOrdersData,
		isFetching,
		isError,
		isFetched,
	} = useQuery(
		["getMatchingTempWorkerInformations", orderId, unifiedTempWorkerId],
		() => getMatchingTempWorkerOtherOrders(orderId, unifiedTempWorkerId),
		{
			placeholderData: null,
			enabled: !!orderId && !!unifiedTempWorkerId,
		}
	);

	const { data: tempWorkerOtherOrders } = tempWorkerOtherOrdersData || {
		data: [],
	};

	const handleSeeAllClick = () => {
		setSeeAllModalOpen(true);
	};

	const readyToShow = !isFetching && !isError && isFetched;

	return (
		<div>
			<div className={style.header}>
				<div className={style.title}>
					{!readyToShow || !tempWorkerOtherOrders?.length
						? t("matching.otherOrders.modalTitle")
						: t("matching.otherOrders.title", {
								count: tempWorkerOtherOrders?.length,
							})}
				</div>
				<div>
					{readyToShow && tempWorkerOtherOrders?.length > 2 && (
						<div
							role="button"
							tabIndex={0}
							onKeyDown={handleSeeAllClick}
							onClick={handleSeeAllClick}
							className={style.seeAll}
						>
							{t("matching.otherOrders.seeAll")}
						</div>
					)}
				</div>
			</div>
			<div className={style.orders}>
				{!readyToShow ? (
					<>
						<div className={style.ordersSkeleton}>
							<Skeleton count={1} height={55} width={200} />
							<Skeleton count={1} height={55} width={150} />
						</div>
						<div className={style.skeletonSeparator} />
						<div className={style.ordersSkeleton}>
							<Skeleton count={1} height={55} width={200} />
							<Skeleton count={1} height={55} width={150} />
						</div>
					</>
				) : (
					tempWorkerOtherOrders
						?.filter((_, index) => index < 2)
						?.map((order, i) => (
							<OrderItem key={`${order.clientName}-${order.startDate}-${i}`} order={order} />
						))
				)}
				{readyToShow && tempWorkerOtherOrders?.length === 0 && (
					<div className={style.noOrders}>{t("matching.otherOrders.noOrders")}</div>
				)}
			</div>
			{readyToShow && tempWorkerOtherOrders?.length > 2 && (
				<ModalV2 size="m" isDisplayed={seeAllModalOpen} onClose={() => setSeeAllModalOpen(false)}>
					<div className={style.modalContent}>
						<div className={style.modalTitle}>{t("matching.otherOrders.modalTitle")}</div>
						<div className={style.orders}>
							{tempWorkerOtherOrders?.map((order) => (
								<OrderItem key={`${order.clientName}-${order.startDate}`} order={order} />
							))}
						</div>
					</div>
				</ModalV2>
			)}
		</div>
	);
};

export default MatchingProfileSectionOtherOrders;
