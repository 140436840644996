// @ts-nocheck
import { useMemo } from "react";

import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";

import {
	getAgencyAvailableFeatures,
	getAgencyDetails,
	getClientDetails,
	getOrderQualification,
} from "../services/commandService";
import { getOrder } from "src/services/commands/getOrder";

// Hooks
import { useAppContext } from "../store/AppContext";

import { CREATE_MODE } from "../constants";

const useOrderData = (orderId) => {
	const history = useHistory();
	const [{ user }] = useAppContext();
	const IS_EDITING = orderId !== CREATE_MODE;
	const STALETIME = 500000;
	const {
		data: orderData,
		isError,
		isLoading,
		isFetched: isOrderFetched,
		refetch: refetchOrderData,
	} = useQuery(["getOrder", orderId], () => getOrder(orderId), {
		onError: () => history.push("/commandes"),
		enabled: IS_EDITING,
	});

	const { agencyId, clientId, commonQualificationId, commonQualificationName } = orderData?.data || {};

	const hasCommonQualificationId = useMemo(() => {
		if (!orderData) return undefined;
		return !!commonQualificationId;
	}, [orderData?.data?.commonQualificationId]);

	const commonQualificationDetails = {
		id: commonQualificationId,
		name: commonQualificationName,
	};

	const isUser24by7 = useMemo(() => {
		if (!user?.roles) return false;
		return !!Object.keys(user?.roles).find((key) => key === "24by7");
	}, [user]);

	const { data: agencyData } = useQuery(["getAgencyDetails", agencyId], () => getAgencyDetails(agencyId), {
		enabled: !!agencyId,
		staleTime: STALETIME,
	});

	const { data: clientData } = useQuery(["getClientDetails", clientId], () => getClientDetails(clientId), {
		enabled: !!clientId,
		staleTime: STALETIME,
	});

	const { data: agencyFeaturesData } = useQuery(
		["getAgencyAvailableFeatures", agencyId],
		() => getAgencyAvailableFeatures(agencyId),
		{
			enabled: !!agencyId,
			staleTime: STALETIME,
		}
	);

	const { data: qualificationData } = useQuery(
		["getQualificationData", orderId],
		async () => getOrderQualification(orderId),
		{ enabled: !!orderId && IS_EDITING, staleTime: STALETIME }
	);

	if (isError) {
		history.push("/commandes");
	}

	return {
		agencyData: agencyData?.data,
		clientData: clientData?.data,
		agencyFeaturesData: agencyFeaturesData?.data,
		qualificationData: qualificationData?.data,
		isLoading,
		isOrderFetched,
		orderData,
		isUser24by7,
		hasCommonQualificationId,
		commonQualificationDetails,
		refetchOrderData,
	};
};

export default useOrderData;
