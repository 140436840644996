import { useState } from "react";

import { Button, Picto, Popover } from "@zolteam/react-ras-library";

// Style
import style from "./PaginationSearch.module.css";

interface IOptionMultiSelectProps {
	onInputChange: (params: any) => void;
	max: number;
}

export const PaginationSearch: React.FC<IOptionMultiSelectProps> = ({ onInputChange, max }) => {
	const [page, setPage] = useState<number | "">("");

	return (
		<Popover
			minWidth
			placement="top"
			content={
				<form
					className={style.container}
					onSubmit={(e) => {
						e.preventDefault();
						onInputChange({ page });
					}}
				>
					<input
						type="number"
						min="1"
						max={max + 1}
						name="paginationSearch"
						onChange={(e) => {
							return setPage(parseFloat(e.target.value));
						}}
						className={style.input}
						required
					/>
				</form>
			}
		>
			<Button color="transparent" type="button" style={{ display: "flex" }}>
				<Picto icon="more" style={{ transform: "rotate(90deg) translate(0px, -8px)" }} />
			</Button>
		</Popover>
	);
};
