import { TDocumentsRulesApiResponse, TDocumentsRulesFormValues } from "src/types/TIntegration";

export const DEFAULT_DOCUMENT_TYPES_FILTERS = {
	searchText: "",
};

export const INIT_DOCUMENT_PROPERTIES_TYPESB: TDocumentsRulesApiResponse = {
	acceptedRefusedRule: null,
	permanentRule: null,
	expirationDateRule: null,
	effectiveDateRule: null,
	attachmentsRule: null,
	referenceRule: null,
};

export const INIT_RADIO_OPTIONS = {
	mandatory: true,
	optional: false,
};

export const INIT_DOCUMENT_PROPERTIES_TYPES_OPTIONS: TDocumentsRulesFormValues = {
	referenceRule: {
		value: null,
		type: "int",
	},
	permanentRule: {
		value: null,
		type: "int",
	},
	acceptedRefusedRule: {
		value: null,
		type: "int",
	},
	expirationDateRule: {
		value: null,
		type: "int",
	},
	effectiveDateRule: {
		value: null,
		max: 0,
		maxKey: "maxAttachments",
		type: "int",
	},
	attachmentsRule: {
		value: null,
		type: "int",
	},
};
