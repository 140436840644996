import { FC } from "react";

import style from "./OptionAppealCase.module.css";

interface IOptionAppealCaseProps {
	tempoId?: string;
	designation?: string;
}

export const OptionAppealCase: FC<IOptionAppealCaseProps> = ({ tempoId = "", designation = "" }) => (
	<div className={`${style.optionContainer}`} data-testid={`option-${tempoId}-${designation}`}>
		<span className={style.optionTempoId}>{`${tempoId}`}</span>
		<span className={style.optionName}>{`${designation}`}</span>
	</div>
);
