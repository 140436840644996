import { isNil } from "lodash";
import { OrderInterface } from "src/types/OrderInterface";
import * as Yup from "yup";

const isValidEndHourRequired = (endHour: string | null, context: Yup.TestContext<OrderInterface>): boolean => {
	const { startHour } = context.parent;
	return !(!isNil(startHour) && isNil(endHour));
};

export default isValidEndHourRequired;
