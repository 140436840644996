// Style
import { FC } from "react";

import { useTranslation } from "react-i18next";
import { TAgency } from "src/types/TAgencies";

import { Text } from "@zolteam/react-ras-library";

import style from "./SuspiciousSheetFooter.module.css";

interface ISuspiciousSheetFooterProps {
	agencies: TAgency[];
	children: React.ReactNode;
}

export const SuspiciousSheetFooter: FC<ISuspiciousSheetFooterProps> = ({ agencies, children }) => {
	const { t } = useTranslation();
	return (
		<div className={style.footer}>
			<div className={style.container}>
				{agencies.length > 0 ? (
					<div className={style.agencies}>
						<Text tag="span" size="paragraph02">
							{t("suspiciousSheet.relevantAgenciesModifications", {
								count: agencies.length,
							})}
						</Text>
						<div className={style.list}>
							{agencies.map(({ id, name }, index) => (
								<Text key={id} tag="span" size="paragraph02" fontWeight="bold" className="mr-xs">
									{index !== agencies.length - 1 ? `${name},` : `${name}`}
								</Text>
							))}
						</div>
					</div>
				) : null}
			</div>
			<div className={style.submit}>{children}</div>
		</div>
	);
};
