import { isNil } from "lodash";
import moment from "moment";
import { NightDaysSelectedType } from "src/types/NightDaysSelectedType";
import { WeekDaysSelectedType } from "src/types/WeekDaysSelectedType";

import {
	DEFAULT_DAY_WORKING_TIME,
	DEFAULT_NIGHT_WORKING_TIME,
} from "src/views/Command/Configuration/forms/CommandTimeFormBlock/constants";
import { commandPeriodFormDefaultValues } from "src/views/Command/Configuration/forms/commandFormDefaultValues";

import { getDayDiff, getDayOfWeek } from "src/utils/dates";

import { WORK_PERIOD_TYPES } from "src/constants";
import { DAYS_INDEXES_TABLE } from "src/constants/daysIndexesTable";
import { NIGHT_DAYS_INDEXES_TABLE } from "src/constants/nightDaysIndexesTable";

const defaultWorkingDaysSelected: WeekDaysSelectedType = {
	monday: true,
	tuesday: true,
	wednesday: true,
	thursday: true,
	friday: true,
	saturday: true,
	sunday: true,
};

const defaultWorkingNightsSelected: NightDaysSelectedType = {
	mondayNight: true,
	tuesdayNight: true,
	wednesdayNight: true,
	thursdayNight: true,
	fridayNight: true,
	saturdayNight: true,
	sundayNight: true,
};

export const selectDefaultWorkingDays = (
	momentStartDate?: moment.Moment | null,
	momentEndDate?: moment.Moment | null,
	workPeriodType: string = WORK_PERIOD_TYPES.DAY
) => {
	if (isNil(momentStartDate) || isNil(momentEndDate) || momentStartDate.isAfter(momentEndDate)) {
		if (workPeriodType === WORK_PERIOD_TYPES.DAY) {
			return commandPeriodFormDefaultValues.workingTime.workingDays;
		}

		return commandPeriodFormDefaultValues.workingTime.workingNights;
	}

	const startDate = momentStartDate.clone().utcOffset(0);
	const endDate = momentEndDate.clone().utcOffset(0);
	const startJSDate = startDate.toDate();
	const daysDiff = getDayDiff(startJSDate, endDate.toDate());

	// if more than 6 days, select all days
	if (daysDiff > 6) {
		if (workPeriodType === WORK_PERIOD_TYPES.DAY) {
			return defaultWorkingDaysSelected;
		} else {
			return defaultWorkingNightsSelected;
		}
	}

	// if less than 6 days, select only the days between start and end
	const isNight = workPeriodType === WORK_PERIOD_TYPES.NIGHT;
	const isDay = workPeriodType === WORK_PERIOD_TYPES.DAY;

	// For day mode, we need to add 1 to the index limit because we need to include the last day of the period
	const indexLimit = daysDiff + (isNight ? 0 : 1);
	const selectedDays: { [key: string]: boolean } = {};

	for (let i = 0; i < indexLimit; i += 1) {
		const dayIndex = getDayOfWeek(startJSDate);
		const day = isDay ? DAYS_INDEXES_TABLE[dayIndex] : NIGHT_DAYS_INDEXES_TABLE[dayIndex];
		selectedDays[day] = true;
		startJSDate.setDate(startJSDate.getDate() + 1);
	}

	return {
		...(isDay ? DEFAULT_DAY_WORKING_TIME.workingDays : DEFAULT_NIGHT_WORKING_TIME.workingNights),
		...selectedDays,
	};
};
