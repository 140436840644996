import { useState, useEffect } from "react";

import { merge, cloneDeep } from "lodash";
import { useTranslation } from "react-i18next";

// Services
import { getOrder } from "src/services/commands/getOrder";

// Utils
import commandFormDefaultValues, { commandQualificationFormDefaultValues } from "../forms/commandFormDefaultValues";
// Hooks
import useDuplication from "./useDuplication";

const useConfiguration = () => {
	const { t } = useTranslation();

	const { isInDuplicationMode, duplicatedOrderId } = useDuplication();

	const [duplicationWideLoader, setDuplicationWideLoader] = useState({
		displayed: isInDuplicationMode,
		message: isInDuplicationMode ? t("commands.duplication.loading") : "",
	});
	const [duplicatedInitialValues, setDuplicatedInitialValues] = useState(null);

	const prepareDuplicatedOrderValues = async () => {
		const orderData = await getOrder(duplicatedOrderId);
		const clonedOrderData = cloneDeep(orderData.data);

		// Add additional duplicated fields here
		const {
			type,
			nature,
			agencyId,
			commercialCode,
			qualificationNote,
			clientId,
			qualification,
			missionDescription,
			workPeriodType,
			workingTime,
			workAddress,
			contact,
			managementType,
			agencyInstructionCustomTempoId1,
			agencyInstructionCustomTempoId2,
			agencyInstructionCustomTempoId3,
			agencyInstructionReferenceSalaryTempoId1,
			agencyInstructionReferenceSalaryTempoId2,
			agencyInstructionSalaryTempoId1,
			agencyInstructionSalaryTempoId2,
			agencyInstructionBillingTempoId1,
			agencyInstructionBillingTempoId2,
			statusReasonDescription,
			commonQualificationId,
		} = clonedOrderData;

		const duplicatedValues = {
			type,
			nature,
			agencyId,
			commercialCode,
			qualificationNote,
			clientId,
			qualification: {
				...qualification,
				workerRequestedCount: commandQualificationFormDefaultValues.qualification.workerRequestedCount,
			},
			missionDescription,
			workPeriodType,
			workingTime: {
				slots: workingTime?.slots,
				particularWorkTime: workingTime?.particularWorkTime,
				periodNotWorking: workingTime?.periodNotWorking,
				firstDayStartHour: workingTime?.firstDayStartHour,
			},
			workAddress,
			contact: {
				...contact,
				// In case contact is deleted from tempo, we convert the contact to custom
				isCustom: !!(contact?.id === null && contact?.name),
			},
			managementType,
			agencyInstructionCustomTempoId1,
			agencyInstructionCustomTempoId2,
			agencyInstructionCustomTempoId3,
			agencyInstructionReferenceSalaryTempoId1,
			agencyInstructionReferenceSalaryTempoId2,
			agencyInstructionSalaryTempoId1,
			agencyInstructionSalaryTempoId2,
			agencyInstructionBillingTempoId1,
			agencyInstructionBillingTempoId2,
			statusReasonDescription,
			commonQualificationId,
		};

		const clonedCommandFormDefaultValues = cloneDeep(commandFormDefaultValues);
		const mergedValues = merge(clonedCommandFormDefaultValues, duplicatedValues);

		setDuplicatedInitialValues(mergedValues);
		setDuplicationWideLoader({
			displayed: false,
			message: "",
		});
	};

	useEffect(() => {
		if (isInDuplicationMode) {
			prepareDuplicatedOrderValues();
		}
	}, [isInDuplicationMode, duplicatedOrderId]);

	return {
		isInDuplicationMode,
		duplicationWideLoader,
		duplicatedInitialValues,
	};
};

export default useConfiguration;
