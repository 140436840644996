import { AxiosResponse } from "axios";

import client from "../client";

export interface SideMenuInformationsInterface {
	id: number;
	orderTempoId: string | null;
	orderStatus: string;
	qualificationName: string;
	agencyName: string;
	clientName: string;
	city: string | null;
	zipCode?: string;
	startDate: Date;
	endDate: Date;
	startHourAm: string | null;
	endHourAm: string | null;
	startHourPm: string | null;
	endHourPm: string | null;
	contactName: string | null;
	contactPhone: string | null;
	contactPhone2: string | null;
	contactEmail: string | null;
	notProvidedReason: string | null;
	statusReasonDescription: string | null;
}

const getOrderSideBarInformations = (orderId: string): Promise<AxiosResponse<SideMenuInformationsInterface>> => {
	return client.get<SideMenuInformationsInterface>(`/api-client-v2/orders/${orderId}/sidebar`);
};

export default getOrderSideBarInformations;
