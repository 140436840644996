import { FormikErrors, FormikTouched } from "formik";
import { get, isEmpty } from "lodash";
import { OrderInterface } from "src/types/OrderInterface";

export const showWorkingTimeTooltipError = (
	touched: FormikTouched<OrderInterface>,
	errors: FormikErrors<OrderInterface>
): boolean => {
	return get(touched?.workingTime, "slots", false) && !isEmpty(errors.workingTime?.slots);
};
