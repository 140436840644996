import { omit } from "lodash";
import { OrderInterface } from "src/types/OrderInterface";

import {
	DEFAULT_DAY_WORKING_TIME,
	DEFAULT_NIGHT_WORKING_TIME,
} from "src/views/Command/Configuration/forms/CommandTimeFormBlock/constants";
import { commandPeriodFormDefaultValues } from "src/views/Command/Configuration/forms/commandFormDefaultValues";

import { WORK_PERIOD_TYPES } from "src/constants";
import { WORKING_MODE } from "src/constants/workingMode";

const getDefaultWorkingTime = (workPeriodType: string) => {
	if (workPeriodType === WORK_PERIOD_TYPES.DAY) {
		return omit({ ...commandPeriodFormDefaultValues.workingTime, ...DEFAULT_DAY_WORKING_TIME }, [
			WORKING_MODE.NIGHTS,
		]);
	} else if (workPeriodType === WORK_PERIOD_TYPES.NIGHT) {
		return omit({ ...commandPeriodFormDefaultValues.workingTime, ...DEFAULT_NIGHT_WORKING_TIME }, [
			WORKING_MODE.DAYS,
		]);
	}

	throw new Error("Invalid workPeriodType");
};

export const getNewValuesOnModeChanged = (workPeriodType: string, values: OrderInterface, isNewCommand: boolean) => {
	const newValues = Object.assign({}, values, {
		workPeriodType: workPeriodType,
		workingTime: {
			...getDefaultWorkingTime(workPeriodType),
			particularWorkTime: values.workingTime.particularWorkTime,
			periodNotWorking: values.workingTime.periodNotWorking,
		},
	});

	if (!isNewCommand) {
		newValues.period = {
			startDate: null,
			endDate: null,
		};
	}

	return newValues;
};
