import { ChangeEvent, FC, KeyboardEvent, useEffect, useState } from "react";

import { InputControlled, Picto } from "@zolteam/react-ras-library";
import { Tag } from "src/components/atoms";
import { ListMaxWidth } from "src/components/organisms";

export type TItem = {
	name: string;
	id: string | number;
};

interface InputMultiProps {
	label: string;
	name: string;
	maxWidth?: string;
	initialValues?: TItem[];
	onDelete?: (item: TItem) => void;
	onAdd?: (item: TItem[]) => void;
	className?: string;
	classnameContainer?: string;
	disabled?: boolean;
}

export const InputMulti: FC<InputMultiProps> = ({
	label,
	name,
	maxWidth = "100%",
	onDelete,
	onAdd,
	initialValues,
	className,
	classnameContainer,
	disabled = false,
}) => {
	const [selectedItems, setSelectedItems] = useState<TItem[]>(initialValues ? [...initialValues] : []);
	const [inputValue, setInputValue] = useState("");

	const handleEnterPressed = (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Enter") {
			e.preventDefault();
			setSelectedItems((prev) => [...prev, { name: inputValue, id: selectedItems?.length + inputValue }]);
			setInputValue("");
			onAdd && onAdd([...selectedItems, { name: inputValue, id: selectedItems?.length + inputValue }]);
		}
	};

	useEffect(() => {
		if (initialValues) {
			setSelectedItems([...initialValues]);
		}
	}, [initialValues]);

	return (
		<div className={classnameContainer}>
			{selectedItems.length ? (
				<div
					style={{
						marginBottom: "var(--margin-s)",
					}}
				>
					<ListMaxWidth
						array={selectedItems}
						component={({ data: { name, id } }) => {
							return (
								<Tag color="primary" size="xs" key={id}>
									<span>{name}</span>
									<button
										type="button"
										className="noButton"
										aria-label="Delete"
										onClick={() => {
											setSelectedItems((prev) =>
												prev.filter((item) => {
													return item.id !== id;
												})
											);
											onDelete && onDelete({ name: name, id: id });
										}}
									>
										<Picto icon="close" />
									</button>
								</Tag>
							);
						}}
						keyListElem="id"
						margeError={36}
						maxWidth={maxWidth}
						maxWidthMarge={-2}
						addMaxWidthMarge={true}
					/>
				</div>
			) : null}

			<InputControlled
				type="text"
				name={name}
				label={label}
				className={className}
				onChange={(e: ChangeEvent<HTMLInputElement>) => {
					setInputValue(e.target.value);
				}}
				value={inputValue}
				onKeyDown={handleEnterPressed}
				disabled={disabled}
			/>
		</div>
	);
};
