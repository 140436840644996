import { TDocumentsTypes } from "src/types/TDocuments";
import { TDocumentsTypeDetails } from "src/types/TIntegration";
import { TMobileQualification } from "src/types/TQualifications";

import client from "./client";

class IntegrationServiceClass {
	getMobileQualifications(): Promise<TMobileQualification[]> {
		return client.get(`/api-client-v2/master-mobile-qualifications`).then((resp) => resp.data);
	}

	getDocumentDetails(documentId: number): Promise<TDocumentsTypeDetails> {
		return client.get(`/api-client-v2/common-documents/${documentId}`).then((resp) => resp.data);
	}

	archiveDocument(document: TDocumentsTypes) {
		return client.patch(`/api-client-v2/common-documents/${document.id}`, { ...document, isArchived: true });
	}

	unarchiveDocument(document: TDocumentsTypes) {
		return client.patch(`/api-client-v2/common-documents/${document.id}`, { ...document, isArchived: false });
	}

	createDocument(data: any) {
		return client.post("/api-client-v2/common-documents", data);
	}

	updateDocument(documentId: number, data: any) {
		return client.patch(`/api-client-v2/common-documents/${documentId}`, data);
	}
}

const IntegrationService = new IntegrationServiceClass();
export default IntegrationService;
