// @ts-nocheck
import React, { useEffect, useState, useRef } from "react";

// Hooks
import { useMatchingContext } from "../../../store/MatchingContext";

// Utils
import cn from "../../../utils/cn";
import MatchingProfileSectionDetails from "../../molecules/MatchingProfileSectionDetails";
import MatchingProfileSectionOtherOrders from "../../molecules/MatchingProfileSectionOtherOrders";

// style
import style from "./MatchingProfileSections.module.css";

const MatchingProfileSections = () => {
	const [{ unifiedTempWorkerId }] = useMatchingContext();
	const [sectionsOpenStatus, setSectionsOpenStatus] = useState([false, false]); // Matches the number of worker sections
	const renderedSections = useRef(false);
	const currentSectionIndex = useRef(0);

	// Does not handle reverse animation
	useEffect(() => {
		if (!renderedSections.current && unifiedTempWorkerId) {
			renderedSections.current = true;
			// In an interval of 300ms, set the sections open status to true, starting from the first
			const interval = setInterval(() => {
				setSectionsOpenStatus((prev) => {
					if (currentSectionIndex.current === 2) {
						clearInterval(interval);
						return [true, true];
					}

					const newValue = [...prev];
					newValue[currentSectionIndex.current] = true;

					currentSectionIndex.current += 1;
					return newValue;
				});
			}, 100);

			return () => clearInterval(interval);
		}
		return () => {};
	}, [unifiedTempWorkerId]);

	const sections = [
		{
			name: "Details",
			node: <MatchingProfileSectionDetails />,
		},
		{
			name: "OtherOrders",
			node: <MatchingProfileSectionOtherOrders />,
		},
	];

	return (
		<div className={style.sections}>
			{sections.map((section, index) => (
				<div
					key={section.name}
					className={cn([
						style.profileDetailsSection,
						sectionsOpenStatus[index] ? style.profileDetailsSectionOpen : null,
					])}
				>
					{section.node}
				</div>
			))}
		</div>
	);
};

export default MatchingProfileSections;
