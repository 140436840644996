// @ts-nocheck
import { useEffect, useMemo, useState } from "react";

import { useFormikContext } from "formik";
import { omit } from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";

// Services
import { getInterlocutors } from "../../../../../services/commandService";

// Constants
import { useConfigurationContext } from "src/store/ConfigurationContext";

// Constants
import { CREATE_MODE, EDIT_MODE, TITLE_MR, TITLE_MS } from "../../../../../constants";
// Utils
import capitalizeFirstCharacter from "../../../../../utils/capitalizeFirstCharacter";
import cn from "../../../../../utils/cn";
import formatPhoneNumber from "../../../../../utils/formatPhoneNumber";
import { commandInterlocutorFormDefaultValues } from "../commandFormDefaultValues";
// Style
import { InfoMessage, InputControlled, RadioButton, SelectAsync, Tooltip } from "@zolteam/react-ras-library";
// Components
import { Field } from "src/components/molecules";

import style from "./CommandInterlocutorFormBlock.module.css";

const DEFAULT_INTERLOCUTOR_OPTION = {
	...commandInterlocutorFormDefaultValues.contact,
	name: "Autre",
	isCustom: true,
};

const CommandInterlocutorFormBlock = ({ isOrderFetched }) => {
	// Hooks
	const { values, setFieldValue, handleBlur, setFieldTouched } = useFormikContext();
	const { contact, agencyId, clientId } = values;
	const { t } = useTranslation();
	const { orderId } = useParams();
	const isNewCommand = orderId === CREATE_MODE;
	const commandFormMode = isNewCommand ? CREATE_MODE : EDIT_MODE;
	const [contactOptions, setContactOptions] = useState(null);
	const [fieldsModified, setFieldsModified] = useState(false);
	const [initialFormValues, setInitialFormValues] = useState({});
	const [isClientEdited] = useConfigurationContext();

	const isInterlocutorCustom = !contact?.id;

	useEffect(() => {
		setInitialFormValues(values);
	}, [values?.contact?.id]);

	useMemo(() => {
		const areFieldsModified =
			values.contact.name !== initialFormValues.contact?.name ||
			values.contact.function !== initialFormValues.contact?.function ||
			values.contact.phone !== initialFormValues.contact?.phone ||
			values.contact.phone2 !== initialFormValues.contact?.phone2 ||
			values.contact.email !== initialFormValues.contact?.email ||
			values.contact.title !== initialFormValues.contact?.title;

		if (areFieldsModified) {
			setFieldsModified(true);
		} else {
			setFieldsModified(false);
		}
	}, [values, initialFormValues]);

	// API Call
	const interlocutorsOptionsAsync = () => {
		if (!agencyId || !clientId) return Promise.resolve([]);
		return getInterlocutors(agencyId, clientId).then((res) => {
			const newContactsList = [
				...(res?.data?.map((item) => ({ ...item, isCustom: false })) || []),
				DEFAULT_INTERLOCUTOR_OPTION,
			];

			if (commandFormMode === EDIT_MODE) {
				// Is Custom should be true if contact has no ID and at least one of the attributes has been filled
				// (except isCustom). We have no other way to check that, than checking all attributes one by one for now.
				const hasValuesInContactFields = Object.keys(omit(values?.contact, ["id", "isCustom"])).some(
					(contactKey) => values?.contact[contactKey] !== null
				);

				setFieldValue("contact", {
					...values.contact,
					isCustom: hasValuesInContactFields && !values?.contact?.id,
				});
			}

			setContactOptions(newContactsList);
			return newContactsList;
		});
	};

	const handleContactChange = (selectedContact) => {
		// in any case, manually touch custom fields to trigger validation
		setFieldTouched("contact.phone", true);
		setFieldTouched("contact.email", true);

		// Select is cleared
		if (!selectedContact) return setFieldValue("contact", commandInterlocutorFormDefaultValues.contact);

		// Other interlocutor is selected
		if (selectedContact?.isCustom) {
			return setFieldValue("contact", {
				...DEFAULT_INTERLOCUTOR_OPTION,
				name: "",
			});
		}

		// Existing interlocutor is selected
		return setFieldValue("contact", {
			...selectedContact,
			phone: formatPhoneNumber(selectedContact?.phone || ""),
			phone2: formatPhoneNumber(selectedContact?.phone2 || ""),
		});
	};

	const handleFieldBlur = (event) => {
		handleBlur(event.target.name);
		setFieldValue("contact.isCustom", true);
	};

	const handlePhoneBlur = (event) => {
		handleFieldBlur(event);
		setFieldValue("contact.phone", formatPhoneNumber(event.target.value));
	};

	const interlocutorSelectValue = useMemo(() => {
		// interlocutor select field is empty
		if (!contact?.isCustom && contact?.id === null) return null;

		// interlocutor select field is set to "Autre"
		if (isInterlocutorCustom) return DEFAULT_INTERLOCUTOR_OPTION;

		// interlocutor select field is set to an existing interlocutor
		return {
			...contact,
			name: contactOptions?.find((item) => item.id === contact.id)?.name || "",
		};
	}, [contact, isInterlocutorCustom, contactOptions]);

	if ((!isNewCommand && !isOrderFetched) || (!isNewCommand && (!agencyId || !clientId) && !isClientEdited)) {
		return (
			<div className={style.skeleton}>
				<Skeleton height={45} borderRadius={25} />
			</div>
		);
	}

	return (
		<div className={style.content}>
			<div
				className={
					interlocutorSelectValue ? style.interlocutorBox : cn([style.interlocutorBox, style.onlyElement])
				}
			>
				<SelectAsync
					key={`${clientId}-${agencyId}`}
					isClearable
					isSearchable={false}
					cacheOptions
					defaultOptions
					promiseOptions={interlocutorsOptionsAsync}
					onChange={handleContactChange}
					name={isInterlocutorCustom ? "contact.isCustom" : "contact.id"}
					label={t("commands.interlocutor")}
					getOptionLabel={(option) => option.name}
					getOptionValue={(option) => option.id}
					value={interlocutorSelectValue}
					noOptionsMessage={() => t("commands.selectNoInterlocutorsFound")}
					loadingMessage={() => t("commands.selectLoadingInterlocutors")}
					className={style.interlocutorInput}
				/>
				{contact?.id && fieldsModified ? (
					<Tooltip
						placement="top"
						animation={false}
						arrow={false}
						content={<span> {t("commands.interlocutorChange")}</span>}
					>
						<div className={style.infoMessage}>
							<InfoMessage withIcon />
						</div>
					</Tooltip>
				) : null}

				{contact?.name === DEFAULT_INTERLOCUTOR_OPTION.name ? (
					<div className={style.messageContainer}>
						<InfoMessage withIcon>{t("commands.otherInTempo")}</InfoMessage>
					</div>
				) : null}
			</div>

			{contact?.id || contact?.isCustom ? (
				<>
					<div className={style.title}>
						<RadioButton
							key={TITLE_MS}
							data-testid={`radio_${TITLE_MS}`}
							label={t("commands.female")}
							name={`contact_title_${TITLE_MS}`}
							onChange={() => setFieldValue("contact.title", TITLE_MS)}
							className={style.titleRadio}
							checked={contact.title === TITLE_MS}
							color="primary"
						/>
						<RadioButton
							key={TITLE_MR}
							data-testid={`radio_${TITLE_MR}`}
							label={t("commands.male")}
							name={`contact_title_${TITLE_MR}`}
							onChange={() => setFieldValue("contact.title", TITLE_MR)}
							className={style.titleRadio}
							checked={contact.title === TITLE_MR}
							color="primary"
						/>
						<Field
							type="text"
							name="contact.name"
							id="contact.name"
							label={t("commands.name")}
							classNameContainer={cn([style.field, style.fieldName])}
							className={cn([style.capitalize])}
							onBlur={handleFieldBlur}
							customErrorDisplay
						/>
					</div>

					<div className={style.function}>
						<InputControlled
							type="text"
							name="contact.function"
							id="contact.function"
							className={style.field}
							label={t("commands.function")}
							value={values.contact.function}
							onChange={(e) =>
								setFieldValue("contact.function", capitalizeFirstCharacter(e.target.value))
							}
						/>
					</div>
					<div className={style.phone}>
						<Field
							type="tel"
							name="contact.phone"
							id="contact.phone"
							label={t("commands.phone")}
							classNameContainer={cn([style.field, style.phoneField])}
							onBlur={handlePhoneBlur}
							customErrorDisplay
						/>
					</div>

					<div className={style.email}>
						<Field
							type="email"
							name="contact.email"
							id="contact.email"
							label={t("commands.email")}
							classNameContainer={style.field}
							className={cn([style.lowercase])}
							onBlur={handleFieldBlur}
							customErrorDisplay
						/>
					</div>
				</>
			) : null}
		</div>
	);
};

CommandInterlocutorFormBlock.propTypes = {
	isOrderFetched: PropTypes.bool.isRequired,
};

export default CommandInterlocutorFormBlock;
