// @ts-nocheck

/* eslint-disable max-len */

/* eslint-disable import/prefer-default-export */
import client from "./client";

const PREFIX = "/api-client-v2/unified-temp-worker";

export const getClientRelationship = (unifiedId, id) => client.get(`${PREFIX}/${unifiedId}/client-relation/${id}`);

/**
 * @description Creates a new client relationship
 * @param {String} unifiedId // Unified temp worker id
 * @param {String} data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const createClientRelationship = (unifiedId, data) =>
	client.post(`${PREFIX}/${unifiedId}/client-relation`, data);

/**
 * @description Update a client relationship
 * @param {String} unifiedId // Unified temp worker id
 * @param {String} id // Client relationship id
 * @param {String} data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateClientRelationship = (unifiedId, id, data) =>
	client.put(`${PREFIX}/${unifiedId}/client-relation/${id}`, data);

/**
 * @description Gets the list of client relationships
 * @param {String} unifiedId // Unified temp worker id
 * @param {String} data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const searchClientRelationships = (unifiedId, data) =>
	client.post(`${PREFIX}/${unifiedId}/client-relation/search`, data);

/**
 * @description Get list of agencies for a given temp worker
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getWorkerAgencies = (id) => client.get(`${PREFIX}/${id}/agencies`);

/**
 * @description Gets the list of client contracts
 * @param {String} id // Unified temp worker id
 * @param {String} data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const searchClientContracts = (id, data) => client.post(`${PREFIX}/${id}/contract/search`, data);

export const getUnifiedTempWorkerAdministrative = (uniqueId, signal) =>
	client.get(`/api-client-v2/unified-temp-workers/${uniqueId}/administrative-information`, {
		signal,
	});

export const updateUnifiedTempWorkerAdministrative = (userId: string, data: any) => {
	return client.put(`/api-client-v2/unified-temp-workers/${userId}/administrative-information`, data);
};

export const getAvatar = (utwId: number): Promise<AxiosResponse<{ link: string }>> => {
	return client.get(`${PREFIX}/users/${utwId}/avatar`);
};
