// @ts-nocheck
import { useCallback, useEffect, useMemo, useRef } from "react";

import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

// Components
import { TooltipMessage } from "../../../../../components/atoms/index";
// Constants
import {
	COMMAND_BILLING_PACKAGES,
	COMMAND_TYPE_NEW_ORDER,
	CREATE_MODE,
	MANAGEMENT_TYPE,
} from "../../../../../constants";
// Utils
import prefillOrderPackage from "../../../../../utils/prefillOrderPackage";
import { InputControlled, RadioButton, SelectV2 } from "@zolteam/react-ras-library";

// Style
import style from "./BillingFormBlock.module.css";

const BillingFormBlock = () => {
	const { t } = useTranslation();
	const { orderId } = useParams();
	const { values, setFieldValue, errors, handleBlur, setValues } = useFormikContext();
	const currentDate = useRef(new Date());

	const { clientOption, package: packageValue, packageAmount, nature } = values;

	const isOrderInEdition = orderId !== CREATE_MODE;

	const setPackageValue = useCallback(
		(selectedPackage) => {
			setValues({
				...values,
				package: selectedPackage.value === "none" ? null : selectedPackage.value,
				packageAmount: selectedPackage.amount,
			});
		},
		[values, setValues]
	);

	const clientPackagesOptions = useMemo(() => {
		if (clientOption?.clientPackages) {
			return [
				{
					label: t("commands.billingBlock.packageTypes.none"),
					value: "none",
				},
				...Object.values(COMMAND_BILLING_PACKAGES).map((billingPackage) => {
					const packageFound = clientOption?.clientPackages.find((p) => p.name === billingPackage);

					return {
						label: t(`commands.billingBlock.packageTypes.${billingPackage}`),
						value: packageFound?.name || "unknown",
						amount: packageFound?.amount || null,
					};
				}),
			];
		}
		return [];
	}, [clientOption]);

	useEffect(() => {
		if (!isOrderInEdition && clientPackagesOptions.length > 0) {
			setTimeout(() => {
				if (nature === COMMAND_TYPE_NEW_ORDER) {
					const packageByDate = prefillOrderPackage(currentDate.current);

					setPackageValue(clientPackagesOptions.find((p) => p.value === packageByDate));
				} else {
					setValues({
						...values,
						package: null,
						packageAmount: null,
					});
				}
			}, 10);
		}
	}, [isOrderInEdition, nature, clientPackagesOptions]);

	const handleFieldBlur = (event) => {
		handleBlur(event.target.name);
	};

	return (
		<div className={style.content}>
			<div className={style.managementTypeSection}>
				<div className={style.radioBox}>
					<RadioButton
						key="delegation"
						label={t("commands.delegation")}
						name={`managementType_${MANAGEMENT_TYPE.DELEGATION}`}
						className={values.managementType ? "" : style.radioError}
						onChange={() => setFieldValue("managementType", "delegation")}
						checked={values.managementType === MANAGEMENT_TYPE.DELEGATION}
						color="primary"
					/>
					<RadioButton
						key="gestion"
						label={t("commands.gestion")}
						name={`managementType_${MANAGEMENT_TYPE.GESTION}`}
						className={values.managementType ? "" : style.radioError}
						onChange={() => setFieldValue("managementType", "gestion")}
						checked={values.managementType === MANAGEMENT_TYPE.GESTION}
						color="primary"
					/>
				</div>
				{errors.managementType && (
					<TooltipMessage className={style.tooltipMessage} color="error">
						{t("commands.requiredField")}
					</TooltipMessage>
				)}
			</div>
			<div className={style.packageSection}>
				<SelectV2
					options={clientPackagesOptions}
					onChange={setPackageValue}
					name="package"
					placeholder={t("commands.billingBlock.packageType")}
					label={t("commands.billingBlock.packageType")}
					value={clientPackagesOptions.find((p) => p.value === packageValue)?.value || "none"}
					isClearable={false}
					error={!!errors.agencyId}
					noOptionsMessage={() => t("global.noDataText")}
					errorMessage={t("profile.followUp.form.agencyId.error")}
					className={style.packageSelect}
				/>
				{packageValue !== null && (
					<>
						<InputControlled
							type="number"
							name="packageAmount"
							id="packageAmount"
							step={0.1}
							className={style.packageAmountInput}
							error={!!errors.packageAmount}
							placeholder={t("commands.billingBlock.packageAmount")}
							label={t("commands.billingBlock.packageAmount")}
							value={packageAmount === null ? 0 : packageAmount}
							onChange={(e) => setFieldValue("packageAmount", Number(e.target.value))}
							onBlur={handleFieldBlur}
							picto={{
								name: "euro",
								color: "grey",
							}}
						/>
						{errors.packageAmount && <TooltipMessage color="error">{errors.packageAmount}</TooltipMessage>}
						{!errors.packageAmount && packageAmount === null && (
							<TooltipMessage color="primary">{t("commands.billingBlock.noTempoData")}</TooltipMessage>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default BillingFormBlock;
