import { ReactNode } from "react";

import style from "../ModalContentProfileCardDetails.module.css";

const activityDetailTemplate = (
	index: number,
	content: ReactNode,
	orderMessagingId: string,
	concatenatedActivitiesLength: number
) => (
	<div className={style.activity} key={orderMessagingId}>
		<div className={style.dot} />
		{index !== concatenatedActivitiesLength - 1 && <div className={style.verticalBar} />}
		<div className={style.activityDetail}>{content}</div>
	</div>
);

export default activityDetailTemplate;
