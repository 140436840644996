import { isNil, isNumber } from "lodash";
import OrderStepDisplaySectionType from "src/types/OrderStepDisplaySectionType";

// TODO : params must be typed
const useStepDisplaySection = (
	agencyId: number | null,
	clientId: number | null,
	qualification: any,
	agencyOption: any,
	commercialCode: any
): OrderStepDisplaySectionType => {
	const hasCommercialCode = agencyOption?.isGeneric === false || isNil(commercialCode) === false;
	const hasQualificationId = isNumber(qualification?.id) === true;

	return {
		shouldDisplaySecondStep: agencyId !== null && hasCommercialCode,
		shouldDisplayThirdStep: clientId !== null && hasQualificationId,
	};
};

export default useStepDisplaySection;
