// Style
import { FC, PropsWithChildren } from "react";

import Skeleton from "react-loading-skeleton";

import style from "./IdBox.module.css";

interface IdBoxProps extends PropsWithChildren {
	isLoading?: boolean;
}

export const IdBox: FC<IdBoxProps> = ({ children = null, isLoading = false }) => {
	if (isLoading) {
		return (
			<div className="w-full m-1">
				<Skeleton
					className=""
					baseColor="var(--color-neutral-700)"
					highlightColor="var(--color-neutral-600)"
					height={30}
					width={"100%"}
				/>
			</div>
		);
	}
	return <div className={style.container}>{children}</div>;
};
