import { FormikErrors, FormikTouched } from "formik";
import { get, isNil } from "lodash";
import { OrderInterface } from "src/types/OrderInterface";

export const isPeriodError = (touched: FormikTouched<OrderInterface>, errors: FormikErrors<OrderInterface>) => {
	return (
		(get(touched?.period, "startDate", false) && !isNil(errors?.period?.startDate)) ||
		(get(touched?.period, "endDate", false) && !isNil(errors?.period?.endDate))
	);
};
