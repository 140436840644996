// @ts-nocheck
const compareTimesSlots = (initial, actual) => {
	for (let i = 0; i < initial.length; i += 1) {
		const initialObj = initial[i];
		const actualObj = actual[i];

		if (initialObj?.startHour !== actualObj?.startHour || initialObj?.endHour !== actualObj?.endHour) {
			return true;
		}
	}

	return false;
};

export default compareTimesSlots;
