// @ts-nocheck
import * as Yup from "yup";

import i18n from "../../../../../../i18n";

export default Yup.object().shape({
	followUpType: Yup.string().oneOf(["assessment", "incident"]),
	agencyId: Yup.number(),
	clientId: Yup.number().required(i18n.t("profile.followUp.form.client.error")),
	contractId: Yup.number().nullable(),
	startDate: Yup.date().nullable().required(i18n.t("commands.error.mandatoryStartDateField")),
	endDate: Yup.date().nullable().required(i18n.t("commands.error.mandatoryEndDateField")),
	clientQualificationId: Yup.string().nullable(),
	note: Yup.number().min(0).max(4).nullable(),
	reporting: Yup.string().max(1000).nullable(),
	adequacy: Yup.string().nullable(),
	author: Yup.string().max(17, i18n.t("profile.followUp.form.author.lengthError")).nullable(),
	attachment: Yup.mixed().nullable(),
	incidentTypeId: Yup.number().when("followUpType", {
		is: "incident",
		then: () => Yup.number().required(i18n.t("profile.followUp.form.incidentTypeId.error")),
		otherwise: () => Yup.number().nullable(),
	}),
	incidentDate: Yup.string().when("followUpType", {
		is: "incident",
		then: () => Yup.string().required(i18n.t("profile.followUp.form.incidentDate.error")),
		otherwise: () => Yup.string().nullable(),
	}),
});
