// @ts-nocheck
import { useMemo } from "react";

import { useFormikContext } from "formik";
import { orderBy } from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";

// Services
import { getAppealCases } from "../../../../../services/commandService";

// Components
import { OptionAppealCase } from "../../../../../components/atoms/index";
// Constants
import { CREATE_MODE, EDIT_MODE } from "../../../../../constants";
// Style
import cn from "../../../../../utils/cn";
// Misc
import { commandAppealCaseFormDefaultValues } from "../commandFormDefaultValues";
import { Select, SelectAsync, Textarea } from "@zolteam/react-ras-library";

import style from "./CommandAppealCaseFormBlock.module.css";

const CommandAppealCaseFormBlock = ({ isOrderFetched }) => {
	// Hooks
	const { values, setFieldValue, setValues } = useFormikContext();
	const { agencyId, appealCase, appealCaseOption } = values;
	const { t } = useTranslation();
	const { orderId } = useParams();
	const isNewCommand = orderId === CREATE_MODE;
	const commandFormMode = isNewCommand ? CREATE_MODE : EDIT_MODE;

	// API Call
	const appealCasesOptionsAsync = () => {
		if (!agencyId) return Promise.resolve([]);
		return getAppealCases(agencyId).then((res) => {
			if (commandFormMode === EDIT_MODE && res.data.length !== 0) {
				const selectedAppealCase = res?.data?.find(
					(appealCaseData) => appealCaseData.id === values?.appealCase?.id
				);
				setFieldValue("appealCaseOption", selectedAppealCase);
			}
			return orderBy(res.data, ["tempoId"]);
		});
	};

	const appealCaseModelOption = useMemo(
		() => appealCaseOption?.appealCaseModels?.find((model) => model.id === appealCase.model?.id),
		[appealCaseOption, appealCase.model]
	);

	const handleSelectAppealCase = (selectedOption) => {
		const isThereSingleModel = selectedOption?.appealCaseModels?.length === 1;

		setValues({
			...values,
			appealCaseOption: selectedOption,
			appealCase: {
				id: selectedOption?.id || null,
				tempoId: selectedOption?.id,
				model: isThereSingleModel
					? selectedOption.appealCaseModels[0]
					: { ...commandAppealCaseFormDefaultValues.appealCase.model },
			},
		});
	};

	const handleSelectAppealCaseModel = (selectedOption) => {
		if (!selectedOption) {
			setFieldValue("appealCase.model", {
				id: null,
				template: null,
			});
			return;
		}
		setFieldValue("appealCase.model", { ...selectedOption });
	};

	if ((!isNewCommand && !isOrderFetched) || (!isNewCommand && !agencyId)) {
		return (
			<div className={style.skeleton}>
				<div className={style.skeletonRow}>
					<Skeleton containerClassName={style.skeletonCase} height={50} borderRadius={25} />
					<Skeleton containerClassName={style.skeletonModel} height={50} borderRadius={25} />
				</div>
				<div>
					<Skeleton containerClassName={style.skeletonFullWidth} height={100} borderRadius={25} />
				</div>
			</div>
		);
	}

	return (
		<div className={style.content}>
			<div className={style.selectCase}>
				<SelectAsync
					isClearable
					isSearchable={false}
					cacheOptions
					defaultOptions
					promiseOptions={appealCasesOptionsAsync}
					onChange={handleSelectAppealCase}
					name="appealCase.id"
					label={t("commands.appealCase")}
					getOptionLabel={(option) => option.designation}
					getOptionValue={(option) => option.id}
					formatOptionLabel={(option) => (
						<OptionAppealCase
							key={option.id}
							tempoId={`${option.tempoId}`}
							designation={`${option.designation}`}
						/>
					)}
					value={appealCaseOption}
					noOptionsMessage={() => t("commands.selectNoAppealCaseFound")}
					loadingMessage={() => t("commands.selectLoadingAppealCase")}
				/>
			</div>
			<div className={style.selectModel}>
				<Select
					isClearable
					isSearchable={appealCaseOption?.appealCaseModels?.length > 0}
					disabled={!appealCase?.id}
					options={appealCaseOption?.appealCaseModels}
					onChange={handleSelectAppealCaseModel}
					name="appealCase.model.id"
					label={t("commands.appealCaseModel")}
					value={appealCaseModelOption || null}
					getOptionLabel={(option) => option.template}
					getOptionValue={(option) => option.id}
					noOptionsMessage={() => t("commands.selectNoAppealCaseModelFound")}
					loadingMessage={() => t("commands.selectLoadingAppealCaseModel")}
				/>
			</div>
			<Textarea
				name="appealCase.model.template"
				disabled={!appealCase?.id}
				placeholder={t("commands.mission.textareaPlaceholder")}
				className={cn([style.mtm, style.textarea])}
				label={null}
			/>
		</div>
	);
};

CommandAppealCaseFormBlock.propTypes = {
	isOrderFetched: PropTypes.bool.isRequired,
};

export default CommandAppealCaseFormBlock;
