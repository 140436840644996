const toggleModal = (
	modalName: string,
	isOpen: boolean,
	setModalStates: React.Dispatch<React.SetStateAction<Record<string, boolean>>>
) => {
	setModalStates((prevStates) => ({
		...prevStates,
		[modalName]: isOpen,
	}));
};

export default toggleModal;
