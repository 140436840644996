export const CURRENT_AVAILABLE_FILTERS = {
	EQUIPMENTS: "EQUIPMENTS_V1",
	GEO_DISTANCE: "GEO_DISTANCE_V1",
	MEDICAL_CHECKUP: "MEDICAL_CHECKUP_V1",
	OUT_OF_BUSINESS: "OUT_OF_BUSINESS_V1",
	QUALIFICATION: "QUALIFICATION_V1",
	REFERENCE_AGENCY: "REFERENCE_AGENCY_V1",
	STATUS: "STATUS_V1",
	VEHICLES: "VEHICLES_V1",
	DOCUMENTS: "COMMON_DOCUMENT_V1",
	PERIOD: "PERIOD_V1",
	UNAVAILABILITY_ORDER_PERIODS_V1: "UNAVAILABILITY_ORDER_PERIODS_V1",
	UNAVAILABILITY_ORDER_PERIODS_V2: "UNAVAILABILITY_ORDER_PERIODS_V2",
	AVAILABILITY_UNIFIED_TEMP_WORKER_V1: "AVAILABILITY_UNIFIED_TEMP_WORKER_V1",
	AVAILABILITY_UNIFIED_TEMP_WORKER_V2: "AVAILABILITY_UNIFIED_TEMP_WORKER_V2",
	UNAVAILABILITY_CONTRACT_PERIODS_V1: "UNAVAILABILITY_CONTRACT_PERIODS_V1",
	TIME_OFF_V1: "TIME_OFF_V1",
	TIME_OFF_V2: "TIME_OFF_V2",
	ADEQUATE_FOR_CLIENT: "ADEQUATE_FOR_CLIENT_V1",
	ALREADY_WORKED_FOR_CLIENT: "ALREADY_WORKED_FOR_CLIENT_V1",
};
