import React, { ReactNode, useContext } from "react";

// Store
import TableListingContext from "../../../store/TableListingContext";

interface IColumnListingProps<P> {
	field: (data: any) => any;
	component: React.FunctionComponent<P & { data: any }>;
	genericEmptyCellsMessage?: React.ReactNode;
	id?: string;
	name?: ReactNode | string;
	isSortable?: boolean;
	containsLink?: boolean;
}

export const ColumnListing = <P extends {}>({
	field,
	component,
	genericEmptyCellsMessage,
	id,
	name,
}: IColumnListingProps<P>) => {
	const { data } = useContext(TableListingContext);
	const cellData = field(data);
	//Determines if the cell has data to display generic message
	const hasData = Object.values(cellData).some((value) => {
		if (Array.isArray(value)) {
			// Vérifiez si le tableau est vide
			return value.length > 0;
		}
		return value != null && value !== "" && value !== undefined;
	});

	//TODO: Add a generic message to display when the cell is empty, check all table listings
	return (
		<>
			{hasData || !genericEmptyCellsMessage
				? React.createElement(component, {
						data: field(data),
					} as P & { data: any })
				: genericEmptyCellsMessage}
		</>
	);
};
