// @ts-nocheck
import React, { FC } from "react";

import moment from "moment";

import { DATE_REGEX } from "../../../constants";
import { Popover } from "@zolteam/react-ras-library";
import { DatePicker, DateRangeInput } from "src/components/atoms";

interface IDatePickerFieldProps {
	label: string;
	onChange: (date: Date) => void;
	value: Date;
}

export const DatePickerField: FC<IDatePickerFieldProps> = ({ label, onChange, value }) => {
	const [Value, setValue] = React.useState(value);

	const getCurDate = (date: string) => {
		if (!date?.length || !DATE_REGEX.test(date)) return Value;

		return new Date(date.split("/").reverse().join("/"));
	};

	return (
		<div style={{ position: "relative" }}>
			<Popover
				key={Date.now().toString()}
				content={
					<DatePicker
						dropdownMode="select"
						inline
						selected={value ? getCurDate(value) : null}
						onChange={(val) => {
							setValue(val);
							onChange(val);
						}}
					/>
				}
			>
				<DateRangeInput
					label={label}
					numberOfMonths={1}
					value={value ? moment(getCurDate(value)).format("DD/MM/YYYY") : null}
					onClick={() => null}
					onClear={(e) => {
						e.stopPropagation();
						onChange(null);
					}}
				/>
			</Popover>
		</div>
	);
};
