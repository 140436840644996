import { isNil } from "lodash";

import { validateTime } from "@zolteam/react-ras-library";

const isValidValidationTime = (value: string | null): boolean => {
	if (isNil(value)) {
		return true;
	}

	return validateTime(value);
};
export default isValidValidationTime;
