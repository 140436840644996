// @ts-nocheck
import { getAtomFromDate } from "../../../../../../utils/formatDate";

// Filters Modal sections
const followUpFormDefaultValues = {
	followUpType: "assessment", // "assessment" | "incident"
	agencyId: null,
	clientId: null,
	contractId: null,
	startDate: getAtomFromDate(new Date()),
	endDate: getAtomFromDate(new Date()),
	clientQualificationId: null,
	note: null,
	reporting: null,
	adequacy: null, // "adequate" | "inadequate"
	author: null,
	attachment: null,
	incidentTypeId: null,
	incidentDate: null,
};

export default followUpFormDefaultValues;
