// @ts-nocheck
// Templates
import { WithSideMenu, WithTopBarre } from "src/components/templates";

const Home = () => (
	<WithSideMenu>
		<WithTopBarre quickEntryContent={<div>test</div>}>
			<div>Bienvenue sur myDaily !</div>
		</WithTopBarre>
	</WithSideMenu>
);

export default Home;
