import { FC, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { Prompt } from "react-router-dom";

import { ConfirmModal } from "src/components/molecules/ConfirmModal/ConfirmModal";

interface IBlockNavigationProps {
	navigate: any;
	when?: boolean;
	shouldBlockNavigation: any;
	title?: string;
	text?: string;
}

export const BlockNavigation: FC<IBlockNavigationProps> = ({ navigate, when, shouldBlockNavigation, title, text }) => {
	const { t } = useTranslation();
	const [modalVisible, updateModalVisible] = useState(false);
	const [lastLocation, updateLastLocation] = useState<any>();
	const [confirmedNavigation, updateConfirmedNavigation] = useState(false);

	const showModal = (location: any) => {
		updateModalVisible(true);
		updateLastLocation(location);
	};

	const closeModal = (cb: () => void) => {
		updateModalVisible(false);
		if (cb && typeof cb === "function") {
			cb();
		}
	};

	const handleBlockedNavigation = (nextLocation: any) => {
		if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
			showModal(nextLocation);
			return false;
		}
		return true;
	};
	const handleConfirmNavigationClick = () => {
		closeModal(() => {
			if (lastLocation) {
				updateConfirmedNavigation(true);
			}
		});
	};

	useEffect(() => {
		if (confirmedNavigation) {
			lastLocation && navigate(lastLocation?.pathname);
			updateConfirmedNavigation(false);
		}
	}, [confirmedNavigation]);

	return (
		<>
			<Prompt when={when} message={handleBlockedNavigation} />
			<ConfirmModal
				title={title ?? t("global.blockNavigation.title")}
				text={
					text ?? (
						<div>
							<span>{t("global.blockNavigation.text")}</span>
							<div>{t("global.blockNavigation.info")}</div>
						</div>
					)
				}
				isOpen={modalVisible}
				onClose={closeModal}
				onCancel={closeModal}
				onConfirm={handleConfirmNavigationClick}
				confirmText={t("global.blockNavigation.leave")}
				cancelText={t("global.blockNavigation.stay")}
				size="s"
				switchButton
			/>
		</>
	);
};
