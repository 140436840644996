import { TTempoparyWorkerSummary, TTemporaryWorkerDocuments, TTemporaryWorkerProfile } from "src/types";

import client from "./client";

class UserTemporaryWorkerClass {
	getUserTemporaryWorker = (userId: string): Promise<{ data: TTemporaryWorkerProfile }> => {
		return client.get(`/api-client-v2/users/${userId}/profile`);
	};

	getDocuments = (unifiedTempWorkerId: string, signal: AbortSignal): Promise<TTemporaryWorkerDocuments[]> => {
		return client
			.get(`/api-client-v2/unified-temp-workers/${unifiedTempWorkerId}/common-documents`, {
				signal,
			})
			.then((resp) => resp.data);
	};

	getUserTemporaryWorkerSummary = (userId: string, signal: AbortSignal): Promise<TTempoparyWorkerSummary> => {
		return client.get(`/api-client-v2/users/${userId}/summary-information`, { signal }).then((res) => res.data);
	};
}
const UserTemporaryWorkerService = new UserTemporaryWorkerClass();
export default UserTemporaryWorkerService;
