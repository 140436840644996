const PICTO_STATUS_VARIANTS = {
	FAILURE: "error",
	UNDELIVERED: "error",
	SUCCESS: "filled",
	IN_PROGRESS: "disabled",
};

const handlePictoVariant = (status: keyof typeof PICTO_STATUS_VARIANTS): string => {
	return PICTO_STATUS_VARIANTS[status] || "error";
};

export default handlePictoVariant;
