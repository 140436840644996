// @ts-nocheck

/* eslint-disable max-len */
import React from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { format } from "date-fns";
import localeFr from "date-fns/locale/fr";

// Hooks
import useOrderOffer from "../../../hooks/useOrderOffer";

// Utils
import cn from "../../../utils/cn";
import { Tag, Text } from "@zolteam/react-ras-library";

// Style
import style from "./JobOffer.module.css";

const defaultOrder = {
	missionName: "",
	clientName: "",
	meetingplace: "",
	missionDate: "",
	missionTime: "",
	workingTime: "",
	jobDescription: "",
	agenceName: "",
	missionType: "",
	furtherInformation: "",
	workingDaysFormatted: "",
};

const JobOffer = ({
	currentOrder = { ...defaultOrder },
	showCityAndPostalCode = true,
	showClientName = true,
	context,
}) => {
	// Hooks
	const { t } = useTranslation();
	const isNotSharingOffer = context !== "sharingOffer";
	const missionOrderFull = context === "missionOrderFull";

	const {
		agencyName,
		qualificationName,
		period,
		clientName,
		missionTime,
		clientLocation,
		missionDescription,
		workAddress,
	} = useOrderOffer(currentOrder);

	const { firstDayStartHour } = currentOrder?.workingTime ?? {};

	function formaterPoste(poste) {
		const posteMinuscules = poste.toLowerCase();

		let posteFormate = posteMinuscules.charAt(0).toUpperCase() + posteMinuscules.slice(1);

		const hfIndex = posteFormate.indexOf("h/f");

		if (hfIndex !== -1) {
			posteFormate = posteFormate.slice(0, hfIndex) + posteFormate.slice(hfIndex).toUpperCase();
		}

		return posteFormate;
	}

	const hasMeetingPlace = !!workAddress?.meetingAddress;

	const renderLocation = () => (showCityAndPostalCode ? clientLocation : null);

	const renderPeriod = () => {
		const { startHour, endHour, startHour2, endHour2 } = missionTime ?? {};
		const hasFirstSlotValid = !!startHour && !!endHour;
		const hasLastSlotValid = !!startHour2 && !!endHour2;
		const hasExactlyOneEmptyStringValue = (obj) => {
			const values = Object.values(obj);
			const emptyStringCount = values.filter((value) => value === "").length;
			return emptyStringCount === 1;
		};

		const renderTimeSpan = (start, end) => (
			<Text tag="p" size="paragraph01" fontWeight="light" lineHeight="m">
				De{" "}
				{t("commands.profiles.modalsContent.sharingOffers.timeSpanWithLetter", {
					startHour: start,
					endHour: end,
				})}
			</Text>
		);

		if (hasExactlyOneEmptyStringValue(missionTime)) {
			return "";
		}

		if (hasFirstSlotValid && hasLastSlotValid) {
			return (
				<Text tag="p" size="paragraph01" fontWeight="light" lineHeight="m">
					De{" "}
					{t("commands.profiles.modalsContent.sharingOffers.timeSpanWithLetter", {
						startHour: missionTime?.startHour,
						endHour: missionTime?.endHour,
					})}
					{missionTime?.startHour2 &&
						t("commands.profiles.modalsContent.sharingOffers.timeSpan2", {
							startHour: missionTime?.startHour2,
							endHour: missionTime?.endHour2,
						})}
				</Text>
			);
		}
		if (hasFirstSlotValid && !hasLastSlotValid) {
			return renderTimeSpan(startHour, endHour);
		}
		if (!hasFirstSlotValid && hasLastSlotValid) {
			return renderTimeSpan(startHour2, endHour2);
		}

		return "";
	};

	const renderMissionPlace = () => {
		const address = workAddress?.address || "";
		const addressComplement = workAddress?.addressComplement || "";
		const postalCode = workAddress?.postalCode || "";
		const city = workAddress?.city || "";

		let formatedAdress = `${address}`;

		if (addressComplement) {
			formatedAdress += `, ${addressComplement}`;
		}

		if (address || addressComplement) {
			formatedAdress += `, `;
		}

		formatedAdress += `${postalCode} ${city}`;

		return formatedAdress;
	};

	const renderMeetingPlace = () => {
		const meetingAddress = workAddress?.meetingAddress || "";
		const meetingAddressComplement = workAddress?.meetingAddressComplement || "";
		const meetingPostalCode = workAddress?.meetingPostalCode || "";
		const meetingCity = workAddress?.meetingCity || "";

		let formatedAdress = `${meetingAddress}`;

		if (meetingAddressComplement) {
			formatedAdress += `, ${meetingAddressComplement}`;
		}

		if (meetingAddress || meetingAddressComplement) {
			formatedAdress += `, `;
		}

		formatedAdress += `${meetingPostalCode} ${meetingCity}`;

		return formatedAdress;
	};

	return (
		<div className={style.jobOffer}>
			<div className={style.header}>
				<h3 className={style.agancyName}>{formaterPoste(qualificationName)}</h3>
				<Tag className={style.tag} color="primary">
					Intérim
				</Tag>
				<Tag color="primary" outline>
					{agencyName}
				</Tag>
			</div>

			{isNotSharingOffer && (
				<Text tag="p" className={style.subTitle} size="paragraph01" fontWeight="normal" lineHeight="l">
					{t("commands.profiles.modalsContent.jobOffer.clientName")}
				</Text>
			)}
			<div className={style.contact}>
				{showClientName && (
					<Text
						tag="p"
						size="paragraph01"
						fontWeight="light"
						lineHeight="l"
						className={style.jobInformations}
					>
						{clientName}
					</Text>
				)}
				{isNotSharingOffer && hasMeetingPlace && (
					<Text tag="p" className={style.subTitle} size="paragraph01" fontWeight="bold" lineHeight="l">
						{t("commands.profiles.modalsContent.jobOffer.meetingPlace")}
					</Text>
				)}
				{!isNotSharingOffer && (
					<div>
						<Text
							tag="p"
							size="paragraph01"
							fontWeight="light"
							lineHeight="l"
							className={style.jobInformations}
						>
							{renderLocation()}
						</Text>
						<Text
							tag="p"
							size="paragraph01"
							fontWeight="light"
							lineHeight="l"
							className={style.jobInformations}
						>
							{workAddress?.geographicSector}
						</Text>
					</div>
				)}
				{missionOrderFull && (
					<Text tag="p" size="paragraph01" fontWeight="light" lineHeight="l" className={style.subTitle}>
						{renderMeetingPlace()}
					</Text>
				)}
				{missionOrderFull && workAddress.comment && (
					<Text
						tag="p"
						color="grey"
						fontWeight="light"
						className={style.comment}
						size="paragraph01"
						lineHeight="l"
					>
						{workAddress.comment}
					</Text>
				)}

				{isNotSharingOffer && (
					<Text tag="p" className={style.subTitle} size="paragraph01" fontWeight="bold" lineHeight="l">
						{t("commands.profiles.modalsContent.jobOffer.missionPlace")}
					</Text>
				)}
				{missionOrderFull && (
					<Text tag="p" size="paragraph01" fontWeight="light" lineHeight="l" className={style.subTitle}>
						{renderMissionPlace()}
					</Text>
				)}
				{/* {missionOrderFull && (
          <div className={style.documentsAccess}>
            <Picto
              className={style.tag}
              icon="resume"
              style={{
                width: '18px',
                height: '24px',
                color: 'var(--color-primary-500)',
              }}
            />
            <Text
              tag="p"
              size="paragraph01"
              fontWeight="normal"
              color="primary"
              lineHeight="m"
            >
              {t('commands.profiles.modalsContent.jobOffer.seeAccessDocuments')}
            </Text>
          </div>
        )} */}
				{isNotSharingOffer && (
					<Text tag="p" size="paragraph01" fontWeight="normal" lineHeight="m" className={style.subTitle}>
						{t("commands.profiles.modalsContent.jobOffer.missionDate")}
					</Text>
				)}
				<Text tag="p" size="paragraph01" fontWeight="light" lineHeight="m" className={style.jobInformations}>
					{t("commands.profiles.modalsContent.sharingOffers.periodSpan", {
						startDate: period?.startDate
							? format(new Date(period?.startDate), "dd MMM yyyy", {
									locale: localeFr,
								})
							: "",
						endDate: period?.endDate
							? format(new Date(period?.endDate), "dd MMM yyyy", {
									locale: localeFr,
								})
							: "",
					})
						.split(" ")
						.map((word) => word.charAt(0) + word.slice(1))
						.join(" ")}
				</Text>

				<Text tag="p" size="paragraph01" fontWeight="light" lineHeight="m" className={style.jobInformations}>
					{currentOrder?.workingDaysFormatted || ""}
				</Text>

				{missionOrderFull && firstDayStartHour && (
					<>
						<Text tag="p" size="paragraph01" fontWeight="normal" lineHeight="m" className={style.subTitle}>
							{t("commands.profiles.modalsContent.jobOffer.firstDaySchedule")}
						</Text>
						<Text
							tag="p"
							size="paragraph01"
							fontWeight="light"
							lineHeight="m"
							className={style.jobInformations}
						>
							{firstDayStartHour}
						</Text>
					</>
				)}

				{missionOrderFull && renderPeriod() && (
					<Text tag="p" size="paragraph01" fontWeight="normal" lineHeight="m" className={style.subTitle}>
						{t("commands.profiles.modalsContent.jobOffer.nextDaysSchedule")}
					</Text>
				)}
				{context === "missionOrder" && (
					<Text tag="p" size="paragraph01" fontWeight="normal" lineHeight="m">
						{t("commands.profiles.modalsContent.jobOffer.missionTime")}
					</Text>
				)}
				{renderPeriod()}
			</div>

			{missionDescription && (
				<>
					<Text
						tag="p"
						className={cn([style.subTitle, style.jobDescription])}
						size="paragraph01"
						lineHeight="m"
						fontWeight="normal"
					>
						{t("commands.profiles.modalsContent.jobOffer.jobDescription")}
					</Text>

					<div className={style.missionDescription}>
						<Text tag="p" fontWeight="light" size="paragraph01" lineHeight="l">
							{missionDescription}
						</Text>
					</div>
				</>
			)}
		</div>
	);
};

JobOffer.propTypes = {
	showCityAndPostalCode: PropTypes.bool,
	showClientName: PropTypes.bool,
	context: PropTypes.oneOf(["sharingOffer", "missionOrderFull", "missionOrder"]).isRequired,
	currentOrder: PropTypes.shape({
		missionName: PropTypes.string,
		clientName: PropTypes.string,
		meetingplace: PropTypes.string,
		missionDate: PropTypes.string,
		missionTime: PropTypes.string,
		workingTime: PropTypes.shape(),
		jobDescription: PropTypes.string,
		agenceName: PropTypes.string,
		missionType: PropTypes.string,
		furtherInformation: PropTypes.string,
		workingDaysFormatted: PropTypes.string,
	}),
};

export default JobOffer;
