export const DATE_REGEX = /^((0|)[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/\d{4}$/;

export const ALLOWED_NAMES_CHAR_REGEX = /^[A-ZÀÂÄÇÉÈÊËÏÎÔÖÙÛÜŸa-zàâäçéèêëïîôöùûüÿ-\s,']+$/;

export const ALLOWED_LETTERS_CHAR_REGEX = /^[a-zA-Z\s]*$/;

export const ALLOWED_NUMBERS_CHAR_REGEX = /^[0-9]*$/;

export const SSN_REGEX = /^([1278])\s?(\d{2})\s?(0[1-9]|1[0-2]|20)\s?(\d{2}|2[AB])\s?(\d{3})\s?(\d{3})\s?(\d{2})$/;

export const PATTERN_NSS_TEMPORARY = /^\d{6}[\dab](\d)?$/i;

export const ALLOWED_LETTERS_AND_NUMBERS_REGEX = /^[a-zA-Z0-9\s]*$/;

export const EMAIL_REGEX = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

export const PHONE_REGEX = /^(01|02|03|04|05|09)\d{8}$/;

export const MOBILE_REGEX = /^(06|07)\d{8}$/;
