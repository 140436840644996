import { FC, PropsWithChildren } from "react";

import { keyBy } from "lodash";
import { useQuery } from "react-query";

import user from "../../../services/user";

import useAppContext from "../../../store/useAppContext";

interface IUserLoadingScreenProps extends PropsWithChildren {
	loading: React.ReactNode;
}

export const UserLoadingScreen: FC<IUserLoadingScreenProps> = ({ loading, children }) => {
	const [, appDispatch] = useAppContext();

	const getUserMe = useQuery("getUserMe", () => user.getUserMe(), {
		onSuccess: ({ data: { groups } }) => {
			const roles = keyBy(groups, "name");
			appDispatch({
				type: "SET_USER",
				payload: { roles },
			});
		},
		onError: () => {
			appDispatch({
				type: "CLEAR_USER",
			});
		},
	});

	return getUserMe?.isSuccess ? children : loading;
};
