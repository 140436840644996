import React, { ChangeEvent, useState } from "react";

import { MaskitoOptions } from "@maskito/core";
import { useMaskito } from "@maskito/react";
import { useFormikContext } from "formik";
import { t } from "i18next";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { StatusUser } from "src/enum/enumStatusUser";
import { TTemporaryWorkerProfile } from "src/types";

import { TParams } from "../TemporaryWorkerSheet";
import { TAdministrativeFormValues } from "./Administrative";
import { TooltipMessage } from "src/components/atoms";
import { Field } from "src/components/molecules";

import { uCleanSpaces } from "src/utils/UStrings";
import { isTemporaryNss } from "src/utils/UTemporaryWorkerInformations";
import { cntm } from "src/utils/cntm";

import style from "./Administative.module.css";

interface ISocialSecurityInformationsProps {
	disabled?: boolean;
}

const ssnMask: MaskitoOptions = {
	mask: [
		/\d/,
		" ",
		/\d/,
		/\d/,
		" ",
		/\d/,
		/\d/,
		" ",
		/\d/,
		/\d/,
		" ",
		/\d/,
		/\d/,
		/\d/,
		" ",
		/\d/,
		/\d/,
		/\d/,
		" ",
		/\d/,
		/\d/,
	],
};

const SocialSecurityInformations: React.FC<ISocialSecurityInformationsProps> = ({ disabled }) => {
	const { values, errors, setFieldValue, setFieldTouched } = useFormikContext<TAdministrativeFormValues>();
	const [warnings, setWarnings] = useState<Partial<TAdministrativeFormValues>>({
		socialSecurityNumber: isTemporaryNss(uCleanSpaces(values.socialSecurityNumber))
			? t("global.formErrors.ssnTemp")
			: "",
	});
	const queryClient = useQueryClient();
	let { userId } = useParams<TParams>();
	const temporaryWorker: TTemporaryWorkerProfile = queryClient.getQueryData<any>(["getUser", userId])?.data;
	const isCandidtateOrWorker = [StatusUser.CANDIDATE, StatusUser.WORKER].includes(temporaryWorker?.twStatus);

	const inputRef = useMaskito({ options: ssnMask });

	const handleSSNChange = (e: ChangeEvent<HTMLInputElement>) => {
		//Calcul des warings
		const ssnTemporaryWarning = isTemporaryNss(uCleanSpaces(e.target.value.trim()));
		if (ssnTemporaryWarning) {
			setWarnings((prev) => ({ ...prev, socialSecurityNumber: t("global.formErrors.ssnTemp") }));
		} else {
			setWarnings((prev) => ({ ...prev, socialSecurityNumber: "" }));
		}
		setFieldValue("socialSecurityNumber", e.target.value || null);
	};

	return (
		<div className={cntm([disabled && "cursor-not-allowed", "w-full"])}>
			<div className="relative flex flex-col items-start justify-start w-full gap-4">
				<div className={cntm(["flex flex-col gap-2 w-full", disabled && "pointer-events-none opacity-70"])}>
					<h2 className="text-xl font-medium">{t("suspiciousSheet.workerInfo.health")}</h2>
					<div className="flex flex-row gap-2 items-center">
						<Field
							className={style.ssn}
							label={t("suspiciousSheet.workerInfo.ssn")}
							name="socialSecurityNumber"
							type="text"
							maxLength={21}
							value={values.socialSecurityNumber || undefined}
							onInput={handleSSNChange}
							innerRef={inputRef}
							onBlur={(e) => {
								setFieldTouched("dateOfBirth", true);
								setFieldTouched("bornDepartment", true);
							}}
							customErrorDisplay
							required={isCandidtateOrWorker}
						/>
						<div>
							{!errors?.socialSecurityNumber && warnings?.socialSecurityNumber && (
								<TooltipMessage color="warning">{warnings?.socialSecurityNumber}</TooltipMessage>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SocialSecurityInformations;
