import { FC } from "react";

import cn from "../../../utils/cn";

import style from "./OptionClient.module.css";

interface IOptionClientProps {
	tempoId?: string;
	name?: string;
	city?: string;
}

export const OptionClient: FC<IOptionClientProps> = ({ tempoId = "", name = "", city = "" }) => (
	<div className={cn([style.optionContainer, "ellipsis"])} data-testid={`option-${tempoId}-${name}-${city}`}>
		<span className={style.optionTempoId}>{`${tempoId} -`}</span>
		<span className={style.optionName}>{`${name}`}</span>
		{city ? <span className={style.optionCity}>{`- ${city}`}</span> : null}
	</div>
);
