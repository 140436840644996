// @ts-nocheck
import { useCallback, useEffect, useMemo, useState } from "react";

import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { useInfiniteQuery, useQuery, useQueryClient } from "react-query";

// Service
import { getCommunicationContent, postProfileDetails } from "src/services/commandService";
import { getUnifiedTempWorker } from "src/services/missionFollowUpService";

// Store
import { useOrderProfilesContext } from "src/store/OrderProfilesContext";

import setCommunicationContent from "../internals/setCommunicationContent";
import renderProfileResponseActivity from "../renders/renderProfileResponseActivity/renderProfileResponseActivity";
import renderResponseReceivedActivity from "../renders/renderResponseReceivedActivity/renderResponseReceivedActivity";
import renderSendMissionCommunicationActivity from "../renders/renderSendMissionCommunicationActivity/renderSendMissionCommunicationActivity";
import renderSendMissionStatusActivity from "../renders/renderSendMissionStatusActivity/renderSendMissionStatusActivity";
// Hooks
import { useProfileDetailNotes } from "./useProfileDetailNotes";

// Constants
import { ACTIVITY_PROFILES_EVENTS, COMMUNICATION_SENT } from "src/constants";

const useModalContentProfileCardDetails = (profile, setNotesInitialValues) => {
	const { resetForm: resetNotesForm, dirty: dirtyNotes } = useFormikContext();

	const { unifiedTempWorkerId, orderProfileId, isVip, isCdiInterim } = profile;

	const [{ notesAreConflicting, isEditingNote, isNoteSuccessDisplayed }, dispatchOrderProfiles] =
		useOrderProfilesContext();
	const { t } = useTranslation();
	const queryClient = useQueryClient();

	const [activeTab, setActiveTab] = useState(0);
	const tabs = [
		{ content: t("commands.profiles.modalsContent.profileDetail.activity.title"), id: 0 },
		{ content: t("commands.profiles.modalsContent.profileDetail.notes.title"), id: 1 },
	];

	const isActivityTabActive = activeTab === 0;

	// Api calls

	const { notesAreFetched, notesAreFetching, isNotesTabActive, isCancelModalOpen, setIsCancelModalOpen } =
		useProfileDetailNotes({
			orderProfileId,
			activeTab,
			setNotesInitialValues,
		});

	const { data: unifiedTempWorker, isFetching } = useQuery(["unified-temp-worker"], () =>
		getUnifiedTempWorker(unifiedTempWorkerId)
	);

	const { firstName, lastName, avatarUrl, channelPreference, phone, email, isUnifiedTempWorkerValidated } =
		unifiedTempWorker?.data ?? {};

	const fetchProfileDetails = useCallback(async ({ pageParam = 1 }) => {
		const results = await postProfileDetails(orderProfileId, {
			limit: 15,
			page: pageParam,
		});

		return results;
	});

	const {
		data,
		isFetched: activitesAreFetched,
		isFetchingNextPage,
		hasNextPage,
		fetchNextPage,
	} = useInfiniteQuery({
		queryKey: ["postProfileDetails", orderProfileId],
		queryFn: fetchProfileDetails,
		getNextPageParam: (_, allPages) => {
			if (allPages.length * 15 > allPages[0]?.data?.total) {
				return undefined;
			}
			if (allPages?.length > 0) {
				return allPages.length + 1;
			}
			return 1;
		},
		enabled: !!orderProfileId,
	});

	const handleScroll = (e) => {
		const element = e.target;
		const atBottom = element.scrollHeight - element.scrollTop - 5 <= element.clientHeight;
		if (atBottom && hasNextPage && !isFetchingNextPage) {
			fetchNextPage();
		}
	};

	const concatenatedActivities = useMemo(() => {
		if (!data) return [];
		if (activitesAreFetched) {
			// Concatenate all pages of result into one array
			return data.pages.reduce((acc, current) => [...acc, ...current.data.items], []);
		}
		return [];
	}, [data, activitesAreFetched]);

	useEffect(() => {
		dispatchOrderProfiles({ type: "SET_NOTES_ARE_CONFLICTING", payload: false });
	}, [activeTab]);

	useEffect(
		() => () => {
			queryClient.resetQueries({ queryKey: ["postProfileDetails", orderProfileId], exact: true });
			dispatchOrderProfiles({ type: "SET_NOTES_ARE_CONFLICTING", payload: false });
			dispatchOrderProfiles({ type: "SET_NOTE_SUCCESS_DISPLAYED", payload: false });
		},
		[]
	);

	const handleComunicationClick = async (id) => {
		const targetedActivity = concatenatedActivities.find(({ orderMessagingId }) => orderMessagingId === id);
		let htmlContent = "";

		setCommunicationContent(id, "SET_OPEN", htmlContent, queryClient);

		if (targetedActivity && targetedActivity.content) {
			htmlContent = targetedActivity?.content;
		} else {
			try {
				const response = await getCommunicationContent(orderProfileId, id);
				htmlContent = response?.data.content;
			} catch (e) {
				// eslint-disable-next-line no-console
				console.error(e);
			}
		}

		if (htmlContent === "") {
			setCommunicationContent(id, "SET_CONTENT", " ", queryClient);
			return;
		}

		setCommunicationContent(id, "SET_CONTENT", htmlContent, queryClient);
	};

	const retrievePrimaryCommunicationDetails = (activity) => {
		if (!activity.communicationMediumForCommunicationSent) return null;
		const primaryMedium = activity.communicationMediumForCommunicationSent.find(
			(item) => item.medium === "sms" || item.medium === "email"
		);

		return primaryMedium;
	};

	const dispatchActivityBasedOnType = (activity, index) => {
		if (ACTIVITY_PROFILES_EVENTS.statusUpdate === activity.activityType) {
			return renderResponseReceivedActivity(activity, index, t, concatenatedActivities.length);
		}
		if (ACTIVITY_PROFILES_EVENTS.receivedResponse === activity.activityType) {
			return renderProfileResponseActivity(activity, index, t, concatenatedActivities.length);
		}
		if (
			ACTIVITY_PROFILES_EVENTS.communicationSent === activity.activityType &&
			COMMUNICATION_SENT.send.includes(activity.communicationType)
		) {
			return renderSendMissionStatusActivity(
				activity,
				index,
				t,
				concatenatedActivities.length,
				retrievePrimaryCommunicationDetails,
				handleComunicationClick
			);
		}
		if (
			ACTIVITY_PROFILES_EVENTS.communicationSent === activity.activityType &&
			COMMUNICATION_SENT.share.includes(activity.communicationType)
		) {
			return renderSendMissionCommunicationActivity(
				activity,
				index,
				t,
				handleComunicationClick,
				concatenatedActivities.length,
				retrievePrimaryCommunicationDetails
			);
		}
		return null;
	};

	return {
		isFetching,
		firstName,
		lastName,
		avatarUrl,
		channelPreference,
		phone,
		email,
		isUnifiedTempWorkerValidated,
		notesAreConflicting,
		isEditingNote,
		isNoteSuccessDisplayed,
		isVip,
		isCdiInterim,
		tabs,
		resetNotesForm,
		dirtyNotes,
		isActivityTabActive,
		activeTab,
		setActiveTab,
		handleScroll,
		notesAreFetched,
		notesAreFetching,
		isNotesTabActive,
		isCancelModalOpen,
		setIsCancelModalOpen,
		dispatchActivityBasedOnType,
		activitesAreFetched,
		concatenatedActivities,
		isFetchingNextPage,
	};
};

export default useModalContentProfileCardDetails;
