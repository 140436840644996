import { useTranslation } from "react-i18next";
import MediumType from "src/types/MediumType";
import ProfileCommunicationDeliveryStatusType from "src/types/ProfileCommunicationDeliveryStatusType";

import useOrderProfileChannelNotification from "./useOrderProfileChannelNotification";
import { Picto } from "@zolteam/react-ras-library";

export interface CommunicationMedium {
	medium: MediumType;
	status: ProfileCommunicationDeliveryStatusType;
}

interface OrderProfileChannelNotificationProps {
	communicationMediumForCommunicationSent: CommunicationMedium[];
}

const OrderProfileChannelNotification: React.FC<OrderProfileChannelNotificationProps> = ({
	communicationMediumForCommunicationSent,
}) => {
	const { t } = useTranslation();
	const { primary, secondary } = useOrderProfileChannelNotification(communicationMediumForCommunicationSent);

	return (
		<>
			{secondary !== null ? (
				<p className="text-sm font-normal leading-4 text-neutral-500">
					{t(`commands.profiles.modalsContent.profileDetail.communicationMedium.${secondary.medium}`)}
				</p>
			) : null}
			<div className={`flex items-center ml-1.5 ${primary?.color}`}>
				<Picto icon={primary?.picto} className="mr-1.5 h-3.5 w-3.5" />
				<p className="text-sm font-normal leading-4">
					{t(`commands.profiles.orderProfileChannelNotification.${primary?.translationKey}`, {
						medium: primary?.medium
							? t(`commands.profiles.orderProfileChannelNotification.${primary?.medium}`)
							: "",
					})}
				</p>
			</div>
		</>
	);
};

export default OrderProfileChannelNotification;
