// @ts-nocheck
import client from "./client";

/**
 * @description Post search unified temp workers suggestions
 * @param {Object} search
 * @returns {Promise<AxiosResponse<any>>}
 */
const postUnifiedTempWorkerSuggestionsSearch = (search) =>
	client.post("api-client-v2/unified-temporary-worker-suggestion/search", search);

/**
 * @description Get unified temp worker suggestions
 * @param {string} id
 * @returns {Promise<AxiosResponse<any>>}
 */
const getUnifiedTempWorkerSuggestions = (id) =>
	client.get(`api-client-v2/unified-temporary-worker-suggestion/detail/${id}`);

/**
 * @description Get suggestion lock or none if any
 * @param {Object} id
 * @returns {Promise<AxiosResponse<any>>}
 */
const getUnifiedTempWorkerSuggestionsLock = (id) =>
	client.get(`api-client-v2/unified-temporary-worker-suggestion/detail/${id}/lock`);

/**
 * @description Post search unified temp workers suggestions
 * @param {Object} search
 * @returns {Promise<AxiosResponse<any>>}
 */
const postUnifiedTempWorkerSuggestionsSave = (id, data) =>
	client.post(`api-client-v2/unified-temporary-worker-suggestion/detail/${id}/save`, data);

const UnifiedTemporaryWorkerSuggestionService = {
	postUnifiedTempWorkerSuggestionsSearch,
	getUnifiedTempWorkerSuggestions,
	getUnifiedTempWorkerSuggestionsLock,
	postUnifiedTempWorkerSuggestionsSave,
};

export default UnifiedTemporaryWorkerSuggestionService;
