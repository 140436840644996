import { FC, useContext } from "react";

import { Redirect, Route, Switch } from "react-router-dom";
import { TRoute } from "src/types/TRoute";

import { AppStateContext } from "src/store/AppContext";

import { SideMenuSecondaryItem } from "src/components/atoms";
import Page404 from "src/views/404/404";

import cn from "src/utils/cn";

import style from "./SideMenuSecondary.module.css";

interface SideMenuSecondaryProps {
	routes: TRoute[];
	header?: JSX.Element;
	className?: string;
	classNamePanel?: string;
	classNameHeader?: string;
	classNameMenuItem?: string;
	classNameItemContainer?: string;
}

export const SideMenuSecondary: FC<SideMenuSecondaryProps> = ({
	routes,
	header,
	className,
	classNamePanel,
	classNameHeader,
	classNameMenuItem,
	classNameItemContainer,
}) => {
	const { user } = useContext<any>(AppStateContext);

	return (
		<div className={`${style.container} flex flex-col lg:flex-row gap-5 w-full`}>
			<div
				className={cn([
					style.sidemenu,
					style.sidemenuDesktop,
					"overflow-y-hidden flex flex-row lg:flex-col ",
					className,
				])}
				data-testid="formSideMenu"
			>
				{header && <div className={`p-5 ${classNameHeader}`}>{header}</div>}
				<div
					className={cn([
						"flex  flex-row lg:flex-col  p-5 overflow-y-auto items-center",
						classNameItemContainer,
					])}
				>
					{routes.map((route) => (
						<SideMenuSecondaryItem
							className={classNameMenuItem}
							key={route.label}
							path={route.navPath || route.path}
						>
							{route.label}
						</SideMenuSecondaryItem>
					))}
				</div>
			</div>
			<div className={`flex-1 flex flex-col ${classNamePanel}`}>
				<Switch>
					{user?.roles &&
						routes.map((route) => {
							if (route.permissions && !route.permissions.some((permission) => user.roles[permission])) {
								return <Redirect to="/403" />;
							}
							return (
								<Route key={route.path} exact path={route.path}>
									{route.component}
								</Route>
							);
						})}
					<Route path="*">
						<Page404 className="p-0" />
					</Route>
				</Switch>
			</div>
		</div>
	);
};
