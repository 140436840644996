// @ts-nocheck
import React, { createContext, useContext, useReducer } from "react";

import PropTypes from "prop-types";

const initOrderProfilesStore = {
	notesAreConflicting: false,
	isEditingNote: false,
	isNoteSuccessDisplayed: false,
};

const orderProfilesReducer = (state, action) => {
	switch (action.type) {
		case "SET_NOTES_ARE_CONFLICTING": {
			return { ...state, notesAreConflicting: action.payload };
		}
		case "SET_IS_EDITING_NOTE": {
			return { ...state, isEditingNote: action.payload };
		}
		case "SET_NOTE_SUCCESS_DISPLAYED": {
			return { ...state, isNoteSuccessDisplayed: action.payload };
		}
		case "RESET_ORDER_PROFILES": {
			return initOrderProfilesStore;
		}
		default: {
			throw new Error(`[ORDER_PROFILES] - Unhandled action type: ${action.type}`);
		}
	}
};

export const OrderProfilesStateContext = createContext();
const OrderProfilesDispatchContext = createContext();

export const OrderProfilesFormProvider = ({ children }) => {
	const [state, dispatch] = useReducer(orderProfilesReducer, initOrderProfilesStore);

	return (
		<OrderProfilesStateContext.Provider value={state}>
			<OrderProfilesDispatchContext.Provider value={dispatch}>{children}</OrderProfilesDispatchContext.Provider>
		</OrderProfilesStateContext.Provider>
	);
};

export const useOrderProfilesContext = () => [
	useContext(OrderProfilesStateContext),
	useContext(OrderProfilesDispatchContext),
];

OrderProfilesFormProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
