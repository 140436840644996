import React, { FC, useEffect } from "react";

import { Form, Formik, FormikProps } from "formik";
import { t } from "i18next";
import { TFilters } from "src/types";

import { useFiltersContext } from "src/store/FiltersContext/useFiltersContext";

import { Button, ModalV2 } from "@zolteam/react-ras-library";
import { Spinner } from "src/components/atoms";

import style from "./FilterModal.module.css";

type TFilterModalChildrenProps = FormikProps<any> & {
	resetField: (fieldName: string) => void;
};

interface IFilterModalProps {
	title?: string;
	isDisplayed: boolean;
	onCloseModal: () => void;
	children: (childrenProps: TFilterModalChildrenProps) => React.ReactNode;
	defaultFilters: any;
	onSubmit: (filters: any) => void;
	filterKey?: keyof TFilters<any>;
	size?: "s" | "m" | "l" | "auto";
	isLoading?: boolean;
}

export const FilterModal: FC<IFilterModalProps> = ({
	title = t("global.filtersPanel.title"),
	isDisplayed,
	onCloseModal,
	children,
	defaultFilters,
	onSubmit,
	filterKey,
	size = "m",
	isLoading,
}) => {
	const { filters, setFilters } = useFiltersContext(filterKey);

	useEffect(() => {
		setFilters({
			...defaultFilters,
			...filters,
		});
	}, []);

	return (
		<ModalV2
			title={title}
			isDisplayed={isDisplayed}
			onClose={onCloseModal}
			className={style.filterModal}
			size={size}
		>
			{isLoading ? (
				<Spinner />
			) : (
				<Formik initialValues={filters} onSubmit={onSubmit}>
					{(formikHelpers) => {
						return (
							<Form>
								<div className={style.filterModalContent}>
									{children({
										resetField: (fieldName) => {
											formikHelpers.setFieldValue(fieldName, defaultFilters[fieldName]);
										},
										...formikHelpers,
									})}
								</div>
								<div className={style.filterModalButtons}>
									<Button
										className={style.clearFiltersModal}
										type="button"
										color="transparent"
										onClick={() => {
											formikHelpers.setValues(defaultFilters);
										}}
									>
										{t("global.filtersPanel.clearAll")}
									</Button>
									<Button type="submit" color="primary">
										{t("commands.filtersModal.confirmAction")}
									</Button>
								</div>
							</Form>
						);
					}}
				</Formik>
			)}
		</ModalV2>
	);
};
