import { FC } from "react";

import { useTranslation } from "react-i18next";

import { PhoneInput } from "src/components/atoms";
import { Field } from "src/components/molecules";

import { ALLOWED_NAMES_CHAR_REGEX } from "src/constants/CRegex";

import style from "./Administative.module.css";

const EmergencyInformations: FC = () => {
	const { t } = useTranslation();

	return (
		<div className="flex flex-col w-full gap-2">
			<h2 className="text-xl font-medium">{t("usersListing.temporaryWorker.administrative.emergencyContact")}</h2>

			<div className="flex flex-row gap-3 items-center flex-wrap">
				<Field
					label={t("usersListing.temporaryWorker.administrative.emergencyName")}
					type="text"
					name="emergencyContactName"
					maxLength={30}
					allowedCharacters={ALLOWED_NAMES_CHAR_REGEX}
					classNameContainer={style.fields}
					customErrorDisplay
					className={"flex-row flex-nowrap"}
				/>

				<PhoneInput
					name="emergencyContactPhone"
					label={t("commands.phone")}
					classNameContainer={style.fields}
				/>
			</div>
		</div>
	);
};

export default EmergencyInformations;
