// @ts-nocheck
import React from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";

import { CREATE_MODE } from "../../../../../constants";
import { Textarea } from "@zolteam/react-ras-library";

import style from "./CommandNotesFormBlock.module.css";

const CommandNotesFormBlock = ({ isOrderFetched }) => {
	const { t } = useTranslation();

	const { orderId } = useParams();
	const isNewCommand = orderId === CREATE_MODE;

	if (!isOrderFetched && !isNewCommand) {
		return (
			<div className={style.skeletonColumn}>
				<div className={style.skeletonRow}>
					<Skeleton containerClassName={style.skeletonFullWidth} height={110} borderRadius={25} />
				</div>
			</div>
		);
	}

	return (
		<Textarea
			name="qualificationNote"
			placeholder={t("commands.internalNotes")}
			className={style.textarea}
			label={t("commands.internalNotes")}
			maxLength={800}
			shouldDisplayErrorMessage={false}
		/>
	);
};

CommandNotesFormBlock.propTypes = {
	isOrderFetched: PropTypes.bool.isRequired,
};

export default CommandNotesFormBlock;
