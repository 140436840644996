// @ts-nocheck
import React from "react";

import { Form, Formik } from "formik";
import { t } from "i18next";
import moment from "moment";
import PropTypes from "prop-types";

import advancePayments from "../../../../services/advancePayments";

import { useToastContext } from "../../../../store/ToastContext";

import cn from "../../../../utils/cn";
import FormCreateAdvancePaymentInputs from "./FormCreateAdvancePaymentInputs";
import createAdvancePaymentsFormDefaultValues from "./createAdvancePaymentsFormDefaultValues";
import createAdvancePaymentsFormValidation from "./createAdvancePaymentsFormValidation";
import { Button } from "@zolteam/react-ras-library";

import "./FormCreateAdvancePayments.css";
import style from "./FormCreateAdvancePayments.module.css";

const FormCreateAdvancePayments = ({ onSubmitSuccess, onCancel }) => {
	const [, toastDispatch] = useToastContext();

	const handleSubmitForm = (form) =>
		advancePayments
			.postAdvancePayments({
				...form,
				userId: form.userId.value,
				desiredPaymentDate: moment(form.desiredPaymentDate).format("YYYY-MM-DD"),
			})
			.then(
				() => {
					onSubmitSuccess();
				},
				(error) => {
					const msgError =
						error.response?.data?.message === "Validation errors"
							? "ERR_BR_ADVANCE_PAYMENT_0000_FORM_ERROR"
							: error.response?.data?.message;

					toastDispatch({
						type: "ERROR",
						payload: {
							icon: "alertCircle",
							msg: t(`global.apiErrors.${msgError}`),
						},
					});
				}
			);

	return (
		<Formik
			enableReinitialize
			validateOnMount
			initialValues={createAdvancePaymentsFormDefaultValues}
			validationSchema={createAdvancePaymentsFormValidation}
			onSubmit={handleSubmitForm}
		>
			{({ values, isValid, isSubmitting }) => {
				return (
					<Form id="formCreate" className="mt-l">
						<FormCreateAdvancePaymentInputs />
						<hr className={cn([style.separator])} />
						<div className={cn([style.buttonsBlock])}>
							<Button
								className={style.cancelButton}
								id="cancelButton"
								type="button"
								onClick={() => onCancel()}
								color="transparentPrimary"
								disabled={isSubmitting}
							>
								{t("advancePayments.cancelAction")}
							</Button>
							<Button
								id="submitButton"
								className={style.validationButtonRight}
								disabled={!isValid || (!values.isMaximumAdvanceRequested && !values.requestedAmount)}
								color="primary"
								type="submit"
								isLoading={isSubmitting}
							>
								{t("advancePayments.addAction")}
							</Button>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};

FormCreateAdvancePayments.propTypes = {
	onSubmitSuccess: PropTypes.func,
	onCancel: PropTypes.func,
};

FormCreateAdvancePayments.defaultProps = {
	onSubmitSuccess: () => {},
	onCancel: () => {},
};

export default FormCreateAdvancePayments;
