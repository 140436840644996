import i18n from "src/i18n";
import * as Yup from "yup";

import { WORK_PERIOD_TYPES } from "src/constants";

const WorkingNightsRule = () => {
	return Yup.object()
		.nullable()
		.when("workPeriodType", {
			is: (value: string) => value === WORK_PERIOD_TYPES.NIGHT,
			then: (schema) => schema.required(i18n.t("commands.error.requiredField")),
			otherwise: (schema) => schema.nullable(),
		});
};

export default WorkingNightsRule;
