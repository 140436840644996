import { ChangeEvent, FC, useRef, useState } from "react";

import { AxiosResponse } from "axios";
import { Form, Formik, Field as FormikField, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { TDocumentsTypes } from "src/types/TDocuments";
import { TDocumentsRulesApiResponse, TDocumentsTypeDetails } from "src/types/TIntegration";

import IntegrationService from "src/services/IntegrationService";

import { DocumentsTypesFormValidationSchema } from "./DocumentsTypesFormValidationSchema";
import { Button, InfoMessage } from "@zolteam/react-ras-library";
import { ConfirmModal, Field, InputMulti, ToggleRadioInput } from "src/components/molecules";
import { TItem } from "src/components/molecules/InputMulti/InputMulti";
import { CheckboxListFilter } from "src/components/organisms";

import {
	INIT_DOCUMENT_PROPERTIES_TYPESB,
	INIT_DOCUMENT_PROPERTIES_TYPES_OPTIONS,
	INIT_RADIO_OPTIONS,
} from "src/constants/CIntegration";

import style from "./DocumentsTypes.module.css";

interface DocumentsTypesPanelProps {
	selectedDocument?: TDocumentsTypes;
	isLoading: boolean;
}

interface MobileQualificationsProps {
	initialValues?: number[];
}

export type TDocumentsTypesFormValues = {
	name: string;
	patterns: TItem[];
	masterMobileQualificationIds: number[];
	rules: TDocumentsRulesApiResponse;
	isExportableToTempo: boolean;
	maximumAttachments: number | null;
};

const MobileQualifications: FC<MobileQualificationsProps> = ({ initialValues }) => {
	const { t } = useTranslation();
	const { setFieldValue, values } = useFormikContext<TDocumentsTypesFormValues>();

	const getMobilesQualifications = useQuery({
		queryKey: ["getMobilesQualifications"],
		queryFn: () => {
			return IntegrationService.getMobileQualifications();
		},
		staleTime: Infinity,
	});
	const { isError, isLoading, isFetching, data: mobilesQualifications } = getMobilesQualifications;

	if (isFetching || isLoading) {
		return (
			<div className="grid grid-cols-2 w-full gap-1">
				{Array.from({ length: 16 }, (_, index) => {
					return <Skeleton key={index} height={20} baseColor="lightGrey" />;
				})}
			</div>
		);
	}

	if (isError) {
		return (
			<div className="[&>div]:w-full [&>div]:box-border">
				<InfoMessage withIcon color="error">
					{t("global.error")}
				</InfoMessage>
			</div>
		);
	}

	if (mobilesQualifications)
		return (
			<CheckboxListFilter
				items={mobilesQualifications?.reduce<Record<string, boolean>>((acc, item) => {
					acc[item.id] = initialValues ? (initialValues[item.id] ? true : false) : false;
					return acc;
				}, {})}
				onChange={(type) => {
					const id = parseInt(type);
					values.masterMobileQualificationIds?.includes(id)
						? setFieldValue(
								"masterMobileQualificationIds",
								values.masterMobileQualificationIds.filter((qId) => qId !== id)
							)
						: setFieldValue("masterMobileQualificationIds", [...values.masterMobileQualificationIds, id]);
				}}
				getId={(index) => `mobileQualification-checkbox-${index}`}
				getValue={(type) => {
					const id = parseInt(type);
					return values.masterMobileQualificationIds?.includes(id);
				}}
				getLabel={(type) => {
					return mobilesQualifications?.find((item) => item.id === parseInt(type))?.name;
				}}
				listStyle={{
					display: "grid",
					gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
					gap: "0.4rem",
					alignItems: "start",
				}}
				labelClassName={"text-xs " + style.label}
			/>
		);
};

const DocumentsTypesPanel: FC<DocumentsTypesPanelProps> = ({ selectedDocument, isLoading }) => {
	const { t } = useTranslation();
	const formRef = useRef(null);
	const queryClient = useQueryClient();
	const [showArchivedModal, setShowArchiveModal] = useState(false);

	//Query
	const getDocumentDetails = useQuery({
		queryKey: ["getDocumentDetails", selectedDocument?.id],
		queryFn: () => {
			return IntegrationService.getDocumentDetails(selectedDocument?.id);
		},
		enabled: !!selectedDocument?.id,
	});

	const initialValues: TDocumentsTypesFormValues = {
		name: selectedDocument?.name || "",
		patterns: selectedDocument
			? getDocumentDetails?.data?.patterns?.map((v, index) => {
					return {
						name: v,
						id: index,
					};
				})
			: [],
		masterMobileQualificationIds: selectedDocument ? getDocumentDetails?.data?.masterMobileQualificationIds : [],
		rules: selectedDocument ? getDocumentDetails?.data?.rules : INIT_DOCUMENT_PROPERTIES_TYPESB,
		isExportableToTempo: selectedDocument ? getDocumentDetails?.data?.isExportableToTempo : false,
		maximumAttachments: selectedDocument ? getDocumentDetails?.data?.maximumAttachments || 0 : 0,
	};

	const updateQueryDocumentList = (data: TDocumentsTypeDetails) => {
		return queryClient.setQueriesData(["getDocumentsTypes"], (oldData: { items: TDocumentsTypes[] }) => {
			return {
				...oldData,
				items: oldData.items.map((item) => {
					if (item.id === selectedDocument.id) {
						return { ...selectedDocument, ...data };
					}
					return item;
				}),
			};
		});
	};

	const archiveOrUnarchiveDocument = useMutation<AxiosResponse<any, any>, unknown>(
		() => {
			return selectedDocument?.isArchived
				? IntegrationService.unarchiveDocument(selectedDocument)
				: IntegrationService.archiveDocument(selectedDocument);
		},
		{
			onSuccess: (data) => {
				toast.success(
					t(
						`integration.documentsTypes.apiResponse.success.${selectedDocument.isArchived ? "unarchived" : "archived"}`
					)
				);
				//Si le status archivé est modifié, on met à jour le status dans la liste des documents
				updateQueryDocumentList(data.data);
			},
		}
	);

	const handleSubmitForm = useMutation<AxiosResponse<any, any>, unknown, TDocumentsTypeDetails>(
		(values) => {
			return selectedDocument
				? IntegrationService.updateDocument(selectedDocument.id, values)
				: IntegrationService.createDocument(values);
		},
		{
			onSuccess: (data) => {
				toast.success(
					t(`integration.documentsTypes.apiResponse.success.${selectedDocument ? "updated" : "created"}`)
				);
				if (selectedDocument) {
					queryClient.setQueryData(["getDocumentDetails", selectedDocument?.id], data.data);
					formRef?.current?.resetForm({ values: { ...data.data, rules: data.data.rules } });
					//Si le nom du document est modifié, on met à jour le nom dans la liste des documents
					updateQueryDocumentList(data.data);
				}
			},
		}
	);

	const handleSubmit = (values: TDocumentsTypesFormValues) => {
		const { patterns, ...formValues } = values;
		return handleSubmitForm.mutateAsync({
			...formValues,
			patterns: patterns?.map((pattern) => pattern.name) || [],
		});
	};

	if (isLoading || getDocumentDetails.isLoading || getDocumentDetails.isFetching) {
		return (
			<div className="bg-white p-10 flex-1 flex">
				<div className="bg-neutral-100 rounded-lg p-6 flex-1">
					<Skeleton height={"100%"} baseColor="lightGrey" />
				</div>
			</div>
		);
	}

	return (
		<>
			<div className="bg-white p-10 flex-1 flex">
				<div className="bg-neutral-100 rounded-lg px-6 pt-6 flex-1 overflow-auto">
					<Formik
						innerRef={formRef}
						initialValues={initialValues}
						onSubmit={handleSubmit}
						validationSchema={DocumentsTypesFormValidationSchema()}
						initialTouched={{
							type: true,
						}}
						validateOnChange
						validateOnBlur
						validateOnMount={true}
						enableReinitialize={true}
					>
						{({ isValid, dirty, isSubmitting, setFieldValue, values }) => {
							const canSubmit = isValid && dirty;

							return (
								<Form className="relative flex flex-col items-start justify-start gap-6 h-full ">
									<Field
										type="text"
										name="name"
										label={t("integration.documentsTypes.type") + "*"}
										customErrorDisplay
										className={`${style.adminField} min-w-[300px] w-full h-[41px]`}
										classNameContainer="w-full"
										onChange={(e: ChangeEvent<HTMLInputElement>) => {
											setFieldValue("name", e.target.value);
										}}
									/>

									<div className="w-full flex flex-col gap-2">
										{!getDocumentDetails.isFetching &&
											values?.rules &&
											Object.entries(INIT_DOCUMENT_PROPERTIES_TYPES_OPTIONS).map(
												([key, value]) => {
													const formValue = values?.rules[key];
													const displayMaxInput =
														key === "attachmentsRule" && formValue !== null;
													return (
														<div
															className={`items-center ${style.toggleRadioInput}`}
															key={key}
														>
															<ToggleRadioInput
																name={`rules.${key}`}
																label={t(`integration.documentsTypes.rules.${key}`)}
																onChange={() => {
																	setFieldValue(`rules.${key}`, formValue ? null : 2);
																}}
																toggled={formValue !== null}
																radioOptions={{ ...INIT_RADIO_OPTIONS }}
																onRadioChange={() => {
																	setFieldValue(
																		`rules.${key}`,
																		formValue === 2 ? 1 : 2
																	);
																}}
																radioValue={formValue === 2 ? "optional" : "mandatory"}
																getRadioLabel={(type) => t(`global.${type}`)}
																classNameLabel="text-sm text-neutral-600 cursor-pointer"
																disableRadio={value?.options === false}
															/>
															{displayMaxInput && (
																<div className="flex flex-row items-center gap-1">
																	<label className="text-xs text-neutral-600 ml-4">
																		{t("integration.documentsTypes.max")}
																	</label>
																	<FormikField
																		label={``}
																		type="number"
																		name={`maximumAttachments`}
																		className={`border-2 border-neutral-200 border-solid rounded w-10`}
																		max={99}
																		min={0}
																	/>
																</div>
															)}
														</div>
													);
												}
											)}
										<ToggleRadioInput
											name={`isExportableToTempo`}
											label={t(`integration.documentsTypes.rules.tempoRule`)}
											onChange={() => {
												setFieldValue(`isExportableToTempo`, !values?.isExportableToTempo);
											}}
											toggled={values?.isExportableToTempo}
											classNameLabel="text-sm text-neutral-600 cursor-pointer"
											disableRadio={true}
										/>
									</div>

									<div className="w-full">
										<h2 className="text-lg font-medium mb-1">
											{t("integration.documentsTypes.variants")}
										</h2>
										{
											<InputMulti
												label={t("integration.documentsTypes.variantPlaceholder")}
												name="patterns"
												className={`${style.adminField} min-w-[300px] w-full h-[41px]`}
												onAdd={(items) => setFieldValue("patterns", items)}
												onDelete={(item) => {
													const updatedPatterns = values?.patterns.filter(
														(pattern) => pattern.id !== item.id
													);

													setFieldValue("patterns", updatedPatterns);
												}}
												disabled={!!getDocumentDetails.isFetching || !!getDocumentDetails.error}
												classnameContainer="w-full"
												initialValues={values?.patterns as TItem[]}
												maxWidth="100%"
											/>
										}
									</div>

									<div className="w-full">
										<h2 className="text-lg font-medium mb-1">
											{t("integration.documentsTypes.qualificationsTitle")}
										</h2>
										<MobileQualifications
											initialValues={getDocumentDetails?.data?.masterMobileQualificationIds}
										/>
									</div>

									<div className="flex flex-row gap-2 ml-auto mt-auto pb-6">
										{selectedDocument && (
											<Button
												data-testid="docType-save"
												color={"primary"}
												type="button"
												onClick={() => {
													if (!selectedDocument.isArchived) return setShowArchiveModal(true);
													return archiveOrUnarchiveDocument.mutateAsync();
												}}
												isLoading={archiveOrUnarchiveDocument.isLoading}
											>
												{selectedDocument.isArchived
													? t("integration.documentsTypes.unarchive")
													: t("integration.documentsTypes.archive")}
											</Button>
										)}

										<Button
											data-testid="docType-save"
											disabled={!canSubmit}
											color={!canSubmit ? "greyDark" : "primary"}
											type="submit"
											isLoading={isSubmitting}
										>
											{t("global.register")}
										</Button>
									</div>
								</Form>
							);
						}}
					</Formik>
				</div>
			</div>
			{
				<ConfirmModal
					title={t("integration.documentsTypes.archiveTitle")}
					isOpen={showArchivedModal}
					onConfirm={() => {
						return archiveOrUnarchiveDocument.mutateAsync();
					}}
					onClose={() => setShowArchiveModal(false)}
					size="s"
					confirmText={t("integration.documentsTypes.confirmArchive")}
				>
					<div className="flex flex-col gap-6">
						<div>
							{t("integration.documentsTypes.archiveText", {
								type: selectedDocument?.name,
							})}
						</div>
						<div>{t("integration.documentsTypes.archiveTextConfirm")}</div>
					</div>
				</ConfirmModal>
			}
		</>
	);
};

export default DocumentsTypesPanel;
