// @ts-nocheck
import { createContext } from "react";

const TableListingContext = createContext({
	data: {},
	selected: [],
	toggleSelected: () => {},
});

export default TableListingContext;
