// @ts-nocheck

/* eslint-disable react/jsx-no-useless-fragment */
import { useMemo } from "react";

import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

// Services
import { getAgencies, getMatchingTempWorkerInformations } from "../../../services/commandService";

// Hooks
import { useMatchingContext } from "../../../store/MatchingContext";

import cn from "../../../utils/cn";
import formatAgencyListing from "../../../utils/matching/formatAgencyListing";
import { Picto, Tag, Tooltip } from "@zolteam/react-ras-library";
// Utils
import { SelectAgency } from "src/components/atoms";

// Style
import style from "./MatchingProfileSectionDetails.module.css";

const MatchingProfileSectionDetails = () => {
	const [{ unifiedTempWorkerId, filters }] = useMatchingContext();
	const { orderId } = useParams();
	const { t } = useTranslation();

	const isGlobalSearch = filters.agency === "all";

	const {
		data: agencies,
		isSuccess: isAgenciesSuccess,
		isLoading: isAgenciesLoading,
	} = useQuery("getAgencies", () => getAgencies(""), {
		select: (data) => data?.data,
	});

	const currentAgencyId = useMemo(() => {
		if (isAgenciesSuccess && !isAgenciesLoading) {
			return agencies.find((agency) => agency.tempoFolderName === filters.agency)?.id || null;
		}
	}, [isAgenciesSuccess, isAgenciesLoading, agencies, filters.agency]);

	const {
		data: unifiedTempWorkerInformationsData,
		isFetching,
		isError,
		isFetched,
	} = useQuery(
		["getMatchingTempWorkerInformations", orderId, currentAgencyId, unifiedTempWorkerId, isGlobalSearch],
		() => getMatchingTempWorkerInformations(orderId, unifiedTempWorkerId, isGlobalSearch ? null : currentAgencyId),
		{
			enabled: !!orderId && (isGlobalSearch || !!currentAgencyId) && !!unifiedTempWorkerId,
		}
	);

	const { data: unifiedTempWorkerInformations } = unifiedTempWorkerInformationsData || {};

	const readyToShow = !isFetching && !isError && isFetched;
	const hasLocation = !!unifiedTempWorkerInformations?.city;
	const hasTransportType = !!unifiedTempWorkerInformations?.transportTypes?.length;

	const mapTransportTypeToPicto = (transportType) => {
		switch (transportType) {
			case "Mobylette":
				return "moped";
			case "Transport en commun":
				return "publicTransport";
			case "Vélo":
				return "bike";
			case "Voiture ou moto":
				return "car";
			default:
				return "car";
		}
	};

	const handleTitleCorrespondence = (title) => {
		if (title === "MR") return "M.";
		if (title === "MRS") return "Mme";
		return "";
	};

	const renderTransportTypes = () => {
		if (unifiedTempWorkerInformations?.transportTypes.length === 0) {
			return null;
		}
		const hidePicto = unifiedTempWorkerInformations?.transportTypes[0].toLowerCase().includes("aucun");

		if (unifiedTempWorkerInformations.transportTypes.length === 1) {
			return (
				<div className={style.transport}>
					{hidePicto ? null : (
						<Picto icon={mapTransportTypeToPicto(unifiedTempWorkerInformations.transportTypes[0])} />
					)}
					<p className={cn([style.text, style.transportText])}>
						{unifiedTempWorkerInformations.transportTypes[0]}
					</p>
				</div>
			);
		}

		return (
			readyToShow && (
				<div className={style.transport}>
					{hidePicto ? null : (
						<Picto icon={mapTransportTypeToPicto(unifiedTempWorkerInformations.transportTypes[0])} />
					)}
					<Tooltip
						placement="right"
						animation={false}
						arrow={false}
						content={unifiedTempWorkerInformations.transportTypes.slice(1).map((transportType) => (
							<div className={style.tooltip}>
								{transportType.toLowerCase().includes("aucun") ? null : (
									<Picto
										className={style.tooltipPicto}
										icon={mapTransportTypeToPicto(transportType)}
									/>
								)}
								<span>{transportType}</span>
							</div>
						))}
					>
						<p className={cn([style.pointer, style.text])}>
							{`${unifiedTempWorkerInformations.transportTypes[0]} ...`}
						</p>
					</Tooltip>
				</div>
			)
		);
	};

	const BASE_URL = `${process.env.REACT_APP_BASE_OLD_URL}/#/fr/contacts/`;

	return (
		<div className={style.wrapper}>
			<div className={style.avatarBox}>
				{readyToShow ? (
					<>
						{unifiedTempWorkerInformations?.avatar ? (
							<img className={style.avatar} src={unifiedTempWorkerInformations.avatar} alt="avatar" />
						) : (
							<div className={style.avatarPlaceholder}>
								<Picto icon="userDelete" />
							</div>
						)}
						{unifiedTempWorkerInformations?.isVipAgencies.length ? (
							<span>
								<Picto className={style.vipPicto} icon="vip" />
							</span>
						) : null}
						{unifiedTempWorkerInformations?.isVipAgencies.length && isGlobalSearch ? (
							<Tooltip
								arrow
								className={style.vipTooltip}
								animation={false}
								placement="top"
								content={formatAgencyListing(unifiedTempWorkerInformations?.isVipAgencies).map(
									(agency) => (
										<span>{agency}</span>
									)
								)}
							>
								<span>
									<Picto className={style.vipPicto} icon="vip" />
								</span>
							</Tooltip>
						) : null}
					</>
				) : (
					<Skeleton circle height={80} width={80} className={style.skeletonCircle} />
				)}
			</div>
			<div className={style.profileDetailsBox}>
				<div className={style.profileDetails}>
					{readyToShow ? (
						<p className={style.fullName}>
							{`${handleTitleCorrespondence(unifiedTempWorkerInformations?.title)}
                  ${unifiedTempWorkerInformations?.firstName || ""}
                  ${unifiedTempWorkerInformations?.lastName || ""}
                `}
						</p>
					) : (
						<Skeleton count={1} height={30} width={280} />
					)}
					<div className={style.profilAttributes}>
						{readyToShow ? (
							<>
								{unifiedTempWorkerInformations?.isCdiInterimAgencies.length && !isGlobalSearch ? (
									<Tag className={style.profilAttribute} color="primaryLight">
										CDII
									</Tag>
								) : null}
								{unifiedTempWorkerInformations?.isCdiInterimAgencies.length > 0 && isGlobalSearch && (
									<Tooltip
										arrow
										animation={false}
										placement="top"
										content={formatAgencyListing(
											unifiedTempWorkerInformations?.isCdiInterimAgencies
										).map((agency) => (
											<span>{agency}</span>
										))}
									>
										<span className={style.tag}>
											<Tag className={style.profilAttribute} color="primaryLight">
												CDII
											</Tag>
										</span>
									</Tooltip>
								)}
							</>
						) : (
							<Skeleton
								className={style.profilAttribute}
								count={1}
								borderRadius={30}
								height={30}
								width={45}
							/>
						)}
					</div>
				</div>
				{readyToShow ? (
					<>
						{unifiedTempWorkerInformations?.qualifications.length > 0 ? (
							<Tooltip
								placement="bottom"
								animation={false}
								arrow={false}
								content={unifiedTempWorkerInformations?.qualifications.map((qualification) => (
									<span className={style.tooltipQualification}>{qualification}</span>
								))}
							>
								<p className={style.qualifications}>
									{`${unifiedTempWorkerInformations?.qualifications.length}
                    qualification${unifiedTempWorkerInformations?.qualifications.length !== 1 ? "s" : ""}`}
								</p>
							</Tooltip>
						) : null}
					</>
				) : (
					<Skeleton count={1} height={24} width={115} />
				)}
				<div className={style.locationTransport}>
					{readyToShow ? (
						<>
							{hasLocation && (
								<div className={style.location}>
									<Picto icon="map" />
									<p
										className={cn([style.text, style.city])}
									>{`${unifiedTempWorkerInformations?.city}`}</p>

									{unifiedTempWorkerInformations?.distance && (
										<p className={style.text}>
											(à {unifiedTempWorkerInformations?.distance}
											km)
										</p>
									)}
								</div>
							)}
							{hasLocation && hasTransportType && <div className={style.divider} />}
							{renderTransportTypes()}
						</>
					) : (
						<div className={style.transportSkeleton}>
							<Skeleton count={1} height={24} width={100} />
							<Skeleton count={1} height={24} width={100} />
						</div>
					)}
				</div>
			</div>
			{readyToShow && (
				<div className={style.individualFileBox}>
					{unifiedTempWorkerInformations?.users.length === 1 && (
						<>
							<a
								href={`${BASE_URL}${unifiedTempWorkerInformations?.users[0].userId}`}
								className={style.individualFileLink}
								target="_blank"
								rel="noreferrer"
							>
								{t("matching.matchingProfileSections.matchingProfileSectionDetails.individualFileLink")}
							</a>
							<a
								href={`${BASE_URL}${unifiedTempWorkerInformations?.users[0].userId}`}
								className={style.individualFilePicto}
								target="_blank"
								rel="noreferrer"
								aria-label="individualFilePicto"
							>
								<Picto icon="profile" />
							</a>
						</>
					)}
					{unifiedTempWorkerInformations?.users.length > 1 && (
						<SelectAgency options={unifiedTempWorkerInformations?.users || []} />
					)}
				</div>
			)}
		</div>
	);
};

export default MatchingProfileSectionDetails;
