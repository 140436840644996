// @ts-nocheck
/**
 *
 * @param {string} text
 * @param {string} font
 * @param {int} marginError
 * @returns
 */
const getTextWidth = (text, font, marginError = 0) => {
	const canvas = document.createElement("canvas");
	const context = canvas.getContext("2d");
	context.font = font;
	return context.measureText(text).width + marginError;
};

export default getTextWidth;
