export const ucFirst = (str: string) => {
	if (!str) return str;
	return str.charAt(0).toUpperCase() + str.slice(1)?.toLowerCase();
};

export const uCleanSpaces = (str: string) => {
	if (!str) return str;
	return str.replace(/\s+/g, "").trim();
};

export const uNormalizeAndCleanString = (str: string) => {
	if (!str) return str;
	return str
		.trim()
		.toLowerCase()
		.normalize("NFD")
		.replace(/[\u0300-\u036f]/g, "");
};
