// @ts-nocheck
import * as Yup from "yup";

import EXTRACT_FORMAT from "src/constants/extractFormat";
import EXTRACT_PERIMETER from "src/constants/extractPerimeter";

export default Yup.object().shape({
	extractPerimeter: Yup.string()
		.oneOf([EXTRACT_PERIMETER.DISPLAYED, EXTRACT_PERIMETER.SELECTED, EXTRACT_PERIMETER.ALL])
		.required(),
	format: Yup.string().oneOf([EXTRACT_FORMAT.CSV, EXTRACT_FORMAT.EXCEL]).required(),
});
