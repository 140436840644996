// Style
import style from "./NavigationTab.module.css";

interface INavigationTabsProps {
	tabs: {
		id: number;
		content: React.ReactNode;
	}[];
	onTabChange: (tabId: number) => void;
	activeTab: number;
}

export const NavigationTabs: React.FC<INavigationTabsProps> = ({ tabs, onTabChange, activeTab }) => (
	<div className={style.tabsContainer}>
		<ul className={style.tabs}>
			{tabs.map((tab) => (
				<li key={tab.id}>
					<button
						onClick={() => onTabChange(tab.id)}
						type="button"
						onKeyDown={() => onTabChange(tab.id)}
						className={tab.id === activeTab ? style.tabActive : style.tab}
					>
						{tab.content}
					</button>
				</li>
			))}
		</ul>
	</div>
);
