import { FC, PropsWithChildren, useEffect, useState } from "react";

import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation, useRouteMatch } from "react-router-dom";

// Store
import useAppContext from "../../../store/useAppContext";

import packageJSON from "../../../../package.json";
// Constants
import { V1_TOKEN_COOKIE } from "../../../constants";
// Translations
import i18n from "../../../i18n";
import cn from "../../../utils/cn";
// Style
import { Badge, Button, Picto } from "@zolteam/react-ras-library";
// Components
import { MenuItem, Point } from "src/components/atoms";

import style from "./WithSideMenu.module.css";

type TMenu = {
	label: string;
	path: string;
	picto?: string;
	isExternal: boolean;
	activePicto?: boolean;
	permissions?: string[];
	displayPoint?: boolean;
	displayBadge?: boolean;
	childs?: TMenu[];
};

interface IWithSideMenuProps extends PropsWithChildren {
	onOpen?: () => void;
	onClose?: () => void;
	containerClassName?: string;
	className?: string;
}

export const menuEntries: {
	[key: string]: TMenu[];
	menuItemsGeneral: TMenu[];
} = {
	menuItemsGeneral: [
		{
			label: i18n.t("global.externalHomePageTitle"),
			path: "/#/fr",
			isExternal: true,
			picto: "home",
			activePicto: false,
		},
		{
			label: i18n.t("clientsMyTemp.menuTitle"),
			path: "/notifications",
			picto: "briefcase",
			isExternal: false,
			activePicto: false,
			permissions: ["user"],
			displayPoint: true,
			childs: [
				{
					label: i18n.t("clientsMyTemp.notifications.submenu"),
					path: "/notifications",
					isExternal: false,
					displayBadge: true,
				},
				{
					label: i18n.t("clientsMyTemp.messaging.submenu"),
					path: "/#/fr/messages/clients",
					isExternal: true,
					displayBadge: true,
				},
			],
		},
		{
			label: i18n.t("global.messagesPageTitle"),
			path: "/#/fr/messages/sent",
			isExternal: true,
			picto: "mail",
			activePicto: false,
		},
		{
			label: i18n.t("commands.pageTitle"),
			path: "/commandes",
			picto: "clipboard",
			isExternal: false,
			activePicto: true,
		},
		{
			label: i18n.t("suspicious.pageTitle"),
			path: "/suspicions",
			picto: "users",
			isExternal: false,
			activePicto: false,
			displayPoint: true,
			childs: [
				{
					label: i18n.t("global.contactsPageTitle"),
					path: "/#/fr/contacts",
					isExternal: true,
				},
				{
					label: i18n.t("profile.advancePayments.pageTitle"),
					path: "/advance-payments",
					isExternal: false,
				},
				{
					label: i18n.t("global.contactsV2PageTitle"),
					path: "/users",
					isExternal: false,
					permissions: ["digital.division"],
				},
				{
					label: i18n.t("suspicious.subpageTitle"),
					path: "/suspicions",
					isExternal: false,
					permissions: ["digital.division"],
				},
				{
					label: i18n.t("expiredDocuments.title"),
					path: "/expired-documents",
					isExternal: false,
				},
			],
		},
		{
			label: i18n.t("global.amaPageTitle"),
			path: "/#/fr/ama-accidents",
			isExternal: true,
			picto: "fire",
			activePicto: false,
		},
		{
			label: i18n.t("global.adminPageTitle"),
			picto: "settings",
			path: "/#/fr/administration/users",
			isExternal: false,
			activePicto: false,
			childs: [
				{
					label: i18n.t("global.adminPageTitle"),
					path: "/#/fr/administration/users",
					isExternal: true,
				},
				{
					label: i18n.t("global.purgeRGPD"),
					path: "/purge-rgpd",
					isExternal: false,
					permissions: ["rgpd"],
				},
				{
					label: i18n.t("global.integration"),
					path: "/integration",
					isExternal: false,
					permissions: ["digital.division"],
				},
			],
		},
	],
};

export const WithSideMenu: FC<IWithSideMenuProps> = ({
	children,
	onOpen = () => {},
	onClose = () => {},
	containerClassName = "",
}) => {
	const [
		{
			user: { roles },
			countUnreadNotifications,
			countUnreadMessages,
		},
	] = useAppContext();

	const { t } = useTranslation();
	const [sideOpen, setSideOpen] = useState(false);
	const [activeChilds, setActiveChilds] = useState("");
	const location = useLocation();

	const handleActiveChilds = (path: string) => {
		if (activeChilds === path) {
			setActiveChilds("");
		} else {
			setActiveChilds(path);
		}
	};

	useEffect(() => {
		if (sideOpen) {
			onOpen();
		} else {
			onClose();
		}
	}, [sideOpen]);

	useEffect(() => {
		setActiveChilds(`/${location.pathname.split("/")[1]}`);
	}, []);

	const showPoint = (menuItemKey: string) => {
		switch (menuItemKey) {
			case i18n.t("clientsMyTemp.menuTitle"):
				return (
					countUnreadNotifications !== null &&
					countUnreadMessages !== null &&
					(countUnreadNotifications > 0 || countUnreadMessages > 0)
				);
			default:
				return false;
		}
	};

	const logout = () => {
		Cookies.remove(V1_TOKEN_COOKIE);
		window.location.href = `${process.env.REACT_APP_BASE_OLD_URL}/#/fr/signin`;
	};

	const filteredByPermissions = (items: TMenu[]) => {
		return items.filter(({ permissions }) => {
			return !permissions || permissions.some((permission) => roles[permission]);
		});
	};
	return (
		<>
			<nav className={cn([style.sideMenu, sideOpen ? style.open : null])}>
				<div>
					<Picto className={style.logo} icon={sideOpen ? "logoMyDaily" : "logoMyDailySmall"} />
					{Object.keys(menuEntries).map((menuEntry) => (
						<ul className={style.menuBloc} key={menuEntry}>
							{filteredByPermissions(menuEntries[menuEntry]).map(
								({ label, path, picto, isExternal, activePicto, childs, displayPoint }) => {
									//eslint-disable-next-line
									const match = useRouteMatch({ path });
									return (
										<MenuItem
											key={label}
											picto={activePicto && match ? `${picto}Active` : picto}
											path={path}
											isExternal={isExternal}
											popoverLabel={label}
											handleMenuOpen={setSideOpen}
											menuOpen={sideOpen}
											open={!!match}
											handleActiveChilds={handleActiveChilds}
											activeChilds={activeChilds}
											hasChilds={filteredByPermissions(childs || [])?.length > 0}
											childs={
												filteredByPermissions(childs || [])?.length
													? filteredByPermissions(childs)?.map(
															({
																label: labelChild,
																isExternal: isExternalChild,
																displayBadge: badge,
																path: pathChild,
															}) =>
																isExternalChild ? (
																	<a
																		href={`${process.env.REACT_APP_BASE_OLD_URL}${pathChild}`}
																		key={labelChild}
																		className={style.menuItemChildLabel}
																	>
																		{labelChild}

																		{labelChild ===
																			t("clientsMyTemp.notifications.submenu") &&
																			badge && (
																				<Badge
																					content={countUnreadNotifications}
																					className="ml-s"
																					color="accent"
																				/>
																			)}
																		{badge && (
																			<Badge
																				content={countUnreadMessages}
																				className="ml-s"
																				color="accent"
																			/>
																		)}
																	</a>
																) : (
																	<NavLink
																		key={labelChild}
																		to={pathChild}
																		activeClassName={style.menuItemChildActive}
																		className={style.menuItemChildLabel}
																	>
																		{labelChild}
																		{labelChild ===
																			t("clientsMyTemp.notifications.submenu") &&
																			badge && (
																				<Badge
																					content={countUnreadNotifications}
																					className="ml-s"
																					color="accent"
																				/>
																			)}
																	</NavLink>
																)
														)
													: null
											}
										>
											{displayPoint && showPoint(label) && <Point classname={style.point} />}
											<span className={style.menuItemLabel}>{label}</span>
										</MenuItem>
									);
								}
							)}
						</ul>
					))}
					<div className={cn([style.menuBloc, style.menuLogout])}>
						<div className={style.menuItem}>
							<button type="button" className={style.menuLogoutButton} onClick={logout}>
								<Picto className={style.picto} icon="power" style={{ width: "1.375rem" }} />
								<span className={style.menuItemLabel}>{t("global.logout")}</span>
							</button>
						</div>
					</div>
				</div>
				<Button
					className={cn([style.navVisibilityButton])}
					type="button"
					size="l"
					color="transparentPrimary"
					onClick={() => setSideOpen(!sideOpen)}
				>
					{sideOpen ? (
						<span className={style.navVisibilityButtonVersion}>{`v${packageJSON.version}`}</span>
					) : null}
					<Picto icon={sideOpen ? "arrowBarToLeft" : "arrowBarToRight"} />
				</Button>
			</nav>

			<div className={cn([style.wrapperWithMenu, containerClassName])}>{children}</div>
		</>
	);
};
