import { FC } from "react";

import { ErrorMessage } from "formik";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { TNationality } from "src/types/TNationality";

import PublicService from "src/services/PublicServices";

import { Select } from "@zolteam/react-ras-library";
import { TooltipMessage } from "src/components/atoms";

import { ALLOWED_LETTERS_CHAR_REGEX } from "src/constants/CRegex";

interface NationalitySelectProps {
	value?: TNationality;
	name?: string;
	onChange?: (value: any) => void;
	onBlur?: (value: any) => void;
	disabled?: boolean;
	className?: string;
	required?: boolean;
	containerClassname?: string;
}

export const NationalitySelect: FC<NationalitySelectProps> = ({
	value,
	name,
	onChange,
	onBlur,
	disabled,
	className,
	required = false,
	containerClassname,
}) => {
	const { t } = useTranslation();
	// API Call
	const { data, isLoading, isFetching } = useQuery<any>({
		queryKey: "getNationalities",
		queryFn: () =>
			PublicService.getNationalities().then((res) => {
				return res.data;
			}),
		staleTime: Infinity,
	});

	// Function to filter out unauthorized characters
	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (!ALLOWED_LETTERS_CHAR_REGEX.test(e.key)) {
			e.preventDefault(); // Empêche l'entrée de caractères non autorisés
		}
	};

	return (
		<div className={"flex flex-row items-center gap-3  " + containerClassname}>
			<Select
				isSearchable
				disabled={disabled}
				className={"flex-1 " + className}
				options={data || []}
				showIndicator={!disabled}
				onChange={onChange}
				onBlur={onBlur}
				name={name || "nationality"}
				label={t("activityLogs.commonFields.wording.nationality") + (required ? "*" : "")}
				getOptionLabel={(option: any) => `${option.trigram || ""} ${option.trigram ? "-" : ""} ${option.name}`}
				getOptionValue={(option: any) => option.id}
				noOptionsMessage={() => t("usersListing.temporaryWorker.administrative.noNationalityFound")}
				value={data?.find((option: any) => option.id === value) || null}
				loadingMessage={() => t("commands.locationForm.countrySelectLoading")}
				onKeyDown={handleKeyDown}
				displayFormikError={false}
				maxLenght={30}
				isLoading={isLoading || isFetching}
			/>
			{!isLoading && !isFetching && (
				<ErrorMessage name={name}>{(msg) => <TooltipMessage color="error">{msg}</TooltipMessage>}</ErrorMessage>
			)}
		</div>
	);
};
