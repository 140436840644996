import React, { FC, useCallback, useEffect, useRef, useState } from "react";

import getTextWidth from "../../../utils/getTextWidth";
// Atoms
import { PopoverListMaxWidth } from "src/components/atoms";

// Utils
import cn from "src/utils/cn";

// Style
import style from "./ListMaxWidth.module.css";

// Utils

type TListItem = {
	width: number;
	toDisplay: string;
	name: string;
	id: string | number;
};

interface IListMaxWidthProps {
	array: { name: string; id: string | number }[];
	component: FC<{ data: { name: string; id: string | number }; index: number }>;
	keyListElem: string;
	maxWidth?: string;
	margeError?: number;
	maxWidthMarge?: number;
	addMaxWidthMarge?: boolean;
	oneItem?: boolean;
	className?: string;
}

const PADDING = 10;
const HIDDEN_CHIP_COUNT_WIDTH = 46;

export const ListMaxWidth: FC<IListMaxWidthProps> = ({
	array,
	component,
	keyListElem,
	maxWidth = "300",
	margeError = 0,
	maxWidthMarge = 0,
	addMaxWidthMarge = false,
	oneItem = false,
	className,
}) => {
	const containerRef = useRef(null);

	const calculateListWidth = useCallback(() => {
		let totalWidth = 0;
		const containerWidth = containerRef.current?.offsetWidth || 0;

		const finalWidth = maxWidth === "100%" ? containerWidth : parseInt(maxWidth, 10);

		return array.map((elem) => {
			const width = getTextWidth(elem.name, "10.3px DM sans", margeError);
			totalWidth += width;

			const max = oneItem
				? getTextWidth(array[0].name, "10.3px DM sans", margeError)
				: finalWidth - 2 * PADDING - HIDDEN_CHIP_COUNT_WIDTH - (addMaxWidthMarge ? maxWidthMarge + PADDING : 0);

			return {
				...elem,
				width,
				toDisplay: totalWidth <= max,
			};
		});
	}, [array, maxWidth, margeError, maxWidthMarge, oneItem, addMaxWidthMarge]);

	const [list, setList] = useState(calculateListWidth());

	useEffect(() => {
		setList(calculateListWidth());
	}, [array]);

	return (
		<div className={cn([style.container, className])} ref={containerRef}>
			{list.map((item, index) =>
				item.toDisplay
					? React.createElement(component, {
							key: item[keyListElem as keyof TListItem] as any,
							data: item,
							index,
						})
					: null
			)}
			{list.filter((item) => !item.toDisplay).length > 0 ? (
				<PopoverListMaxWidth
					content={
						<ul className={style.listPopover}>
							{list
								.filter((item) => !item.toDisplay)
								.map((item) => (
									<li key={item.id} className={style.itemPopover}>
										{item.name}
									</li>
								))}
						</ul>
					}
				>
					<span>{`+ ${list.filter((item) => !item.toDisplay).length}`}</span>
				</PopoverListMaxWidth>
			) : null}
		</div>
	);
};
