// @ts-nocheck
import { useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { useQueries, useQueryClient } from "react-query";
import { useTitle } from "react-use";

import { format } from "date-fns";
import localeFr from "date-fns/locale/fr";

import advancePaymentsService from "../../services/advancePayments";

import WithSideTableFiltersPanel from "../../components/templates/WithSideTableFiltersPanel";
// Constants
import { DEFAULT_ADVANCE_PAYMENTS_LIST_OPTIONS, PAGINATION_OFFSET_OPTIONS } from "../../constants";
import { sourceMyDaily, sourceMyRas } from "../../enum/enumSources";
import { definePageOffset } from "../../utils/paginationUtils";
import ModalContentAdvancePayments from "./Modals/ModalContentAdvancePayments";
// Services
import { Button, InputSearch, PaginationV2, Picto, SelectPopover, Text, Title } from "@zolteam/react-ras-library";
// Utils
import { Tag } from "src/components/atoms";
// Molecules
import { ColumnListing, PaginationSearch, TableInfo } from "src/components/molecules";
import { TableListing } from "src/components/organisms";
import { WithSideMenu, WithTopBarre } from "src/components/templates";
// Organisms
import { TableFilters } from "src/components/templates";

// Enums
import { cntm } from "src/utils/cntm";

import style from "./AdvancePayments.module.css";

const AdvancePayments = () => {
	// Hooks
	const { t } = useTranslation();
	const [advancePaymentsFilters, setAdvancePaymentsFilters] = useState({
		searchText: null,
	});
	const queryClient = useQueryClient();

	useTitle(t("profile.advancePayments.pageTitle"));

	// Ref
	const searchTimeOutRef = useRef(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isNew, setIsNew] = useState(false);
	const [advancePaymentId, setAdvancePaymentId] = useState(null);

	// states
	const [options, setOptions] = useState(DEFAULT_ADVANCE_PAYMENTS_LIST_OPTIONS);
	const [postAdvancePaymentsSearchQuery] = useQueries([
		{
			queryKey: ["postAdvancePaymentsSearch", advancePaymentsFilters, options],
			queryFn: () =>
				advancePaymentsService.postAdvancePaymentsSearch({
					...advancePaymentsFilters,
					...options,
				}),
		},
	]);

	const handleSearch = (filter) => {
		const tempFilter = filter;

		if (filter?.searchText?.length === 0) {
			tempFilter.searchText = null;
		}

		clearTimeout(searchTimeOutRef.current);

		searchTimeOutRef.current = setTimeout(() => {
			setAdvancePaymentsFilters({
				...advancePaymentsFilters,
				searchText: tempFilter.searchText,
			});
		}, 800);

		setOptions((prev) => ({
			...prev,
			page: 1,
		}));
	};

	const {
		isError: isErrorAdvancePaymentsSearch,
		isLoading: isLoadingAdvancePaymentsSearch,
		data: paginator,
	} = postAdvancePaymentsSearchQuery;

	const getPageMaxNumber = () => Math.round((paginator?.data?.total ?? 0) / options.limit);

	const getStartPagination = options.limit * options.page - options.limit + 1;

	const getEndPagination =
		options.limit * options.page <= paginator?.data?.total ? options.limit * options.page : paginator?.data?.total;

	const handlePaginationChange = (params) => {
		setOptions((prev) => ({
			...prev,
			...params,
		}));
	};

	const handleSource = (source) => {
		switch (source) {
			case sourceMyRas:
				return t("profile.advancePayments.sources.myras");
			case sourceMyDaily:
				return t("profile.advancePayments.sources.mydaily");
			default:
				return "";
		}
	};

	const handleStatusExportTagLabelChange = (isExported) => {
		switch (isExported) {
			case true:
				return t("profile.advancePayments.statusExport.exported");
			case false:
				return t("profile.advancePayments.statusExport.exportFailed");
			default:
				return t("profile.advancePayments.statusExport.exportInProgress");
		}
	};

	const handleStatusExportTagColorChange = (isExported) => {
		switch (isExported) {
			case true:
				return "success";
			case false:
				return "error";
			default:
				return "primary";
		}
	};

	const handleRefetch = () => {
		options.page = 1;
		queryClient.refetchQueries(["postAdvancePaymentsSearch", advancePaymentsFilters, options]);
	};

	return (
		<WithSideMenu>
			<WithSideTableFiltersPanel>
				<WithTopBarre
					quickEntryContent={<div>test</div>}
					title={
						<Title tag="h1" size="heading01" lineHeight="s">
							{t("profile.advancePayments.title")}
						</Title>
					}
					containerClassname="container"
				>
					<TableFilters
						leftContent={
							<div>
								<Button
									type="button"
									color="primary"
									onClick={() => {
										setIsModalOpen(true);
										setIsNew(true);
									}}
									data-testid="createAdvancePayments"
								>
									{t("advancePayments.add")}
								</Button>
							</div>
						}
						rightContent={
							<div className={cntm([style.filterField, " mr-m items-center gap-4"])}>
								<div className={style.searchTextFilterWidth}>
									<InputSearch
										value={advancePaymentsFilters?.searchText ?? ""}
										type="text"
										name="advancePaymentsSearch"
										placeholder={t("global.filters.searchText")}
										onClick={(value) => handleSearch({ searchText: value })}
										removeValue={(value) => handleSearch({ searchText: value })}
										size="s"
									/>
								</div>
							</div>
						}
					/>
					<TableListing
						loading={isLoadingAdvancePaymentsSearch}
						error={isErrorAdvancePaymentsSearch}
						data={paginator?.data?.items}
						handleSearch={handleSearch}
						initialColumnSort={{
							field: "createdAt",
							orderBy: "DESC",
						}}
						canBeChecked={false}
						showCustomMessage={false}
						onDoubleClick={(elem) => {
							setIsModalOpen(true);
							setIsNew(false);
							setAdvancePaymentId(elem.id);
						}}
						footer={
							paginator?.data?.total ? (
								<>
									<PaginationV2
										previousLabel={<Picto icon="chevronLeft" />}
										nextLabel={<Picto icon="chevronRight" />}
										breakLine={
											<PaginationSearch
												onInputChange={handlePaginationChange}
												max={getPageMaxNumber()}
											/>
										}
										currentPage={options.page}
										totalCount={paginator?.data.total}
										pageSize={options.limit}
										siblingCount={2}
										onPageChange={(activePage) => handlePaginationChange({ page: activePage })}
									/>
									<Text tag="span" size="paragraph02" color="grey">
										{t("global.pagination.resultsPagination", {
											resultStart: getStartPagination,
											resultEnd: getEndPagination,
											resultTotal: paginator?.data?.total,
										})}
									</Text>
									<SelectPopover
										name="limit"
										options={PAGINATION_OFFSET_OPTIONS}
										onChange={(value) =>
											handlePaginationChange({
												limit: value,
												page: definePageOffset(value, options.limit, options.page),
											})
										}
										value={
											options.limit
												? PAGINATION_OFFSET_OPTIONS.find(
														(option) => option.value === options.limit
													)
												: null
										}
									/>
								</>
							) : null
						}
					>
						<ColumnListing
							id="uniqueId"
							name={
								<Text tag="span" size="paragraph02">
									{t("profile.advancePayments.uniqueId")}
								</Text>
							}
							field={(elem) => ({ uniqueId: elem.uniqueId })}
							component={({ data: { uniqueId } }) => (
								<Text tag="span" size="paragraph02">
									{uniqueId || ""}
								</Text>
							)}
						/>
						<ColumnListing
							id="agencyName"
							name={
								<Text tag="span" size="paragraph02">
									{t("profile.advancePayments.agency")}
								</Text>
							}
							field={(elem) => ({ agencyName: elem.agencyName })}
							component={({ data: { agencyName } }) => (
								<Text tag="span" size="paragraph02">
									{agencyName || ""}
								</Text>
							)}
						/>
						<ColumnListing
							id="tempoId"
							name={
								<Text tag="span" size="paragraph02">
									{t("profile.advancePayments.tempoId")}
								</Text>
							}
							field={(elem) => ({ tempoId: elem.tempoId })}
							component={({ data: { tempoId } }) => (
								<Text tag="span" size="paragraph02">
									{tempoId || ""}
								</Text>
							)}
						/>
						<ColumnListing
							id="userIdentity"
							name={
								<Text tag="span" size="paragraph02">
									{t("profile.advancePayments.user.identity")}
								</Text>
							}
							field={(elem) => ({ userFirstName: elem.firstName, userLastName: elem.lastName })}
							component={({ data: { userFirstName, userLastName } }) => (
								<Text tag="span" size="paragraph02">
									{`${userFirstName} ${userLastName}`}
								</Text>
							)}
						/>
						<ColumnListing
							id="createdBy"
							name={
								<Text tag="span" size="paragraph02">
									{t("profile.advancePayments.createdBy")}
								</Text>
							}
							field={(elem) => ({
								creatorFirstName: elem.createdByFirstName ?? elem.firstName,
								creatorLastName: elem.createdByLastName ?? elem.lastName,
								source: elem.source,
							})}
							component={({ data: { creatorFirstName, creatorLastName, source } }) => (
								<div>
									<div>
										<Text tag="span" size="paragraph02">
											{`${creatorFirstName} ${creatorLastName}`}
										</Text>
									</div>
									<div className={style.infoIndice}>
										{`${t("profile.advancePayments.sourceLabel")} ${handleSource(source)}`}
									</div>
								</div>
							)}
						/>
						<ColumnListing
							id="createdAt"
							name={
								<Text tag="span" size="paragraph02">
									{t("profile.advancePayments.createdAt")}
								</Text>
							}
							field={(elem) => ({ createdAt: elem.createdAt })}
							component={({ data: { createdAt } }) => (
								<div className="pr-6">
									<TableInfo
										info={
											createdAt
												? format(new Date(createdAt), "dd MMM yyyy", {
														locale: localeFr,
													})
												: ""
										}
									/>
									<div className={style.infoIndice}>
										{createdAt
											? format(new Date(createdAt), "HH:mm", {
													locale: localeFr,
												})
											: ""}
									</div>
								</div>
							)}
							tdClassName="min-column-width"
						/>
						<ColumnListing
							id="requestedAmount"
							name={
								<Text tag="span" size="paragraph02">
									{t("profile.advancePayments.requestedAmount")}
								</Text>
							}
							field={(elem) => ({ requestedAmount: elem.requestedAmount })}
							component={({ data: { requestedAmount } }) => (
								<Text tag="span" size="paragraph02">
									{requestedAmount
										? `${requestedAmount} €`
										: `${t("profile.advancePayments.maximum")}`}
								</Text>
							)}
						/>
						<ColumnListing
							id="desiredPaymentDate"
							name={
								<Text tag="span" size="paragraph02">
									{t("profile.advancePayments.desiredPaymentDate")}
								</Text>
							}
							field={(elem) => ({ desiredPaymentDate: elem.desiredPaymentDate })}
							component={({ data: { desiredPaymentDate } }) => (
								<div>
									<TableInfo
										info={
											desiredPaymentDate
												? format(new Date(desiredPaymentDate), "dd MMM yyyy", {
														locale: localeFr,
													})
												: ""
										}
									/>
								</div>
							)}
							tdClassName="min-column-width"
						/>
						<ColumnListing
							id="advancePaymentsStatusExport"
							name={
								<Text tag="span" size="paragraph02">
									{t("profile.advancePayments.status")}
								</Text>
							}
							field={(elem) => ({ isExported: elem.isExported })}
							component={({ data: { isExported } }) => (
								<Tag
									size="m"
									color={handleStatusExportTagColorChange(isExported)}
									className={style.tagWithoutSpace}
								>
									{handleStatusExportTagLabelChange(isExported)}
								</Tag>
							)}
						/>
						<ColumnListing
							id="advancePaymentId"
							field={(elem) => ({ id: elem.id })}
							component={({ data: { id } }) => <div className={style.invisible}>{id}</div>}
						/>
					</TableListing>
				</WithTopBarre>
			</WithSideTableFiltersPanel>
			<ModalContentAdvancePayments
				isModalOpen={isModalOpen}
				setIsModalOpen={setIsModalOpen}
				isNew={isNew}
				advancePaymentId={advancePaymentId}
				handleRefetch={handleRefetch}
			/>
		</WithSideMenu>
	);
};

export default AdvancePayments;
