// @ts-nocheck

/* eslint-disable no-use-before-define */

/* eslint-disable no-restricted-syntax */
import React from "react";

import PropTypes from "prop-types";

import { Title } from "@zolteam/react-ras-library";

import style from "./OrderFormBlock.module.css";

const OrderFormBlock = ({ title = null, children }) => (
	<div className={style.formBlock}>
		{title && (
			<Title tag="h2" size="heading03" fontWeight="bold" lineHeight="s" className={style.title}>
				{title}
			</Title>
		)}
		<div className={style.content}>{children}</div>
	</div>
);

OrderFormBlock.propTypes = {
	title: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default OrderFormBlock;
