import { FC, useRef } from "react";

import cn from "../../../utils/cn";

import style from "./TextArea.module.css";

interface ITextAreaInputProps {
	label?: string;
	placeholder?: string;
	className?: string;
	disabled?: boolean;
	autoScale?: boolean;
	value: string;
	onChange: (value: string) => void;
	maxLength?: number;
}

export const TextAreaInput: FC<ITextAreaInputProps> = ({
	label = "",
	placeholder = "",
	className = "",
	disabled = false,
	maxLength = null,
	autoScale = false,
	value,
	onChange,
}) => {
	const elementRef = useRef(null);

	const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		const newValue = event.target.value;
		if (onChange) {
			onChange(newValue);
		}
	};

	return (
		<div className={cn([style.container, className])}>
			<label className={cn([style.label])}>{label}</label>
			<div className={cn([style.wrapper, style.field])}>
				<textarea
					ref={elementRef}
					data-testid="textarea"
					value={value}
					placeholder={placeholder}
					disabled={disabled}
					onChange={handleChange}
					style={
						autoScale && elementRef.current
							? {
									height: elementRef.current.scrollHeight,
									overflow: "hidden",
									resize: "none",
								}
							: null
					}
				/>
			</div>
			{maxLength ? (
				<span className={cn([style.lengthHint, value.length > maxLength && style.errorLengthHint])}>
					{value.length}/{maxLength}
				</span>
			) : null}
		</div>
	);
};
