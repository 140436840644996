const OUT_BUSINESS_STATUS = {
	NOT_DEFINED: 1,
	CDI: 2,
	NON_ISO: 4,
	INTERVIEW: 5,
	APPLICATION: 6,
	INTERVIEW_WAITING: 7,
	UNAVAILABLE: 8,
	RETIREMENT: 9,
	OTHER: 10,
	PREFECTURE_VALIDATION: 13,
};

export default OUT_BUSINESS_STATUS;
