import { useMemo } from "react";

import { useQuery, UseQueryResult } from "react-query";

import { format, getYear } from "date-fns";
import { fr } from "date-fns/locale/fr";

import getOrderSideBarInformations from "src/services/commands/getOrderSideBarInformationsService";
import { SideMenuInformationsInterface } from "src/services/commands/getOrderSideBarInformationsService";

import { CREATE_MODE } from "src/constants";

const useOrderSideBarInformations = (orderId: string) => {
	const {
		data: sideBarInformations,
		isLoading,
		refetch: refetchSideBarInformations,
	}: UseQueryResult<SideMenuInformationsInterface, unknown> = useQuery(
		["getOrderSideBarInformations", orderId],
		() => getOrderSideBarInformations(orderId),
		{
			enabled: !!orderId && orderId !== CREATE_MODE,
			select: (data) => data?.data,
		}
	);

	const {
		startHourAm,
		startHourPm,
		endHourAm,
		endHourPm,
		city,
		zipCode,
		startDate,
		endDate,
		contactPhone,
		contactPhone2,
		orderStatus,
		statusReasonDescription,
		notProvidedReason,
		orderTempoId,
		agencyName,
		clientName,
		qualificationName,
		contactName,
		contactEmail,
	} = sideBarInformations ?? {};

	const convertHour = (hour: string | undefined) => hour?.replace(":", "h") || "";

	const formatedMissionTime = useMemo(
		() => ({
			startHourAm: convertHour(startHourAm),
			endHourAm: convertHour(endHourAm),
			startHourPm: convertHour(startHourPm),
			endHourPm: convertHour(endHourPm),
		}),
		[startHourAm, startHourPm, endHourAm, endHourPm]
	);

	const clientLocation = useMemo(() => {
		if (isLoading) return undefined;
		if (city && zipCode) return `${city} (${zipCode})`;
		if (zipCode) return `(${zipCode})`;
		if (city) return city;
		return null;
	}, [city, zipCode]);

	const periods = useMemo(() => {
		if (!startDate && !endDate) return "";

		const formatedStartDate = new Date(startDate);
		const formatedEndDate = new Date(endDate);

		const isYearSame = getYear(formatedStartDate) === getYear(formatedEndDate);
		return `${format(formatedStartDate, `dd MMM ${isYearSame ? "" : "yyyy"}`, {
			locale: fr,
		})} - ${format(formatedEndDate, "dd MMM yyyy", {
			locale: fr,
		})}`;
	}, [startDate, endDate]);

	const primaryPhone = useMemo(() => contactPhone ?? contactPhone2, [contactPhone, contactPhone2]);

	return {
		formatedMissionTime,
		clientLocation,
		startDate,
		endDate,
		periods,
		primaryPhone,
		contactPhone,
		contactPhone2,
		orderStatus,
		statusReasonDescription,
		notProvidedReason,
		orderTempoId,
		agencyName,
		clientName,
		qualificationName,
		contactName,
		contactEmail,
		refetchSideBarInformations,
	};
};

export default useOrderSideBarInformations;
