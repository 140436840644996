// @ts-nocheck
import * as Yup from "yup";

import i18n from "../../../../../../i18n";

export default Yup.object().shape({
	agencyId: Yup.number().required(i18n.t("profile.clientRelationship.form.agencyId.error")),
	clientId: Yup.number().required(i18n.t("profile.clientRelationship.form.clientId.error")),
	contact: Yup.string().nullable(),
	isAdequate: Yup.boolean().required(i18n.t("profile.clientRelationship.form.isAdequate.error")),
});
