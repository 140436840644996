import { FC, useState } from "react";

import { ErrorMessage, useFormikContext } from "formik";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { StatusUser } from "src/enum/enumStatusUser";
import i18n from "src/i18n";
import { TTemporaryWorkerProfile } from "src/types/TTemporaryWorker";

import { TParams } from "../TemporaryWorkerSheet";
import { TAdministrativeFormValues } from "./Administrative";
import { RadioButton } from "@zolteam/react-ras-library";
import { TooltipMessage } from "src/components/atoms";
import { CountrySelect, DateSinglePickerField, Field, NationalitySelect } from "src/components/molecules";

import { capitalizeOnBlur } from "src/utils/form/capitalizeOnBlur";
import { calculateAge } from "src/utils/formatDate";

import {
	ALLOWED_LETTERS_AND_NUMBERS_REGEX,
	ALLOWED_LETTERS_CHAR_REGEX,
	ALLOWED_NAMES_CHAR_REGEX,
} from "src/constants/CRegex";

import style from "./Administative.module.css";

const birthDateWarnings = (birthDate: string) => {
	const age = birthDate ? calculateAge(birthDate) : null;

	if (age !== null) {
		if (age < 18) {
			return i18n.t(`global.formErrors.underage`);
		}
		if (age >= 60 && age < 80) {
			return i18n.t(`global.formErrors.ageMoreThan`, { value: 60 });
		}
		if (age >= 80) {
			return i18n.t(`global.formErrors.ageMoreThan`, { value: 80 });
		}
	}
};

const CivilityInformations: FC = () => {
	const { t } = useTranslation();
	const { values, errors, touched, handleBlur, setFieldValue, setFieldTouched } =
		useFormikContext<TAdministrativeFormValues>();
	const [warnings, setWarnings] = useState<Partial<TAdministrativeFormValues>>({});
	let { userId } = useParams<TParams>();
	const queryClient = useQueryClient();

	const temporaryWorker = queryClient.getQueryData<{ data: TTemporaryWorkerProfile }>(["getUser", userId]);
	const isCandidtateOrWorker = [StatusUser.CANDIDATE, StatusUser.WORKER].includes(temporaryWorker?.data?.twStatus);

	return (
		<div className="flex flex-col w-full gap-2">
			<h2 className="text-xl font-medium">{t("usersListing.temporaryWorker.administrative.civility")}</h2>
			<div className="flex flex-col w-full gap-3">
				<div className="flex flex-row gap-3 justify-start mr-auto items-center h-[42px]">
					<RadioButton
						key="female"
						label={t("activityLogs.commonFields.wording.female")}
						className={values.sex || !isCandidtateOrWorker ? "" : style.radioError}
						onChange={() => {
							setFieldValue("sex", "female");
						}}
						name="female"
						checked={values.sex === "female"}
						color="primary"
					/>
					<RadioButton
						key="male"
						name="male"
						label={t("activityLogs.commonFields.wording.male")}
						className={values.sex || !isCandidtateOrWorker ? "" : style.radioError}
						onChange={() => {
							setFieldValue("sex", "male");
						}}
						checked={values.sex === "male"}
						color="primary"
					/>
					{errors.sex && touched.sex && (
						<ErrorMessage name={"sex"}>
							{(msg) => <TooltipMessage color="error">{msg}</TooltipMessage>}
						</ErrorMessage>
					)}
				</div>
				<div className="flex flex-row gap-3 items-center mr-auto flex-wrap">
					<Field
						label={t("activityLogs.commonFields.wording.lastName")}
						type="text"
						name="lastName"
						maxLength={30}
						allowedCharacters={ALLOWED_NAMES_CHAR_REGEX}
						classNameContainer={style.fields}
						onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
							if (e.target.value) return setFieldValue("lastName", e.target.value.toUpperCase());
							return null;
						}}
						required
						customErrorDisplay
						className={"flex-row flex-nowrap"}
					/>
					<Field
						label={t("activityLogs.commonFields.wording.birthName")}
						type="text"
						name="birthName"
						maxLength={30}
						allowedCharacters={ALLOWED_NAMES_CHAR_REGEX}
						classNameContainer={style.fields}
						onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
							if (e.target.value) return setFieldValue("birthName", e.target.value.toUpperCase());
							return null;
						}}
						className={"flex-row flex-nowrap"}
						customErrorDisplay
					/>
					<Field
						label={t("activityLogs.commonFields.wording.firstName")}
						type="text"
						name="firstName"
						maxLength={30}
						allowedCharacters={ALLOWED_NAMES_CHAR_REGEX}
						classNameContainer={style.fields}
						onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
							if (e.target.value) return capitalizeOnBlur(e, setFieldValue, handleBlur);
							return null;
						}}
						required
						customErrorDisplay
						className={"flex-row flex-nowrap"}
					/>

					<div className={"flex flex-row items-center gap-3 " + style.datePicker}>
						<DateSinglePickerField
							label={t("activityLogs.commonFields.wording.dateOfBirth")}
							containerClassname={"flex-1 "}
							numberOfMonths={1}
							name="dateOfBirth"
							id="dateOfBirth"
							onDateChange={(date: Date) => {
								const strDate = moment(date).format("DD/MM/yyyy");
								//Add a warning instead of an error, not blocking the form
								const birthdateWarnings = birthDateWarnings(strDate);
								setWarnings((prev) => ({ ...prev, dateOfBirth: birthdateWarnings }));
								setFieldValue("dateOfBirth", strDate);
							}}
							value={values?.dateOfBirth}
							onClear={() => {
								setWarnings((prev) => ({ ...prev, dateOfBirth: null }));
								setFieldTouched("dateOfBirth", true);
								setFieldValue("dateOfBirth", null);
							}}
							onBlur={() => {
								const birthdateWarnings = birthDateWarnings(values?.dateOfBirth);
								setWarnings((prev) => ({ ...prev, dateOfBirth: birthdateWarnings }));
								setFieldTouched("dateOfBirth", true);
							}}
							error={errors?.dateOfBirth}
							selectableMonthsYears
							writable
							showErrorMessage={false}
							required={isCandidtateOrWorker}
							convertToMoment={false}
						/>

						{errors?.dateOfBirth && touched?.dateOfBirth && (
							<TooltipMessage color="error">{errors?.dateOfBirth}</TooltipMessage>
						)}
						{!errors?.dateOfBirth && warnings?.dateOfBirth && (
							<TooltipMessage color="warning">{warnings?.dateOfBirth}</TooltipMessage>
						)}
					</div>
				</div>

				<div className="flex flex-row gap-3 items-center mr-auto flex-wrap">
					<Field
						label={t("activityLogs.commonFields.wording.birthPlace")}
						type="text"
						name="bornCity"
						classNameContainer={style.fields}
						required={isCandidtateOrWorker}
						allowedCharacters={ALLOWED_LETTERS_CHAR_REGEX}
						customErrorDisplay
						className={"flex-row flex-nowrap"}
						onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
							if (e.target.value) return setFieldValue("bornCity", e.target.value.toUpperCase());
							return null;
						}}
						maxLength={30}
					/>
					<CountrySelect
						name="nativeCountryId"
						value={values?.nativeCountryId}
						label={t("activityLogs.commonFields.wording.birthCountry")}
						required={isCandidtateOrWorker}
						onBlur={() => {
							setFieldTouched("nativeCountryId", true);
						}}
						onChange={(item) => {
							setFieldValue("nativeCountryId", item?.id);
						}}
						containerClassname={style.fields}
						className={
							style.fields + " " + (errors.nativeCountryId && touched.nativeCountryId ? style.error : "")
						}
					/>
					<Field
						label={t("activityLogs.commonFields.wording.bornDepartmentBis")}
						type="text"
						name="bornDepartment"
						classNameContainer={style.fields}
						allowedCharacters={ALLOWED_LETTERS_AND_NUMBERS_REGEX}
						customErrorDisplay
						className={"flex-row flex-nowrap"}
						maxLength={3}
						onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
							if (e.target.value) return setFieldValue("bornDepartment", e.target.value.toUpperCase());
							return null;
						}}
					/>

					<NationalitySelect
						name="nationalityId"
						value={values?.nationalityId}
						required={isCandidtateOrWorker}
						containerClassname={style.fields}
						onChange={(item) => {
							setFieldValue("nationalityId", item?.id);
						}}
						className={
							style.fields + " " + (errors.nationalityId && touched.nationalityId ? style.error : "")
						}
					/>
				</div>
			</div>
		</div>
	);
};

export default CivilityInformations;
