import { FC, useState } from "react";

import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

import { getCommonDocuments } from "src/services/commandService";

import useSelectable from "src/hooks/useSelectable";

import DocumentsTypesColumns from "./DocumentsTypesColumns";
import DocumentsTypesPanel from "./DocumentsTypesPanel";
import { Button, Title } from "@zolteam/react-ras-library";
import { InputSearch } from "src/components/molecules/InputSearch/InputSearch";
import { TableListingInfiniteScroll } from "src/components/organisms";

import style from "./DocumentsTypes.module.css";

const DocumentsTypes: FC = () => {
	const { t } = useTranslation();
	const [filters, setFilters] = useState<{ search?: string }>({});
	const handleSearch = (searchText: string) => {
		setFilters({ search: searchText || null });
	};

	//Selection
	const { selectedItems, singleSelect, clearSelection } = useSelectable({ targetField: "id" });

	//Query
	const postUsersListingSearchQuery = useQuery({
		queryKey: ["getDocumentsTypes", filters],
		queryFn: () => {
			return getCommonDocuments({ ...filters, limit: 10000, includeArchived: true }).then((res) => {
				return res.data;
			});
		},
		onSuccess: (data) => {
			//Permet d'initialiser la selection automatique de la première ligne
			if (selectedItems.length === 0) singleSelect({ id: data.items[0]?.id });
		},
	});

	const { isError, isLoading, isFetching, data } = postUsersListingSearchQuery;

	return (
		<div className="flex flex-row h-full">
			<div className="flex flex-col flex-[1.6]">
				<div className={`${style.filterHeader} flex flex-row items-center justify-between gap-2 p-6`}>
					<Title tag="h2" size="heading03" fontWeight="bold" lineHeight="s" className="flex-1">
						{t("integration.menu.documentTypes")}
					</Title>
					<InputSearch
						label={t("global.filters.searchText")}
						onChange={(value) => {
							handleSearch(value);
						}}
						minLength={0}
						debounced
						name="search"
						value={filters?.search || ""}
						className="w-auto max-w-[220px] h-[41px]"
						variant="squared"
					/>
					<Button
						type="button"
						color="primary"
						onClick={() => clearSelection()}
						data-testid="addDocument"
						className={style.button}
					>
						{t("integration.documentsTypes.add")}
					</Button>
				</div>
				<TableListingInfiniteScroll
					loading={isLoading || isFetching}
					error={isError}
					data={data?.items || []}
					handleSearch={() => {}}
					initialColumnSort={{
						field: "createdAt",
						orderBy: "ASC",
					}}
					tableContainerClassName="rounded-none"
					canBeChecked={false}
					selectedItems={selectedItems}
					onRowClick={singleSelect}
					fullHeight
					showHeader={false}
					showCustomMessage={false}
					genericEmptyCellsMessage={<div className="text-neutral-300">-</div>}
				>
					{DocumentsTypesColumns()}
				</TableListingInfiniteScroll>
			</div>
			{!isError && (
				<DocumentsTypesPanel
					selectedDocument={data?.items.find((item) => item.id === selectedItems[0])}
					isLoading={isLoading || isFetching}
				/>
			)}
		</div>
	);
};

export default DocumentsTypes;
