import { FC } from "react";

import style from "./ImgUser.module.css";

interface IImgUserProps {
	link: string;
	alt: string;
}

export const ImgUser: FC<IImgUserProps> = ({ link, alt }) => <img src={link} alt={alt} className={style.image} />;
