import { FC, useEffect, useRef, useState } from "react";

import useElementOnScreen from "src/hooks/useElementOnScreen";

import { TableListing, TableListingProps } from "../TableListing/TableListing";

interface TableListingInfiniteScrollProps extends TableListingProps {
	maxRows?: number;
}

export const TableListingInfiniteScroll: FC<TableListingInfiniteScrollProps> = (props) => {
	const { maxRows = 40, data } = props;
	const [displayedData, setDisplayedData] = useState([]);
	const [startIndex, setStartIndex] = useState(0);
	const listRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		setDisplayedData(data.slice(startIndex, startIndex + maxRows));
	}, [data]);

	const handleUpdateDataBottom = (isVisible: boolean) => {
		//TODO: Gérer le cas ou toutes les données ne sont pas chargée
		if (!isVisible || startIndex + maxRows >= data.length) return;
		const newStartIndex = displayedData?.length === 0 ? 0 : startIndex + maxRows / 2;
		const newEndIndex = displayedData?.length === 0 ? maxRows : newStartIndex + maxRows;
		setStartIndex(newStartIndex);
		setDisplayedData(data.slice(newStartIndex, newEndIndex));
	};

	const handleUpdateDataTop = (isVisible: boolean) => {
		if (!isVisible || startIndex === 0) return;
		const newStartIndex = displayedData?.length === 0 ? 0 : startIndex - maxRows / 2;
		const newEndIndex = displayedData?.length === 0 ? maxRows : newStartIndex + maxRows;

		setStartIndex(newStartIndex);
		setDisplayedData(data.slice(newStartIndex, newEndIndex));

		setTimeout(() => {
			// Ajuste la position de scroll pour compenser le scroll automatique vers le haut lors du rajout de données en début de tableau
			maxRows / 2 > 0 && listRef.current?.children[maxRows / 2 + 1]?.scrollIntoView({ block: "start" });
		}, 0); // Attends un cycle de rafraîchissement du DOM
	};

	const { container: bottomContainer } = useElementOnScreen({ threshold: 0.5 }, handleUpdateDataBottom);
	const { container: topContainer } = useElementOnScreen({ threshold: 0.5 }, handleUpdateDataTop);

	const observerElementTop = props.loading ? (
		<></>
	) : (
		<tr style={{ height: "20px", background: "transparent" }}>
			<td>
				<div ref={topContainer}></div>
			</td>
		</tr>
	);

	const observerElementBottom = props.loading ? (
		<></>
	) : (
		<tr style={{ height: "20px", background: "transparent" }}>
			<td>
				<div ref={bottomContainer}></div>
			</td>
		</tr>
	);

	return (
		<TableListing
			{...props}
			data={displayedData}
			observerElementBottom={observerElementBottom}
			observerElementTop={observerElementTop}
			listRef={listRef}
		>
			{props.children}
		</TableListing>
	);
};
