import { TUsersListingFilters } from "src/types/TUsersListing";

export const DEFAULT_USERS_LISTING_SEARCH_PARAMS = {
	limit: 40,
	page: 1,
	sorting: "createdAt",
	orderBy: "DESC",
};

export const DEFAULT_USERS_LISTING_FILTERS: TUsersListingFilters = {
	count: 0,
	searchText: "",
	agenciesIds: [],
	createdSince: null,
	hasMyRas: null,
	jobTypes: [],
	paymentInfos: {
		hasIbanToValidate: null,
		isMissingIban: null,
	},
	qualifications: [],
	competencies: [],
	equipments: [],
	workConditions: [],
	commonDocuments: [],
	status: {
		waiting: null,
		candidate: null,
		temporaryWorker: null,
		student: null,
		isVip: null,
		isCdiInterim: null,
	},
};

export const OUT_BUSINESS_REASONS_MOCKED = [
	{ name: "NOT_DEFINED", value: "1" },
	{ name: "CDI", value: "2" },
	{ name: "NON_ISO", value: "4" },
	{ name: "INTERVIEW", value: "5" },
	{ name: "APPLICATION", value: "6" },
	{ name: "INTERVIEW_WAITING", value: "7" },
	{ name: "UNAVAILABLE", value: "8" },
	{ name: "RETIREMENT", value: "9" },
	{ name: "PREFECTURE_VALIDATION", value: "13" },
	{ name: "OTHER", value: "10" },
];
