// @ts-nocheck

/* eslint-disable import/prefer-default-export */
import { addressGouvClient } from "./client";

/**
 * Search for addresses via the government API.
 *
 * This function uses Axios to perform a GET request to the French government
 * address autocompletion API.
 *
 * @param {string} address - The address or part of the address to search for.
 * @param {number} limit - The maximum number of results to return.
 * @returns {Promise<AxiosResponse>} - A promise that resolves with the Axios response containing
 * the search results.
 */
export const searchGouvAddress = (address, limit) =>
	addressGouvClient.get(`/search`, {
		params: {
			limit,
			q: address,
		},
	});

/**
 * Search for address using coordinates
 *
 * This function uses Axios to perform a GET request to the French government
 * address autocompletion API.
 *
 * @param {number} latitude - The latitude of the address.
 * @param {number} longitude - The longitude of the address.
 * @param {number} limit - The maximum number of results to return.
 * @returns {Promise<AxiosResponse>} - A promise that resolves with the Axios response containing
 * the search results.
 */
export const searchByCoordinates = (latitude, longitude, limit) =>
	addressGouvClient.get(`/reverse`, {
		params: {
			lat: latitude,
			lon: longitude,
			limit,
		},
	});
