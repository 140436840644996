// Style
import { FC } from "react";

import { Picto, Text } from "@zolteam/react-ras-library";

import styles from "./TableNumberRow.module.css";

interface ITableNumberRowProps {
	number?: string | number;
	type?: string;
	emergency?: boolean;
}

export const TableNumberRow: FC<ITableNumberRowProps> = ({ number = "", type = "", emergency = false }) => (
	<div className={styles.container}>
		<div className={styles.info}>
			<Text tag="span" fontWeight="normal" lineHeight="m" size="paragraph02" className={styles.number}>
				{number}
			</Text>
			<Text tag="span" color="black" lineHeight="m" fontWeight="light" size="paragraph03" className={styles.type}>
				{type}
			</Text>
		</div>
		<div className={styles.emergency}>{emergency && <Picto icon="ringingBell" style={{ width: "16px" }} />}</div>
	</div>
);
