// @ts-nocheck

/* eslint-disable max-len */
import moment from "moment/moment";

/**
 * Checks if a given period overlaps with specified working days.
 *
 * @param {Object} orderWorkingDays An object with the days of the week as keys and a boolean as value
 * @param {string|Date|moment.Moment} matchingStartDate
 * @param {string|Date|moment.Moment} matchingEndDate
 * @returns {boolean} Returns true if the period overlaps with working days
 *
 * @example
 * const orderWorkingDays = {
 *   monday: true,
 *   tuesday: true,
 *   wednesday: true,
 *   thursday: true,
 *   friday: true,
 *   saturday: false,
 *   sunday: false
 * };
 *
 * const matchingStartDate = '2024-06-03T00:00:00+0000';
 * const matchingEndDate = '2024-06-09T00:00:00+0000';
 *
 * isOverlapWithWorkingDays(orderWorkingDays, matchingStartDate, matchingEndDate); // Returns true if the period overlaps with working days
 */

const isOverlapWithWorkingDays = (orderWorkingDays, matchingStartDate, matchingEndDate) => {
	if (!orderWorkingDays || !matchingStartDate || !matchingEndDate) return true;

	const start = moment(matchingStartDate).locale("en");
	const end = moment(matchingEndDate).locale("en");
	const periodLength = end.diff(start, "days");

	if (periodLength > 7) return true;

	const days = Array.from({ length: periodLength + 1 }, (_, i) =>
		moment(start).add(i, "days").format("dddd").toLowerCase()
	);

	return days.some((day) => orderWorkingDays[day]);
};

export default isOverlapWithWorkingDays;
