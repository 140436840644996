import i18n from "src/i18n";
import * as Yup from "yup";

const ParticularWorkTimeRule = () => {
	return Yup.string()
		.nullable()
		.max(120, i18n.t("commands.error.maxCaractersLimit", { count: 120 }));
};

export default ParticularWorkTimeRule;
