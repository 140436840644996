import { FC } from "react";

import { DatePickerField, FilterCardHeader } from "src/components/molecules";

interface IDatePickerFilterProps {
	placeholder?: string;
	onChange: (date: Date) => void;
	value: Date;
	handleReset?: () => void;
	title: string;
}

export const DatePickerFilter: FC<IDatePickerFilterProps> = ({
	placeholder = null,
	onChange,
	value = null,
	handleReset = null,
	title,
}) => (
	<div className="[&_p]:mt-0">
		<FilterCardHeader title={title} handleReset={handleReset} />
		<DatePickerField label={placeholder} onChange={onChange} value={value} />
	</div>
);
