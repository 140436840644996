export const DropdownIndicator = ({ isLoading }: { isLoading: boolean }): JSX.Element | null => {
	if (isLoading) {
		return (
			<img
				className="h-4 w-4 animate-spin"
				src="/circle-loader.png"
				width="75"
				height="auto"
				alt="circle-loader"
			/>
		);
	}
	return null;
};
