import { Trans } from "react-i18next";

import ProfilesAvailableType from "../../../types/ProfilesAvailableType";

import OUT_BUSINESS_STATUS from "src/constants/outOfBusinessStatus";

const getNonSelectableProfileMessage = (
	option: ProfilesAvailableType,
	t: (key: string, options?: { [key: string]: any }) => string
) => {
	const reasons: string[] = [];

	if (option.isInadequate) {
		reasons.push(t("commands.profiles.modalsContent.addProfile.notValidProfileReasons.statusInadequateWithClient"));
	}
	if (!option.hasValidIdentityDocument) {
		reasons.push(t("commands.profiles.modalsContent.addProfile.notValidProfileReasons.invalidIdentityDocument"));
	}
	if (option.outOfBusinessReason === OUT_BUSINESS_STATUS.NON_ISO) {
		reasons.push(t("commands.profiles.modalsContent.addProfile.notValidProfileReasons.nonIsoExit"));
	}
	if (option.outOfBusinessReason === OUT_BUSINESS_STATUS.PREFECTURE_VALIDATION) {
		reasons.push(t("commands.profiles.modalsContent.addProfile.notValidProfileReasons.prefectureValidationExit"));
	}

	const reasonsString = reasons.join(", ");
	return (
		<Trans
			i18nKey="commands.profiles.modalsContent.addProfile.notValidatedProfileMessage"
			values={{ reasons: reasonsString }}
			components={{ br: <br /> }}
		/>
	);
};

export default getNonSelectableProfileMessage;
