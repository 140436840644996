import { AxiosResponse } from "axios";

import client from "../client";

export const postCreateUser = (
	unifiedTempWorkerId: string,
	agencyId: number,
	agencyIdSource: number
): Promise<AxiosResponse<void>> => {
	return client.post<void>(`/api-client-v2/unified-temp-workers/${unifiedTempWorkerId}/create-user`, {
		agencyId,
		agencyIdSource,
	});
};
