import i18n from "src/i18n";
import * as Yup from "yup";

export default Yup.object().shape({
	agencyId: Yup.number(),
	userIdentity: Yup.string(),
	requestedAmount: Yup.string()
		.nullable()
		.matches(/^\d+([,.]\d{1,2})?$/, i18n.t("advancePayments.error.formatCurrencyNotValid")),
	comment: Yup.string().nullable(),
	desiredPaymentDate: Yup.string(),
});
