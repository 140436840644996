// Style
import { FC } from "react";

import { useTranslation } from "react-i18next";
import { TTemporaryWorkerSuspision } from "src/types";

// Store
import useAppContext from "../../../store/useAppContext";

import { formatDate } from "../../../utils/formatDate";
// Organisms
import { Text, Title } from "@zolteam/react-ras-library";
import { ImgUser, ProgressBar, Tag } from "src/components//atoms";
// Atoms
import { ListMaxWidth } from "src/components/organisms";

import style from "./SuspiciousSheetHeader.module.css";

interface ISuspiciousSheetHeaderProps {
	temporaryWorker: TTemporaryWorkerSuspision;
}

export const SuspiciousSheetHeader: FC<ISuspiciousSheetHeaderProps> = ({
	temporaryWorker: {
		firstName,
		lastName,
		avatarPath,
		mainQualification,
		isAvailable,
		lastActivityType,
		lastActivityDate,
		agencies,
	},
}) => {
	const { t } = useTranslation();
	const [{ suspiciousFormTotalFields, suspiciousFormUnfilledFields }] = useAppContext();

	return (
		<div className={style.container}>
			<div className={style.left}>
				<div className={style.tempWorkerContainer}>
					{avatarPath ? <ImgUser link="" alt="" /> : null}
					<div className={style.tempWorkerInfoWrapper}>
						<div className={style.tempWorkerInfo}>
							<Title tag="h3" size="heading03" lineHeight="s">
								{`${firstName} ${lastName}`}
							</Title>
							{mainQualification ? (
								<div className="ml-10">
									<Text tag="span" size="paragraph01">
										{mainQualification}
									</Text>
								</div>
							) : null}
							{isAvailable ? (
								<Tag size="m" radius="s" color="success" className="ml-10">
									{t("suspiciousSheet.available")}
								</Tag>
							) : (
								<Tag size="m" radius="s" color="grey" className="ml-10">
									{t("suspiciousSheet.inactive")}
								</Tag>
							)}
						</div>
						{lastActivityType ? (
							<Text color="grey" tag="span" size="paragraph02" className="mt-xs">
								{`${t(`suspiciousSheet.lastActivityType.${lastActivityType}`)} le ${formatDate(lastActivityDate)}`}
							</Text>
						) : null}
						{agencies?.length ? (
							<span className="mt-s">
								<ListMaxWidth
									array={agencies}
									keyListElem="id"
									oneItem
									component={({ data: agency, index }) => (
										<Text tag="span" size="paragraph01" fontWeight="bold">
											{index === 0 ? `${agency.name}` : `, ${agency.name}`}
										</Text>
									)}
								/>
							</span>
						) : null}
					</div>
				</div>
			</div>
			<div className={style.right}>
				{suspiciousFormTotalFields?.length ? (
					<div className={style.sheetSteps}>
						<Text size="paragraph02" tag="span" data-testid="progressTotal">
							{`${suspiciousFormUnfilledFields} 
            / ${suspiciousFormTotalFields?.length}`}
						</Text>
						<div className={style.gauge}>
							<ProgressBar
								completed={
									(suspiciousFormUnfilledFields / (suspiciousFormTotalFields?.length ?? 1)) * 100
								}
								color="primary"
							/>
						</div>
					</div>
				) : null}
			</div>
		</div>
	);
};
