import * as Yup from "yup";

export default Yup.object().shape({
	agency: Yup.object()
		.shape({
			id: Yup.number().required(),
			updatedAt: Yup.string().required(),
			agencyId: Yup.string().required(),
			agencyName: Yup.string().required(),
		})
		.required(),
});
