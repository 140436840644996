import { CSSProperties, FC, PropsWithChildren } from "react";

import { Tooltip } from "@zolteam/react-ras-library";

interface TooltipConditionalProps extends PropsWithChildren {
	placement?:
		| "top"
		| "right"
		| "bottom"
		| "left"
		| "top-start"
		| "top-end"
		| "right-start"
		| "right-end"
		| "bottom-start"
		| "bottom-end"
		| "left-start"
		| "left-end";
	content?: React.ReactNode;
	arrow?: boolean;
	visible?: boolean;
	tooltipBoxStyle?: CSSProperties;
	containerClassName?: string;
}

const TooltipConditional: FC<TooltipConditionalProps> = ({
	placement,
	content,
	children,
	arrow,
	visible,
	tooltipBoxStyle,
	containerClassName,
	...props
}) => {
	if (!content) return <>{children}</>;
	return (
		<Tooltip
			placement={placement}
			content={<div>{content}</div>}
			arrow={arrow}
			visible={visible}
			tooltipBoxStyle={tooltipBoxStyle}
			{...props}
		>
			<div className={containerClassName}>{children}</div>
		</Tooltip>
	);
};

export default TooltipConditional;
