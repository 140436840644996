import { FC } from "react";

import { Picto, Tag } from "@zolteam/react-ras-library";

import style from "./VipTag.module.css";

interface IVipTagProps {
	className?: string;
	withLabel?: boolean;
}

export const VipTag: FC<IVipTagProps> = ({ className = "", withLabel = false }) => (
	<Tag className={`${style.vipTag} ${className}`} color="primaryLight">
		<Picto
			icon="star"
			className={style.starPicto}
			style={{
				height: "10px",
				width: "10px",
				marginRight: 0,
			}}
		/>
		{withLabel && <span className={style.label}>VIP</span>}
	</Tag>
);
