// @ts-nocheck

/* eslint-disable react/destructuring-assignment */
import { getUnfilledFields, removeDissociatedUserValuesFromForm } from "../utils/suspiciousSheetUtils";

const AppReducer = (state, action) => {
	switch (action.type) {
		case "SET_USER": {
			return { ...state, user: action.payload };
		}
		case "CLEAR_USER": {
			return { ...state, user: null };
		}
		case "SET_SUSPICIOUS_FORM_WORKER_VALUES": {
			return {
				...state,
				suspiciousSheetForm: {
					...state.suspiciousSheetForm,
					temporaryWorker: action.payload,
				},
				suspiciousFormUnfilledFields: getUnfilledFields(action.payload, state.suspiciousFormTotalFields),
			};
		}
		case "SET_SUSPICIOUS_FORM_TOTAL_FIELDS": {
			return { ...state, suspiciousFormTotalFields: action.payload };
		}
		case "SET_SUSPICIOUS_FORM_USERS": {
			return {
				...state,
				suspiciousSheetForm: {
					...state.suspiciousSheetForm,
					users: action.payload,
				},
			};
		}
		case "SET_SUSPICIOUS_FORM_DISSOCIATED_USERS": {
			const newWorker = removeDissociatedUserValuesFromForm({
				dissociatedUserIds: action.payload?.dissociated,
				worker: state.suspiciousSheetForm.temporaryWorker,
				users: action.payload?.all,
			});
			return {
				...state,
				suspiciousSheetForm: {
					...state.suspiciousSheetForm,
					dissociatedUsers: action.payload?.dissociated,
					temporaryWorker: newWorker,
				},
				suspiciousFormUnfilledFields: getUnfilledFields(newWorker, state.suspiciousFormTotalFields),
			};
		}
		case "CLEAR_SUSPICIOUS_FORM": {
			return {
				...state,
				suspiciousSheetForm: {
					temporaryWorker: null,
					users: [],
					dissociatedUsers: [],
				},
				suspiciousFormTotalFields: [],
				suspiciousFormUnfilledFields: 0,
			};
		}
		case "CLEAR_SUSPICIOUS_FORM_DISSOCIATED_USERS": {
			return {
				...state,
				suspiciousSheetForm: {
					...state.suspiciousSheetForm,
					dissociatedUsers: [],
				},
			};
		}
		case "SET_SUSPICIOUS_TABLE_FILTER": {
			return {
				...state,
				suspiciousFilter: { ...state.suspiciousFilter, ...action.payload },
			};
		}
		case "SET_NOTIFICATIONS_TABLE_FILTERS": {
			return {
				...state,
				notificationsFilters: {
					...state.notificationsFilters,
					...action.payload,
				},
			};
		}
		case "SET_TOTAL_UNREAD_NOTIFICATIONS_COUNT": {
			return {
				...state,
				countUnreadNotifications: action.payload.count,
			};
		}
		case "SET_TOTAL_UNREAD_MESSAGES_COUNT": {
			return {
				...state,
				countUnreadMessages: action.payload.count,
			};
		}
		case "SET_EXPIRED_DOCUMENTS_FILTERS": {
			return {
				...state,
				expiredDocumentsFilters: { ...state.expiredDocumentsFilters, ...action.payload },
			};
		}
		case "SET_ORDER_MESSAGE": {
			return {
				...state,
				orderMessage: {
					displayed: action.payload.displayed,
					content: action.payload.content,
					color: action.payload.color,
					withIcon: action.payload.withIcon,
				},
			};
		}
		case "CLEAR_ORDER_MESSAGE": {
			return {
				...state,
				orderMessage: {
					displayed: false,
					content: null,
					color: "primary",
					withIcon: false,
				},
			};
		}
		case "SET_ADVANCE_PAYMENTS_TABLE_FILTER": {
			return {
				...state,
				advancePaymentsFilter: {
					...state.advancePaymentsFilter,
					...action.payload,
				},
			};
		}
		case "SET_USER_AGENCIES": {
			return {
				...state,
				userAgencies: action.payload.agencies,
			};
		}
		case "SET_USERS_LIST_FILTERS": {
			return {
				...state,
				advancePaymentsUsersFilter: {
					...state.advancePaymentsUsersFilter,
					...action.payload,
				},
			};
		}
		default: {
			throw new Error(`Unhandled action type: ${action.type}`);
		}
	}
};

export default AppReducer;
