import { FC, useCallback } from "react";

import { useTranslation } from "react-i18next";
// Style
import { TTemporaryWorkerSuspision } from "src/types";
import { TUser } from "src/types/TUser";

// Store
import useAppContext from "../../../store/useAppContext";

import { CheckableInput } from "../../atoms/CheckableInput/CheckableInput";
// Components
import { Text } from "@zolteam/react-ras-library";

// Utils
import {
	checkSuspiciousValue,
	formatSuspiciousFormFields,
	getSuspiciousCheckboxValue,
	getValueToSet,
	isTheSameForAll,
	setSuspiciousCheckboxValue,
} from "src/utils/suspiciousSheetUtils";

// Constants
import {
	SUSPICION_TABLE_FIRST_ROW_HEIGHT,
	SUSPICION_TABLE_ROW_CATEGORY_HEIGHT,
	SUSPICION_TABLE_ROW_HEIGHT,
	SUSPICION_TABLE_ROW_WIDTH,
	WORKER_INFO_SECTION,
} from "src/constants";

import style from "./SuspiciousTableLeftFixedColumn.module.css";

interface ISuspiciousTableLeftFixedColumnProps {
	left: React.CSSProperties["left"];
	isHover: number | null;
	setHover: (hover: number | null) => void;
	temporaryWorker: TTemporaryWorkerSuspision;
	users: TUser[];
}

export const SuspiciousTableLeftFixedColumn: FC<ISuspiciousTableLeftFixedColumnProps> = ({
	left,
	setHover,
	isHover = null,
	temporaryWorker,
	users,
}) => {
	// hooks
	const { t } = useTranslation();
	const [{ suspiciousSheetForm }, appDispatch] = useAppContext();

	const getTextValue = (property: string) => {
		if (checkSuspiciousValue(suspiciousSheetForm?.temporaryWorker?.[property])) {
			return formatSuspiciousFormFields(
				property,
				suspiciousSheetForm?.temporaryWorker?.[property] ?? "",
				suspiciousSheetForm.temporaryWorker
			);
		}
		return formatSuspiciousFormFields(property, temporaryWorker?.[property] ?? "", temporaryWorker);
	};

	const getValue = useCallback(
		(property: string) => {
			if (checkSuspiciousValue(suspiciousSheetForm?.temporaryWorker?.[property])) {
				return true;
			}
			return getSuspiciousCheckboxValue({
				property,
				userValue: temporaryWorker?.[property],
				suspiciousSheetForm,
			});
		},
		[suspiciousSheetForm, temporaryWorker]
	);

	const getInitialValue = (property: string) => {
		if (
			checkSuspiciousValue(suspiciousSheetForm?.temporaryWorker?.[property]) &&
			suspiciousSheetForm?.temporaryWorker?.[property] !== temporaryWorker?.[property]
		) {
			return formatSuspiciousFormFields(property, temporaryWorker?.[property], temporaryWorker);
		}
		return "";
	};

	return (
		<div
			style={{
				width: SUSPICION_TABLE_ROW_WIDTH + 4,
				top: SUSPICION_TABLE_FIRST_ROW_HEIGHT,
				left,
			}}
			className={style.tableLeftColumn}
		>
			{WORKER_INFO_SECTION.map((info, index) => {
				const { isCategory, isNotCheckableIfNull } = Object.values(info)?.[0] ?? {};
				return (
					<div
						key={Object.keys(info)[0]}
						onMouseEnter={() => setHover(index)}
						onMouseLeave={() => setHover(null)}
						className={`${style.tableLeftColumnRow} ${isHover === index ? style.hover : ""}`}
						style={{
							width: SUSPICION_TABLE_ROW_WIDTH + 4,
							height: isCategory ? SUSPICION_TABLE_ROW_CATEGORY_HEIGHT : SUSPICION_TABLE_ROW_HEIGHT,
						}}
					>
						{isCategory ? (
							<Text tag="p" size="paragraph01" fontWeight="bold" className={style.categoryLabel}>
								{t(`suspiciousSheet.workerInfo.${Object.keys(info)?.[0]}`)}
							</Text>
						) : (
							<div className={style.inputContainer}>
								<CheckableInput
									id={`${temporaryWorker.id}-${Object.keys(info)?.[0]}`}
									initialTextValue={getInitialValue(Object.keys(info)?.[0])}
									disabled={isTheSameForAll(Object.keys(info)?.[0], [...users, ...[temporaryWorker]])}
									isCheckable={
										!(isNotCheckableIfNull && !getTextValue(Object.keys(info)?.[0]).toString())
									}
									textValue={getTextValue(Object.keys(info)?.[0]).toString()}
									value={getValue(Object.keys(info)?.[0])}
									onChange={(value) =>
										setSuspiciousCheckboxValue({
											property: Object.keys(info)?.[0],
											value: value
												? getValueToSet(Object.keys(info)?.[0], temporaryWorker)
												: undefined,
											appDispatch,
											suspiciousSheetForm,
										})
									}
									placeholder={t(`suspiciousSheet.workerInfo.${Object.keys(info)?.[0]}`)}
									hoverPlaceholder={t(`suspiciousSheet.workerInfo.old${Object.keys(info)?.[0]}`)}
								/>
							</div>
						)}
					</div>
				);
			})}
		</div>
	);
};
