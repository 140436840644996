import { FC } from "react";

import { Picto } from "@zolteam/react-ras-library";

import style from "./OptionQualificationTempo.module.css";

interface IOptionQualificationTempoProps {
	id?: string | number;
	name?: string;
	level?: string;
	coefficient?: string;
	hoursCoefficient?: string;
	tempoId?: string;
}

export const OptionQualificationTempo: FC<IOptionQualificationTempoProps> = ({
	id = "",
	name = "",
	level = null,
	coefficient = null,
	hoursCoefficient = null,
	tempoId = null,
}) => (
	<div className={style.optionContainer} data-testid={`option-${id}-${name}`} key={id}>
		<Picto icon="isSynchronizable" className={style.picto} />
		<div className={style.textContainer}>
			<span className={style.optionName}>{`${name ?? ""}`}</span>
			<span className={style.optionInfos}>
				{`${tempoId ?? "/"} - ${level || "/"} - ${hoursCoefficient ?? "/"} - ${coefficient ?? "/"}`}
			</span>
		</div>
	</div>
);
