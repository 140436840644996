// @ts-nocheck

/* eslint-disable import/prefer-default-export */
import moment from "moment";

import { addWeeks, getISOWeek, isSunday, startOfMonth } from "date-fns";

/**
 * Checks if the given time in milliseconds has already passed.
 *
 * @param {number} time - The time in milliseconds to check.
 * @returns {boolean} - Returns `true` if the given time is in the past, otherwise `false`.
 */
export const isTimePast = (time, delay) => {
	const now = new Date().getTime();
	return time < now - delay;
};

export const getDayDiff = (startDate: Date, endDate: Date): number => {
	if (startDate > endDate) {
		return 0;
	}

	const timeDifference = Number(endDate) - Number(startDate);
	const millisPerDay = 1000 * 60 * 60 * 24;
	return Math.ceil(timeDifference / millisPerDay);
};

export const getDayOfWeek = (date: Date): number => {
	const dayOfWeek = date.getDay();
	return dayOfWeek === 0 ? 7 : dayOfWeek;
};

export const getYears = (startYear, endYear) => {
	const FuturYear = endYear ? endYear : new Date().getFullYear();
	const pastYear = new Date().getFullYear() - startYear;
	return Array.from({ length: pastYear }, (v, i) => FuturYear - pastYear + i + 1);
};

export function getSecondOrThirdWeekOfMonths(year) {
	const weekNumbers = [];

	for (let month = 0; month < 12; month++) {
		// Premier jour du mois
		const firstDayOfMonth = startOfMonth(new Date(year, month));

		// Vérifiez si le premier jour est un dimanche
		const isFirstDaySunday = isSunday(firstDayOfMonth);

		// Si le premier jour est un dimanche, on utilise la 3eme semaine, sinon la 2eme
		const targetWeekStart = addWeeks(firstDayOfMonth, isFirstDaySunday ? 2 : 1);

		// Numéro de la semaine ISO
		const weekNumber = getISOWeek(targetWeekStart);

		// Ajoutez le numéro de semaine au tableau
		weekNumbers.push(weekNumber);
	}

	return weekNumbers;
}

/**
 * Capitalize month text
 * @param {*} month month text
 * @returns {string} capitalized month text
 */
export const formatMonthText = (month) => {
	let text = moment(month).locale("fr").format("MMMM Y");
	text = text.charAt(0).toUpperCase() + text.slice(1);

	return text;
};
