import { FC } from "react";

import { FormikProps } from "formik";
import { t } from "i18next";
import { isEqual } from "lodash";
import { useQuery } from "react-query";
import { TAgency, TJobType } from "src/types";
import { TQualification } from "src/types/TQualifications";
import { TUsersListingFilters } from "src/types/TUsersListing";

import user from "../../../services/user";
import { getCommonDocuments } from "src/services/commandService";
import { getCommonCompetencies, getCommonQualifications } from "src/services/commonQualificationsService";
import JobTypesService from "src/services/jobTypesService";

import { useFiltersContext } from "src/store/FiltersContext/useFiltersContext";

import { CheckboxListFilter, DatePickerFilter, FilterModal, SelectFilter } from "src/components/organisms";
import { AgenciesFilter } from "src/components/templates";

import { DEFAULT_USERS_LISTING_FILTERS } from "src/constants/CUsersListing";

interface IUsersListingFiltersModalProps {
	onSubmit: (filters: TUsersListingFilters) => void;
	isDisplayed: boolean;
	onCloseModal: () => void;
}

const UsersListingFiltersModal: FC<IUsersListingFiltersModalProps> = ({ onSubmit, isDisplayed, onCloseModal }) => {
	const { filters } = useFiltersContext<TUsersListingFilters>("users");

	const { data: userAgenciesData, isLoading } = useQuery(["getUserAgencies", "filtermodal"], () =>
		user.getUserAgencies().then((res) => {
			if (!filters?.agenciesIds?.length) {
				onSubmit({ ...filters });
			}
			return res.data;
		})
	);

	const onClose = () => {
		onCloseModal();
	};

	const handleChangeMyRas = (type: string, formikCtx: FormikProps<TUsersListingFilters>) => {
		formikCtx.setFieldValue("hasMyRas", type);
	};

	return (
		<FilterModal
			onSubmit={onSubmit}
			isDisplayed={isDisplayed}
			onCloseModal={onClose}
			defaultFilters={{
				...DEFAULT_USERS_LISTING_FILTERS,
			}}
			filterKey="users"
			size="l"
			isLoading={isLoading}
		>
			{(formikCtx) => {
				const { setFieldValue, values, resetField } = formikCtx;

				return (
					<>
						<div>
							{userAgenciesData?.length > 0 && (
								<div>
									<AgenciesFilter
										titleOnTop
										activeSelectAllOption
										options={userAgenciesData || []}
										selectedOptions={values?.agenciesIds?.map((agency: TAgency) => agency.id)}
										handleFilter={(selectedAgencies) => {
											setFieldValue("agenciesIds", selectedAgencies);
										}}
										canExpendOrFold={false}
										agenciesInputWidth="100%"
										inputSize="m"
										canReset={values?.agenciesIds?.length !== 0}
										onReset={() => resetField("agenciesIds")}
									/>
								</div>
							)}
							<DatePickerFilter
								handleReset={values.createdSince ? () => resetField("createdSince") : null}
								placeholder={values?.createdSince ? null : "DD/MM/YYYY"}
								onChange={(date) => setFieldValue("createdSince", date)}
								title={t("clientsMyTemp.notifications.filterTitles.startDate")}
								value={values?.createdSince ? new Date(values.createdSince) : null}
							/>
							<CheckboxListFilter
								title={t("usersListing.filters.myRas")}
								items={{
									true: true,
									false: false,
								}}
								onChange={(type) => handleChangeMyRas(type, formikCtx)}
								type="radio"
								orientation="horizontal"
								getLabel={(type) => t(`global.${type}`)}
								name="hasMyRas"
								getValue={(type) => {
									return type === values?.hasMyRas;
								}}
								getId={(index) => `hasMyRAS-radio-${index}`}
								listStyle={{
									display: "flex",
									justifyContent: "flex-start",
								}}
								labelClassName="flex-1"
								handleReset={
									values.hasMyRas !== null
										? () => setFieldValue("hasMyRas", DEFAULT_USERS_LISTING_FILTERS.hasMyRas)
										: null
								}
							/>
							<SelectFilter
								title={t("usersListing.filters.commonDocuments.title")}
								placeholder={t("usersListing.filters.commonDocuments.placeholder")}
								handleReset={
									values?.commonDocuments?.length
										? () => {
												resetField("commonDocuments");
											}
										: null
								}
								query={{
									queryKey: ["commonDocuments"],
									queryFn: (args) =>
										getCommonDocuments({
											search: args.search?.length >= 3 ? args.search : undefined,
											limit: 100,
										}).then((resp) => resp?.data?.items),
								}}
								getOptionLabel={(option: TJobType) => option.name}
								getOptionValue={(option: TJobType) => option.id.toString()}
								isMulti
								value={values?.commonDocuments}
								onChange={(value) => setFieldValue("commonDocuments", value)}
								name="commonDocuments"
								minSearchLength={3}
								isSearchable={true}
							/>
							<CheckboxListFilter
								title={t("usersListing.filters.paymentInfos.title")}
								items={values.paymentInfos || {}}
								onChange={(type) =>
									setFieldValue("paymentInfos", {
										...values.paymentInfos,
										[type]: !values.paymentInfos[type] ? true : null,
									})
								}
								getValue={(type) => {
									return values?.paymentInfos[type] !== null && values?.paymentInfos[type];
								}}
								getId={(index) => `paymentInfos-checkbox-${index}`}
								getLabel={(type) => t(`usersListing.filters.paymentInfos.${type}`)}
								orientation="horizontal"
								labelClassName="flex-1"
								handleReset={
									values.paymentInfos?.hasIbanToValidate || values.paymentInfos?.isMissingIban
										? () =>
												setFieldValue(
													"paymentInfos",
													DEFAULT_USERS_LISTING_FILTERS.paymentInfos
												)
										: null
								}
							/>
						</div>
						<div>
							<SelectFilter
								title={t("usersListing.filters.jobTypes.title")}
								handleReset={
									values?.jobTypes?.length
										? () => {
												resetField("jobTypes");
											}
										: null
								}
								query={{
									queryKey: ["jobTypes"],
									queryFn: () => JobTypesService.getJobTypes(),
									options: {
										staleTime: Infinity,
									},
								}}
								getOptionLabel={(option: TJobType) => option.name}
								getOptionValue={(option: TJobType) => option.id.toString()}
								isMulti
								value={values?.jobTypes}
								onChange={(value) => setFieldValue("jobTypes", value)}
								selectAllOption
								selectAllLabel={t("usersListing.filters.jobTypes.all")}
								name="jobTypes"
							/>
							<SelectFilter
								title={t("activityLogs.commonFields.tab.CONDITIONS")}
								value={values?.workConditions}
								onChange={(value) => setFieldValue("workConditions", value)}
								handleReset={
									values?.workConditions?.length
										? () => {
												resetField("workConditions");
											}
										: null
								}
								isMulti
								query={{
									queryKey: ["workConditions"],
									queryFn: () => {
										return JobTypesService.getWorkConditions();
									},
									options: {
										staleTime: Infinity,
									},
								}}
								getOptionLabel={(option: TJobType) => t(`activityLogs.conditionsOfWork.${option.name}`)}
								getOptionValue={(option: TJobType) => option.id.toString()}
								name="workConditions"
							/>
							<SelectFilter
								title={t("activityLogs.commonFields.tab.EQUIPMENT")}
								value={values?.equipments}
								onChange={(value) => setFieldValue("equipments", value)}
								handleReset={
									values?.equipments?.length
										? () => {
												resetField("equipments");
											}
										: null
								}
								isMulti
								query={{
									queryKey: ["equipments"],
									queryFn: () => JobTypesService.getWorkEquipment(),
								}}
								getOptionLabel={(option: TJobType) => t(`activityLogs.equipments.${option.name}`)}
								getOptionValue={(option: TJobType) => option.id.toString()}
								name="equipments"
							/>
							<div>
								<SelectFilter
									title={t("usersListing.filters.qualifications.title")}
									query={{
										queryKey: ["usersFilters", "getQualifications"],
										queryFn: (arg) => {
											return getCommonQualifications({
												search: arg.search,
												limit: 100,
											}).then((res) => res.data?.items);
										},
										options: {
											staleTime: Infinity,
											refetchOnMount: false,
										},
									}}
									value={values.qualifications}
									onChange={(options) => setFieldValue("qualifications", options)}
									isMulti
									handleReset={
										values.qualifications?.length || values?.competencies?.length
											? () => {
													resetField("qualifications");
													resetField("competencies");
												}
											: null
									}
									getOptionLabel={(option: TQualification) => option.name}
									getOptionValue={(option: TQualification) => option.id.toString()}
									isSearchable={true}
									placeholder={t("usersListing.filters.qualifications.qualifications")}
									name="qualifications"
								/>
								<SelectFilter
									title={""}
									query={{
										queryKey: ["usersFilters", "getCompetences"],
										queryFn: (arg) => {
											return getCommonCompetencies(
												arg.search?.length >= 3 ? arg.search : ""
											).then((res) => res.data);
										},
										options: {
											staleTime: Infinity,
											refetchOnMount: false,
										},
									}}
									value={values.competencies}
									onChange={(options) => setFieldValue("competencies", options)}
									isMulti
									handleReset={null}
									getOptionLabel={(option: TQualification) => option.name}
									getOptionValue={(option: TQualification) => option.id.toString()}
									isSearchable={true}
									placeholder={t("usersListing.filters.qualifications.competencies")}
									name="competencies"
								/>
							</div>
						</div>
						<div>
							<CheckboxListFilter
								title={t("usersListing.filters.status.title")}
								items={{
									waiting: null,
									candidate: null,
									temporaryWorker: null,
									student: null,
									isVip: null,
									isCdiInterim: null,
								}}
								onChange={(type) => {
									setFieldValue("status", {
										...values.status,
										[type]: !values.status[type] ? true : null,
									});
								}}
								getLabel={(type) => t(`usersListing.filters.status.${type}`)}
								getValue={(type) => {
									return !!values?.status[type];
								}}
								orientation="horizontal"
								labelClassName="min-w-[48%]"
								handleReset={
									!isEqual(values.status, DEFAULT_USERS_LISTING_FILTERS.status) &&
									values.status !== null
										? () => setFieldValue("status", DEFAULT_USERS_LISTING_FILTERS.status)
										: null
								}
								name="status"
							/>
						</div>
					</>
				);
			}}
		</FilterModal>
	);
};

export default UsersListingFiltersModal;
