const ROLES = {
	ADMIN: "admin",
	AMA_ADMIN: "ama.admin",
	MANAGER: "manager",
	USER: "user",
	AMA_VISIBLE: "ama.visible",
	MYTEMP_AVAILABLE: "mytemp.available",
	BETA_USER: "beta.user",
	RGPD: "rgpd",
	TWENTYFOURBY7: "24by7",
	DIGITAL_DIVISION: "digital.division",
};

export default ROLES;
