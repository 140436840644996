// @ts-nocheck
import React, { useState } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { TextAreaInput } from "../../../../components/atoms/index";
import JobOffer from "../../../../components/molecules/JobOffer";
import cn from "../../../../utils/cn";
import { Button, Title, Text, InfoMessage } from "@zolteam/react-ras-library";

// Style
import style from "./Modals.module.css";

const ModalContentAddProfile = ({ onAddClick, onCancelClick, currentOrder, isColumnAction }) => {
	// Hooks
	const { t } = useTranslation();
	const [additionalInformations, setAdditionalInformations] = useState("");

	return (
		<div className={style.content}>
			<Title tag="h3" size="heading01" lineHeight="l" className={cn([style.title])}>
				{t("commands.profiles.modalsContent.missionOrder.title")}
			</Title>
			<div className={style.header}>
				{!isColumnAction && (
					<div className={style.infoMessage}>
						<InfoMessage color="primary" withIcon>
							{t("commands.profiles.modalsContent.missionOrder.informationMessageBulk")}
						</InfoMessage>
					</div>
				)}
				<div className={style.infoMessage}>
					<InfoMessage color="primary" withIcon>
						{t("commands.profiles.modalsContent.missionOrder.informationMessage")}
					</InfoMessage>
				</div>

				<div className={style.subTitle}>
					<Text tag="p" size="paragraph01" fontWeight="bold" lineHeight="m">
						{t("commands.profiles.modalsContent.missionOrder.missionOrderOverview")}
					</Text>
				</div>
			</div>

			<JobOffer currentOrder={currentOrder} context="missionOrderFull" />

			<div className={style.textBoxAlt}>
				<TextAreaInput
					label={t("commands.profiles.modalsContent.missionOrder.textOffer")}
					name="workingTime.particularWorkTime"
					onChange={(value) => setAdditionalInformations(value)}
				/>
			</div>

			<div className={style.footerMessage}>
				<Text tag="p" size="paragraph01" fontWeight="light" lineHeight="l">
					{t("commands.profiles.modalsContent.missionOrder.furtherInformation")}
				</Text>
			</div>

			<hr className={style.separator} />
			<div className={style.buttonsBlock}>
				<Button
					className={style.validationButtonRight}
					type="button"
					color="primary"
					onClick={onCancelClick}
					outline
				>
					{t("commands.profiles.modalsContent.missionOrder.cancelAction")}
				</Button>
				<Button
					className={style.validationButtonRight}
					type="button"
					color="primary"
					onClick={() => onAddClick(additionalInformations)}
				>
					{t("commands.profiles.modalsContent.missionOrder.addAction")}
				</Button>
			</div>
		</div>
	);
};

ModalContentAddProfile.propTypes = {
	onAddClick: PropTypes.func.isRequired,
	onCancelClick: PropTypes.func.isRequired,
	currentOrder: PropTypes.shape().isRequired,
	isColumnAction: PropTypes.bool.isRequired,
};

export default ModalContentAddProfile;
