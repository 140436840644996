import React from "react";

import { Picto } from "@zolteam/react-ras-library";

import style from "./CheckPicto.module.css";

interface ICheckPictoProps {
	customClass?: string;
}

export const CheckPicto: React.FC<ICheckPictoProps> = ({ customClass = "" }) => (
	<div className={`${style.pictoContainer} ${customClass}`}>
		<Picto icon="validate" className={style.picto} />
	</div>
);
