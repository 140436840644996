import { QueryClient } from "react-query";

import TProfileActivity from "../types/TProfileActivity";
import TProfileActivityRender from "../types/TProfileActivityRender";

type AxiosPageResponse<T> = {
	data: {
		items: T[];
	};
};

export type PostProfileDetailsResponse = {
	pages: AxiosPageResponse<TProfileActivity>[];
};

const setCommunicationContent = (
	orderProfileId: number,
	orderMessagingId: string,
	type: string,
	content: string,
	queryClient: QueryClient
) => {
	queryClient.setQueryData(
		["postProfileDetails", orderProfileId],
		(queryData: PostProfileDetailsResponse | undefined) => {
			if (!queryData || !Array.isArray(queryData.pages)) return queryData;

			const updatedPages = queryData.pages.map((page) => {
				if (!Array.isArray(page.data.items)) return page;

				const updatedItems = page.data.items.map((activity: TProfileActivityRender) => {
					if (activity.orderMessagingId !== orderMessagingId) return activity;

					return type === "SET_OPEN" ? { ...activity, isOpen: !activity.isOpen } : { ...activity, content };
				});

				return {
					...page,
					data: {
						...page.data,
						items: updatedItems,
					},
				};
			});

			return {
				...queryData,
				pages: updatedPages,
			};
		}
	);
};

export default setCommunicationContent;
