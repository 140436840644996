import { FC } from "react";

import cn from "../../../utils/cn";

import style from "./ProgressBar.module.css";

export interface IProgressBarProps {
	completed?: number;
	color?: "primary" | "success" | "warning" | "error";
	colorAuto?: boolean;
}

export const ProgressBar: FC<IProgressBarProps> = ({ completed = 0, color = "success", colorAuto = false }) => {
	const progressColorAuto = (percentage: number) => {
		if (percentage <= 10) return "error";
		if (percentage <= 60) return "warning";
		return "success";
	};

	return (
		<div
			className={cn([style.containerStyles, colorAuto ? style[progressColorAuto(completed)] : style[color]])}
			data-testid={`progressBar-${colorAuto ? progressColorAuto(completed) : color}`}
		>
			<div className={style.fillerStyles} style={{ width: `${completed}%` }} />
		</div>
	);
};
