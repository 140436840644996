import { TQualification } from "src/types/TQualifications";

import client from "src/services/client";

export interface CommonQualificationsApiResponse {
	total: number;
	items: TQualification[];
}

interface QueryData {
	search: string;
	limit: number;
}

export const getCommonQualifications = (params: QueryData): Promise<AxiosResponse<CommonQualificationsApiResponse>> =>
	client.post("/api-client-v2/common-qualification/search", params);

export const getCommonCompetencies = (search: string): Promise<AxiosResponse<CommonQualificationsApiResponse>> =>
	client.get(`/api-client-v2/competencies${!!search ? `?search=${search}` : ""}`);
