import { isEmpty } from "lodash";

import { uCleanSpaces } from "./UStrings";

import { PATTERN_NSS_TEMPORARY, SSN_REGEX } from "src/constants/CRegex";

export const isTemporaryNss = (ssn: string): boolean => {
	return PATTERN_NSS_TEMPORARY.test(ssn);
};

export const isGenderValid = (ssn: string, gender: string): boolean => {
	if (!gender || !ssn) {
		return true;
	}
	let genderSsn = ssn[0];
	if ((genderSsn === "2" && gender === "female") || (genderSsn === "1" && gender === "male")) {
		return true;
	}
	return false;
};

export const isDepartmentValid = (ssn: string, department: string): boolean => {
	if (isEmpty(department) || isEmpty(ssn)) {
		return true;
	}
	let ssnDepartment = ssn.slice(5, 5 + department.length);
	return ssnDepartment === department;
};

// Fonction principale de validation du NSS
export const isValidSSN = (value: string) => {
	const trimValue = uCleanSpaces(value);
	if (trimValue) {
		if (trimValue.length === 7) return true;
		const normalKey = 97 - (parseInt(trimValue.substring(0, 13), 10) % 97);

		return (
			SSN_REGEX?.test(trimValue) &&
			normalKey === parseInt(trimValue.substring(trimValue?.length - 2, trimValue?.length), 10)
		);
	}
	return true;
};

export const ssnMonthIsValid = (ssnMonth: string, month: string): boolean => {
	if (ssnMonth === month) {
		return true;
	}
	const ssnMonthInt = parseInt(ssnMonth);
	return (ssnMonthInt >= 20 && ssnMonthInt <= 42) || ssnMonthInt === 50;
};

//Fonction de validation année de naissance vs SSN
export const isBirthdayValidWithSSN = (ssn: string, birthday: string): boolean => {
	if (!birthday || !ssn) {
		return true;
	}

	let ssnYear = ssn.slice(1, 3);
	let ssnMonth = ssn.slice(3, 5);

	const [, month, year] = birthday.split("/"); // dd/mm/yyyy

	return ssnYear === year.slice(-2) && ssnMonthIsValid(ssnMonth, month);
};

export const formatPhoneNumber = (value: string) => {
	// Supprimer tout sauf les chiffres
	const digits = value.replace(/\D/g, "");

	// Ajouter un espace après chaque paire de chiffres
	const formatted = digits.match(/.{1,2}/g)?.join(" ") || "";

	return formatted;
};
