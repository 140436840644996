// @ts-nocheck
const createAdvancePaymentsFormDefaultValues = {
	agencyId: null,
	userId: null,
	requestedAmount: null,
	isMaximumAdvanceRequested: false,
	comment: "",
	desiredPaymentDate: null,
};

export default createAdvancePaymentsFormDefaultValues;
