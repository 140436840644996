// @ts-nocheck
/* eslint-disable import/prefer-default-export */
// Mimics the output after applying css rule: text-transform: capitalize
export const capitalizeWords = (str) => {
	if (!str) return null;

	const wordsWithSeparators = str.split(/(\s+|-|_|\.|,|:|;|\/|\\|\(|\)|\[|\]|{|}|'|"|!|\?)(?=\S)/);

	return wordsWithSeparators
		.map((word) => {
			if (!/(\s+|-|_|\.|,|:|;|\/|\\|\(|\)|\[|\]|{|}|'|"|!|\?)/.test(word)) {
				return word.charAt(0).toUpperCase() + word.slice(1);
			}
			return word;
		})
		.join("");
};
