// @ts-nocheck
import React, { useContext, useReducer } from "react";

import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Picto } from "@zolteam/react-ras-library";
// Molecules
import { Toast } from "src/components/molecules";

// Reducers
const toastReducer = (state, { type, payload: { icon, msg, params } }) => {
	switch (type) {
		case "SUCCESS": {
			toast.success(<Toast icon={icon} msg={msg} />, { ...params, icon: icon && false });
			break;
		}
		case "ERROR": {
			toast.error(<Toast icon={icon} msg={msg} />, { ...params, icon: icon && false });
			break;
		}
		case "INFO": {
			toast.info(<Toast icon={icon} msg={msg} />, { ...params, icon: icon && false });
			break;
		}
		case "WARN": {
			toast.warn(<Toast icon={icon} msg={msg} />, { ...params, icon: icon && false });
			break;
		}
		default: {
			throw new Error(`Unhandled action type: ${type}`);
		}
	}
};

const ToastStateContext = React.createContext(undefined);
const ToastDispatchContext = React.createContext(undefined);

const ToastProvider = ({ children }) => {
	const [state, dispatch] = useReducer(toastReducer, null);

	return (
		<ToastStateContext.Provider value={state}>
			<ToastDispatchContext.Provider value={dispatch}>
				{children}
				<ToastContainer
					hideProgressBar
					pauseOnHover={false}
					closeOnClick
					limit={3}
					icon={({ type }) => {
						if (type === "success") return <Picto icon={"checkCircle"} />;
						if (type === "error") return <Picto icon={"alertCircle"} />;
						if (type === "info") return <Picto icon={"info"} />;
						if (type === "warning") return <Picto icon={"warning"} />;
						else return null;
					}}
				/>
			</ToastDispatchContext.Provider>
		</ToastStateContext.Provider>
	);
};

ToastProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

const useToastContext = () => [useContext(ToastStateContext), useContext(ToastDispatchContext)];

export { ToastDispatchContext, ToastProvider, ToastStateContext, useToastContext };
