// @ts-nocheck
// Filters Modal sections
export const matchingBusinessInformationDefaultValues = {
	qualifications: [],
	commonDocuments: [],
	statuses: [],
	vehicles: [],
	outOfBusinessReasonsIds: [],
	equipments: [],
	distance: "30",
	isMedicalCheckupValid: false,
	isAdequateForClient: false,
	hasAlreadyWorkedForClient: false,
	isOutOfBusiness: false,
	isTempWorkerAvailabilitiesFilterEnabled: true,
	isTempWorkerTimeOffFilterEnabled: true,
	isTempWorkerOrderFilterEnabled: true,
	isTempWorkerContractFilterEnabled: true,
	period: {
		startDate: null,
		endDate: null,
	},
};

const DefaultValues = {
	...matchingBusinessInformationDefaultValues,
};

export default DefaultValues;
