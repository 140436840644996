// @ts-nocheck

/* eslint-disable react/jsx-no-constructed-context-values */

/* eslint-disable no-unused-expressions */

/* eslint-disable react/prop-types */
import React, { useContext, useRef, useState } from "react";

import { IsLockedModal } from "../views/SuspiciousSheet/Modals/SuspiciousSheetModal";

const ConfirmationModalContext = React.createContext({});

const ConfirmationModalContextProvider = ({ children }) => {
	const [showConfirmationModal, setShowConfirmationModal] = useState({
		data: {},
		isLockedModal: false,
	});
	const resolver = useRef();

	const handleShow = (name, data) => {
		setShowConfirmationModal((prevState) => ({
			...prevState,
			[name]: true,
			data,
		}));

		return new Promise((resolve) => {
			resolver.current = resolve;
		});
	};

	const handleConfirm = (name) => {
		resolver.current && resolver.current(true);
		setShowConfirmationModal((prevState) => ({
			...prevState,
			[name]: false,
			data: {},
		}));
	};

	const handleCancel = (name) => {
		resolver.current && resolver.current(false);
		setShowConfirmationModal((prevState) => ({
			...prevState,
			[name]: false,
			data: {},
		}));
	};

	return (
		<ConfirmationModalContext.Provider value={{ showConfirmation: handleShow }}>
			{children}

			<IsLockedModal
				isDisplayed={showConfirmationModal.isLockedModal}
				handleCancel={() => handleCancel("isLockedModal")}
				handleConfirm={() => handleConfirm("isLockedModal")}
				data={showConfirmationModal.data}
			/>
		</ConfirmationModalContext.Provider>
	);
};

export const useConfirmationModalContext = () => useContext(ConfirmationModalContext);

export default ConfirmationModalContextProvider;
