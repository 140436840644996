// Store
import { useAppContext } from "../../../store/AppContext";

import { InfoMessage } from "@zolteam/react-ras-library";

// Style
import style from "./OrderMessage.module.css";

export const OrderMessage = () => {
	const [{ orderMessage }] = useAppContext();

	if (!orderMessage.displayed) {
		return null;
	}

	return (
		<div className={style.message}>
			<InfoMessage color={orderMessage.color} withIcon={orderMessage.withIcon}>
				{orderMessage.content}
			</InfoMessage>
		</div>
	);
};
