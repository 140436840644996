export const formatSSN = (value: string) => {
	if (!value) return value;
	// Enlever tous les espaces existants
	const cleanValue = value.replace(/\s+/g, "");

	// Séparer le premier chiffre, puis ajouter des espaces tous les deux chiffres
	let formattedValue;

	if (cleanValue.length <= 7) {
		// Formatage pour 7 chiffres : "1 92 05 69"
		formattedValue = cleanValue.replace(/^(\d{1})(\d{2})(\d{2})(\d{2})$/, "$1 $2 $3 $4");
	} else {
		formattedValue = cleanValue.replace(
			/^(\d{1})(\d{2})(\d{2})(\d{2})(\d{3})(\d{3})(\d{2})$/,
			"$1 $2 $3 $4 $5 $6 $7"
		);
	}

	return formattedValue.trim();
};
