// @ts-nocheck

/* eslint-disable max-len */
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { useTitle } from "react-use";

import { format, getUnixTime } from "date-fns";
import localeFr from "date-fns/locale/fr";

// Services
import { searchMissionFollowUp } from "../../../../services/missionFollowUpService";

import useInterval from "../../../../hooks/useInterval";

// Constants
import { PAGINATION_OFFSET_OPTIONS } from "../../../../constants";
// Utils
import cn from "../../../../utils/cn";
import { dateDifference } from "../../../../utils/formatDate";
import { definePageOffset } from "../../../../utils/paginationUtils";
// import PropTypes from 'prop-types';
import { Button, PaginationV2, Picto, SelectPopover, Text, Title, Tooltip } from "@zolteam/react-ras-library";
// Hooks
import { ColumnListing, PaginationSearch } from "src/components/molecules";
import { TableListing } from "src/components/organisms";

// Templates
import style from "./ProfileMissionsFollowUp.module.css";

const DEFAULT_SEARCH_PARAMS = {
	page: 1,
	limit: 40,
};

const ProfileMissionsFollowUp = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const { profileId } = useParams();
	useTitle(t("profile.followUp.title"));

	const [data, setData] = useState();
	const [search, setSearch] = useState(DEFAULT_SEARCH_PARAMS);
	const [isFirstLoading, setIsFirstLoading] = useState(true);

	const setUpdatedData = (res) => {
		const updatedData = res?.items?.reduce((acc, item) => {
			let updateDiff = null;
			if (item.updatedAt === item.createdAt) {
				updateDiff = null;
			} else {
				updateDiff = dateDifference(
					getUnixTime(new Date(item.updatedAt) * 1000),
					getUnixTime(Date.now()) * 1000
				);
			}
			acc.push({ ...item, updateDiff });
			return acc;
		}, []);
		setData({ ...res, items: updatedData });
	};

	const searchMissionFollowUpMutation = useMutation((params) => searchMissionFollowUp(profileId, params), {
		onSuccess: (result) => {
			setUpdatedData(result?.data);
			setIsFirstLoading(false);
		},
	});

	const { isError: isSearchFollowUpError, isLoading: isSearchFollowUpLoading } = searchMissionFollowUpMutation;

	useInterval(() => {
		setUpdatedData(data);
	}, 60000);

	const handleSearch = (sort) => {
		setSearch((prev) => {
			const searchRequest = { ...prev, ...sort };
			return searchRequest;
		});
		searchMissionFollowUpMutation.mutate({ ...search, ...sort });
	};

	const handleEditFollowUp = (followUpId) => {
		history.push(`/profile/${profileId}/missions/follow-up/${followUpId}`);
	};

	const isListLoading = () => (isFirstLoading && !isSearchFollowUpError) || isSearchFollowUpLoading;

	useEffect(() => {
		searchMissionFollowUpMutation.mutate(search);
	}, []);

	const getTableRowHref = ({ id }) => `/profile/${id}/missions/follow-up/${id}`;

	return (
		<div className={style.missionFollowUp}>
			<div className={style.header}>
				<Title tag="h1" fontWeight="bold" size="heading01" lineHeight="m">
					{t("profile.followUp.title")}
				</Title>
				<div className={style.tableOptions}>
					<Button
						type="button"
						onClick={() => history.push(`/profile/${profileId}/missions/follow-up/create`)}
						color="primary"
					>
						Ajouter un suivi de mission
					</Button>
					<div className={style.filter}>
						<Picto icon="sliders" className={style.filterPicto} />
						Filtrer
					</div>
				</div>
			</div>
			<div className={style.followUpList}>
				<TableListing
					loading={isListLoading()}
					fullHeight
					canBeChecked={false}
					error={isSearchFollowUpError}
					data={data?.items}
					handleSearch={() => {}}
					onDoubleClick={({ id }) => handleEditFollowUp(id)}
					noBorder
					getTableRowHref={getTableRowHref}
					styleInactiveRows={(elem) => !elem.canEdit}
					initialColumnSort={{
						field: "createdAt",
						orderBy: "DESC",
					}}
					footer={
						data?.total ? (
							<>
								<PaginationV2
									previousLabel={<Picto icon="chevronLeft" />}
									nextLabel={<Picto icon="chevronRight" />}
									breakLine={
										<PaginationSearch
											onInputChange={handleSearch}
											max={Math.round(data.total / search.limit)}
										/>
									}
									currentPage={search.page}
									totalCount={data.total}
									pageSize={search.limit}
									siblingCount={2}
									onPageChange={(activePage) => handleSearch({ page: activePage })}
								/>
								<Text tag="span" size="paragraph02" color="grey">
									{t("global.pagination.resultsPagination", {
										resultStart: search.limit * search.page - search.limit + 1,
										resultEnd:
											search.limit * search.page <= data?.total
												? search.limit * search.page
												: data?.total,
										resultTotal: data?.total,
									})}
								</Text>
								<SelectPopover
									name="limit"
									options={PAGINATION_OFFSET_OPTIONS}
									onChange={(value) =>
										handleSearch({
											limit: value,
											page: definePageOffset(value, search.limit, search.page),
										})
									}
									value={
										search.limit
											? PAGINATION_OFFSET_OPTIONS.find((option) => option.value === search.limit)
											: null
									}
								/>
							</>
						) : null
					}
				>
					{/* Agence */}
					<ColumnListing
						id="agency"
						name={
							<Text tag="span" size="paragraph02" color="grey">
								{t("profile.followUp.listing.agencyName")}
							</Text>
						}
						field={(elem) => ({ agencyName: elem.agencyName })}
						component={({ data: { agencyName } }) => (
							<div className={style.column}>
								{agencyName?.length > 20 ? (
									<Tooltip animation={false} arrow={false} content={agencyName || ""}>
										<div>{`${agencyName?.substring(0, 20)}...`}</div>
									</Tooltip>
								) : (
									agencyName
								)}
							</div>
						)}
						tdClassName="min-column-width"
					/>

					{/* Client */}
					<ColumnListing
						id="clientName"
						name={
							<Text tag="span" size="paragraph02" color="grey">
								{t("profile.followUp.listing.clientName")}
							</Text>
						}
						field={(elem) => ({ clientName: elem.clientName })}
						component={({ data: { clientName } }) => (
							<div className={cn([style.column, style.columnClient])}>
								{clientName?.length > 20 ? (
									<Tooltip animation={false} arrow={false} content={clientName || ""}>
										<div>{`${clientName?.substring(0, 20)}...`}</div>
									</Tooltip>
								) : (
									clientName
								)}
							</div>
						)}
						tdClassName="min-column-width"
					/>

					{/* Code client */}
					<ColumnListing
						id="clientTempoId"
						name={
							<Text tag="span" size="paragraph02" color="grey">
								{t("profile.followUp.listing.clientTempoId")}
							</Text>
						}
						field={(elem) => ({ clientTempoId: elem.clientTempoId })}
						component={({ data: { clientTempoId } }) => (
							<div className={cn([style.column, style.columnClientId])}>{clientTempoId}</div>
						)}
						tdClassName="min-column-width"
					/>

					{/* Début */}
					<ColumnListing
						id="startDate"
						name={
							<Text tag="span" size="paragraph02" color="grey">
								{t("profile.followUp.listing.startDate")}
							</Text>
						}
						field={(elem) => ({ startDate: elem.startDate })}
						component={({ data: { startDate } }) => (
							<div className={style.column}>
								{format(new Date(startDate), "dd MMM yyyy", {
									locale: localeFr,
								})}
							</div>
						)}
						tdClassName="min-column-width"
					/>

					{/* Fin */}
					<ColumnListing
						id="endDate"
						name={
							<Text tag="span" size="paragraph02" color="grey">
								{t("profile.followUp.listing.endDate")}
							</Text>
						}
						field={(elem) => ({ endDate: elem.endDate })}
						component={({ data: { endDate } }) => (
							<div className={style.column}>
								{format(new Date(endDate), "dd MMM yyyy", {
									locale: localeFr,
								})}
							</div>
						)}
						tdClassName="min-column-width"
					/>

					{/* Qualification */}
					<ColumnListing
						id="qualificationName"
						name={
							<Text tag="span" size="paragraph02" color="grey">
								{t("profile.followUp.listing.qualificationName")}
							</Text>
						}
						field={(elem) => ({ qualificationName: elem.qualificationName })}
						component={({ data: { qualificationName } }) => (
							<div className={style.column}>
								{qualificationName?.length > 20 ? (
									<Tooltip animation={false} arrow={false} content={qualificationName || ""}>
										<div>{`${qualificationName?.substring(0, 20)}...`}</div>
									</Tooltip>
								) : (
									qualificationName
								)}
							</div>
						)}
						tdClassName="min-column-width"
					/>

					{/* Note */}
					<ColumnListing
						id="note"
						name={
							<Text tag="span" size="paragraph02" color="grey">
								{t("profile.followUp.listing.note")}
							</Text>
						}
						field={(elem) => ({ note: elem.note })}
						component={({ data: { note } }) => (
							<div className={cn([style.column, style.columnSmall])}>{note}</div>
						)}
						tdClassName="min-column-width"
					/>

					{/* Commentaire */}
					<ColumnListing
						id="reporting"
						name={
							<Text tag="span" size="paragraph02" color="grey">
								{t("profile.followUp.listing.reporting")}
							</Text>
						}
						field={(elem) => ({ reporting: elem.reporting })}
						component={({ data: { reporting } }) => (
							<div className={style.column}>
								{reporting?.length > 20 ? (
									<Tooltip animation={false} arrow={false} content={reporting || ""}>
										<div>{`${reporting?.substring(0, 20)}...`}</div>
									</Tooltip>
								) : (
									reporting
								)}
							</div>
						)}
						tdClassName="min-column-width"
					/>

					{/* Type d'incident */}
					<ColumnListing
						id="incidentType"
						name={
							<Text tag="span" size="paragraph02" color="grey">
								{t("profile.followUp.listing.incidentType")}
							</Text>
						}
						field={(elem) => ({ incidentType: elem.incidentType })}
						component={({ data: { incidentType } }) => (
							<div className={cn([style.column, style.columnIncidentType])}>
								{incidentType ? (
									<Tooltip
										animation={false}
										arrow={false}
										content={t(`profile.followUp.form.incidentTypeId.types.${incidentType}`) || ""}
									>
										<div>
											{`${t(`profile.followUp.form.incidentTypeId.types.${incidentType}`)?.substring(0, 20)}...`}
										</div>
									</Tooltip>
								) : (
									""
								)}
							</div>
						)}
						tdClassName="min-column-width"
					/>

					{/* Pièce jointe */}
					<ColumnListing
						id="attachment"
						name={
							<Text tag="span" size="paragraph02" color="grey">
								{t("profile.followUp.listing.attachment")}
							</Text>
						}
						field={(elem) => ({
							attachmentName: elem.attachmentName,
							attachmentURL: elem.attachmentURL,
						})}
						// eslint-disable-next-line no-empty-pattern
						component={({ data: { attachmentName, attachmentURL } }) => (
							<div className={cn([style.column, style.columnAttachment])}>
								<a href={attachmentURL} download target="_blank" rel="noreferrer">
									{attachmentName}
								</a>
							</div>
						)}
						tdClassName="min-column-width"
					/>

					{/* Créée le */}
					<ColumnListing
						id="createdAt"
						name={
							<Text tag="span" size="paragraph02" color="grey">
								{t("profile.followUp.listing.createdAt")}
							</Text>
						}
						field={(elem) => ({ createdAt: elem.createdAt })}
						component={({ data: { createdAt } }) => (
							<div className={style.column}>
								{format(new Date(createdAt), "dd MMM yyyy", {
									locale: localeFr,
								})}
							</div>
						)}
						tdClassName="min-column-width"
					/>

					{/* Par */}
					<ColumnListing
						id="author"
						name={
							<Text tag="span" size="paragraph02" color="grey">
								{t("profile.followUp.listing.author")}
							</Text>
						}
						field={(elem) => ({ author: elem.author })}
						component={({ data: { author } }) => <div className={style.column}>{author}</div>}
						tdClassName="min-column-width"
					/>

					{/* Options */}
					{/* <ColumnListing
            id="hasAccess"
            name={(
              <Text tag="span" size="paragraph02" color="grey" />
            )}
            field={(elem) => ({ hasAccess: elem.hasAccess })}
            component={({ data: { hasAccess } }) => (
              <>
                { hasAccess ? (
                  <div className={cn([style.column, style.columnOptions])}>
                    <Picto icon="edit" className={style.optionPicto} />
                    <Picto icon="trash" className={style.optionPicto} />
                  </div>
                )
              : null}
              </>
            )}
            tdClassName="min-column-width"
          /> */}
				</TableListing>
			</div>
		</div>
	);
};

ProfileMissionsFollowUp.propTypes = {};

export default ProfileMissionsFollowUp;
