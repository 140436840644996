import { FC } from "react";

import style from "./Point.module.css";

interface IPointProps {
	classname?: string;
	bgColor?: string;
}

export const Point: FC<IPointProps> = ({ classname = "", bgColor = "var(--color-accent-500)" }) => (
	<span className={`${style.point} ${classname}`} style={{ backgroundColor: bgColor }} />
);
