import { FC } from "react";

import { maskitoDateOptionsGenerator } from "@maskito/kit";
import { useMaskito } from "@maskito/react";
import { useFormikContext } from "formik";
import moment from "moment";

import cn from "../../../utils/cn";
import { Picto } from "@zolteam/react-ras-library";
import { Field } from "src/components/molecules";

import style from "./DateRangeInput.module.css";

interface IDateRangeInputProps {
	label: string;
	value?: string;
	onClick: () => void;
	onClear: (e: React.MouseEvent) => void;
	className?: string;
	isActive?: boolean;
	error?: string;
	info?: string;
	disabled?: boolean;
	writable?: boolean;
	required?: boolean;
	type?: string;
	name?: string;
	showErrorMessage?: boolean;
	onDateChange?: (date: string, name: string) => void;
	onBlur?: (name: string, isValid: boolean) => void;
}

export const DateRangeInput: FC<IDateRangeInputProps> = ({
	label,
	value = null,
	onClick,
	onClear,
	className = null,
	isActive = false,
	error = null,
	info = null,
	disabled = false,
	writable = false,
	required = false,
	type = "text",
	name = "",
	showErrorMessage = true,
	onDateChange = () => {},
	onBlur = () => {},
}) => {
	const hasValue = !!value;

	//Masque pour l'input date
	const options = maskitoDateOptionsGenerator({
		mode: "dd/mm/yyyy",
		separator: "/",
	});
	const inputRef = useMaskito({ options });
	const formikContext = useFormikContext();

	if (writable) {
		return (
			<div className="flex flex-row items-center gap-3">
				<Field
					innerRef={inputRef}
					label={label}
					type={type}
					name={name}
					value={value}
					classNameContainer="flex-1 flex items-center relative"
					className="w-full"
					required={required}
					showErrorMessage={showErrorMessage}
					onInput={(v: React.ChangeEvent<HTMLInputElement>) => {
						!disabled && formikContext.setFieldValue(name, v.target.value);
						onBlur(name, true);
					}}
					onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
						const momentDate = moment(e.target.value, "DD/MM/YYYY", true);
						const isValidDate = momentDate.isValid();
						isValidDate && onBlur(name, true);
					}}
					onPictoClick={onClick}
				>
					{
						<Picto
							className="absolute right-5 text-neutral-500 cursor-pointer"
							icon={hasValue && !disabled ? "close" : "calendar"}
							onClick={hasValue && !disabled ? onClear : onClick}
						/>
					}
				</Field>
			</div>
		);
	}

	return (
		<div className={cn([className, disabled ? style.disabled : null])}>
			<button
				className={cn([
					style.button,
					isActive ? style.active : null,
					error ? style.error : null,
					disabled ? style.disabledBtn : null,
				])}
				type="button"
				onClick={onClick}
			>
				<span className={cn([style.label, hasValue ? style.withValue : null])}>{label}</span>
				{hasValue ? <p className={style.value}>{value}</p> : null}
				{!disabled && (
					<div className={style.pictobtn} onClick={hasValue ? onClear : () => undefined}>
						<Picto className={style.picto} icon={hasValue && !disabled ? "close" : "calendar"} />
					</div>
				)}
			</button>
			{info && !error ? <p className={style.info}>{info}</p> : null}
		</div>
	);
};
