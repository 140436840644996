import { useMemo } from "react";

import { CommunicationMedium } from "./OrderProfileChannelNotification";

import PROFILE_COMMUNICATION_DELIVERY_STATUS from "src/constants/ProfileCommunicationStatus";

export const PICTOS = {
	INFO: "info",
	CHECK_CIRCLE: "checkCircle",
	CLOCK: "clock",
} as const;

export const COLORS = {
	ERROR: "text-error-500",
	SUCCESS: "text-success-500",
	NEUTRAL: "text-neutral-500",
} as const;

export const TRANSLATION_KEYS = {
	NON_DELIVERED: "nonDelivered",
	DELIVERED: "delivered",
	IN_PROCESS: "inProcess",
} as const;

type TranslationKey = (typeof TRANSLATION_KEYS)[keyof typeof TRANSLATION_KEYS];

interface CommunicationStatus {
	translationKey: TranslationKey;
	picto: string;
	color: string;
	medium: string;
}

// Nouvelle version du hook
const useOrderProfileChannelNotification = (
	communicationMediumForCommunicationSent: CommunicationMedium[]
): { primary: CommunicationStatus; secondary?: CommunicationMedium } => {
	return useMemo(() => {
		const primaryMedium = communicationMediumForCommunicationSent?.find(
			(item) => item.medium === "sms" || item.medium === "email" || null
		);

		const secondaryMedium = communicationMediumForCommunicationSent?.find(
			(item) => item.medium !== primaryMedium?.medium || null
		);

		const getCommunicationStatus = (medium: typeof primaryMedium): CommunicationStatus => {
			switch (medium?.status) {
				case PROFILE_COMMUNICATION_DELIVERY_STATUS.FAILURE:
				case PROFILE_COMMUNICATION_DELIVERY_STATUS.UNDELIVERED:
					return {
						translationKey: TRANSLATION_KEYS.NON_DELIVERED,
						picto: "info",
						color: "text-error-500",
						medium: medium?.medium,
					};
				case PROFILE_COMMUNICATION_DELIVERY_STATUS.SUCCESS:
					return {
						translationKey: TRANSLATION_KEYS.DELIVERED,
						picto: "checkCircle",
						color: "text-success-500",
						medium: medium?.medium,
					};
				case PROFILE_COMMUNICATION_DELIVERY_STATUS.IN_PROGRESS:
					return {
						translationKey: TRANSLATION_KEYS.IN_PROCESS,
						picto: "clock",
						color: "text-neutral-500",
						medium: medium?.medium,
					};
				default:
					return {
						translationKey: TRANSLATION_KEYS.IN_PROCESS,
						picto: "clock",
						color: "text-neutral-500",
						medium: null,
					};
			}
		};

		return {
			primary: getCommunicationStatus(primaryMedium),
			secondary: secondaryMedium ? secondaryMedium : null,
		};
	}, [communicationMediumForCommunicationSent]);
};

export default useOrderProfileChannelNotification;
