import i18n from "src/i18n";
import * as Yup from "yup";

import isValidValidationTime from "src/views/Command/Configuration/forms/Rules/Validators/isValidValidationTime";

const FirstDayStartHourRule = () => {
	return Yup.string()
		.nullable()
		.test("isValidValidationTime", i18n.t("commands.error.wrongTimeFormat"), isValidValidationTime);
};

export default FirstDayStartHourRule;
