import { FC, PropsWithChildren } from "react";

import cn from "../../../utils/cn";

import style from "./Tag.module.css";

interface ITagProps extends PropsWithChildren {
	size?: "xs" | "s" | "m" | "l";
	color?: "primary" | "grey" | "success" | "error";
	radius?: "s" | "m" | "l";
	className?: string;
}

export const Tag: FC<ITagProps> = ({ size = "m", color = "primary", radius = "m", className = "", children }) => (
	<span
		className={cn([
			style.tag,
			style[size],
			style[color],
			style[`radius-${radius}`],
			style[children as string],
			className,
		])}
	>
		{children}
	</span>
);
