// @ts-nocheck

const clientRelationshipFormDefaultValues = {
	agencyId: null,
	clientId: null,
	contact: null,
	isAdequate: null,
};

export default clientRelationshipFormDefaultValues;
