import { FC } from "react";

import { fr } from "date-fns/locale";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import cn from "src/utils/cn";

import style from "./DatePicker.module.css";

registerLocale("fr", fr);

interface IDatePickerProps extends ReactDatePicker {
	className?: string;
}

export const DatePicker: FC<IDatePickerProps> = ({ className, ...props }) => (
	<ReactDatePicker
		/* eslint-disable-next-line react/jsx-props-no-spreading */
		{...props}
		locale="fr"
		dateFormat="dd/MM/yyyy"
		className={cn([style.DatePicker, className])}
	/>
);
