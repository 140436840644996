// @ts-nocheck
import * as Yup from "yup";

import { ORDER_STATUS } from "../../constants";

export default Yup.object().shape({
	statuses: Yup.array()
		.of(Yup.string().oneOf([ORDER_STATUS.IN_PROGRESS, ORDER_STATUS.IS_FILLED, ORDER_STATUS.NOT_PROVIDED]))
		.required(),
});
