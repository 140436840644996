// @ts-nocheck
import React, { createContext, useContext, useReducer } from "react";

import PropTypes from "prop-types";

const initConfigurationStore = {
	// User selected a client, regardless of order creation or edition
	isClientEdited: false,
};

const configurationReducer = (state, action) => {
	switch (action.type) {
		case "SET_IS_CLIENT_EDITED": {
			return { ...state, isClientEdited: action.payload };
		}
		default: {
			throw new Error(`[CONFIGURATION] - Unhandled action type: ${action.type}`);
		}
	}
};

const ConfigurationStateContext = createContext();
const ConfigurationDispatchContext = createContext();

export const ConfigurationFormProvider = ({ children }) => {
	const [state, dispatch] = useReducer(configurationReducer, {
		...initConfigurationStore,
	});

	return (
		<ConfigurationStateContext.Provider value={state}>
			<ConfigurationDispatchContext.Provider value={dispatch}>{children}</ConfigurationDispatchContext.Provider>
		</ConfigurationStateContext.Provider>
	);
};

export const useConfigurationContext = () => [
	useContext(ConfigurationStateContext),
	useContext(ConfigurationDispatchContext),
];

ConfigurationFormProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
