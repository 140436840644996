// @ts-nocheck

/* eslint-disable react/jsx-props-no-spreading */
import { useRef } from "react";

import "@zolteam/react-ras-library/dist/index.css";

import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Redirect, Route, BrowserRouter as Router, Switch } from "react-router-dom";

// Store
import { AppProvider, AppStateContext } from "./store/AppContext";
import { ToastProvider, useToastContext } from "./store/ToastContext";

// Components
import { UserLoadingScreen } from "./components/atoms";
import "./i18n";
import "./i18n";
// Routes
import routes from "./routes";
import { FiltersProvider } from "./store";
import Page403 from "./views/403/403";
import Page404 from "./views/404/404";

function App() {
	const [, toastDispatch] = useToastContext();
	const { t } = useTranslation();

	const handleClientErrors = (error) => {
		if (error?.request?.status === 401) {
			window.location.href = `${process.env.REACT_APP_BASE_OLD_URL}/#/fr/signin`;
		}
		const msgError = error.response?.data?.message;
		if (i18n.exists(`global.apiErrors.${msgError}`)) {
			toastDispatch({
				type: "ERROR",
				payload: {
					icon: "alertCircle",
					msg: t(`global.apiErrors.${msgError}`),
				},
			});
		}
	};

	const queryClient = useRef(
		new QueryClient({
			defaultOptions: {
				queries: {
					retry: 1,
					refetchOnWindowFocus: false,
				},
			},
			queryCache: new QueryCache({
				onError: (error) => {
					handleClientErrors(error);
				},
			}),
			mutationCache: new MutationCache({
				onError: (error) => {
					handleClientErrors(error);
				},
			}),
		})
	);

	return (
		<QueryClientProvider client={queryClient.current}>
			<AppProvider>
				<SkeletonTheme baseColor="var(--color-neutral-100)">
					<AppStateContext.Consumer>
						{({ user }) => (
							<UserLoadingScreen loading={<span />}>
								<FiltersProvider>
									<Router>
										<Switch>
											{user?.roles &&
												routes.map((route) => {
													const { component, ...routeProps } = route;
													const hasNotPermissions =
														route.permissions &&
														!route.permissions.some((permission) => user.roles[permission]);

													return (
														<Route
															key={route.label}
															{...routeProps}
															render={({ location }) =>
																hasNotPermissions ? (
																	<Redirect
																		to={{
																			pathname: "/403",
																			state: { from: location },
																		}}
																	/>
																) : (
																	<route.component />
																)
															}
														/>
													);
												})}
											<Route path="/403">
												<Page403 />
											</Route>
											<Route path="*">
												<Page404 />
											</Route>
										</Switch>
									</Router>
								</FiltersProvider>
							</UserLoadingScreen>
						)}
					</AppStateContext.Consumer>
				</SkeletonTheme>
				<ReactQueryDevtools />
			</AppProvider>
		</QueryClientProvider>
	);
}

function AppWrapper() {
	return (
		<ToastProvider>
			<App />
		</ToastProvider>
	);
}

export default AppWrapper;
