import React, { Fragment } from "react";

import { t } from "i18next";

import { Button, ModalV2 } from "@zolteam/react-ras-library";

import cn from "src/utils/cn";

import style from "./ConfirmModal.module.css";

interface IConfirmModalCallback {
	onConfirm: () => Promise<any> | any;
	onCancel?: (cb?: () => void) => void;
	onClose: (cb?: () => void) => void;
	closeOnConfirm?: boolean;
}

interface IConfirmModalProps extends IConfirmModalCallback {
	className?: string;
	isOpen: boolean;
	title: string;
	text?: string | React.ReactNode;
	cancelText?: string;
	confirmText?: string;
	children?: React.ReactNode;
	size?: "s" | "m" | "l";
	buttons?: (React.ReactNode | ((props: IConfirmModalCallback) => React.ReactNode) | "confirm" | "cancel")[];
	switchButton?: boolean;
	disableConfirm?: boolean;
	closeOnClickOutside?: boolean;
}

export const ConfirmModal: React.FC<IConfirmModalProps> = ({ closeOnConfirm = true, ...props }: IConfirmModalProps) => {
	const { isOpen, onConfirm, onClose, onCancel, switchButton, closeOnClickOutside } = props;
	const [isLoading, setIsLoading] = React.useState(false);

	const handleConfirm = () => {
		let res = onConfirm();
		setIsLoading(true);
		if (!res?.then) {
			res = new Promise((resolve) => resolve(res));
		}
		res.then(
			() => {
				setIsLoading(false);
				if (closeOnConfirm) onClose();
			},
			() => {
				setIsLoading(false);
			}
		);
	};

	const cancelButton = () => (
		<Button
			outline={!switchButton}
			type="button"
			color="primary"
			className={
				switchButton
					? "border border-solid border-primary-500"
					: "!text-primary-500 dark:!text-primary-300 hover:!text-white "
			}
			onClick={onCancel ?? onClose}
			disabled={isLoading}
		>
			{props.cancelText ?? t("global.cancel")}
		</Button>
	);

	const confirmButton = () => (
		<Button
			outline={switchButton}
			type="button"
			color="primary"
			onClick={handleConfirm}
			isLoading={isLoading}
			disabled={props.disableConfirm === true}
			className={cn([
				switchButton
					? "!text-primary-500 dark:!text-primary-300 hover:!text-white "
					: "border border-solid border-primary-500",
				props.disableConfirm && "opacity-50",
			])}
		>
			{props.confirmText ?? t("global.confirm")}
		</Button>
	);
	return (
		<ModalV2
			isDisplayed={isOpen}
			onClose={onClose}
			size={props.size ?? "m"}
			className={cn([
				"ConfirmModal !min-w-[90%] sm:!min-w-[70%] md:!min-w-0",
				"dark:[&>div:first-child]:bg-neutral-800 dark:[&>div:first-child]:text-white dark:bg-neutral-800 dark:text-white overflow-hidden",
				props.className,
			])}
			title={props.title}
			closeOnClickOutside={closeOnClickOutside}
		>
			<div className="flex flex-col text-left gap-m mt-4">
				<div className="mb-5">{props.children ?? props.text}</div>
				<div className={`h-px bg-neutral-100 mt-4 ${style.separator}`}></div>
				<div className="flex flex-wrap-reverse justify-end gap-4 [&>*]:w-full sm:[&>*]:w-auto  pt-8  pb-2">
					{!!props.buttons?.length ? (
						props.buttons.map((button, key) => {
							return (
								<Fragment key={key}>
									{(button === "confirm" && confirmButton()) ||
										(button === "cancel" && cancelButton()) ||
										(typeof button === "function"
											? button({
													onConfirm: handleConfirm,
													onCancel: onCancel,
													onClose: onClose,
												})
											: button)}
								</Fragment>
							);
						})
					) : (
						<>
							{switchButton ? confirmButton() : cancelButton()}
							{switchButton ? cancelButton() : confirmButton()}
						</>
					)}
				</div>
			</div>
		</ModalV2>
	);
};
