import { FC, useRef } from "react";

import { AxiosResponse } from "axios";
import { Form, Formik } from "formik";
import { upperFirst } from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { StatusUser } from "src/enum/enumStatusUser";
import { TLocation, TTemporaryWorkerProfile } from "src/types";
import { TCity } from "src/types/TCity";
import { TNationality } from "src/types/TNationality";

import {
	getUnifiedTempWorkerAdministrative,
	updateUnifiedTempWorkerAdministrative,
} from "src/services/unifiedTempWorkerService";

import { TParams } from "../TemporaryWorkerSheet";
import { AdministrativeFormValidationSchema } from "./AdministrativeFormValidation";
import CivilityInformations from "./CivilityInformations";
import ContactInformations from "./ContactInformations";
import EmergencyInformations from "./EmergencyInformations";
import FamilySituationInformations from "./FamilySituationInformations";
import SocialSecurityInformations from "./SocialSecurityInformations";
import { Button, InfoMessage, Picto, Tooltip } from "@zolteam/react-ras-library";
import { Spinner } from "src/components/atoms";
import { BlockNavigation } from "src/components/organisms";

import { formatSSN } from "src/utils/UInputs";
import { uCleanSpaces } from "src/utils/UStrings";
import { transformDate } from "src/utils/formatDate";
import formatPhoneNumber from "src/utils/formatPhoneNumber";

import { FRENCH_CITY_ID } from "src/constants/CLocations";
import { familySituationsArray } from "src/constants/CUnifiedTemporaryWorker";

import style from "./Administative.module.css";

interface IAdministrativeProps {
	uniqueId: string;
}

export type TAdministrativeFormValues = {
	lastName: string;
	birthName: string;
	firstName: string;
	dateOfBirth: string | null;
	bornCity: string;
	nativeCountryId: number;
	bornDepartment: string;
	nationalityId: TNationality;
	sex: "female" | "male" | "";
	socialSecurityNumber: string;
	familySituation: number;
	childrenQuantity: number;
	addressCountryId: number;
	addressStreet: string;
	addressBuilding: string;
	addressCity?: string | TCity;
	addressPostalCode?: string;
	location?: TLocation;
	email: string;
	phone: string;
	phone2: string;
	emergencyContactName: string;
	emergencyContactPhone: string;
	tempWorkerStatus?: string;
};

export type TAdministrativeApiValues = Omit<TAdministrativeFormValues, "location"> & {
	addressLocationId?: number;
};

const formatFormData = (data: TAdministrativeFormValues, tempWorkerStatus: string): TAdministrativeFormValues => ({
	...data,
	lastName: data?.lastName?.toUpperCase() || null,
	birthName: data?.birthName?.toUpperCase() || null,
	firstName: upperFirst(data?.firstName?.toLowerCase()) || null,
	dateOfBirth: data?.dateOfBirth ? moment(data?.dateOfBirth).format("DD/MM/yyyy") : null,
	socialSecurityNumber: formatSSN(data?.socialSecurityNumber) || null,
	tempWorkerStatus: tempWorkerStatus,
	phone: formatPhoneNumber(data?.phone) || null,
	phone2: formatPhoneNumber(data?.phone2) || null,
	emergencyContactPhone: formatPhoneNumber(data?.emergencyContactPhone) || null,
	familySituation: data?.familySituation || (familySituationsArray[0].value as number),
	nativeCountryId: data?.nativeCountryId || FRENCH_CITY_ID,
});

const Administrative: FC<IAdministrativeProps> = ({ uniqueId }) => {
	const { t } = useTranslation();
	let { userId } = useParams<TParams>();
	const queryClient = useQueryClient();
	const formRef = useRef(null);
	const history = useHistory();

	const temporaryWorker: TTemporaryWorkerProfile = queryClient.getQueryData<any>(["getUser", userId])?.data;
	const temporaryWorkerQueryState: any = queryClient.getQueryState<any>(["getUser", userId]);
	const isCandidtateOrWorker = [StatusUser.CANDIDATE, StatusUser.WORKER].includes(temporaryWorker?.twStatus);
	const isCandidtate = [StatusUser.CANDIDATE].includes(temporaryWorker?.twStatus);

	const getTemporaryWorkerProfile = useQuery({
		queryKey: ["getTemporaryWorkerProfile", uniqueId],
		queryFn: ({ signal }) => getUnifiedTempWorkerAdministrative(uniqueId, signal),
		enabled: !!uniqueId,
	});

	const { data: dataTempWorker } = getTemporaryWorkerProfile;
	const isLoading =
		(getTemporaryWorkerProfile.status === "idle" && !temporaryWorkerQueryState.error) ||
		getTemporaryWorkerProfile.isLoading;

	const initialValues: TAdministrativeFormValues = formatFormData(dataTempWorker?.data, temporaryWorker?.twStatus);

	const mutation = useMutation<
		AxiosResponse<TAdministrativeApiValues>,
		unknown,
		{ userId: string; data: TAdministrativeApiValues }
	>(({ userId, data }) => updateUnifiedTempWorkerAdministrative(userId, data), {
		onSuccess: (data) => {
			queryClient.setQueryData(["getTemporaryWorkerProfile"], data.data);
			formRef.current?.resetForm({ values: formatFormData(data.data, temporaryWorker?.twStatus) });
			// Gérer la réussite de la requête PUT
			toast.success(t(`usersListing.apiResponse.success.save`));
		},
		onError: (error: any) => {
			//Validation error from API
			if (error?.response?.data?.message === "Validation errors") {
				const errorsObject: { [key: string]: string | string[] } = error?.response?.data?.errors?.reduce(
					(acc: { [key: string]: string | string[] }, current: { [key: string]: string }) => {
						const key = Object.keys(current)[0];
						const value = Object.values(current)[0];

						if (acc[key]) {
							// Si la clé existe déjà, convertit la valeur en tableau ou ajoute à l'existant
							if (Array.isArray(acc[key])) {
								(acc[key] as string[]).push(value);
							} else {
								acc[key] = [acc[key] as string, value];
							}
						} else {
							acc[key] = value;
						}

						return acc;
					},
					{}
				);
				const errors = Object.fromEntries(
					Object.entries(errorsObject).map(([key, value]) => [
						key,
						Array.isArray(value)
							? value.map((v) => t(`global.apiErrors.${v}`))
							: t(`global.apiErrors.${value}`),
					])
				);
				return formRef.current?.setErrors(errors);
			}
			toast.error(t(`global.apiErrors.notSaved`));
		},
	});

	const handleSubmit = (values: TAdministrativeFormValues) => {
		const { addressCity, addressPostalCode, location, ...formData } = values;
		const filteredData = {
			...formData,
			dateOfBirth: values.dateOfBirth ? transformDate(values.dateOfBirth) : null,
			uniqueId: uniqueId,
			socialSecurityNumber: uCleanSpaces(formData.socialSecurityNumber),
			phone: uCleanSpaces(formData.phone),
			phone2: uCleanSpaces(formData.phone2) || null,
			emergencyContactPhone: uCleanSpaces(formData.emergencyContactPhone) || null,
			...(values?.addressCountryId === FRENCH_CITY_ID
				? {
						addressLocationId: values?.location?.id || null,
					}
				: { addressPostalCode, addressCity }),
		};
		mutation.mutate(
			{ userId: uniqueId, data: filteredData },
			{
				onSettled: () => {
					formRef.current?.setSubmitting(false);
				},
			}
		);
	};

	if (isLoading)
		return (
			<Spinner
				className="flex flex-1 flex-col items-center justify-center w-full h-full gap-3"
				size={50}
				text={t("global.loading")}
			/>
		);

	if (!uniqueId)
		return (
			<>
				<div className="[&>div]:w-full [&>div]:box-border pr-12">
					<InfoMessage withIcon color="error">
						{t("global.apiErrors.ERR_UNIQUE_ID_NOT_FOUND")}
					</InfoMessage>
				</div>
			</>
		);

	return (
		<div className="bg-white w-full rounded-tl-3xl shadow-lg p-10 flex flex-col gap-8 max-lg:h-fit ">
			<div className="flex flex-row items-center gap-3">
				<h1 className="text-2xl font-bold">{t("usersListing.temporaryWorker.administrative.title")} </h1>
				<Tooltip
					content={
						<div className="whitespace-nowrap">
							{t("usersListing.temporaryWorker.administrative.uniqueSheet")}{" "}
						</div>
					}
				>
					<div>{<Picto icon="buildings" />} </div>
				</Tooltip>
			</div>

			<Formik
				innerRef={formRef}
				initialValues={initialValues}
				onSubmit={handleSubmit}
				validationSchema={AdministrativeFormValidationSchema(isCandidtateOrWorker, isCandidtate)}
				initialTouched={{
					sex: true,
					firstName: true,
					lastName: true,
					bornCity: true,
					dateOfbirth: true,
					nativeCountryId: true,
					nationalityId: true,
					bornDepartment: true,
					socialSecurityNumber: true,
					email: true,
					phone: true,
					phone2: true,
					addressCountryId: true,
					addressStreet: true,
					addressPostalCode: true,
					addressCity: true,
					addressCityPostal: true,
					location: true,
					emergencyContactName: true,
					emergencyContactPhone: true,
				}}
				validateOnChange
				validateOnBlur
				validateOnMount={true}
			>
				{({ isValid, dirty, isSubmitting }) => {
					const canSubmit = isValid && dirty;

					return (
						<>
							<Form className="relative flex flex-col items-start justify-start gap-8 h-full mr-12 pr-9 lg:pr-0">
								<CivilityInformations />

								<SocialSecurityInformations />

								<FamilySituationInformations />

								<ContactInformations />

								<EmergencyInformations />

								<div className={style.buttonWrapper}>
									<Button
										data-testid="command-save"
										disabled={!canSubmit}
										color={!canSubmit ? "grey" : "primary"}
										type="submit"
										isLoading={isSubmitting}
										className={style.submitButton}
									>
										{t("global.save")}
									</Button>
								</div>
							</Form>
							<BlockNavigation
								when={dirty}
								navigate={(path: string) => history.push(path)}
								shouldBlockNavigation={(location: Location) => {
									const isNotAdministrativePath = !/^\/users\/\d+\/administrative$/.test(
										location?.pathname
									);
									return isNotAdministrativePath;
								}}
							/>
						</>
					);
				}}
			</Formik>
		</div>
	);
};

export default Administrative;
