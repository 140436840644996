import moment from "moment";
import { Trans } from "react-i18next";

import TProfileActivityRender from "../../types/TProfileActivityRender";
import activityDetailTemplate from "../activityDetailTemplate";
import renderCommunicationContent from "../renderCommunicationContent/renderCommunicationContent";
import { Picto } from "@zolteam/react-ras-library";
import OrderProfileChannelNotification from "src/components/molecules/OrderProfileChannelNotification/OrderProfileChannelNotification";

import PROFILE_COMMUNICATION_DELIVERY_STATUS from "src/constants/ProfileCommunicationStatus";

import style from "../../ModalContentProfileCardDetails.module.css";

const renderSendMissionStatusActivity = (
	activity: TProfileActivityRender,
	index: number,
	t: (key: string, options?: any) => string,
	concatenatedActivitiesLength: number,
	retrievePrimaryCommunicationDetails: (activity: TProfileActivityRender) => { medium: string; status: string },
	handleComunicationClick: (orderMessagingId: string) => void
) => {
	const content = (
		<>
			<span className={style.activityText}>
				<Trans
					i18nKey="commands.profiles.modalsContent.profileDetail.activity.templates.sendMissionStatus"
					values={{
						messageType: t(
							`commands.profiles.modalsContent.profileDetail.sendMissionStatus.${[activity.communicationType]}`
						),
						orderProfileName: `${activity.tempWorkerFirstName} ${activity.tempWorkerLastName}`,
					}}
				/>
			</span>
			<div className="flex items-center mb-1.5">
				<span className={style.activityTime}>
					{t("commands.profiles.modalsContent.profileDetail.activity.when", {
						date: moment(activity.createdAt).format("DD MMM YYYY"),
						time: moment(activity.createdAt).format("HH[h]mm"),
						communicationMethods: "",
					})}
				</span>
				<OrderProfileChannelNotification
					communicationMediumForCommunicationSent={activity?.communicationMediumForCommunicationSent}
				/>
			</div>
			{retrievePrimaryCommunicationDetails(activity)?.status === PROFILE_COMMUNICATION_DELIVERY_STATUS.SUCCESS ? (
				<div>
					<div
						className={style.contentAction}
						onClick={() => handleComunicationClick(activity.orderMessagingId)}
						onKeyDown={() => handleComunicationClick(activity.orderMessagingId)}
						role="button"
						tabIndex={index}
					>
						<span>
							{t("commands.profiles.modalsContent.profileDetail.contentActionButton", {
								sendMissionType: t(
									`commands.profiles.modalsContent.profileDetail.sendMissionType.${[activity.communicationType]}`
								),
							})}
						</span>
						<Picto icon={activity?.isOpen ? "chevronUp" : "chevronDown"} />
					</div>
					{renderCommunicationContent(activity, index, retrievePrimaryCommunicationDetails(activity).medium)}
				</div>
			) : null}
		</>
	);
	return activityDetailTemplate(index, content, activity.orderMessagingId, concatenatedActivitiesLength);
};

export default renderSendMissionStatusActivity;
