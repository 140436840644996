import { t } from "i18next";

import { differenceInHours, format } from "date-fns";
import { fr as localeFr } from "date-fns/locale";

import { Text } from "@zolteam/react-ras-library";
import { Tag } from "src/components/atoms";
import { ColumnListing, TableInfo } from "src/components/molecules";

import { dateDifference, formatDate } from "src/utils/formatDate";

const DocumentsTypesColumns = () => {
	return [
		<ColumnListing
			key="name"
			id="name"
			name={
				<Text tag="span" size="paragraph02">
					{t("usersListing.name")}
				</Text>
			}
			field={(elem) => ({ name: elem.name })}
			component={({ data: { name } }) => (
				<Text tag="span" size="paragraph02">
					{name || ""}
				</Text>
			)}
		/>,
		<ColumnListing
			key="type"
			id="type"
			name={
				<Text tag="span" size="paragraph02">
					{t("usersListing.type")}
				</Text>
			}
			field={(elem) => ({ type: elem.type })}
			component={({ data: { type } }) => (
				<Text tag="span" size="paragraph02">
					{t(`usersListing.temporaryWorker.documents.types.${type}`)}
				</Text>
			)}
		/>,
		<ColumnListing
			key="createdAt"
			id="createdAt"
			name={
				<Text tag="span" size="paragraph02">
					{t("usersListing.createdAt")}
				</Text>
			}
			field={(elem) => ({ createdAt: elem.createdAt, updatedAt: elem.updatedAt })}
			component={({ data: { createdAt, updatedAt } }) => {
				const hoursDiff = differenceInHours(new Date(), new Date(updatedAt));
				const subInfo =
					hoursDiff > 24
						? t("global.updatedAtDayTimes", {
								updatedDay: formatDate(updatedAt, "PP", true),
								updatedTime: formatDate(updatedAt, "HH:mm", true),
							})
						: t("global.updatedAt", { updated: dateDifference(new Date(updatedAt), new Date()) });
				return (
					<TableInfo
						className="whitespace-nowrap"
						info={
							createdAt
								? format(new Date(createdAt), "dd MMM yyyy", {
										locale: localeFr,
									})
								: ""
						}
						subInfo={subInfo}
					/>
				);
			}}
		/>,

		<ColumnListing
			key="isArchived"
			id="isArchived"
			name={
				<Text tag="span" size="paragraph02">
					{t("usersListing.isArchived")}
				</Text>
			}
			field={(elem) => ({
				isArchived: elem.isArchived,
			})}
			component={({ data: { isArchived } }) => (
				<Text tag="div" size="paragraph02" className={"flex flex-row gap-3 items-center whitespace-nowrap"}>
					{isArchived && (
						<Tag color={"grey"} size={"s"}>
							{t("integration.documentsTypes.archived")}
						</Tag>
					)}
				</Text>
			)}
		/>,
	];
};

export default DocumentsTypesColumns;
