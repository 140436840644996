import { useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { useTitle } from "react-use";
import { StatusUser } from "src/enum/enumStatusUser";
import { TAction, TSearchFilters, TTemporaryWorker } from "src/types";
import { TUserListing, TUsersListingFilters } from "src/types/TUsersListing";

import { format } from "date-fns";

import usersListingService from "../../../services/usersListingService";

import useSelectable from "src/hooks/useSelectable";
import { useFiltersContext } from "src/store/FiltersContext/useFiltersContext";

import { DEFAULT_USERS_LISTING_OPTIONS, PAGINATION_OFFSET_OPTIONS } from "../../../constants";
import { definePageOffset } from "../../../utils/paginationUtils";
import ActionDeleteUser from "./Actions/ActionDeleteUser";
import ActionOutOfBuisness from "./Actions/ActionOutOfBuisness";
import ActionUpdateVip from "./Actions/ActionUpdateVip";
import UsersListColumns from "./UsersListingColumns";
import UsersListingFiltersModal from "./UsersListingFiltersModal";
import { Badge, PaginationV2, Picto, SelectPopover, Text, Title } from "@zolteam/react-ras-library";
import { InputSearch, PaginationSearch } from "src/components/molecules";
import { SuspiciousModal, TableListing } from "src/components/organisms";
import { TableFilters, WithSideMenu, WithTopBarre } from "src/components/templates";

import { cntm } from "src/utils/cntm";

import style from "./UsersListing.module.css";

const countActiveFilters = (filters: TUsersListingFilters) => {
	let counter = 0;

	counter += filters.agenciesIds?.length > 0 ? 1 : 0;
	counter += filters.createdSince !== null ? 1 : 0;
	counter += filters.hasMyRas !== null ? 1 : 0;
	counter += filters.jobTypes?.length > 0 ? 1 : 0;
	counter += filters.paymentInfos?.hasIbanToValidate !== null || filters.paymentInfos?.isMissingIban !== null ? 1 : 0;
	counter += !!filters.qualifications?.length || !!filters.competencies?.length ? 1 : 0;
	counter += filters.equipments?.length > 0 ? 1 : 0;
	counter += filters.workConditions?.length > 0 ? 1 : 0;
	counter += filters.commonDocuments?.length > 0 ? 1 : 0;
	counter += Object.values(filters.status).filter((status) => status).length > 0 ? 1 : 0;

	return counter;
};

const UsersListing = () => {
	// Hooks
	const { t } = useTranslation();
	useTitle(t("usersListing.pageTitle"));
	const { selectedItems, toggleSelected, selectAll, selectedValuesStatus } = useSelectable({});
	const history = useHistory();
	const [Options, setOptions] = useState(DEFAULT_USERS_LISTING_OPTIONS);
	const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
	const [suspiciousUTW, setSuspiciousUTW] = useState(null);
	// states
	const { filters, setFilter, setFilters, count } = useFiltersContext("users", countActiveFilters);
	const [isUpdateVipStatusModal, setIsUpdateVipStatusModal] = useState(false);
	const [isUpdateStatusModalOpen, setIsUpdateStatusModalOpen] = useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const userActionSelected = useRef<TUserListing | null>(null);

	const handleRefresh = () => {
		postUsersListingSearchQuery.refetch();
	};

	const postUsersListingSearchQuery = useQuery({
		queryKey: ["postUsersListingSearch", filters, Options],
		queryFn: () => {
			const {
				count,
				jobTypes,
				paymentInfos,
				equipments,
				workConditions,
				qualifications,
				competencies,
				commonDocuments,
				status: { isVip, isCdiInterim, ...restStatus },
				...restFilters
			} = filters;

			return usersListingService.postUsersListingSearch({
				...Options,
				query: {
					...restFilters,
					createdSince: filters.createdSince ? format(filters.createdSince, "yyyy-MM-dd") : null,
					agenciesIds: filters.agenciesIds?.map((agency) => agency.id),
					hasMyRas: filters.hasMyRas === null ? null : filters.hasMyRas === "true",
					jobTypeIds: jobTypes?.map((jobType) => jobType.id),
					workConditionIds: workConditions?.map((workCondition) => workCondition.id),
					hasIbanToValidate: paymentInfos?.hasIbanToValidate,
					isMissingIban: paymentInfos?.isMissingIban,
					qualificationIds: qualifications?.map((qualification) => qualification.id),
					competencyIds: competencies?.map((competency) => competency.id),
					equipmentIds: equipments?.map((equipment) => equipment.id),
					commonDocumentIds: commonDocuments?.map((doc) => doc.id),
					temporaryWorkerStatus: {
						...restStatus,
					},
					isVip,
					isCdiInterim,
				},
			});
		},
	});

	const {
		isError: isErrorUsersListingSearch,
		isLoading: isLoadingUsersListingSearch,
		isFetching: isFetchingUsersListingSearch,
		data: users,
	} = postUsersListingSearchQuery;

	const getPageMaxNumber = () => Math.round((users?.data?.total ?? 0) / Options.limit);

	const getStartPagination = Options.limit * Options.page - Options.limit + 1;

	const getEndPagination =
		Options.limit * Options.page <= users?.data?.total ? Options.limit * Options.page : users?.data?.total;

	const handleUpdateOptions = (params: TSearchFilters) => {
		setOptions((prev) => ({
			...prev,
			...params,
		}));
	};

	const handleSearch = (searchText: string) => {
		setFilter("searchText", searchText);
		setOptions((prev) => ({
			...prev,
			page: 1,
		}));
	};

	//Actions primaires [...]
	const actions: (elem: TTemporaryWorker) => TAction[] = (elem: TTemporaryWorker) => {
		return [
			{
				label: t("usersListing.primaryActions.documents.go"),
				action: () => {},
				disable: true,
			},
			{
				label: t("usersListing.primaryActions.absences.manage"),
				action: () => {},
				disable: true,
			},
			{
				label: t("usersListing.primaryActions.messages.send"),
				action: () => {},
				disable: true,
			},
			{
				label: elem?.isVip
					? t("usersListing.primaryActions.vip.remove")
					: t("usersListing.primaryActions.vip.add"),
				action: () => setIsUpdateVipStatusModal(true),
			},
			{
				label: t("usersListing.primaryActions.status.update"),
				action: () => setIsUpdateStatusModalOpen(true),
				disable: elem.isOutOfBusiness,
			},
			{
				label: t("global.delete"),
				action: () => setIsDeleteModalOpen(true),
				disable: elem.temporaryWorkerStatus === StatusUser.WORKER,
			},
		];
	};

	const handleDoubleClick = (elem: TTemporaryWorker) => {
		if (elem.unifiedTempWorkerIdOnSuspicion) {
			return setSuspiciousUTW(elem);
		}

		history.push(`/users/${elem.id}/administrative`);
	};

	return (
		<WithSideMenu>
			<WithTopBarre
				quickEntryContent={<div>test</div>}
				title={
					<Title tag="h1" size="heading01" lineHeight="s">
						{t("usersListing.pageTitle")}
					</Title>
				}
				containerClassname="container"
			>
				<TableFilters
					rightContent={
						<div className={cntm([style.filterField, " mr-m items-center gap-4"])}>
							<div className={style.searchTextFilterWidth}>
								<InputSearch
									label={t("global.filters.searchText")}
									onChange={(value) => {
										handleSearch(value);
									}}
									minLength={0}
									debounced
									name="search"
									value={filters?.searchText || ""}
									className="min-w-[300px] w-full sm:w-fit "
								/>
							</div>
							<button onClick={handleRefresh} className="flex items-center gap-2">
								<Picto
									className={"text-primary-500"}
									icon="refresh"
									style={{ width: "20px", height: "20px" }}
								/>
								<Text tag="span">{t("global.filters.refresh")}</Text>
							</button>
							<button className="flex items-center gap-2" onClick={() => setIsFilterModalOpen(true)}>
								<Picto
									className={"text-primary-500"}
									icon="sliders"
									style={{ width: "20px", height: "20px" }}
								/>
								<Text tag="span">{t("global.filtersPanel.openPanel")}</Text>

								{!!count && (
									<Badge
										color="primary"
										content={count}
										className="w-5 h-5 justify-center items-center [&_*]:box-border [&_*]:w-full [&_*]:h-full"
									/>
								)}
							</button>
						</div>
					}
				/>
				<TableListing
					loading={isLoadingUsersListingSearch || isFetchingUsersListingSearch}
					error={isErrorUsersListingSearch}
					data={users?.data?.items}
					handleSearch={handleUpdateOptions}
					initialColumnSort={{
						field: Options?.sorting,
						orderBy: Options?.orderBy,
					}}
					onDoubleClick={handleDoubleClick}
					onRowActionClick={(user: TUserListing) => (userActionSelected.current = user)}
					canBeChecked={true}
					selectedItems={selectedItems}
					toggleSelected={toggleSelected}
					selectAll={selectAll}
					actions={actions}
					selectedValuesStatus={selectedValuesStatus}
					showCustomMessage={false}
					genericEmptyCellsMessage={<div className="text-neutral-300">-</div>}
					footer={
						users?.data?.total ? (
							<>
								<PaginationV2
									previousLabel={<Picto icon="chevronLeft" />}
									nextLabel={<Picto icon="chevronRight" />}
									breakLine={
										<PaginationSearch
											onInputChange={handleUpdateOptions}
											max={getPageMaxNumber()}
										/>
									}
									currentPage={Options.page}
									totalCount={users?.data.total}
									pageSize={Options.limit}
									siblingCount={2}
									onPageChange={(activePage: number) => handleUpdateOptions({ page: activePage })}
								/>
								<Text tag="span" size="paragraph02" color="grey">
									{t("global.pagination.resultsPagination", {
										resultStart: getStartPagination,
										resultEnd: getEndPagination,
										resultTotal: users?.data?.total,
									})}
								</Text>
								<SelectPopover
									name="limit"
									options={PAGINATION_OFFSET_OPTIONS}
									onChange={(value: number) =>
										handleUpdateOptions({
											limit: value,
											page: definePageOffset(value, Options.limit, Options.page),
										})
									}
									value={
										Options.limit
											? PAGINATION_OFFSET_OPTIONS.find((option) => option.value === Options.limit)
											: null
									}
								/>
							</>
						) : null
					}
				>
					{UsersListColumns()}
				</TableListing>
			</WithTopBarre>
			<ActionUpdateVip
				isOpen={isUpdateVipStatusModal}
				setIsOpen={setIsUpdateVipStatusModal}
				selectedItem={userActionSelected.current}
			/>
			<ActionOutOfBuisness
				isOpen={isUpdateStatusModalOpen}
				setIsOpen={setIsUpdateStatusModalOpen}
				selectedItem={userActionSelected.current}
			/>
			<ActionDeleteUser
				isOpen={isDeleteModalOpen}
				setIsOpen={setIsDeleteModalOpen}
				selectedItem={userActionSelected.current}
			/>

			<SuspiciousModal suspiciousUTW={suspiciousUTW} setSuspiciousUTW={setSuspiciousUTW} />
			<UsersListingFiltersModal
				onSubmit={(newFilters) => {
					setFilters(newFilters);
					setIsFilterModalOpen(false);
					setOptions((prev) => ({
						...prev,
						page: 1,
					}));
				}}
				isDisplayed={isFilterModalOpen}
				onCloseModal={() => setIsFilterModalOpen(false)}
			/>
		</WithSideMenu>
	);
};

export default UsersListing;
