/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, ReactNode, useState } from "react";

import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { TAction, TOrderBy, TSearchFilters } from "src/types";

// Store
import TableListingContext from "../../../store/TableListingContext";

import cn from "../../../utils/cn";
import { Checkbox, Picto, Popover, StatusBox, Text } from "@zolteam/react-ras-library";
import { PopoverMenuItem } from "src/components/atoms";
import { ColumnListing, WideLoader } from "src/components/molecules";

// Style
import styles from "./TableListing.module.css";

interface IColumnProps {
	component: React.FC<any>;
	field: (elem: string) => string;
	id: string;
	name: React.ReactNode;
	thClassName?: string;
	tdClassName?: string;
	containsLink?: boolean;
	isSortable?: boolean;
	hideOnDesktop?: boolean;
	cellClassName?: string;
}

type TTableData = {
	id: number;
	[key: string | number]: any;
};

export interface TableListingProps {
	children: React.ReactElement<IColumnProps>[];
	loading: boolean;
	data: TTableData[];
	handleSearch?: (params: TSearchFilters | string) => void;
	initialColumnSort: {
		field: keyof TTableData;
		orderBy: TOrderBy;
	};
	footer?: React.ReactNode;
	canBeChecked?: boolean;
	onDoubleClick?: (data: TTableData) => void;
	noDataText?: string;
	error?: boolean;
	showCustomMessage?: boolean;
	customMessage?: string;
	disabledRow?: (data: TTableData) => boolean;
	styleInactiveRows?: (data: TTableData) => boolean;
	noBorder?: boolean;
	fullHeight?: boolean;
	getTableRowHref?: (data: TTableData) => string;
	selectedItems?: number[];
	toggleSelected?: (data?: TTableData) => void;
	selectAll?: (data: TTableData[]) => void;
	selectedValuesStatus?: (data: TTableData[]) => string;
	loadingMessage?: string;
	hasCustomFooter?: boolean;
	actions?: (elem: any) => TAction[];
	onRowActionClick?: (elem: any) => void;
	genericEmptyCellsMessage?: ReactNode;
	showHeader?: boolean;
	onRowClick?: (row: any) => void;
	tableContainerClassName?: string;
	tableClassName?: string;
	observerElementBottom?: ReactNode;
	observerElementTop?: ReactNode;
	listRef?: any;
}

export const TableListing: FC<TableListingProps> = ({
	children: columns,
	loading,
	error = false,
	data = [],
	initialColumnSort,
	handleSearch,
	footer = null,
	canBeChecked = true,
	onDoubleClick = () => {},
	noDataText = i18n.t("global.noDataText"),
	showCustomMessage = false,
	customMessage = "",
	disabledRow = () => false,
	noBorder = false,
	fullHeight = false,
	styleInactiveRows = () => false,
	getTableRowHref = () => "#",
	selectedItems = [],
	toggleSelected = () => {},
	selectAll = () => {},
	selectedValuesStatus = () => {},
	loadingMessage = i18n.t("global.loading"),
	hasCustomFooter = false,
	actions = undefined,
	onRowActionClick = () => {},
	genericEmptyCellsMessage,
	showHeader = true,
	onRowClick = () => {},
	tableContainerClassName = "",
	tableClassName = "",
	observerElementBottom = null,
	observerElementTop = null,
	listRef = null,
}) => {
	const { t } = useTranslation();
	const [columnSort, setColumnSort] = useState(initialColumnSort);

	const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		if (!e.ctrlKey && !e.metaKey) {
			e.preventDefault();
		}
	};

	const getMessage = () => {
		if (loading) {
			return null;
		}

		switch (error) {
			case true:
				return t("global.error");
			default:
				return showCustomMessage ? customMessage : noDataText;
		}
	};

	const onPressSort = (field: keyof TTableData) => {
		// If we click on same column, we retrieve the currentSort order
		// Else, we use the default sort order which is DESC, but as we call handleSearch
		// With the new sort order we want, we just say that the current order is ASC
		// So the invert is sent to handleSearch
		let newOrderBy: TOrderBy = "";
		let orderBy: TOrderBy = "";

		// exception createdAt field
		if (field === "createdAt") {
			newOrderBy = columnSort.orderBy === "DESC" ? "ASC" : "DESC";
			orderBy = field === columnSort.field ? newOrderBy : "DESC";
		} else {
			newOrderBy = columnSort.orderBy === "ASC" ? "DESC" : "ASC";
			orderBy = field === columnSort.field ? newOrderBy : "ASC";
		}

		handleSearch({
			sorting: field,
			orderBy,
		});
		setColumnSort({
			field,
			orderBy,
		});
	};

	const renderRows = () =>
		data.map((elem, rowIndex) => {
			const isRowDisabled = disabledRow(elem);
			const isChecked = selectedItems?.includes(elem.id);
			return (
				<tr
					onDoubleClick={() => (isRowDisabled ? null : onDoubleClick(elem))}
					key={`row-${elem.id}`}
					data-testid={`row-${elem.id}`}
					className={cn([
						styles.row,
						isRowDisabled ? styles.rowDisabled : null,
						styleInactiveRows(elem) ? styles.greyRow : null,
					])}
					onClick={() => {
						onRowClick && onRowClick(elem);
					}}
				>
					{canBeChecked ? (
						<td onClick={(e) => e.stopPropagation()} onDoubleClick={(e) => e.stopPropagation()}>
							<a>
								<div
									className={cn([
										styles.cellWrapper,
										styles.cellCheckbox,
										selectedItems?.find((val) => val === elem.id) != null &&
											styles.cellWrapperSelected,
									])}
								>
									<Checkbox
										value={isChecked}
										onChange={() => toggleSelected(elem)}
										id={`childSelect-${elem.id}`}
										theme="primary"
									/>
								</div>
							</a>
						</td>
					) : null}
					{columns.map((column) => {
						const renderColumn = () => (
							<div
								className={cn([
									styles.cellWrapper,
									isChecked && styles.cellWrapperSelected,
									column.props.cellClassName,
								])}
							>
								<TableListingContext.Provider
									value={{
										data: data[rowIndex],
										toggleSelected,
										selected: selectedItems,
									}}
								>
									<ColumnListing
										field={column.props.field}
										component={column.props.component}
										genericEmptyCellsMessage={genericEmptyCellsMessage}
									/>
								</TableListingContext.Provider>
							</div>
						);

						return (
							<td
								key={`cell-${column.props.id}-${elem.id}`}
								data-testid={`cell-${column.props.id}`}
								className={cn([
									column.props.tdClassName,
									column.props.hideOnDesktop && styles.hideOnDesktop,
								])}
							>
								{!column.props.containsLink ? (
									<a href={getTableRowHref(elem)} onClick={handleClick}>
										{renderColumn()}
									</a>
								) : (
									renderColumn()
								)}
							</td>
						);
					})}
					{actions && (
						<td onClick={(e) => e.stopPropagation()} onDoubleClick={(e) => e.stopPropagation()}>
							<div
								className={cn([
									styles.cellWrapper,
									styles.cellActions,
									selectedItems?.find((val) => val === elem.id) != null && styles.cellWrapperSelected,
								])}
							>
								<Popover
									minWidth
									placement="left-start"
									animation="perspective"
									offset={[-20, 10]}
									clickInside
									content={
										<>
											{actions(elem)
												?.filter((action) => action?.permission !== false)
												.map((action) => (
													<PopoverMenuItem
														key={action.label}
														click={(e) => {
															return action.disable
																? e.preventDefault()
																: action.action(elem);
														}}
														className={`p-3 rounded-lg hover:bg-neutral-100 ${action.disable && "!cursor-default"}`}
														disabled={action.disable}
													>
														<Text
															tag="div"
															size="paragraph01"
															className={action.disable ? "!text-neutral-300 " : ""}
														>
															{action.label}
														</Text>
													</PopoverMenuItem>
												))}
										</>
									}
								>
									<button
										type="button"
										onClick={(e) => {
											e.preventDefault();
											onRowActionClick && onRowActionClick(elem);
										}}
										aria-label="more"
									>
										<Picto
											icon="more"
											style={{
												width: "18px",
												transform: "rotate(90deg)",
												color: "var(--color-neutral-500)",
											}}
										/>
									</button>
								</Popover>
							</div>
						</td>
					)}
				</tr>
			);
		});

	const getTableListingContent = () =>
		!loading && data?.length > 0 && !showCustomMessage ? (
			renderRows()
		) : (
			<tr>
				<td
					colSpan={columns?.length}
					key="cell-message"
					data-testid="cell-message"
					style={{
						textAlign: "center",
						padding: "1rem",
					}}
				>
					{getMessage()}
				</td>
			</tr>
		);

	const needCenterMessage = loading || data?.length === 0 || (data?.length > 0 && showCustomMessage);

	return (
		<>
			<div
				className={cn([
					styles.tableContainer,
					fullHeight ? styles.tableFullHeight : null,
					tableContainerClassName,
				])}
			>
				<table
					cellPadding={noBorder ? 0 : null}
					cellSpacing={noBorder ? 0 : null}
					className={cn([
						styles.table,
						noBorder ? styles.noBorder : null,
						needCenterMessage ? styles.tableModeMessage : null,
						tableClassName,
					])}
				>
					{showHeader && (
						<thead className={styles.head}>
							<tr>
								{canBeChecked ? (
									<th className={styles.checkboxColumn}>
										<StatusBox
											value={selectedValuesStatus(data)}
											onClick={() => selectAll(data)}
											name="selectAll"
											theme="primary"
										/>
									</th>
								) : null}
								{columns.map((column) => (
									<th
										key={`column-${column.props.id}`}
										data-testid={`column-${column.props.id}`}
										className={cn([
											column.props.id === columnSort.field ? styles.selected : null,
											column.props.thClassName,
											column.props.hideOnDesktop && styles.hideOnDesktop,
										])}
										onClick={() => column.props.isSortable && onPressSort(column.props.id)}
										onKeyDown={() => column.props.isSortable && onPressSort(column.props.id)}
									>
										<div
											className={cn([
												styles.columnTitle,
												column.props.isSortable &&
													columnSort.field === column.props.id &&
													styles.columnActive,
												column.props.isSortable && styles.columnInteractive,
											])}
										>
											{column.props.name}
											{column.props.isSortable &&
												(columnSort.orderBy === "ASC" &&
												columnSort.field === column.props.id ? (
													<Picto
														icon="chevronFilter"
														className={styles.chevronFilter}
														style={{
															transform: "rotate(180deg) translate(-4px, -0.075rem)",
														}}
													/>
												) : (
													<Picto icon="chevronFilter" className={styles.chevronFilter} />
												))}
										</div>
									</th>
								))}
								{actions && <th className={styles.checkboxColumn}></th>}
							</tr>
						</thead>
					)}

					<tbody className={styles.body} ref={listRef}>
						{observerElementTop}
						{getTableListingContent()}
						{observerElementBottom}
					</tbody>
				</table>
				{loading && <WideLoader message={loadingMessage} />}
			</div>
			{!loading && footer && (
				<div className={hasCustomFooter ? cn([styles.footer, styles.selectedFooter]) : cn([styles.footer])}>
					{footer}
				</div>
			)}
		</>
	);
};
