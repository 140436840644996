// @ts-nocheck
import React, { useEffect, useCallback } from "react";

import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

// Service
import { getInstructions } from "src/services/commandService";

import useDuplication from "../../hooks/useDuplication";
import { useConfigurationContext } from "src/store/ConfigurationContext";

import cn from "../../../../../utils/cn";
import { Title, SelectInstructions } from "@zolteam/react-ras-library";

import parseTextWithLineBreaks from "src/utils/parseTextWithLineBreaks";

// Constants
import { CREATE_MODE, EDIT_MODE } from "src/constants";

// Style
import style from "./CommandRulesFormBlock.module.css";

const CommandRulesFormBlock = ({ isOrderFetched }) => {
	const { t } = useTranslation();
	const { orderId } = useParams();
	const { values, setValues } = useFormikContext();
	const [{ isClientEdited }] = useConfigurationContext();
	const { clientOption } = values;
	const { isInDuplicationMode } = useDuplication();

	// States
	const isNewCommand = orderId === CREATE_MODE;
	const commandFormMode = isNewCommand ? CREATE_MODE : EDIT_MODE;

	const {
		agencyInstructionCustomTempoId1,
		agencyInstructionCustomDescription1,
		agencyInstructionCustomTempoId2,
		agencyInstructionCustomDescription2,
		agencyInstructionCustomTempoId3,
		agencyInstructionCustomDescription3,
		agencyInstructionSalaryTempoId1,
		agencyInstructionSalaryDescription1,
		agencyInstructionSalaryTempoId2,
		agencyInstructionSalaryDescription2,
		agencyInstructionReferenceSalaryTempoId1,
		agencyInstructionReferenceSalaryDescription1,
		agencyInstructionReferenceSalaryTempoId2,
		agencyInstructionReferenceSalaryDescription2,
		agencyInstructionBillingTempoId1,
		agencyInstructionBillingDescription1,
		agencyInstructionBillingTempoId2,
		agencyInstructionBillingDescription2,
	} = values;

	const { data: instructionsList, isFetched: isinstructionListFetched } = useQuery(
		["getInstructions"],
		() => getInstructions(values?.agencyId),
		{
			enabled: !!values?.agencyId,
		}
	);

	const getInstructionDesignationFromId = (id) => {
		if (instructionsList?.data) {
			return instructionsList?.data?.find((instruction) => instruction.id === id)?.designation;
		}
		return null;
	};

	const getTempoIdFromInstructionList = (id) => {
		if (instructionsList?.data) {
			return instructionsList?.data?.find((instruction) => instruction.id === id)?.tempoId;
		}
		return null;
	};

	useEffect(() => {
		if (isClientEdited && commandFormMode === "create" && clientOption) {
			setValues((prevValues) => ({
				...prevValues,
				agencyInstructionCustomTempoId1: getTempoIdFromInstructionList(
					clientOption?.agencyInstructionCustom1Id
				),
				agencyInstructionCustomDescription1: getInstructionDesignationFromId(
					clientOption?.agencyInstructionCustom1Id
				),
				agencyInstructionCustomTempoId2: getTempoIdFromInstructionList(
					clientOption?.agencyInstructionCustom2Id
				),
				agencyInstructionCustomDescription2: getInstructionDesignationFromId(
					clientOption?.agencyInstructionCustom2Id
				),
				agencyInstructionCustomTempoId3: getTempoIdFromInstructionList(
					clientOption?.agencyInstructionCustom3Id
				),
				agencyInstructionCustomDescription3: getInstructionDesignationFromId(
					clientOption?.agencyInstructionCustom3Id
				),
				agencyInstructionSalaryTempoId1: getTempoIdFromInstructionList(
					clientOption?.agencyInstructionSalary1Id
				),
				agencyInstructionSalaryDescription1: getInstructionDesignationFromId(
					clientOption?.agencyInstructionSalary1Id
				),
				agencyInstructionSalaryTempoId2: getTempoIdFromInstructionList(
					clientOption?.agencyInstructionSalary2Id
				),
				agencyInstructionSalaryDescription2: getInstructionDesignationFromId(
					clientOption?.agencyInstructionSalary2Id
				),
				agencyInstructionReferenceSalaryTempoId1: getTempoIdFromInstructionList(
					clientOption?.agencyInstructionReferenceSalary1Id
				),
				agencyInstructionReferenceSalaryDescription1: getInstructionDesignationFromId(
					clientOption?.agencyInstructionReferenceSalary1Id
				),
				agencyInstructionReferenceSalaryTempoId2: getTempoIdFromInstructionList(
					clientOption?.agencyInstructionReferenceSalary2Id
				),
				agencyInstructionReferenceSalaryDescription2: getInstructionDesignationFromId(
					clientOption?.agencyInstructionReferenceSalary2Id
				),
				agencyInstructionBillingTempoId1: getTempoIdFromInstructionList(
					clientOption?.agencyInstructionBilling1Id
				),
				agencyInstructionBillingDescription1: getInstructionDesignationFromId(
					clientOption?.agencyInstructionBilling1Id
				),
				agencyInstructionBillingTempoId2: getTempoIdFromInstructionList(
					clientOption?.agencyInstructionBilling2Id
				),
				agencyInstructionBillingDescription2: getInstructionDesignationFromId(
					clientOption?.agencyInstructionBilling2Id
				),
			}));
		}
	}, [instructionsList, isClientEdited, clientOption, commandFormMode, setValues]);

	useEffect(() => {
		if (isInDuplicationMode) {
			const findDescriptionByTempoId = (tempoId: string) =>
				instructionsList?.data?.find(({ tempoId: id }) => id === tempoId)?.designation || null;

			setValues((prevValues) => ({
				...prevValues,
				agencyInstructionCustomDescription1: findDescriptionByTempoId(
					prevValues.agencyInstructionCustomTempoId1
				),
				agencyInstructionCustomDescription2: findDescriptionByTempoId(
					prevValues.agencyInstructionCustomTempoId2
				),
				agencyInstructionCustomDescription3: findDescriptionByTempoId(
					prevValues.agencyInstructionCustomTempoId3
				),
				agencyInstructionSalaryDescription1: findDescriptionByTempoId(
					prevValues.agencyInstructionSalaryTempoId1
				),
				agencyInstructionSalaryDescription2: findDescriptionByTempoId(
					prevValues.agencyInstructionSalaryTempoId2
				),
				agencyInstructionReferenceSalaryDescription1: findDescriptionByTempoId(
					prevValues.agencyInstructionReferenceSalaryTempoId1
				),
				agencyInstructionReferenceSalaryDescription2: findDescriptionByTempoId(
					prevValues.agencyInstructionReferenceSalaryTempoId2
				),
				agencyInstructionBillingDescription1: findDescriptionByTempoId(
					prevValues.agencyInstructionBillingTempoId1
				),
				agencyInstructionBillingDescription2: findDescriptionByTempoId(
					prevValues.agencyInstructionBillingTempoId2
				),
			}));
		}
	}, [isInDuplicationMode, instructionsList, setValues]);

	const handleOrderInstructionChange = (instruction, fieldId, fieldDescription) => {
		if (instruction === null) {
			setValues({
				...values,
				[fieldId]: null,
				[fieldDescription]: null,
			});
			return;
		}

		setValues({
			...values,
			[fieldId]: instruction.tempoId,
			[fieldDescription]: instruction.designation,
		});
	};
	const formatedOption = (instruction) => (
		<div className={style.labelDetails}>
			<span className={style.tempoId}>{instruction.tempoId}</span>
			<span className={style.instructionDetail}> {parseTextWithLineBreaks(instruction.designation)}</span>
		</div>
	);

	const formatOptionLabel = useCallback((option, { context }) => {
		if (context === "menu") {
			return formatedOption(option);
		}
		if (context === "value") {
			return option.tempoId;
		}
		return option.designation;
	}, []);

	const renderSelectInstructions = (name, value, handleChange, placeholder = "-") => (
		<SelectInstructions
			name={name}
			options={instructionsList?.data || []}
			value={instructionsList?.data?.find(({ tempoId }) => tempoId === value) || []}
			variant={"default"}
			placeholder={placeholder}
			isClearable
			getOptionValue={(option) => option.id}
			getOptionLabel={(option) => option.tempoId}
			formatOptionLabel={formatOptionLabel}
			onChange={handleChange}
			noOptionsMessage={() => t("commands.instructionForm.noOptionMessage")}
			filterOption={(option, inputValue) => {
				return option.label.toString().toLowerCase().startsWith(inputValue.toLowerCase());
			}}
		/>
	);

	if (!isOrderFetched && !isNewCommand && !isinstructionListFetched) {
		return (
			<div className={style.skeleton}>
				<div className={style.skeletonFirstColumn}>
					<div className={style.skeletonElement}>
						<Skeleton containerClassName={style.boxHeader} height={20} borderRadius={25} />
						<Skeleton height={100} borderRadius={25} />
					</div>
					<div className={style.skeletonElement}>
						<Skeleton containerClassName={style.boxHeader} height={20} borderRadius={25} />
						<Skeleton height={100} borderRadius={25} />
					</div>
				</div>
				<div className={style.skeletonSecondColumn}>
					<div className={style.skeletonElement}>
						<Skeleton containerClassName={style.boxHeader} height={20} borderRadius={25} />
						<Skeleton height={100} borderRadius={25} />
					</div>
					<div className={style.skeletonElement}>
						<Skeleton containerClassName={style.boxHeader} height={20} borderRadius={25} />
						<Skeleton height={100} borderRadius={25} />
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className={style.wrapper}>
			<div className={style.firstColumn}>
				<div className={cn([style.box])}>
					<div className={style.header}>
						<Title
							tag="h3"
							fontWeight="normal"
							size="paragraph01"
							lineHeight="s"
							className={style.subTitle}
						>
							{t("commands.instructionForm.specialInstructions")}
						</Title>
						{renderSelectInstructions(
							"agencyInstructionCustomTempoId1",
							agencyInstructionCustomTempoId1,
							(instruction) => {
								handleOrderInstructionChange(
									instruction,
									"agencyInstructionCustomTempoId1",
									"agencyInstructionCustomDescription1"
								);
							}
						)}
						{renderSelectInstructions(
							"agencyInstructionCustomTempoId2",
							agencyInstructionCustomTempoId2,
							(instruction) =>
								handleOrderInstructionChange(
									instruction,
									"agencyInstructionCustomTempoId2",
									"agencyInstructionCustomDescription2"
								)
						)}
						{renderSelectInstructions(
							"agencyInstructionCustomTempoId3",
							agencyInstructionCustomTempoId3,
							(instruction) =>
								handleOrderInstructionChange(
									instruction,
									"agencyInstructionCustomTempoId3",
									"agencyInstructionCustomDescription3"
								)
						)}
					</div>
					<div className={style.textarea} placeholder={t("commands.notes")}>
						<p className={style.instruction}>{agencyInstructionCustomDescription1}</p>
						<p className={style.instruction}>{agencyInstructionCustomDescription2}</p>
						<p className={style.instruction}>{agencyInstructionCustomDescription3}</p>
					</div>
				</div>
				<div className={style.box}>
					<div className={style.header}>
						<Title
							tag="h3"
							fontWeight="normal"
							size="paragraph01"
							lineHeight="s"
							className={style.subTitle}
						>
							{t("commands.instructionForm.remuneration")}
						</Title>
						{renderSelectInstructions(
							"agencyInstructionSalaryTempoId1",
							agencyInstructionSalaryTempoId1,
							(instruction) =>
								handleOrderInstructionChange(
									instruction,
									"agencyInstructionSalaryTempoId1",
									"agencyInstructionSalaryDescription1"
								)
						)}
						{renderSelectInstructions(
							"agencyInstructionSalaryTempoId2",
							agencyInstructionSalaryTempoId2,
							(instruction) =>
								handleOrderInstructionChange(
									instruction,
									"agencyInstructionSalaryTempoId2",
									"agencyInstructionSalaryDescription2"
								)
						)}
					</div>
					<div
						className={style.textarea}
						name="qualificationNote"
						label={null}
						disabled
						placeholder={t("commands.notes")}
					>
						<p className={style.instruction}>{agencyInstructionSalaryDescription1}</p>
						<p className={style.instruction}>{agencyInstructionSalaryDescription2}</p>
					</div>
				</div>
			</div>
			<div className={style.secondColumn}>
				<div className={cn([style.box])}>
					<div className={style.header}>
						<Title
							tag="h3"
							fontWeight="normal"
							size="paragraph01"
							lineHeight="s"
							className={style.subTitle}
						>
							{t("commands.instructionForm.referenceSalary")}
						</Title>
						{renderSelectInstructions(
							"agencyInstructionReferenceSalaryTempoId1",
							agencyInstructionReferenceSalaryTempoId1,
							(instruction) =>
								handleOrderInstructionChange(
									instruction,
									"agencyInstructionReferenceSalaryTempoId1",
									"agencyInstructionReferenceSalaryDescription1"
								)
						)}
						{renderSelectInstructions(
							"agencyInstructionReferenceSalaryTempoId2",
							agencyInstructionReferenceSalaryTempoId2,
							(instruction) =>
								handleOrderInstructionChange(
									instruction,
									"agencyInstructionReferenceSalaryTempoId2",
									"agencyInstructionReferenceSalaryDescription2"
								)
						)}
					</div>
					<div
						className={style.textarea}
						name="qualificationNote"
						label={null}
						disabled
						placeholder={t("commands.notes")}
					>
						<p className={style.instruction}>{agencyInstructionReferenceSalaryDescription1}</p>
						<p className={style.instruction}>{agencyInstructionReferenceSalaryDescription2}</p>
					</div>
				</div>
				<div className={cn([style.box])}>
					<div className={style.header}>
						<Title
							tag="h3"
							fontWeight="normal"
							size="paragraph01"
							lineHeight="s"
							className={style.subTitle}
						>
							{t("commands.instructionForm.billing")}
						</Title>
						{renderSelectInstructions(
							"agencyInstructionBillingTempoId1",
							agencyInstructionBillingTempoId1,
							(instruction) =>
								handleOrderInstructionChange(
									instruction,
									"agencyInstructionBillingTempoId1",
									"agencyInstructionBillingDescription1"
								)
						)}
						{renderSelectInstructions(
							"agencyInstructionBillingTempoId2",
							agencyInstructionBillingTempoId2,
							(instruction) =>
								handleOrderInstructionChange(
									instruction,
									"agencyInstructionBillingTempoId2",
									"agencyInstructionBillingDescription2"
								)
						)}
					</div>
					<div
						className={style.textarea}
						name="qualificationNote"
						label={null}
						disabled
						placeholder={t("commands.notes")}
					>
						<p className={style.instruction}>{agencyInstructionBillingDescription1}</p>
						<p className={style.instruction}>{agencyInstructionBillingDescription2}</p>
					</div>
				</div>
			</div>
		</div>
	);
};

CommandRulesFormBlock.propTypes = {
	isOrderFetched: PropTypes.bool.isRequired,
};

export default CommandRulesFormBlock;
