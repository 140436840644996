// @ts-nocheck
import client from "./client";

/**
 * @description Get myTemp messages count
 * @param {Object} params
 * @returns {Promise<AxiosResponse<any>>}
 */
const postUnreadMessagesCount = (params) => client.post("/api-client-v2/mytemp-messaging/count", params);

const endpoints = { postUnreadMessagesCount };
export default endpoints;
