import { upperFirst } from "lodash";

export const capitalizeOnBlur = (
	e: React.FocusEvent<HTMLInputElement>,
	setFieldValue: (name: string, value: string) => void,
	handleBlur: (e: React.FocusEvent<HTMLInputElement>) => void
) => {
	const { name, value } = e?.target;
	if (value) setFieldValue(name, upperFirst(value));
	handleBlur(e);
};
