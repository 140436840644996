// @ts-nocheck
import { useState } from "react";

import { has } from "lodash";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { useTitle } from "react-use";

// Services
import agencies from "../../services/agencies";
import unifiedTempWorkerSuggestion from "../../services/unifiedTemporaryWorkerSuggestion";

// Store
import ConfirmationModalContextProvider, { useConfirmationModalContext } from "../../store/ModalConfirmationContext";
import useAppContext from "../../store/useAppContext";

// Constants
import { PAGINATION_OFFSET_OPTIONS } from "../../constants";
import { formatDate } from "../../utils/formatDate";
import { definePageOffset } from "../../utils/paginationUtils";
// Utils
import {
	InputSearch,
	PaginationV2,
	Picto,
	SelectPopover,
	Text,
	Title,
	ToggleSwitch,
	Tooltip,
} from "@zolteam/react-ras-library";
// Atoms
import { Tag } from "src/components/atoms";
// Molecules
import {
	ColumnListing,
	MaintenanceHeader,
	PaginationSearch,
	TableInfo,
	TableLockedSheet,
	TitleCount,
} from "src/components/molecules";
import { TableListing } from "src/components/organisms";
// Organisms
import { ListMaxWidth } from "src/components/organisms";
// Templates
import { AgenciesFilter, TableFilters, WithSideMenu, WithTopBarre } from "src/components/templates";

// Utils

const Suspicious = () => {
	const [{ suspiciousFilter }, appDispatch] = useAppContext();

	const modalContext = useConfirmationModalContext();
	const { t } = useTranslation();
	const history = useHistory();

	useTitle(t("suspicious.title"));

	// eslint-disable-next-line no-unused-vars
	const [searchTimeout, setSearchTimeout] = useState(null);
	const [isFirstLoading, setIsFirstLoading] = useState(true);

	// Modal locked utilities
	const handleSheetPage = (id) => history.push(`/suspicions/${id}`);
	const handleIsLockedModal = async (id, lockedByFirstName, lockedByLastName) => {
		const confirm = await modalContext.showConfirmation("isLockedModal", {
			lockedByFirstName,
			lockedByLastName,
		});
		if (confirm) handleSheetPage(id);
	};

	// API Calls
	const postUnifiedTempWorkerSuggestionsSearch = useQuery(
		["postUnifiedTempWorkerSuggestionsSearch", suspiciousFilter],
		() => unifiedTempWorkerSuggestion.postUnifiedTempWorkerSuggestionsSearch(suspiciousFilter),
		{
			onSuccess: ({ data: { items } }) => {
				if (suspiciousFilter.page > 1 && items?.length <= 0) {
					appDispatch({
						type: "SET_SUSPICIOUS_TABLE_FILTER",
						payload: {
							page: 1,
						},
					});
				}
				setIsFirstLoading(false);
			},
		}
	);
	const getUnifiedTempWorkerSuggestionsLock = useMutation(
		["getUnifiedTempWorkerSuggestionsLock"],
		({ id }) => unifiedTempWorkerSuggestion.getUnifiedTempWorkerSuggestionsLock(id),
		{
			enabled: false,
			onSuccess: ({ data: { isLocked, lockedByFirstName, lockedByLastName } }, { id }) => {
				if (isLocked) {
					handleIsLockedModal(id, lockedByFirstName, lockedByLastName);
				} else {
					handleSheetPage(id);
				}
			},
		}
	);
	const getAgencies = useQuery("getAgencies", () => agencies.getAgencies());

	// Destructuring useQuery and useMutation
	const {
		isError: isErrorPostUnifiedTempWorkerSuggestionsSearch,
		isFetching: isFetchingPostUnifiedTempWorkerSuggestionsSearch,
		isLoading: isLoadingPostUnifiedTempWorkerSuggestionsSearch,
	} = postUnifiedTempWorkerSuggestionsSearch;

	/**
	 * handle search for pagination & filter
	 * @param {Object} sort
	 */
	const handleSearch = (sort) => {
		switch (true) {
			case has(sort, "searchText"): {
				if (sort?.searchText?.length > 3) {
					if (searchTimeout) {
						clearTimeout(searchTimeout);
					}
					setSearchTimeout(
						setTimeout(
							() =>
								appDispatch({
									type: "SET_SUSPICIOUS_TABLE_FILTER",
									payload: {
										...sort,
									},
								}),
							1000
						)
					);
				}
				if (sort?.searchText?.length === 3 || sort?.searchText?.length === 0) {
					appDispatch({
						type: "SET_SUSPICIOUS_TABLE_FILTER",
						payload: {
							...sort,
						},
					});
				}
				break;
			}
			case has(sort, "applyFilterToLastAgency"): {
				appDispatch({
					type: "SET_SUSPICIOUS_TABLE_FILTER",
					payload: {
						...sort,
						applyFilterToLastAgency: !!(suspiciousFilter?.agencies?.length && sort.applyFilterToLastAgency),
					},
				});
				break;
			}
			case has(sort, "agencies"): {
				let lastAgencySort = { ...sort };
				if (lastAgencySort?.agencies?.length <= 0) {
					lastAgencySort = {
						...lastAgencySort,
						applyFilterToLastAgency: false,
					};
				}
				if (suspiciousFilter?.agencies?.length <= 0 && sort?.agencies?.length) {
					lastAgencySort = { ...lastAgencySort, applyFilterToLastAgency: true };
				}
				appDispatch({
					type: "SET_SUSPICIOUS_TABLE_FILTER",
					payload: {
						...lastAgencySort,
					},
				});
				break;
			}
			default: {
				appDispatch({
					type: "SET_SUSPICIOUS_TABLE_FILTER",
					payload: {
						...sort,
					},
				});
				break;
			}
		}
	};

	/**
	 * Check if suspicious sheet is locked & redirect
	 * @param {id}
	 */
	const handleDoubleClick = ({ id }) => {
		getUnifiedTempWorkerSuggestionsLock.mutate({ id });
	};

	const isListLoading = () =>
		(isFirstLoading && !isErrorPostUnifiedTempWorkerSuggestionsSearch) ||
		isFetchingPostUnifiedTempWorkerSuggestionsSearch ||
		isLoadingPostUnifiedTempWorkerSuggestionsSearch;

	return (
		<WithSideMenu>
			<WithTopBarre
				quickEntryContent={<div>test</div>}
				title={
					<TitleCount count={postUnifiedTempWorkerSuggestionsSearch?.data?.data?.total}>
						<Title tag="h1" size="heading01" lineHeight="s">
							{t("suspicious.title")}
						</Title>
						{postUnifiedTempWorkerSuggestionsSearch?.data?.data?.total ? (
							<Tag size="m" radius="s" color="primary" className="ml-10">
								{`${postUnifiedTempWorkerSuggestionsSearch?.data?.data?.total} ${t("suspicious.results")}`}
							</Tag>
						) : null}
					</TitleCount>
				}
				containerClassname="container"
			>
				<TableFilters
					leftContent={
						<div className="d-flex f-row align-center f-wrap">
							<AgenciesFilter
								options={getAgencies?.data?.data || []}
								selectedOptions={suspiciousFilter?.agencies}
								handleFilter={(agenciesSelected) =>
									handleSearch({
										agencies: agenciesSelected.map((agency) => agency.id),
									})
								}
								inputSize="s"
							/>
							{suspiciousFilter?.agencies?.length ? (
								<div className="d-flex f-row align-center align-self-end pl-s mb-s mt-s">
									<ToggleSwitch
										id="lastAgencyFilter"
										onChange={() => {
											handleSearch({
												applyFilterToLastAgency: !suspiciousFilter?.applyFilterToLastAgency,
											});
										}}
										toggled={suspiciousFilter?.applyFilterToLastAgency}
										name="lastAgencyFilter"
									>
										<Text tag="span" size="paragraph02" className="toggleText">
											{t("suspicious.toggleFilterAgency")}
										</Text>
									</ToggleSwitch>
								</div>
							) : null}
						</div>
					}
					rightContent={
						<InputSearch
							value={suspiciousFilter?.searchText}
							type="text"
							name="suspiciousSearch"
							placeholder={t("global.filters.searchText")}
							onClick={(value) => handleSearch({ searchText: value })}
							removeValue={(value) => handleSearch({ searchText: value })}
							size="s"
							className="mr-m"
						/>
					}
				/>
				{+process.env.REACT_APP_IGS_IN_MAINTENANCE === 1 ? (
					<MaintenanceHeader title="maintenance.igs.title" description="maintenance.igs.description" />
				) : (
					<TableListing
						loading={isListLoading()}
						error={isErrorPostUnifiedTempWorkerSuggestionsSearch}
						data={postUnifiedTempWorkerSuggestionsSearch?.data?.data?.items}
						handleSearch={handleSearch}
						initialColumnSort={{
							field: suspiciousFilter?.sorting,
							orderBy: suspiciousFilter?.orderBy,
						}}
						canBeChecked={false}
						onDoubleClick={handleDoubleClick}
						footer={
							postUnifiedTempWorkerSuggestionsSearch?.data?.data?.total ? (
								<>
									<PaginationV2
										previousLabel={<Picto icon="chevronLeft" />}
										nextLabel={<Picto icon="chevronRight" />}
										breakLine={
											<PaginationSearch
												onInputChange={handleSearch}
												max={Math.round(
													(postUnifiedTempWorkerSuggestionsSearch?.data?.data?.total ?? 0) /
														suspiciousFilter.limit
												)}
											/>
										}
										currentPage={suspiciousFilter.page}
										totalCount={postUnifiedTempWorkerSuggestionsSearch?.data?.data?.total}
										pageSize={suspiciousFilter.limit}
										siblingCount={2}
										onPageChange={(activePage) => handleSearch({ page: activePage })}
									/>
									<Text tag="span" size="paragraph02" color="grey">
										{t("global.pagination.resultsPagination", {
											resultStart:
												suspiciousFilter.limit * suspiciousFilter.page -
												suspiciousFilter.limit +
												1,
											resultEnd:
												suspiciousFilter.limit * suspiciousFilter.page <=
												postUnifiedTempWorkerSuggestionsSearch?.data?.data?.total
													? suspiciousFilter.limit * suspiciousFilter.page
													: postUnifiedTempWorkerSuggestionsSearch?.data?.data?.total,
											resultTotal: postUnifiedTempWorkerSuggestionsSearch?.data?.data?.total,
										})}
									</Text>
									<SelectPopover
										name="limit"
										options={PAGINATION_OFFSET_OPTIONS}
										onChange={(value) =>
											handleSearch({
												limit: value,
												page: definePageOffset(
													value,
													suspiciousFilter.limit,
													suspiciousFilter.page
												),
											})
										}
										value={
											suspiciousFilter.limit
												? PAGINATION_OFFSET_OPTIONS.find(
														(option) => option.value === suspiciousFilter.limit
													)
												: null
										}
									/>
								</>
							) : null
						}
					>
						<ColumnListing
							id="unifiedTemporaryWorker.uniqueId"
							name={
								<Text tag="span" size="paragraph02" color="grey">
									{t("suspicious.uniqueId")}
								</Text>
							}
							field={(elem) => ({
								unifiedTempWorkerUniqueId: elem.unifiedTempWorkerUniqueId,
							})}
							component={({ data: { unifiedTempWorkerUniqueId } }) => (
								<Text tag="span" size="paragraph02" fontWeight="bold">
									{unifiedTempWorkerUniqueId}
								</Text>
							)}
							isSortable
							tdClassName="min-column-width"
						/>
						<ColumnListing
							id="unifiedTemporaryWorker.lockedByLastName"
							name={
								<Text tag="span" size="paragraph02" color="grey">
									{t("suspicious.tempWorker")}
								</Text>
							}
							field={(elem) => ({
								temporaryWorkerFirstName: elem.temporaryWorkerFirstName,
								temporaryWorkerLastName: elem.temporaryWorkerLastName,
								userCount: elem.userCount,
							})}
							component={({ data: { temporaryWorkerFirstName, temporaryWorkerLastName, userCount } }) => (
								<>
									<Text tag="span" size="paragraph02">
										{`${temporaryWorkerFirstName} ${temporaryWorkerLastName}`}
									</Text>
									<Tooltip
										placement="right"
										animation={false}
										arrow={false}
										content={
											<Text tag="span" size="paragraph02" color="grey">
												{t("suspicious.nbUniqueSheet")}
											</Text>
										}
									>
										<div>
											<Tag className="ml-10" size="xs" color="grey" radius="l">
												{userCount}
											</Tag>
										</div>
									</Tooltip>
								</>
							)}
							tdClassName="min-column-width"
							isSortable
						/>
						<ColumnListing
							id="agencies"
							name={
								<Text tag="span" size="paragraph02" color="grey">
									{t("suspicious.agencies")}
								</Text>
							}
							field={(elem) => ({ agencies: elem.agencies })}
							component={({ data: { agencies: agenciesList } }) => (
								<ListMaxWidth
									array={agenciesList}
									keyListElem="id"
									component={({ data: agency, index }) => (
										<Text tag="span" size="paragraph02">
											{index === 0 ? `${agency.name}` : `, ${agency.name}`}
										</Text>
									)}
									margeError={5}
								/>
							)}
							tdClassName="min-column-width"
						/>
						<ColumnListing
							id="lastActivityDate"
							name={
								<Text tag="span" size="paragraph02" color="grey">
									{t("suspicious.lastActivity")}
								</Text>
							}
							field={(elem) => ({
								lastActivityDate: elem.lastActivityDate,
								lastActivityType: elem.lastActivityType,
							})}
							component={({ data: { lastActivityDate, lastActivityType } }) => (
								<TableInfo
									info={lastActivityType ? formatDate(lastActivityDate) : "-"}
									subInfo={
										lastActivityType
											? t(`suspiciousSheet.lastActivityType.${lastActivityType}`)
											: ""
									}
								/>
							)}
							isSortable
						/>
						<ColumnListing
							id="lastAgencyName"
							name={
								<Text tag="span" size="paragraph02" color="grey">
									{t("suspicious.lastAgency")}
								</Text>
							}
							field={(elem) => ({
								lastAgencyName: elem.lastAgencyName,
							})}
							component={({ data }) => (
								<Text tag="span" size="paragraph02">
									{data?.lastAgencyName || "-"}
								</Text>
							)}
							isSortable={false}
						/>
						<ColumnListing
							id="unifiedTemporaryWorker.createdAt"
							name={
								<Text tag="span" size="paragraph02" color="grey">
									{t("suspicious.createdAt")}
								</Text>
							}
							field={(elem) => ({
								createdAt: elem.createdAt,
								isLocked: elem.isLocked,
								firstName: elem.lockedByFirstName,
								lastName: elem.lockedByLastName,
							})}
							component={({ data: { createdAt, isLocked, firstName, lastName } }) => (
								<TableLockedSheet
									icon="users"
									isLocked={isLocked}
									msg={t("suspiciousSheet.lockedSheetInformation", {
										firstName,
										lastName,
									})}
								>
									<Text tag="span" size="paragraph02" className="ml-10">
										{formatDate(createdAt)}
									</Text>
								</TableLockedSheet>
							)}
							isSortable
							tdClassName="min-column-width"
						/>
					</TableListing>
				)}
			</WithTopBarre>
		</WithSideMenu>
	);
};

export const SuspiciousWrapper = () => (
	<ConfirmationModalContextProvider>
		<Suspicious />
	</ConfirmationModalContextProvider>
);

export default SuspiciousWrapper;
