import React from "react";
// Style
import { FC, ReactNode } from "react";

// Utils
import cn from "src/utils/cn";

import style from "./PopoverMenuItem.module.css";

interface IPopoverMenuItemProps {
	children?: ReactNode;
	click?: (e: React.MouseEvent<HTMLButtonElement>) => void;
	className?: string;
	disabled?: boolean;
}

export const PopoverMenuItem: FC<IPopoverMenuItemProps> = ({
	click = () => {},
	children,
	className,
	disabled = false,
}) => {
	return (
		<button
			type="button"
			className={cn([style.button, className, disabled ? "cursor-not-allowed" : null])}
			onClick={click}
			disabled={disabled}
		>
			{children}
		</button>
	);
};
