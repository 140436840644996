import { FC } from "react";

import { Formik, Form } from "formik";
import { find } from "lodash";
import { useTranslation } from "react-i18next";

//Types
import ProfilesAvailableType from "../../types/ProfilesAvailableType";
import SelectStatusProfileType from "../../types/SelectStatusProfileType";
//Hooks
import useModalContentAddProfile from "./useModalContentAddProfile";
import { Button, Select, SelectAsync, Title, InfoMessage } from "@zolteam/react-ras-library";

import cn from "src/utils/cn";

// Misc
import { PROFILES_STATUS, STATUS_OPTIONS } from "src/constants";

// Style
import style from "../Modals.module.css";

export interface ModalContentAddProfilePropsType {
	onAddClick: (params: { selectedStatus: string; selectedProfiles: number[] }) => void;
	onCancelClick: () => void;
	defaultSelectedStatus?: string;
}

const ModalContentAddProfile: FC<ModalContentAddProfilePropsType> = ({
	onAddClick,
	onCancelClick,
	defaultSelectedStatus = null,
}) => {
	// States
	const { t } = useTranslation();
	const {
		selectedStatus,
		setSelectedStatus,
		selectedProfiles,
		profileInputValue,
		setProfileInputValue,
		orderProfilesOptions,
		handleProfileSelect,
		isProfileDisabled,
		profileOptionLabel,
		renderSelectedProfile,
		handleAddClick,
	} = useModalContentAddProfile(defaultSelectedStatus, onAddClick);

	return (
		<div className={cn([style.content, style.fixedWidth])}>
			<Title tag="h1" size="heading01" lineHeight="l" className={cn([style.title])}>
				{t("commands.profiles.modalsContent.addProfile.title")}
			</Title>
			<div className={style.addedProfilesBox}>
				{selectedProfiles.map((profil) => renderSelectedProfile(profil))}
			</div>
			<Formik
				onSubmit={() => {}}
				enableReinitialize
				initialValues={{ status: defaultSelectedStatus || null, profile: null }}
			>
				{({ values, setFieldValue }) => (
					<Form className={style.addProfileForm}>
						<SelectAsync
							className={style.inputs}
							isSearchable
							searchIcon
							promiseOptions={orderProfilesOptions}
							onChange={(item: ProfilesAvailableType) => {
								handleProfileSelect(item);
							}}
							inputPersistOnOptionSelect
							name="profile"
							label={t("commands.profiles.modalsContent.addProfile.searchLabel")}
							getOptionLabel={(option: ProfilesAvailableType) => profileOptionLabel(option)}
							getOptionValue={(option: ProfilesAvailableType) => option.unifiedTempWorkerUniqueId}
							isOptionDisabled={(option: ProfilesAvailableType) => isProfileDisabled(option)}
							value=""
							handleInputChange={(value: string, { action }: { action: string }) => {
								if (action === "set-value") {
									setProfileInputValue(value);
									return value;
								}
								if (action !== "menu-close") setProfileInputValue(value);
								return value;
							}}
							inputValue={profileInputValue}
							noOptionsMessage={() => t("commands.profiles.modalsContent.addProfile.emptySearch")}
							loadingMessage={() => t("commands.profiles.modalsContent.addProfile.loadingSearch")}
						/>
						<Select
							className={style.inputs}
							options={STATUS_OPTIONS}
							onChange={(item: SelectStatusProfileType) => {
								setSelectedStatus(item.value);
								setFieldValue("status", item.value);
							}}
							getOptionLabel={(item: SelectStatusProfileType) => t(item.label)}
							getOptionValue={(item: SelectStatusProfileType) => item.value}
							name="status"
							value={find(STATUS_OPTIONS, { value: values.status })}
							label={t("commands.profiles.modalsContent.addProfile.selectLabel")}
						/>
						<div className={style.messageBox}>
							{selectedStatus === PROFILES_STATUS.VALIDATED ? (
								<InfoMessage className={style.message} withIcon>
									{t("commands.profiles.modalsContent.addProfile.validatedStatusMessage")}
								</InfoMessage>
							) : null}
							{selectedStatus === PROFILES_STATUS.OFFER_ACCEPTED ||
							selectedStatus === PROFILES_STATUS.PROPOSED ? (
								<InfoMessage className={style.message} withIcon>
									{t("commands.profiles.modalsContent.addProfile.acceptedStatusMessage")}
								</InfoMessage>
							) : null}
						</div>
					</Form>
				)}
			</Formik>

			<hr className={style.separator} />
			<div className={style.buttonsBlock}>
				<Button
					className={style.validationButtonRight}
					type="button"
					color="primary"
					onClick={onCancelClick}
					outline
				>
					{t("commands.profiles.modalsContent.addProfile.cancelAction")}
				</Button>
				<Button
					className={style.validationButtonRight}
					type="button"
					color="primary"
					onClick={() => handleAddClick()}
				>
					{t("commands.profiles.modalsContent.addProfile.addAction")}
				</Button>
			</div>
		</div>
	);
};

export default ModalContentAddProfile;
