import moment, { Moment } from "moment";
import "moment/locale/fr";
import { DayPickerRangeController, FocusedInputShape } from "react-dates";
// Style
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

// Utils
import cn from "../../../utils/cn";
// Components
import { Picto } from "@zolteam/react-ras-library";

import { formatMonthText } from "src/utils/dates";

import style from "./DateRangePicker.module.css";

interface IDateRangePickerProps {
	focusedInput: string | null;
	setFocusedInput: (input: string) => void;
	onDatesChange: (startDate: Moment | string | null, endDate: Moment | string | null) => void;
	dateFrom?: Moment | Date | null;
	dateTo?: Moment | Date | null;
	className?: string;
	isVisible?: boolean;
	isDayBlocked?: (day: moment.Moment) => boolean;
	setIsVisible?: (visible: boolean) => void;
	keepOpenOnDateSelect?: boolean;
}

export const DateRangePicker: React.FC<IDateRangePickerProps> = ({
	dateFrom = null,
	dateTo = null,
	focusedInput,
	setFocusedInput,
	onDatesChange,
	className = "",
	isVisible = true,
	isDayBlocked = undefined,
	setIsVisible = undefined,
	keepOpenOnDateSelect,
}) => {
	/**
	 * Manage when user click outside calendar
	 * @param
	 * @returns
	 */
	const handleOnClickOutside = () => {
		setIsVisible(false);
		setFocusedInput(null);
	};

	return isVisible ? (
		<div data-testid="period-date-range-picker" className={className}>
			<DayPickerRangeController
				keepOpenOnDateSelect={keepOpenOnDateSelect}
				numberOfMonths={2}
				startDate={moment(dateFrom)}
				endDate={moment(dateTo)}
				onFocusChange={setFocusedInput}
				onDatesChange={({ startDate, endDate }) => onDatesChange(startDate, endDate)}
				onOutsideClick={handleOnClickOutside}
				focusedInput={focusedInput as FocusedInputShape}
				initialVisibleMonth={() => moment(dateFrom)}
				isDayBlocked={isDayBlocked}
				monthFormat="MMMM Y"
				minimumNights={0}
				renderMonthElement={({ month }) => formatMonthText(month)}
				renderKeyboardShortcutsButton={() => null}
				renderDayContents={(dayMoment) => <div className={style.dayWrapper}>{dayMoment.format("D")}</div>}
				navPrev={
					<div className={cn([style.chevronWrapper, style.chevronLeftWrapper])}>
						<Picto icon="chevronLeft" className={style.picto} />
					</div>
				}
				navNext={
					<div className={cn([style.chevronWrapper, style.chevronRightWrapper])}>
						<Picto icon="chevronRight" className={style.picto} />
					</div>
				}
			/>
		</div>
	) : null;
};
