// Style
import { HTMLInputTypeAttribute, KeyboardEvent, RefCallback } from "react";

import { ErrorMessage, useField } from "formik";

import cn from "../../../utils/cn";
import { TooltipMessage } from "../../atoms/index";
// Components
import { InfoMessage, Input } from "@zolteam/react-ras-library";

import style from "./Field.module.css";

interface IFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
	type: HTMLInputTypeAttribute;
	name: string;
	readOnly?: boolean;
	label?: string;
	className?: string;
	classNameContainer?: string;
	pictoName?: string;
	children?: React.ReactNode;
	onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
	onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
	onPictoClick?: () => void;
	isFocused?: boolean;
	disabled?: boolean;
	info?: string;
	step?: number;
	showErrorMessage?: boolean;
	customErrorDisplay?: boolean;
	innerRef?: RefCallback<HTMLInputElement>;
	maxLength?: number;
	required?: boolean;
	allowedCharacters?: RegExp;
}

export const Field: React.FC<IFieldProps> = ({
	type,
	name,
	label = "",
	readOnly = false,
	className = "",
	classNameContainer = "",
	children = null,
	pictoName = null,
	onBlur = (e: React.FocusEvent<HTMLInputElement>) => {},
	onFocus = (e: React.FocusEvent<HTMLInputElement>) => {},
	onPictoClick = undefined,
	isFocused = false,
	disabled = false,
	info = null,
	step = null,
	showErrorMessage = true,
	customErrorDisplay = false,
	maxLength = null,
	required = false,
	allowedCharacters = null,
	...props
}) => {
	const [, { error, touched }] = useField(name);

	// Function to filter out unauthorized characters
	const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
		if (!allowedCharacters.test(e.key)) {
			e.preventDefault(); // Empêche l'entrée de caractères non autorisés
		}
	};

	const isErrorDisplayed = error && touched;
	return (
		<div className={cn([style.inputContainer, classNameContainer])}>
			<Input
				type={type}
				name={name}
				label={required ? `${label}*` : label}
				className={cn([style.input, className])}
				pictoName={pictoName}
				readOnly={readOnly}
				onBlur={onBlur}
				onFocus={onFocus}
				onPictoClick={onPictoClick}
				isFocused={isFocused}
				disabled={disabled}
				locked={disabled}
				step={step || null}
				maxLength={maxLength}
				required={required}
				onKeyPress={allowedCharacters && handleKeyPress}
				{...props}
			>
				{!isErrorDisplayed && info && !customErrorDisplay && <p className={style.infoMessage}>{info}</p>}
				{showErrorMessage && !customErrorDisplay && (
					<ErrorMessage name={name}>
						{(msg) => (
							<InfoMessage color="error" withIcon>
								{msg}
							</InfoMessage>
						)}
					</ErrorMessage>
				)}
				{!isErrorDisplayed && info && customErrorDisplay && <p className={style.infoMessage}>{info}</p>}
				{showErrorMessage && customErrorDisplay && (
					<ErrorMessage name={name}>
						{(msg) => {
							return (
								<TooltipMessage color="error">
									{Array.isArray(msg) ? msg.map((m) => <div>{m}</div>) : msg}
								</TooltipMessage>
							);
						}}
					</ErrorMessage>
				)}
			</Input>
			{children}
		</div>
	);
};
