import { FC } from "react";

import { TTemporaryWorkerProfile } from "src/types";

import HeaderSummary from "./HeaderSummary";

interface SummaryProps {
	user: TTemporaryWorkerProfile;
}

const Summary: FC<SummaryProps> = ({ user }) => {
	return (
		<div className="flex flex-row h-full gap-4  mr-4 mb-4">
			<div className="flex flex-col flex-[4] gap-4 ">
				<HeaderSummary />
				<div className="layout flex-1">Content</div>
			</div>
			<div className="layout flex-1 ">Activity</div>
		</div>
	);
};

export default Summary;
