// @ts-nocheck
import { useCallback, useEffect, useMemo, useState } from "react";

import { Form, Formik, useFormikContext } from "formik";
import _ from "lodash";
import moment from "moment/moment";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { components } from "react-select";

import { format } from "date-fns";
import localeFr from "date-fns/locale/fr";

import {
	getClientRelation,
	getMissionFollowUp,
	getMissionFollowUpClientQualifications,
	getMissionFollowUpIncidentTypes,
	getMissionFollowUpWorkerContracts,
	getUnifiedTempWorker,
	putMissionFollowUp,
	registerMissionFollowUp,
} from "../../../../../../services/missionFollowUpService";
import { getWorkerAgencies } from "../../../../../../services/unifiedTempWorkerService";
// Services
import { getClientDetails, getClients } from "src/services/commandService";
import { ClientInfo } from "src/services/types/commandServiceTypes";

// Hooks
import { useToastContext } from "../../../../../../store/ToastContext";

// Constants
import { CREATE_MODE, EDIT_MODE } from "../../../../../../constants";
// Utils
import capitalizeFirstCharacter from "../../../../../../utils/capitalizeFirstCharacter";
import cn from "../../../../../../utils/cn";
import { getAtomFromDate } from "../../../../../../utils/formatDate";
import { getAgencyListing } from "../../../../../../utils/matching/formatAgencyListing";
import followUpFormDefaultValues from "./followUpFormDefaultValues";
import followUpFormValidation from "./followUpFormValidation";
// Utils
import { Button, InfoMessage, SelectV2, Textarea, ToggleSwitch } from "@zolteam/react-ras-library";
// Components
import { DateRangeInput, DropdownIndicator, FileUpload, OptionClient } from "src/components/atoms";
import { DateSinglePicker, Field } from "src/components/molecules";
import ProfileMissionFollowUpPeriod from "src/components/molecules/ProfileMissionFollowUpPeriod/ProfileMissionFollowUpPeriod";

import style from "./ProfileMissionsFollowUpForm.module.css";

const FormSection = ({ title, children }) => (
	<div className={style.formSection}>
		<div className={style.formSectionTitle}>{title}</div>
		{children}
	</div>
);
FormSection.propTypes = {
	title: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
};

const FollowUpFormContent = ({ mode }) => {
	const { t } = useTranslation();
	const { profileId, followUpId } = useParams();
	const history = useHistory();
	const [, toastDispatch] = useToastContext();
	const isEditionMode = mode === EDIT_MODE;
	const [hasPressEditButton, setHasPressEditButton] = useState(!isEditionMode);
	const [isSameFile, setIsSameFile] = useState(true);
	const { values, errors, setFieldValue, setFieldTouched, setValues, handleBlur, isValid, touched, initialValues } =
		useFormikContext();
	const [focusedInputName, setFocusedInputName] = useState("");
	const [clients, setClients] = useState([]);
	const [totalClients, setTotalClients] = useState(0);
	const [searchLoading, setSearchLoading] = useState(false);
	const [userTyping, setUserTyping] = useState(false);

	const MAX_CLIENTS = 100;
	const MINIMUM_SEARCH_LENGTH = 3;

	// Fetch list of agencies
	const { data: agencies } = useQuery(["follow-up-agencies", profileId], () => getWorkerAgencies(profileId));

	// Fetch client from an agency
	let timeoutId: ReturnType<typeof setTimeout>;
	const handleInputChange = async (value: string | undefined): Promise<ClientInfo[]> => {
		clearTimeout(timeoutId);
		return new Promise((resolve) => {
			timeoutId = setTimeout(async () => {
				if (value && value.length >= MINIMUM_SEARCH_LENGTH) {
					try {
						setSearchLoading(true);
						setUserTyping(false);
						const response = await getClients(values?.agencyId, value);
						const clients: ClientInfo[] = response.data.items;

						setSearchLoading(false);
						setClients(clients);
						setTotalClients(response.data.total);
						resolve(clients);
					} catch (error) {
						resolve([]);
					}
				} else {
					resolve([]);
				}
			}, 300);
		});
	};

	const { data: clientData, isFetched: isClientFetched } = useQuery<AxiosResponse<ClientInfo[]>, Error>(
		["getClientDetails"],
		() => getClientDetails(values?.clientId),
		{
			enabled: !!values?.clientId && isEditionMode,
			select: ({ data }) => ({
				...data,
				value: data.id,
				label: data.name,
			}),
		}
	);

	useEffect(() => {
		if (clientData && isEditionMode) {
			setClients(() => [clientData]);
		}
	}, [clientData, isEditionMode, setClients]);

	useEffect(() => {
		if (isEditionMode && isClientFetched && clients.length !== 0) {
			setValues({
				...values,
				clientId: clientData?.id,
				clientQualificationId: null,
				contractId: null,
			});
		}
	}, [clients, setValues]);

	const { data: contracts, isFetched: contractsFetched } = useQuery(
		["follow-up-contracts", profileId, values.clientId],
		() => getMissionFollowUpWorkerContracts(profileId, values.clientId),
		{
			enabled: !!values.clientId && values.canEdit,
		}
	);

	const {
		data: qualifications,
		isFetched: qualificationsFetched,
		isFetching: qualificiationsIsFetching,
	} = useQuery(
		["follow-up-qualifications", profileId, values.clientId],
		() => getMissionFollowUpClientQualifications(values.clientId, ""),
		{
			enabled: !!values.clientId && values.canEdit,
		}
	);

	const { data: incidents } = useQuery(["follow-up-incidents"], () => getMissionFollowUpIncidentTypes());

	const { data: unifiedTempWorker } = useQuery(["unified-temp-worker", profileId], () =>
		getUnifiedTempWorker(profileId)
	);

	const { data: clientRelation } = useQuery(
		["client-relation", values?.clientId],
		() => getClientRelation(profileId, values.clientId),
		{ enabled: !!values?.clientId }
	);

	const agenciesOptions = useMemo(() => {
		if (!values?.canEdit && values?.agencyName && values?.agencyId) {
			return [
				{
					label: values.agencyName,
					value: values.agencyId,
				},
			];
		}

		if (!agencies?.data) return [];

		return getAgencyListing(agencies.data, "name").map((agency) => ({
			label: agency.name,
			value: agency.id,
		}));
	}, [agencies]);

	const contractsOptions = useMemo(() => {
		if (!values?.canEdit && values?.contractNumber && values?.contractId && values?.contractStartDate) {
			return [
				{
					clientQualificationId: values?.clientQualificationId,
					clientQualificationName: values?.qualificationName,
					contractNumber: values?.contractNumber,
					endDate: values?.contractEndDate,
					id: values?.contractId,
					label: values?.contractNumber,
					startDate: values?.contractStartDate,
					value: values?.contractId,
				},
			];
		}
		if (!contracts?.data) return [];

		return contracts.data.map((contract) => ({
			...contract,
			label: `${contract.contractNumber}`,
			value: contract.id,
		}));
	}, [contracts]);

	const qualificationsOptions = useMemo(() => {
		if (!values?.canEdit && values?.qualificationName && values?.clientQualificationId) {
			return [
				{
					label: values?.qualificationName,
					value: values?.clientQualificationId,
				},
			];
		}
		if (!qualifications?.data) return [];

		return qualifications.data.map((qualification) => ({
			...qualification,
			label: `${qualification.name}`,
			value: qualification.clientQualificationId,
		}));
	}, [qualifications]);

	const incidentOptions = useMemo(() => {
		if (!incidents?.data) return [];

		return incidents.data.map((incident) => ({
			...incident,
			label: t(`profile.followUp.form.incidentTypeId.types.${incident.labelKey}`),
			value: incident.id,
		}));
	}, [incidents]);

	// In case of create mode, if only one agency is available, select it by default
	const isOnlyOneAgencyAvailable = agenciesOptions.length === 1;
	useEffect(() => {
		if (isOnlyOneAgencyAvailable) {
			setFieldValue("agencyId", agenciesOptions[0].value);
		}
	}, [isOnlyOneAgencyAvailable]);

	const handleFieldBlur = (event) => {
		handleBlur(event.target.name);
	};

	const isDirty = useMemo(() => {
		const {
			startDate: initialValuesStartDate,
			endDate: initialValuesEndDate,
			note: initialValuesNote,
			attachmentName: initialAttachmentName,
		} = initialValues;
		const {
			startDate: valuesStartDate,
			endDate: valuesEndDate,
			note: valuesNote,
			attachment: valuesAttachment,
		} = values;

		return !_.isEqual(
			{
				...initialValues,
				startDate: moment(initialValuesStartDate).format("DD/MM/YY"),
				endDate: moment(initialValuesEndDate).format("DD/MM/YY"),
				note: Number(initialValuesNote),
				attachmentName: initialAttachmentName,
				attachment: null,
			},
			{
				...values,
				startDate: moment(valuesStartDate).format("DD/MM/YY"),
				endDate: moment(valuesEndDate).format("DD/MM/YY"),
				note: Number(valuesNote),
				attachmentName: valuesAttachment?.name || null,
				attachment: null,
			}
		);
	}, [values]);

	const registerMissionFollowUpMutation = useMutation((v) => registerMissionFollowUp(profileId, v));
	const putMissionFollowUpUpMutation = useMutation((v) => putMissionFollowUp(profileId, followUpId, v));

	const qualificationOptionsTemplate = (option) => (
		<div className={style.contract}>
			{option.contractNumber}
			{option.startDate && (
				<>
					{" "}
					<span className={style.contractDate}>
						-{" "}
						{format(new Date(option.startDate), "dd/MM/yyyy", {
							locale: localeFr,
						})}
					</span>
				</>
			)}
		</div>
	);

	const handleSubmitForm = () => {
		const mutatedValues = {
			...values,
			note: values.note ? Number(values.note) : null,
		};
		registerMissionFollowUpMutation.mutate(mutatedValues, {
			onSuccess: () => {
				toastDispatch({
					type: "SUCCESS",
					payload: {
						icon: "checkCircle",
						msg: t("profile.followUp.success"),
						params: {
							autoClose: true,
							closeOnClick: true,
						},
					},
				});
				history.push(`/profile/${profileId}/missions/follow-up`);
			},
		});
	};

	const handleEditForm = () => {
		const mutatedValues = {
			...values,
			note: values.note ? Number(values.note) : null,
			isSameFile,
			attachment: isSameFile ? null : values.attachment,
		};
		putMissionFollowUpUpMutation.mutate(mutatedValues, {
			onSuccess: () => {
				toastDispatch({
					type: "SUCCESS",
					payload: {
						icon: "checkCircle",
						msg: t("profile.followUp.successEdit"),
						params: {
							autoClose: true,
							closeOnClick: true,
						},
					},
				});
				history.push(`/profile/${profileId}/missions/follow-up`);
			},
		});
	};

	useEffect(() => {
		if (contractsFetched && qualificationsFetched && values.contractId) {
			const contract = contractsOptions.find((contractOption) => contractOption.value === values.contractId);
			if (contract) {
				const qualification = qualificationsOptions.find(
					(qualificationOption) =>
						qualificationOption?.clientQualificationId === contract?.clientQualificationId
				);
				const updatedFields = {
					startDate: contract?.startDate || null,
					endDate: contract?.endDate || null,
					clientQualificationId: qualification?.clientQualificationId || null,
				};
				setValues({
					...values,
					...updatedFields,
				});
			}
		}
	}, [values.contractId, contractsOptions, qualificationsOptions, setValues]);

	const isSubmitDisabled = !isValid || !isDirty;

	const contractSelected = useMemo(
		() => contractsOptions.find((contractOption) => contractOption.value === values.contractId) || null,
		[contractsOptions, values.contractId]
	);
	const needDatesEnteredInTempo = contractSelected && !contractSelected.startDate && !contractSelected.endDate;

	const handleClientRelationInfoMessage = useCallback(() => {
		const { data: clientRelationData, status: clientRelationStatus } = clientRelation || {};
		const { data: unifiedTempWorkerData } = unifiedTempWorker || {};
		const { isAdequate } = clientRelationData || {};
		const { firstName, lastName } = unifiedTempWorkerData || {};
		let message = null;

		if (clientRelationStatus === 204) {
			message = t("profile.followUp.form.adequacy.noClientRelation");
		} else if (isAdequate && unifiedTempWorkerData) {
			message = t("profile.followUp.form.adequacy.adequateRelation", {
				firstName: capitalizeFirstCharacter(firstName),
				lastName: capitalizeFirstCharacter(lastName),
			});
		} else if (!isAdequate && unifiedTempWorkerData) {
			message = t("profile.followUp.form.adequacy.inadequateRelation", {
				firstName: capitalizeFirstCharacter(firstName),
				lastName: capitalizeFirstCharacter(lastName),
			});
		} else {
			return null;
		}

		return <InfoMessage withIcon>{message}</InfoMessage>;
	}, [values.clientId, clientRelation, unifiedTempWorker]);

	useEffect(() => {
		if (values.attachmentURL && values.attachmentName) {
			setFieldValue(
				"attachment",
				new File([values.attachmentURL], values.attachmentName, {
					type: "application/pdf",
				})
			);
		}
	}, []);

	const CustomMenuList = (props) => {
		const totalClientText = useMemo(() => {
			return t("commands.clientForm.totalClientResult", { totalClients, maxClients: MAX_CLIENTS });
		}, [totalClients, MAX_CLIENTS, t]);

		return (
			<div className="relative pb-10">
				<components.MenuList {...props}>{props.children}</components.MenuList>
				<hr className="text-black opacity-10" />
				<div className="absolute bottom-0 left-0 w-full p-2.5 text-center text-primary-500 leading-4">
					{totalClientText}
				</div>
			</div>
		);
	};

	const customComponents = {
		DropdownIndicator: () => <DropdownIndicator isLoading={searchLoading} />,
		...(totalClients > MAX_CLIENTS ? { MenuList: CustomMenuList } : {}),
	};

	return (
		<div className={style.wrap}>
			{/* Type */}
			<FormSection title={t("profile.followUp.form.type.label")}>
				<div className={style.typeSectionGroup}>
					<Button
						type="button"
						disabled={!hasPressEditButton}
						color={values.followUpType === "assessment" ? "primary" : "grey"}
						outline={values.followUpType !== "assessment"}
						onClick={() => {
							if (values.followUpType !== "assessment") {
								setValues({
									...values,
									followUpType: "assessment",
									incidentTypeId: null,
									incidentDate: null,
								});
							}
						}}
						className={cn([
							style.typeBtn,
							values.followUpType !== "assessment" ? style.typeBtnEmpty : style.typeBtnActive,
						])}
					>
						{t("profile.followUp.form.type.assessment")}
					</Button>
					<Button
						type="button"
						disabled={!hasPressEditButton}
						color={values.followUpType === "incident" ? "primary" : "grey"}
						outline={values.followUpType !== "incident"}
						onClick={() => {
							if (values.followUpType !== "incident") {
								setValues({
									...values,
									followUpType: "incident",
									incidentDate: values.incidentDate || getAtomFromDate(new Date()),
								});
							}
						}}
						className={cn([
							style.typeBtn,
							values.followUpType !== "incident" ? style.typeBtnEmpty : style.typeBtnActive,
						])}
					>
						{t("profile.followUp.form.type.incident")}
					</Button>
				</div>
			</FormSection>

			{/* Agency */}
			<FormSection title={t("profile.followUp.form.agencyId.label")}>
				{agenciesOptions.length > 0 ? (
					<>
						{isOnlyOneAgencyAvailable ? (
							<div className={cn([style.readOnlyField, style.readOnlyFieldAgency])}>
								{agenciesOptions.find((agency) => agency.value === values.agencyId)?.label}
							</div>
						) : (
							<SelectV2
								options={agenciesOptions}
								onChange={(selected) => {
									setValues({
										...values,
										agencyId: selected?.value,
										clientId: null,
										contractId: null,
										clientQualificationId: null,
									});
								}}
								disabled={isOnlyOneAgencyAvailable || !hasPressEditButton}
								name="agencyId"
								label={t("profile.followUp.form.agencyId.placeholder")}
								placeholder={t("profile.followUp.form.agencyId.placeholder")}
								value={
									agenciesOptions.find((agency) => agency.value === values.agencyId)?.value || null
								}
								isClearable={false}
								info={t("profile.followUp.form.agencyId.info")}
								error={!!errors.agencyId}
								noOptionsMessage={() => t("global.noDataText")}
								errorMessage={t("profile.followUp.form.agencyId.error")}
								className={style.agencySelect}
							/>
						)}
					</>
				) : (
					<div>
						<Skeleton width={346} height={56} borderRadius={25} />
						<Skeleton className={style.fieldInfoSkeleton} width={110} height={17} />
					</div>
				)}
			</FormSection>
			{values.agencyId && (
				<>
					{/* Client & Code Client */}
					<FormSection title={t("profile.followUp.form.clientId.label")}>
						{clients.length > 0 || isClientFetched || !values.canEdit ? (
							<SelectV2
								isSearchable
								options={clients}
								noOptionsMessage={({ inputValue }) => {
									if (searchLoading) {
										return t("components.gouvAddressSelect.loading");
									}
									if (inputValue.length < MINIMUM_SEARCH_LENGTH) {
										return t("components.gouvAddressSelect.minimumSearchLength", {
											length: MINIMUM_SEARCH_LENGTH,
										});
									}
									if (userTyping) {
										return t("components.gouvAddressSelect.loading");
									}
									return t("components.gouvAddressSelect.noResults");
								}}
								onChange={(selected) => {
									if (selected === null) {
										setClients([]);
									}
									setValues({
										...values,
										clientId: selected?.id,
										contractId: null,
										clientQualificationId: null,
									});
								}}
								getOptionValue={(option: ClientInfo) => option.id || ""}
								formatOptionLabel={(option: ClientInfo) => (
									<OptionClient
										key={option.tempoId}
										tempoId={option.tempoId}
										name={option.name}
										city={option.city}
									/>
								)}
								name="clientId"
								onInputChange={(value) => {
									if (value?.length >= MINIMUM_SEARCH_LENGTH) setUserTyping(true);
									handleInputChange(value);
								}}
								value={values?.clientId || null}
								filterOption={(option) => option.value !== values?.clientId}
								components={customComponents}
								label={t("profile.followUp.form.clientId.label")}
								placeholder={t("profile.followUp.form.clientId.placeholder")}
								isClearable={true}
								info={t("profile.followUp.form.clientId.info")}
								error={!!errors.clientId && touched.clientId}
								errorMessage={t("profile.followUp.form.clientId.error")}
								className={style.clientSelect}
								disabled={!hasPressEditButton}
							/>
						) : (
							<div>
								<Skeleton width={346} height={56} borderRadius={25} />
								<Skeleton className={style.fieldInfoSkeleton} width={110} height={17} />
							</div>
						)}
					</FormSection>
				</>
			)}

			{/* Once Client is selected */}
			{values.clientId ? (
				<>
					{/* Contract */}
					<FormSection title={t("profile.followUp.form.contractId.label")}>
						{contractsOptions.length > 0 || contractsFetched || !values.canEdit ? (
							<SelectV2
								options={contractsOptions}
								onChange={(selected) => {
									setFieldValue("contractId", selected?.value || null);
								}}
								isClearable
								name="contractId"
								label={t("profile.followUp.form.contractId.placeholder")}
								placeholder={t("profile.followUp.form.contractId.placeholder")}
								formatOptionLabel={(option) => qualificationOptionsTemplate(option)}
								value={values.contractId}
								error={!!errors.contractId}
								errorMessage={t("profile.followUp.form.contractId.error")}
								noOptionsMessage={() => t("global.noDataText")}
								className={style.agencySelect}
								disabled={!hasPressEditButton}
							/>
						) : (
							<div>
								<Skeleton width={346} height={56} borderRadius={25} />
								<Skeleton className={style.fieldInfoSkeleton} width={110} height={17} />
							</div>
						)}
					</FormSection>
					{/* Dates */}
					<FormSection title={t("profile.followUp.form.dates.label")}>
						<ProfileMissionFollowUpPeriod
							isLocked={values.contractId || !hasPressEditButton}
							startDateInputName="startDate"
							endDateInputName="endDate"
							startDateLabel={t("profile.followUp.form.dates.startDateLabel")}
							endDateLabel={t("profile.followUp.form.dates.endDateLabel")}
							needDatesEnteredInTempo={needDatesEnteredInTempo}
						/>
						{needDatesEnteredInTempo && (
							<div className={style.noDatesMessage}>
								<InfoMessage color="warning" withIcon>
									{t("profile.followUp.form.dates.contractNoDates")}
								</InfoMessage>
							</div>
						)}
					</FormSection>

					{/* Qualification */}
					<FormSection title={t("profile.followUp.form.qualification.label")}>
						{(qualificationsFetched && !qualificiationsIsFetching) || !values.canEdit ? (
							<>
								<SelectV2
									options={qualificationsOptions}
									onChange={(selected) => {
										setFieldValue("clientQualificationId", selected?.value || null);
									}}
									name="clientQualificationId"
									label={t("profile.followUp.form.qualification.placeholder")}
									placeholder={t("profile.followUp.form.qualification.placeholder")}
									noOptionsMessage={() => t("global.noDataText")}
									disabled={values.contractId || !hasPressEditButton}
									value={values.clientQualificationId}
									isClearable={!values.contractId && values?.canEdit}
									className={style.qualificationSelect}
								/>
								{values.contractId && !values.clientQualificationId && (
									<div className={style.noDatesMessage}>
										<InfoMessage color="warning" withIcon>
											{t("profile.followUp.form.qualification.contractNoMatch")}
										</InfoMessage>
									</div>
								)}
							</>
						) : (
							<div>
								<Skeleton width={346} height={56} borderRadius={25} />
								<Skeleton className={style.fieldInfoSkeleton} width={110} height={17} />
							</div>
						)}
					</FormSection>

					{values.followUpType === "incident" && (
						<>
							{/* Type d'incident */}
							<FormSection title={t("profile.followUp.form.incidentTypeId.label")}>
								<SelectV2
									options={incidentOptions}
									onChange={(selected) => {
										setFieldValue("incidentTypeId", selected?.value);
									}}
									name="incidentTypeId"
									label={t("profile.followUp.form.incidentTypeId.placeholder")}
									placeholder={t("profile.followUp.form.incidentTypeId.placeholder")}
									value={values.incidentTypeId}
									isClearable
									info={t("commands.requiredField")}
									noOptionsMessage={() => t("global.noDataText")}
									className={style.incidentTypeSelect}
									disabled={!hasPressEditButton}
								/>
							</FormSection>

							{/* Date de l'incident */}
							<FormSection title={t("profile.followUp.form.incidentDate.label")}>
								<>
									<DateRangeInput
										label={t("profile.followUp.form.incidentDate.placeholder")}
										disabled={!hasPressEditButton}
										value={
											values.incidentDate ? moment(values.incidentDate).format("DD/MM/yyyy") : ""
										}
										onClick={() => {
											setFieldTouched("incidentDate", true);
											setFocusedInputName("incidentDate");
										}}
										onClear={(event) => {
											event.stopPropagation();
											setFieldTouched("incidentDate", true);
											setFieldValue("incidentDate", null);
										}}
										className={style.incidentDate}
										isActive={focusedInputName === "incidentDate"}
										error={
											touched?.incidentDate && errors?.incidentDate
												? t("commands.error.mandatoryStartDateField")
												: null
										}
										info={t("commands.requiredField")}
									/>
									<DateSinglePicker
										id="incidentDate"
										dateString={values.incidentDate || ""}
										focusedInput={focusedInputName.replace("period.", "")}
										setFocusedInput={(inputName) => {
											setFocusedInputName(inputName ? `period.${inputName}` : "");
										}}
										onDateChange={(date) => {
											setFieldValue("incidentDate", date ? getAtomFromDate(date) : null);
										}}
										className={style.datePickerWrapper}
										isVisible={focusedInputName !== ""}
										setIsVisible={() => setFocusedInputName("")}
										keepOpenOnDateSelect={false}
									/>
								</>
							</FormSection>
						</>
					)}

					{/* Notation */}
					<FormSection title={t("profile.followUp.form.note.label")}>
						<div>
							<Field
								type="number"
								name="note"
								id="note"
								step={0.1}
								classNameContainer={style.noteField}
								placeholder={t("profile.followUp.form.note.placeholder")}
								label={t("profile.followUp.form.note.label")}
								onBlur={handleFieldBlur}
								showErrorMessage={false}
								disabled={!hasPressEditButton}
							/>
							<InfoMessage withIcon>{t("profile.followUp.form.note.help")}</InfoMessage>
						</div>
					</FormSection>

					{/* Compte Rendu */}
					<FormSection title={t("profile.followUp.form.reporting.label")}>
						<Textarea
							className={style.textareaField}
							name="reporting"
							label={null}
							placeholder={t("profile.followUp.form.reporting.placeholder")}
							autoScale
							maxLength={1000}
							disabled={!hasPressEditButton}
						/>
					</FormSection>

					{/* Status de la relation client */}
					<FormSection title={t("profile.followUp.form.adequacy.label")}>
						<div className={style.infoMessage}>{handleClientRelationInfoMessage()}</div>
						<div>
							<div className={style.adequacy}>
								<ToggleSwitch
									id="adequacy-adequate"
									disabled={!hasPressEditButton}
									name="adequacy"
									onChange={() => {
										if (values.adequacy === "adequate") {
											setFieldValue("adequacy", null);
											return;
										}
										setFieldValue("adequacy", "adequate");
									}}
									toggled={values.adequacy === "adequate"}
									className={style.adequacyField}
								>
									<label htmlFor="adequacy-adequate" className={style["toggle-label"]}>
										{t("profile.followUp.form.adequacy.adequate")}
									</label>
								</ToggleSwitch>
							</div>
							<div className={style.adequacy}>
								<ToggleSwitch
									id="adequacy-inadequate"
									disabled={!hasPressEditButton}
									name="adequacy"
									onChange={() => {
										if (values.adequacy === "inadequate") {
											setFieldValue("adequacy", null);
											return;
										}
										setFieldValue("adequacy", "inadequate");
									}}
									toggled={values.adequacy === "inadequate"}
									className={style.adequacyField}
								>
									<label htmlFor="adequacy-inadequate" className={style["toggle-label"]}>
										{t("profile.followUp.form.adequacy.inadequate")}
									</label>
								</ToggleSwitch>
							</div>
						</div>
					</FormSection>

					{/* Nom de l'intervenant */}
					<FormSection title={t("profile.followUp.form.author.label")}>
						<Field
							type="text"
							name="author"
							id="author"
							classNameContainer={style.authorField}
							placeholder={t("profile.followUp.form.author.placeholder")}
							label={t("profile.followUp.form.author.label")}
							onBlur={handleFieldBlur}
							disabled={!hasPressEditButton}
						/>
					</FormSection>

					{/* Pièce jointe */}
					<FormSection title={t("profile.followUp.form.attachment.label")}>
						<FileUpload
							accept="application/pdf"
							isSameFile={isSameFile}
							fileURL={values.attachmentURL}
							disabled={hasPressEditButton}
							files={values.attachment ? [values.attachment] : []}
							info={
								<span className={style.fileInputInfo}>
									{t("missionFollowUp.creationForm.uploadFile")}
									<br />
									{t("profile.followUp.form.attachment.maxSize")}
								</span>
							}
							handleFileChange={(files) => {
								setIsSameFile(false);
								if (files.length === 0) return;

								const { size, type } = files[0];

								if (size > 10 * 1024 * 1024) {
									toastDispatch({
										type: "WARN",
										payload: {
											msg: t("profile.followUp.form.attachment.maxSizeFullMessage"),
										},
									});
									return;
								}
								if (type !== "application/pdf") {
									toastDispatch({
										type: "WARN",
										payload: {
											msg: t("profile.followUp.form.attachment.invalidType"),
										},
									});
									return;
								}

								setFieldValue("attachment", files[0]);
							}}
							handleFileDrop={(files) => {
								setIsSameFile(false);
								const { type, size } = files[0];

								if (type !== "application/pdf") {
									toastDispatch({
										type: "WARN",
										payload: {
											msg: t("profile.followUp.form.attachment.invalidType"),
										},
									});
									return;
								}
								if (size > 10 * 1024 * 1024) {
									toastDispatch({
										type: "WARN",
										payload: {
											msg: t("profile.followUp.form.attachment.maxSizeFullMessage"),
										},
									});
									return;
								}
								if (files.length === 0) return;
								setFieldValue("attachment", files[0]);
							}}
							handleDeleteClick={() => {
								setFieldValue("attachment", null);
								if (isEditionMode) {
									setIsSameFile(false);
								}
							}}
						/>
					</FormSection>
				</>
			) : null}
			{/* SAVE */}
			{hasPressEditButton && (values?.canEdit || !isEditionMode) && (
				<Button
					data-testid="command-save"
					disabled={isSubmitDisabled}
					color={isSubmitDisabled ? "grey" : "primary"}
					type="button"
					onClick={isEditionMode ? handleEditForm : handleSubmitForm}
					isLoading={registerMissionFollowUp.isLoading}
					style={{ position: "fixed", bottom: "2.5rem", right: "3.5rem" }}
				>
					{!isEditionMode ? t("profile.followUp.saveFormCreate") : t("profile.followUp.saveEditForm")}
				</Button>
			)}
			{!hasPressEditButton && values?.canEdit && (
				<Button
					color="primary"
					type="button"
					onClick={() => setHasPressEditButton(true)}
					style={{ position: "fixed", bottom: "2.5rem", right: "3.5rem" }}
				>
					{t("profile.followUp.editFormAccess")}
				</Button>
			)}
		</div>
	);
};
FollowUpFormContent.propTypes = {
	mode: PropTypes.oneOf([CREATE_MODE, EDIT_MODE]).isRequired,
};

const ProfileMissionsFollowUpForm = ({ mode }) => {
	const { profileId, followUpId } = useParams();
	const { t } = useTranslation();
	const isEditionMode = mode === EDIT_MODE;

	// Fetch Mission Follow Up data
	const {
		data: missionFollowUpData,
		isFetched,
		error: clientRelationshipDataError,
	} = useQuery(["getMissionFollowUp", profileId, followUpId], () => getMissionFollowUp(profileId, followUpId), {
		enabled: isEditionMode,
		placeholderData: followUpFormDefaultValues,
		cacheTime: 0,
	});

	if (clientRelationshipDataError?.response?.status === 403) {
		return <div className={style.forbidden}>{t("profile.followUp.form.forbidden")}</div>;
	}

	return isFetched || !isEditionMode ? (
		<Formik
			initialValues={isEditionMode ? missionFollowUpData?.data : followUpFormDefaultValues}
			validationSchema={followUpFormValidation}
		>
			{() => (
				<Form>
					<FollowUpFormContent mode={mode} />
				</Form>
			)}
		</Formik>
	) : (
		<div className={style.wrap}>
			<Skeleton width={150} height={20} />
			<br />
			<Skeleton width={346} height={56} borderRadius={25} />
			<Skeleton className={style.fieldInfoSkeleton} width={110} height={17} />
			<br />
			<br />
			<Skeleton width={150} height={20} />
			<br />
			<Skeleton width={346} height={56} borderRadius={25} />
			<Skeleton className={style.fieldInfoSkeleton} width={110} height={17} />
			<br />
			<br />
			<Skeleton width={150} height={20} />
			<br />
			<Skeleton width={346} height={56} borderRadius={25} />
			<br />
			<br />
			<Skeleton width={150} height={20} />
			<br />
			<Skeleton width={200} height={60} borderRadius={25} />
			<br />
			<Skeleton className={style.fieldInfoSkeleton} width={110} height={17} />
			<br />
			<Skeleton width={200} height={60} borderRadius={25} />
			<br />
			<Skeleton className={style.fieldInfoSkeleton} width={110} height={17} />
			<br />
			<Skeleton width={200} height={60} borderRadius={25} />
			<br />
			<Skeleton className={style.fieldInfoSkeleton} width={110} height={17} />
		</div>
	);
};

ProfileMissionsFollowUpForm.propTypes = {
	mode: PropTypes.oneOf([CREATE_MODE, EDIT_MODE]).isRequired,
};

export default ProfileMissionsFollowUpForm;
