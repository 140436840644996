import { Draggable } from "react-beautiful-dnd";

// Types
import OrderProfileTypes from "src/services/types/OrderProfileTypes";

import TCardAction from "../../types/TCardAction";
// Hooks
import useDragCard from "./useDragCard";
import { Picto, Popover, PopoverCursor } from "@zolteam/react-ras-library";
// Components
import { Tag } from "src/components/atoms";

// Utilities
import cn from "src/utils/cn";
import formatPhoneNumber from "src/utils/formatPhoneNumber";

// Styles
import style from "./DragCard.module.css";

interface DragCardProps {
	profile: OrderProfileTypes;
	availableActions: TCardAction[];
	index: number;
	handleDoubleClick: (id: string | number) => void;
	className?: string;
}

const getItemStyle = (isDragging: boolean, draggableStyle: React.CSSProperties) => ({
	userSelect: "none",
	...draggableStyle,
	boxShadow: isDragging ? "0px 2px 5px rgba(51, 51, 51, 0.1)" : "none",
});

const DragCard: React.FC<DragCardProps> = ({ profile, availableActions, index, handleDoubleClick, className }) => {
	const {
		actions,
		missionOrderShareStatus,
		offerShareStatus,
		memoizedHandlePopoverContent,
		memoizedHandlePictoVariant,
	} = useDragCard(profile, availableActions);

	return (
		<Draggable key={profile.id} draggableId={profile.id.toString()} index={index}>
			{(provided, snapshot) => (
				<div
					onDoubleClick={() => handleDoubleClick(profile.id)}
					className={cn([style.card, className])}
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					// @ts-ignore
					style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
				>
					<div className={style.cardHead}>
						<div className={style.profilHeader}>
							{profile.avatarUrl ? (
								<div className={style.avatarBox}>
									<img className={style.avatar} src={profile.avatarUrl} alt="user profile" />
									{profile.isVip && <Picto className={style.vipPicto} icon="vip" />}
								</div>
							) : (
								<div className={style.avatarPlaceholder}>
									<Picto icon="userDelete" />
									{profile.isVip && <Picto className={style.vipPicto} icon="vip" />}
								</div>
							)}

							{profile.isUnifiedTempWorkerValidated ? (
								<PopoverCursor
									content={<div className={style.popoverBox}>Compte initialisé sur my R.A.S</div>}
								>
									<div className={style.logoMyRas}>
										<Picto icon="logoMyRasAlt" className={style.myRasLogo} />
									</div>
								</PopoverCursor>
							) : null}

							{offerShareStatus ? (
								<PopoverCursor
									content={
										<div className={style.popoverBox}>
											{memoizedHandlePopoverContent(offerShareStatus, "missionOffer")}
										</div>
									}
								>
									<div
										className={cn([
											style.informationsPicto,
											style[memoizedHandlePictoVariant(offerShareStatus, "missionOffer")],
										])}
									>
										<Picto
											icon="paperPlaneSolid"
											variant={memoizedHandlePictoVariant(offerShareStatus, "missionOffer")}
										/>
									</div>
								</PopoverCursor>
							) : null}

							{missionOrderShareStatus ? (
								<PopoverCursor
									content={
										<div className={style.popoverBox}>
											{memoizedHandlePopoverContent(missionOrderShareStatus, "missionOrder")}
										</div>
									}
								>
									<div
										className={cn([
											style.informationsPicto,
											style[memoizedHandlePictoVariant(missionOrderShareStatus, "missionOrder")],
										])}
									>
										<Picto
											icon="clipboardActive"
											variant={memoizedHandlePictoVariant(
												missionOrderShareStatus,
												"missionOrder"
											)}
										/>
									</div>
								</PopoverCursor>
							) : null}

							{profile.isDelegatedBy247 ? (
								<PopoverCursor content={<div className={style.popoverBox}>Délégué par 24/7</div>}>
									<div className={style.isDelegatedBy247Picto}>
										<Picto icon="timeTwentyFour" />
									</div>
								</PopoverCursor>
							) : null}
						</div>

						{actions.length > 0 && (
							<Popover
								placement="bottom-end"
								clickInside
								content={
									<ul className={style.actionsList}>
										{actions.map((action) => (
											<li key={action.label} className={style.actionsListItem}>
												<button
													className={style.actionsListItemButton}
													type="button"
													onClick={() => action.onClick(profile)}
												>
													{action.label}
												</button>
											</li>
										))}
									</ul>
								}
							>
								<Picto icon="more" className={style.more} />
							</Popover>
						)}
					</div>

					<div className={style.cardBody}>
						<div className={style.profileName}>
							<span className={style.fullName}>{`${profile.firstName} ${profile.lastName}`}</span>
							{profile.isCdiInterim && <span className={style.profilAttribute}>CDII</span>}
						</div>

						{profile?.phone && (
							<Tag className={style.profilePhone} size="s" radius="m" color="grey">
								{formatPhoneNumber(`+33${profile.phone.slice(1)}`)}
							</Tag>
						)}
					</div>
				</div>
			)}
		</Draggable>
	);
};

export default DragCard;
