// @ts-nocheck
import moment from "moment";

const isDayBlocked = (day) => {
	const today = moment();
	const isTodayOrBefore = moment(day).isSameOrBefore(today, "day");
	const isFriday = moment(day).isoWeekday() === 5;
	const isAfterTuesday18h = today.isoWeekday() === 2 ? today.hour() >= 18 : today.isoWeekday() > 2;
	const isFridayOfThisWeek = isFriday && moment(day).isoWeek() === today.isoWeek();

	return isTodayOrBefore || (isAfterTuesday18h && isFridayOfThisWeek) || moment(day).isoWeekday() !== 5;
};

export default isDayBlocked;
