// @ts-nocheck
import axios from "axios";
import Cookies from "js-cookie";
import { isEmpty } from "lodash";

// Utils & misc
import { API_URL, POSTMAN_MOCK_URL, POSTMAN_SERVER_MOCK_KEY, V1_TOKEN_COOKIE } from "../constants";

/**
 * @description Create Axios instance
 * @returns {AxiosInstance}
 */
const client = axios.create({
	baseURL: API_URL,
	headers: {
		Accept: "application/json",
		"Content-Type": "application/json",
	},
	timeout: 30000,
});

client.interceptors.request.use((config) => {
	// move to AppContext if we save the token in localstorage
	let accessToken = "";
	let tokenObject = {};
	const { cookie } = document;
	if (cookie) {
		const cookieDeFolie = Cookies.get(V1_TOKEN_COOKIE);
		try {
			tokenObject = JSON.parse(cookieDeFolie);
			// eslint-disable-next-line no-empty
		} catch (e) {}
		accessToken = tokenObject?.access_token;
	}
	if (!isEmpty(accessToken)) {
		// eslint-disable-next-line no-param-reassign
		config.headers = {
			Authorization: `Bearer ${accessToken}`,
		};
	} else {
		// Cleaning local storage to prevent overload problems
		const items = { ...localStorage };
		Object.keys(items).map((key) => {
			if (key.includes("MatchingFilters")) {
				localStorage.removeItem(key);
			}
			return null;
		});
		window.location.href = `${process.env.REACT_APP_BASE_OLD_URL}/#/fr/signin`;
	}
	return config;
});

client.interceptors.response.use(
	(response) => response,
	(error) => {
		if (error?.request?.status === 403) {
			window.location.href = `${process.env.REACT_APP_API_URL}/403`;
		}
		return Promise.reject(error);
	}
);

export default client;

export const addressGouvClient = axios.create({
	baseURL: "https://api-adresse.data.gouv.fr",
	headers: {
		Accept: "application/json",
		"Content-Type": "application/json",
	},
	timeout: 30000,
});

export const mockClient = axios.create({
	baseURL: POSTMAN_MOCK_URL,
	headers: {
		Accept: "application/json",
		"Content-Type": "application/json",
		"x-api-key": POSTMAN_SERVER_MOCK_KEY,
	},
	timeout: 30000,
});
