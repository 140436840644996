import { FC, useRef } from "react";

import { ErrorMessage, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { TCity } from "src/types/TCity";
import { TCountry } from "src/types/TCountry";

import PublicService from "src/services/PublicServices";

import { SelectAsync } from "@zolteam/react-ras-library";
import { TooltipMessage } from "src/components/atoms";
import { Field } from "src/components/molecules";

import { FRENCH_CITY_ID } from "src/constants/CLocations";
import { ALLOWED_LETTERS_AND_NUMBERS_REGEX, ALLOWED_LETTERS_CHAR_REGEX } from "src/constants/CRegex";

const MIN_SEARCH_LENGTH = 3;

interface CitySelectProps {
	initialCountry: number | "todo";
	value?: TCountry | string;
	disabled?: boolean;
	required?: boolean;
	containerClassname?: string;
	minLength?: number;
	labelFr?: string;
	labelPostalCode?: string;
	labelCity?: string;
	classNameContainer?: string;
	classNamePostalInput?: string;
	classNameCityInput?: string;
	nameFr?: string;
	namePostalCode?: string;
	nameCity?: string;
}

export const CitySelect: FC<CitySelectProps> = ({
	initialCountry,
	value,
	required = false,
	minLength = MIN_SEARCH_LENGTH,
	labelFr,
	labelPostalCode,
	labelCity,
	classNameContainer,
	classNamePostalInput,
	classNameCityInput,
	nameFr,
	namePostalCode,
	nameCity,
}) => {
	const { t } = useTranslation();
	const { setFieldValue } = useFormikContext();

	const fetchTM = useRef<NodeJS.Timeout>();

	const formatLabel = (option: any) => {
		return `${option.postalCode && option.postalCode !== "00000" ? `${option.postalCode}` : ""} ${option.name}`;
	};

	const handleSearch = (searchTerm: string) => {
		clearTimeout(fetchTM.current);
		if (searchTerm?.length < minLength) return Promise.resolve([]);

		const prom = new Promise((resolve, reject) => {
			fetchTM.current = setTimeout(() => {
				return PublicService.getLocations("commune", searchTerm).then(resolve, reject);
			}, 500);
		});

		return prom;
	};

	if (initialCountry === FRENCH_CITY_ID) {
		return (
			<div className={"flex flex-row items-center gap-2  " + classNameContainer}>
				<SelectAsync
					defaultOptions
					loadOptions={handleSearch}
					label={(labelFr || t("suspiciousSheet.workerInfo.addressCity")) + (required ? "*" : "")}
					value={value || null}
					getOptionLabel={formatLabel}
					getOptionValue={(option: TCity) => option.id}
					searchIcon={true}
					isSearchable={true}
					onChange={(value: TCity) => {
						return setFieldValue(nameFr || "location", value);
					}}
					name={nameFr || "location"}
					noOptionsMessage={({ inputValue }: { inputValue: string }) => {
						if (inputValue?.length < MIN_SEARCH_LENGTH)
							return t("search.searchMinLength", {
								min: MIN_SEARCH_LENGTH,
							});
						return t("search.noResult", {
							search: inputValue,
						});
					}}
					loadingMessage={() => t("search.loading")}
					className="w-full"
					displayFormikError={false}
				/>

				<ErrorMessage name={nameFr || "location"}>
					{(msg) => {
						return <TooltipMessage color="error">{msg}</TooltipMessage>;
					}}
				</ErrorMessage>
			</div>
		);
	} else {
		return (
			<>
				<Field
					label={labelPostalCode || t("activityLogs.commonFields.wording.addressPostalCode")}
					type="text"
					name={namePostalCode || "addressPostalCode"}
					allowedCharacters={ALLOWED_LETTERS_AND_NUMBERS_REGEX}
					customErrorDisplay
					className={"flex-row flex-nowrap "}
					classNameContainer={classNamePostalInput}
					maxLength={6}
					required={required}
				/>
				<Field
					label={labelCity || t("suspiciousSheet.workerInfo.addressCity")}
					type="text"
					name={nameCity || "addressCity"}
					classNameContainer={classNameCityInput}
					allowedCharacters={ALLOWED_LETTERS_CHAR_REGEX}
					customErrorDisplay
					className={"flex-row flex-nowrap"}
					maxLength={30}
					required={required}
					onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
						if (e.target.value) return setFieldValue("addressCity", e.target.value.toUpperCase());
						return null;
					}}
				/>
			</>
		);
	}
};
