import { FC } from "react";

import { useTranslation } from "react-i18next";

import { Picto, Tooltip } from "@zolteam/react-ras-library";

export const PictoCommonUTWData: FC = () => {
	const { t } = useTranslation();
	return (
		<Tooltip
			content={
				<div className="whitespace-nowrap">{t("usersListing.temporaryWorker.administrative.uniqueSheet")} </div>
			}
		>
			<div>{<Picto icon="buildings" />} </div>
		</Tooltip>
	);
};
