// @ts-nocheck
import React, { useState } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import cn from "../../../../utils/cn";
import { Button, Title, ToggleSwitch } from "@zolteam/react-ras-library";

// Style
import style from "./Modals.module.css";

const ModalContentRecuseProfil = ({ onConfirmClick, onCancelClick }) => {
	// States
	const [shouldSendNotification, setShouldSendNotification] = useState(false);
	// Hooks
	const { t } = useTranslation();

	return (
		<div className={style.content}>
			<Title tag="h1" size="heading01" lineHeight="l" className={cn([style.title])}>
				{t("commands.profiles.modalsContent.recuseProfil.title")}
			</Title>

			<div className={style.textContent}>
				<p className={style.message}>{t("commands.profiles.modalsContent.recuseProfil.infoMessage")}</p>
				<div className={style.toggle}>
					<ToggleSwitch
						id="shouldSendNotification"
						name="shouldSendNotification"
						onChange={() => setShouldSendNotification((prevState) => !prevState)}
						toggled={shouldSendNotification}
					>
						<label htmlFor="shouldSendNotification" className={style["toggle-label"]}>
							{t("commands.profiles.modalsContent.recuseProfil.toggleLabel")}
						</label>
					</ToggleSwitch>
				</div>
				<span className="mt-l">
					<p className={style.message}>{t("commands.profiles.modalsContent.recuseProfil.confirm")}</p>
				</span>
				<p className={cn([style.message, style["message-annotation"]])}>
					{t("commands.profiles.modalsContent.recuseProfil.informationComp")}
				</p>
			</div>

			<hr className={cn([style.separator, "mt-l"])} />
			<div className={cn([style.buttonsBlock])}>
				<Button
					className={style.validationButtonRight}
					type="button"
					color="primary"
					onClick={onCancelClick}
					outline
				>
					{t("commands.profiles.modalsContent.recuseProfil.cancelAction")}
				</Button>
				<Button
					className={style.validationButtonRight}
					type="button"
					color="primary"
					onClick={() => onConfirmClick({ shouldSendNotification })}
				>
					{t("commands.profiles.modalsContent.recuseProfil.confirmAction")}
				</Button>
			</div>
		</div>
	);
};

ModalContentRecuseProfil.propTypes = {
	onConfirmClick: PropTypes.func.isRequired,
	onCancelClick: PropTypes.func.isRequired,
};

export default ModalContentRecuseProfil;
