import * as Yup from "yup";

import i18n from "../../../../i18n";

export default Yup.object().shape({
	agencyId: Yup.number().required(),
	userId: Yup.object().shape({ label: Yup.string(), value: Yup.number().required() }),
	requestedAmount: Yup.string()
		.nullable()
		.matches(/^\d+([,.]\d{1,2})?$/, i18n.t("advancePayments.error.formatCurrencyNotValid")),
	isMaximumAdvanceRequested: Yup.bool(),
	comment: Yup.string().nullable(),
	desiredPaymentDate: Yup.string().required(),
});
