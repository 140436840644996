// @ts-nocheck

/* eslint-disable no-unused-vars */
import { useCallback, useMemo } from "react";

import { Form, Formik, useFormikContext } from "formik";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

// Hooks
import useFilterState from "../../../../../hooks/useFilterState";
import useOrderData from "../../../../../hooks/useOrderData";
import { useMatchingContext } from "../../../../../store/MatchingContext";

// Style
import cn from "../../../../../utils/cn";
// Constants
import matchingFormDefaultValues from "../../matchingFormDefaultValues";
// Utils
import validationSchema from "../../matchingFormValidation";
// Components
import BusinessInformationFilter from "./filtersSection/BusinessInformationFilter";
import { Button } from "@zolteam/react-ras-library";

import style from "./ModalContentMatchingFilter.module.css";

const ModalContentMatchingForm = () => {
	const { t } = useTranslation();
	const { orderId } = useParams();
	const [{ filters }, dispatchMatching] = useMatchingContext();

	const { commonQualificationDetails, orderData } = useOrderData(orderId);
	const commonQualification = commonQualificationDetails?.id ? [commonQualificationDetails] || [] : [];

	// Context
	const { setValues, values, isValid, resetForm, validateForm } = useFormikContext();
	const { updateFilters } = useFilterState(orderId);

	const updateFiltersValue = (fieldName, newValue) => {
		// Special case to handle the outOfBusinessReasonsIds
		if (newValue === false && fieldName === "isOutOfBusiness") {
			setValues((prevValues) => {
				const newCurrentValues = _.cloneDeep(prevValues);
				_.set(newCurrentValues, fieldName, newValue);
				_.set(newCurrentValues, "outOfBusinessReasonsIds", []);
				return newCurrentValues;
			});
		} else {
			setValues((prevValues) => {
				const newCurrentValues = _.cloneDeep(prevValues);
				_.set(newCurrentValues, fieldName, newValue);
				return newCurrentValues;
			});
		}
	};

	const handleConfirmClick = useCallback(() => {
		dispatchMatching({
			type: "SET_UNIFIED_TEMP_WORKER_ID",
			payload: null,
		});
		dispatchMatching({
			type: "SET_HAS_FILTERS",
			payload: true,
		});
		updateFilters({ ...values, agency: filters.agency }, filters, {
			needConversion: true,
			wideLoadingMessage: "Recherche de profils en cours...",
		});
		dispatchMatching({
			type: "SET_FILTER_MATCHING_MODAL_OPEN",
			payload: false,
		});
	}, [values, filters]);

	const handleQualificationSelect = useCallback(
		(item) => {
			const selectedIndex = values?.qualifications.findIndex((profile) => profile.id === item.id);
			if (selectedIndex !== -1) {
				// TODO: replace profiles by qualifications
				const updatedQualifications = values?.qualifications
					.filter((qualification) => qualification.id !== item.id)
					.map((qualification) => ({
						id: qualification.id,
						name: qualification.name,
					}));
				updateFiltersValue("qualifications", updatedQualifications);
			} else {
				updateFiltersValue(
					"qualifications",
					values?.qualifications.concat([item]).map((qualification) => ({
						id: qualification.id,
						name: qualification.name,
					}))
				);
			}
		},
		[values.qualifications]
	);

	const handleQualificationDelete = (qualificationId) => {
		const filteredQualifications = values?.qualifications?.filter(({ id }) => id !== qualificationId);
		updateFiltersValue("qualifications", filteredQualifications);
	};

	const handleCommonDocumentDelete = (commonDocumentId) => {
		const filteredCommonDocuments = values?.commonDocuments?.filter(({ id }) => id !== commonDocumentId);
		updateFiltersValue("commonDocuments", filteredCommonDocuments);
	};

	const handleCommonDocumentSelect = useCallback(
		(item) => {
			const selectedIndex = values?.commonDocuments.findIndex((commonDocument) => commonDocument.id === item.id);
			if (selectedIndex !== -1) {
				const updatedCommonDocuments = values?.commonDocuments
					.filter((commonDocuments) => commonDocuments.id !== item.id)
					.map((commonDocuments) => ({
						id: commonDocuments.id,
						name: commonDocuments.name,
					}));
				updateFiltersValue("commonDocuments", updatedCommonDocuments);
			} else {
				updateFiltersValue(
					"commonDocuments",
					values?.commonDocuments.concat([item]).map((commonDocuments) => ({
						id: commonDocuments.id,
						name: commonDocuments.name,
					}))
				);
			}
		},
		[values.commonDocuments]
	);

	const handleEquipmentSelect = useCallback(
		(item) => {
			const selectedIndex = values?.equipments.findIndex((equipmentId) => equipmentId === item.id);
			if (selectedIndex !== -1) {
				const updatedEquipments = values?.equipments.filter((equipmentId) => equipmentId !== item.id);
				updateFiltersValue("equipments", updatedEquipments);
			} else {
				updateFiltersValue("equipments", values?.equipments.concat([item.id]));
			}
		},
		[values.equipments]
	);

	const handleEquipmentDelete = (equipmentId) => {
		const filteredQualifications = values?.equipments?.filter((id) => id !== equipmentId);
		updateFiltersValue("equipments", filteredQualifications);
	};

	const handleRadiusChange = (newValue) => {
		updateFiltersValue("distance", newValue === 100 ? null : newValue);
	};

	return (
		<div>
			<div className={style.content}>
				{/* // Form sections: only one for the moment add others sections there */}
				<div className={style.sections}>
					<BusinessInformationFilter
						onStatusChange={(fieldName, newValue) => {
							updateFiltersValue(fieldName, newValue);
						}}
						onRadiusChange={handleRadiusChange}
						onQualificationSelect={handleQualificationSelect}
						onQualificationDelete={handleQualificationDelete}
						onCommonDocumentSelect={handleCommonDocumentSelect}
						onCommonDocumentDelete={handleCommonDocumentDelete}
						onVehiclesChange={(fieldName, newValue) => {
							updateFiltersValue(fieldName, newValue);
						}}
						onOutOfBusinessReasons={(fieldName, newValue) => {
							updateFiltersValue(fieldName, newValue);
						}}
						onEquipmentSelect={handleEquipmentSelect}
						onEquipmentDelete={handleEquipmentDelete}
						onAvailabilityElementChange={(fieldName, newValue) => {
							updateFiltersValue(fieldName, newValue);
						}}
						onPeriodChange={(fieldName, newValue) => {
							updateFiltersValue(fieldName, newValue);
						}}
					/>
				</div>
			</div>
			<div className={cn([style.buttonsBlock])}>
				<button
					className={style.resetFilters}
					type="button"
					onClick={() => {
						// FIXME: Once validation form wide is done
						// there will be no need to revert qualifications to initial form values
						const newQualifications = commonQualification.length > 0 ? commonQualification : [];

						resetForm({
							values: {
								...matchingFormDefaultValues,
								qualifications: newQualifications,
								period: {
									startDate: orderData?.data?.period?.startDate,
									endDate: orderData?.data?.period?.endDate,
								},
							},
						});
						requestAnimationFrame(() => {
							validateForm();
						});
					}}
				>
					{t("commands.profiles.modalsContent.matchingFilters.resetFilters")}
				</button>
				<Button
					className={style.validationButtonRight}
					disabled={!isValid}
					color={!isValid ? "grey" : "primary"}
					type="button"
					onClick={handleConfirmClick}
				>
					{t("commands.profiles.modalsContent.matchingFilters.confirmAction")}
				</Button>
			</div>
		</div>
	);
};

const ModalContentMatchingFilter = () => {
	// Context
	const { orderId } = useParams();
	const { getInitialFilters, isEmptyQualification } = useFilterState(orderId);

	const { commonQualificationDetails, orderData } = useOrderData(orderId);

	const initialFilters = getInitialFilters("form");
	const hasInitialPeriod = !!initialFilters?.period?.startDate && !!initialFilters?.period?.endDate;
	const { startDate: orderStartDate, endDate: orderEndDate } = orderData?.data?.period ?? {};
	const { startDate: initialStartDate, endDate: initialEndDate } = initialFilters?.period || {};

	const commonQualification = useMemo(() => {
		if (isEmptyQualification) {
			return [];
		}
		if (
			initialFilters?.qualifications?.length > 0 &&
			initialFilters?.qualifications?.find((q) => q.id === commonQualificationDetails?.id) === undefined
		) {
			// At least one qualification is in the params but not is not the order qualification
			return [];
		}
		return commonQualificationDetails?.id ? [commonQualificationDetails] || [] : [];
	}, [commonQualificationDetails, isEmptyQualification]);

	return (
		<div className={style.container}>
			<Formik
				initialValues={{
					...initialFilters,
					qualifications: [
						...initialFilters.qualifications.filter(
							(qualification) => qualification.id !== commonQualificationDetails.id
						),
						...commonQualification,
					],
					period: {
						startDate: hasInitialPeriod ? initialStartDate : orderStartDate,
						endDate: hasInitialPeriod ? initialEndDate : orderEndDate,
					},
				}}
				validationSchema={validationSchema(orderData)}
				validateOnMount
			>
				<Form>
					<ModalContentMatchingForm />
				</Form>
			</Formik>
		</div>
	);
};

export default ModalContentMatchingFilter;
