// @ts-nocheck

/* eslint-disable jsx-a11y/control-has-associated-label */
import { useEffect, useMemo, useRef, useState } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useScroll } from "react-use";

// store
import useAppContext from "../../../store/useAppContext";

import {
	SUSPICION_TABLE_FIRST_ROW_HEIGHT,
	SUSPICION_TABLE_ROW_CATEGORY_HEIGHT,
	SUSPICION_TABLE_ROW_HEIGHT,
	SUSPICION_TABLE_ROW_WIDTH,
	WORKER_INFO_SECTION,
} from "../../../constants";
import {
	checkSuspiciousValue,
	formatSuspiciousFormFields,
	getPropertyForAllAgencies,
	getSuspiciousCheckboxValue,
	getValueToSet,
	isDissociated,
	isTheSameForAll,
	setSuspiciousCheckboxValue,
} from "../../../utils/suspiciousSheetUtils";
// eslint-disable-next-line max-len
import { Picto, Text } from "@zolteam/react-ras-library";
import { CheckPicto, CheckableInput, SuspiciousTableHeaderCorner } from "src/components/atoms";
// Components
import { SuspiciousTableFixedHeader, SuspiciousTableLeftFixedColumn } from "src/components/molecules";

// Constants and utils
// style
import style from "./SuspiciousTable.module.css";

const SuspiciousTable = ({ width, height, data }) => {
	// local states
	const [isLineHover, setIsLineHover] = useState(null);

	// hooks
	const scrollRef = useRef(null);
	const { x, y } = useScroll(scrollRef);
	const [{ suspiciousSheetForm }, appDispatch] = useAppContext();
	const { t } = useTranslation();

	const hiddenCols = useMemo(() => {
		const totalCol = data?.users?.length;
		const visibleCols = Math.round(width / SUSPICION_TABLE_ROW_WIDTH) - 1;
		const leftHiddenCols = Math.round(x / SUSPICION_TABLE_ROW_WIDTH);
		const rightHiddenCols = totalCol - visibleCols - leftHiddenCols;
		return {
			left: leftHiddenCols > 0 ? leftHiddenCols : 0,
			right: rightHiddenCols > 0 ? rightHiddenCols : 0,
		};
	}, [x, data, width]);

	const notDissociatedUsers = useMemo(
		() => data?.users?.filter(({ id }) => !suspiciousSheetForm?.dissociatedUsers?.includes(id)),
		[suspiciousSheetForm?.dissociatedUsers]
	);

	const orderedUsers = useMemo(() => {
		const notDissociated = notDissociatedUsers?.sort((a, b) => a?.agencyId > b.agencyId);
		const dissociated = data?.users?.filter(({ id }) => suspiciousSheetForm?.dissociatedUsers?.includes(id));
		return notDissociated?.concat(dissociated);
	}, [suspiciousSheetForm?.dissociatedUsers]);

	useEffect(() => {
		const initialFieldToFill = WORKER_INFO_SECTION?.reduce((acc, info) => {
			if (!isTheSameForAll(Object.keys(info)?.[0], [...notDissociatedUsers, ...[data?.temporaryWorker]])) {
				return acc.concat(Object.keys(info)?.[0]);
			}
			return acc;
		}, []);
		appDispatch({
			type: "SET_SUSPICIOUS_FORM_TOTAL_FIELDS",
			payload: initialFieldToFill,
		});
		if (notDissociatedUsers?.length === 0) {
			appDispatch({
				type: "SET_SUSPICIOUS_FORM_WORKER_VALUES",
				payload: data?.temporaryWorker,
			});
		}
	}, [suspiciousSheetForm?.dissociatedUsers]);

	// init form values
	useEffect(() => {
		const initialFormData = Object.entries(data?.temporaryWorker)?.reduce((acc, [key, value]) => {
			if (isTheSameForAll(key, [...notDissociatedUsers, ...[data?.temporaryWorker]])) {
				return { ...acc, [key]: value };
			}
			return acc;
		}, []);

		appDispatch({
			type: "SET_SUSPICIOUS_FORM_WORKER_VALUES",
			payload: initialFormData,
		});

		appDispatch({
			type: "SET_SUSPICIOUS_FORM_USERS",
			payload: data?.users?.map(({ updatedAt, id }) => ({ updatedAt, id })),
		});

		appDispatch({
			type: "CLEAR_SUSPICIOUS_FORM_DISSOCIATED_USERS",
		});
	}, []);

	const renderDissociatedOverlay = (id) => {
		if (isDissociated(id, suspiciousSheetForm)) {
			return (
				<div
					className={style.dissociatedBackground}
					style={{
						width: SUSPICION_TABLE_ROW_WIDTH,
						height: SUSPICION_TABLE_ROW_HEIGHT,
					}}
				/>
			);
		}
		return null;
	};

	return (
		<div
			ref={scrollRef}
			className={style.tableContainer}
			style={{
				width,
				height,
			}}
		>
			{hiddenCols?.right ? (
				<div className={`${style.hiddenColumnsTag} ${style.hiddenTagRight}`}>
					<Text tag="span" size="paragraph01" color="primary">
						{hiddenCols?.right}
					</Text>
					<Picto icon="chevronRight" />
				</div>
			) : null}
			{hiddenCols?.left ? (
				<div className={`${style.hiddenColumnsTag} ${style.hiddenTagLeft}`}>
					<Picto icon="chevronLeft" />
					<Text tag="span" size="paragraph01" color="primary">
						{hiddenCols?.left}
					</Text>
				</div>
			) : null}
			{/* Agencies header */}
			<SuspiciousTableFixedHeader top={y} users={orderedUsers} agencies={data?.agencies} />
			{/* corner header values */}
			<SuspiciousTableHeaderCorner top={y} temporaryWorker={data.temporaryWorker} left={x} />
			{/* Left column with info ordered by categories */}
			<SuspiciousTableLeftFixedColumn
				left={x}
				users={notDissociatedUsers}
				isHover={isLineHover}
				setHover={setIsLineHover}
				temporaryWorker={data.temporaryWorker}
			/>
			{/* Table values */}
			<table>
				<thead>
					<tr>
						<th
							style={{
								minWidth: SUSPICION_TABLE_ROW_WIDTH - 2,
								height: SUSPICION_TABLE_FIRST_ROW_HEIGHT - 2,
							}}
						>
							{" "}
						</th>
						{orderedUsers.map(({ id }) => (
							<th
								key={id}
								style={{
									minWidth: SUSPICION_TABLE_ROW_WIDTH - 2,
									height: SUSPICION_TABLE_FIRST_ROW_HEIGHT - 2,
								}}
							>
								<div />
							</th>
						))}
					</tr>
				</thead>
				<tbody className={style.tableBody}>
					{WORKER_INFO_SECTION.map((info, index) => {
						const { isCategory, isNotCheckableIfNull } = Object.values(info)?.[0] ?? {};
						const rowTitle = Object.keys(info)?.[0];
						return (
							<tr
								// eslint-disable-next-line react/no-array-index-key
								key={`${rowTitle}-${index}`}
								className={`${isLineHover === index ? style.hover : ""}`}
								style={{
									height: isCategory
										? SUSPICION_TABLE_ROW_CATEGORY_HEIGHT
										: SUSPICION_TABLE_ROW_HEIGHT,
								}}
								onMouseEnter={() => setIsLineHover(index)}
								onMouseLeave={() => setIsLineHover(null)}
							>
								<td />
								{isCategory
									? orderedUsers?.map(({ id }) => (
											<td className="relative" key={id}>
												{renderDissociatedOverlay(id)}
											</td>
										))
									: getPropertyForAllAgencies(rowTitle, orderedUsers).map(
											({ value, currentUser }) => (
												<td className="relative" key={`${rowTitle}-${currentUser.id}`}>
													{renderDissociatedOverlay(currentUser.id)}
													<div
														className="d-flex justify-center align-center"
														style={{ width: SUSPICION_TABLE_ROW_WIDTH }}
													>
														{isTheSameForAll(rowTitle, [
															...notDissociatedUsers,
															...[data?.temporaryWorker],
														]) ? (
															<CheckPicto />
														) : (
															<div
																style={{
																	maxWidth: SUSPICION_TABLE_ROW_WIDTH - 80,
																}}
															>
																<CheckableInput
																	id={`${currentUser.id}-${Object.keys(info)?.[0]}`}
																	disabled={isTheSameForAll(Object.keys(info)?.[0], [
																		...notDissociatedUsers,
																		...[data?.temporaryWorker],
																	])}
																	isCheckable={
																		!(isNotCheckableIfNull && value === null)
																	}
																	textValue={formatSuspiciousFormFields(
																		rowTitle,
																		value ?? "",
																		currentUser
																	)}
																	value={getSuspiciousCheckboxValue({
																		userValue: value,
																		property: rowTitle,
																		suspiciousSheetForm,
																	})}
																	onChange={(checkboxValue) => {
																		setSuspiciousCheckboxValue({
																			property: Object.keys(info)?.[0],
																			value: checkboxValue
																				? getValueToSet(
																						Object.keys(info)?.[0],
																						currentUser
																					)
																				: undefined,
																			appDispatch,
																			suspiciousSheetForm,
																		});
																	}}
																	borderColor={
																		checkSuspiciousValue(
																			suspiciousSheetForm?.temporaryWorker?.[
																				rowTitle
																			]
																		)
																			? "grey"
																			: "primary"
																	}
																	placeholder={t(
																		`suspiciousSheet.workerInfo.${Object.keys(info)?.[0]}`
																	)}
																/>
															</div>
														)}
													</div>
												</td>
											)
										)}
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};

SuspiciousTable.propTypes = {
	width: PropTypes.number.isRequired,
	height: PropTypes.number.isRequired,
	data: PropTypes.shape({
		agencies: PropTypes.arrayOf(PropTypes.shape({})),
		temporaryWorker: PropTypes.shape({}),
		users: PropTypes.arrayOf(PropTypes.shape({})),
	}).isRequired,
};

export default SuspiciousTable;
