import client from "src/services/client";

export type OrderProfilesItemType = {
	avatarUrl?: string;
	firstName?: string;
	lastName?: string;
	hasBeenShared: boolean;
	id: number;
	isCdiInterim: boolean;
	isDelegatedBy247: boolean;
	isUnifiedTempWorkerValidated: boolean;
	isVip: boolean;
	missionOrderHasBeenShared: boolean;
	orderId: number;
	phone: string;
	status: string;
	unifiedTempWorkerId: number;
	userId: number;
	version: number;
};

export type OrderProfilesApiResponse = OrderProfilesItemType[];

export const getOrderProfiles = (orderId: string): Promise<AxiosResponse<OrderProfilesApiResponse>> =>
	client.get(`api-client-v2/orders/${orderId}/profiles`);
