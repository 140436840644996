import { FC } from "react";

import { NavLink } from "react-router-dom";

import cn from "../../../utils/cn";
import { Picto, PopoverCursor } from "@zolteam/react-ras-library";

import style from "./MenuItem.module.css";

interface IMenuItemProps {
	children: React.ReactNode;
	popoverLabel: string;
	handleMenuOpen: (value: boolean) => void;
	menuOpen: boolean;
	picto: string;
	path: string;
	isExternal: boolean;
	open: boolean;
	hasChilds: boolean;
	childs?: React.ReactNode;
	handleActiveChilds: (value: string) => void;
	activeChilds: string;
}

export const MenuItem: FC<IMenuItemProps> = ({
	children,
	popoverLabel,
	handleMenuOpen,
	menuOpen,
	picto,
	path,
	isExternal,
	open,
	hasChilds,
	childs = null,
	handleActiveChilds,
	activeChilds,
}) => (
	<PopoverCursor content={menuOpen ? <span /> : <div className={style.popoverBox}>{popoverLabel}</div>}>
		<li className={style.menuItem}>
			{isExternal ? (
				<a href={`${process.env.REACT_APP_BASE_OLD_URL}${path}`} className={style.menuItemLink}>
					<Picto
						className={cn([style.picto, picto === "power" && style.logout])}
						icon={picto}
						style={{ width: "1.375rem" }}
					/>
					{children}
				</a>
			) : null}
			{!isExternal && !hasChilds ? (
				<NavLink className={style.menuItemLink} to={path} activeClassName={style.active}>
					<Picto className={style.picto} icon={picto} style={{ width: "1.375rem" }} />
					{children}
				</NavLink>
			) : null}
			{!isExternal && hasChilds ? (
				<>
					<button
						type="button"
						className={cn([style.menuItemLink, style.menuItemChilds, open && style.active])}
						onClick={() => {
							handleMenuOpen(true);
							if (menuOpen || activeChilds !== path) {
								handleActiveChilds(path);
							}
						}}
					>
						<span className={style.menuItemChildsName}>
							<Picto className={style.picto} icon={picto} style={{ width: "1.375rem" }} />
							{children}
						</span>
						<span className={cn([style.menuItemChildsIcon, activeChilds === path && style.activeChild])}>
							<Picto className={style.menuItemPicto} icon="chevronRight" />
						</span>
					</button>
					{hasChilds ? (
						<div
							className={cn([
								style.menuItemChildsWrapper,
								menuOpen && activeChilds === path && style.activeMenuItemChilds,
							])}
						>
							{childs || null}
						</div>
					) : null}
				</>
			) : null}
		</li>
	</PopoverCursor>
);
