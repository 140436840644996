import { FC } from "react";

import { t } from "i18next";

import { WithSideMenu, WithTopBarre } from "src/components/templates";

interface IPage404Props {
	text?: string;
	className?: string;
}

const Page404: FC<IPage404Props> = ({ text = t("404.message"), className }) => {
	return (
		<WithSideMenu containerClassName={className}>
			<WithTopBarre quickEntryContent={<div>test</div>} title={""} containerClassname={`container`}>
				{text}
			</WithTopBarre>
		</WithSideMenu>
	);
};

export default Page404;
