import React, { PropsWithChildren } from "react";

import { NavLink } from "react-router-dom";

import cn from "src/utils/cn";

import style from "./CommandSideMenuItem.module.css";

interface ICommandSideMenuItemProps extends PropsWithChildren {
	path: string;
}

export const CommandSideMenuItem: React.FC<ICommandSideMenuItemProps> = ({ path, children }) => (
	<button type="button" className={style.btn}>
		<NavLink
			className={(isActive: boolean) => {
				return cn([style.sidemenuItem, isActive && style.active]);
			}}
			to={path}
		>
			{children}
		</NavLink>
	</button>
);
