import { AxiosRequestConfig } from "axios";

const CONFIG_EXPORT_STREAM: AxiosRequestConfig = {
	headers: {
		"Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
	},
	responseType: "stream",
	adapter: "fetch",
	timeout: 0,
};

export default CONFIG_EXPORT_STREAM;
