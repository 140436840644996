// @ts-nocheck
import { useEffect } from "react";

import { useHistory, useParams } from "react-router-dom";

// Hooks
import useEnabledFeatures from "../../../hooks/useEnabledFeatures";
import useOrderData from "../../../hooks/useOrderData";
import { MatchingFormProvider, MatchingStateContext } from "../../../store/MatchingContext";

import MatchingHeader from "../../../components/molecules/MatchingHeader";
import MatchingProfileSections from "../../../components/organisms/MatchingProfileSections";
import MatchingResultList from "../../../components/organisms/MatchingResultList";
// Utils
import cn from "../../../utils/cn";
import EmptySection from "./EmptySection";
// Components
import { WideLoader } from "src/components/molecules";

// Style
import style from "./Matching.module.css";

const Matching = () => {
	const history = useHistory();
	const { orderId } = useParams();
	const { isOrderFetched } = useOrderData(orderId);

	// INFO: remove this when the feature is ready
	const {
		enabledFeatures: { hasMatchingAccess },
		query: { isLoading },
	} = useEnabledFeatures();

	// INFO: Remove when ready
	useEffect(() => {
		if (!isLoading && !hasMatchingAccess) {
			history.push("/commandes");
		}
	}, [isLoading, hasMatchingAccess]);

	// INFO: remove this when the feature is ready
	if (!hasMatchingAccess) {
		return (
			<div className={style.loading}>
				<p>Chargement...</p>
			</div>
		);
	}

	const shouldRenderEmptySection = (profilesQuery, wideLoading, emptySection) => {
		const isProfileQueryReady =
			profilesQuery.hasCommonQualification === false ||
			(profilesQuery.hasCommonQualification === true && profilesQuery.initialFetch && isOrderFetched);
		const isWideLoadingDisplayed = wideLoading.isDisplayed;
		const isEmptySectionDisplayed = emptySection.isDisplayed;

		return isProfileQueryReady && !isWideLoadingDisplayed && isEmptySectionDisplayed;
	};

	return (
		<MatchingFormProvider>
			<MatchingStateContext.Consumer>
				{({ emptySection, wideLoading, profilesQuery }) => (
					<div className={style.wrapper}>
						<div
							className={cn([
								emptySection.isDisplayed ? style.emptyContentSection : null,
								style.contentSection,
							])}
						>
							<div className={style.leftSection}>
								<MatchingHeader />
								<MatchingResultList />
							</div>
							<div
								className={cn([
									style.rightSection,
									wideLoading.isDisplayed || emptySection.isDisplayed
										? style.emptyRightSection
										: null,
								])}
							>
								<MatchingProfileSections />
							</div>
							{shouldRenderEmptySection(profilesQuery, wideLoading, emptySection) && <EmptySection />}
						</div>
						{wideLoading.isDisplayed && <WideLoader message={wideLoading.message} />}
					</div>
				)}
			</MatchingStateContext.Consumer>
		</MatchingFormProvider>
	);
};

export default Matching;
