// @ts-nocheck
import client from "./client";

/**
 * @description Upload purge file to S3
 * @param {Object} params
 * @returns {Promise<AxiosResponse<any>>}
 */
const postUploadPurgeFile = (params) =>
	client.post("/api-client-v2/purge-rgpd/upload-file", params, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});

const endpoints = { postUploadPurgeFile };

export default endpoints;
