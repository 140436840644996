import i18n from "src/i18n";
import * as Yup from "yup";

const PeriodNotWorkingValidatorTest = () => {
	return Yup.string()
		.nullable()
		.max(40, i18n.t("commands.error.maxCaractersLimit", { count: 40 }));
};

export default PeriodNotWorkingValidatorTest;
