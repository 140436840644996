import { useCallback, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

// Types
import OrderProfileTypes from "src/services/types/OrderProfileTypes";

// Hooks
import useOrderData from "src/hooks/useOrderData";

import TCardAction from "../../types/TCardAction";
import handlePictoVariant from "./internals/handlePictoVariant";
// Internals
import handlePopoverContent from "./internals/handlePopoverContent";

// Constants
import { ORDER_STATUS, PROFILES_STATUS } from "src/constants";
import PROFILE_COMMUNICATION_DELIVERY_STATUS from "src/constants/ProfileCommunicationStatus";

const useDragCard = (profile: OrderProfileTypes, availableActions: TCardAction[]) => {
	const { t } = useTranslation();
	const { orderId } = useParams<{ orderId: string }>();
	const { orderData } = useOrderData(orderId);
	const { offerShareStatus, missionOrderShareStatus } = profile;

	const actions = useMemo(() => {
		let allowedActions = availableActions;
		if (!profile.userId) {
			allowedActions = allowedActions.filter((action) => action.type !== "goToFi");
		}
		if (
			(profile.status !== PROFILES_STATUS.SELECTED && profile.status !== PROFILES_STATUS.CONTACTED) ||
			profile.offerShareStatus ||
			orderData?.data?.status === ORDER_STATUS.IS_FILLED
		) {
			allowedActions = allowedActions.filter((action) => action.type !== "shareAction");
		}
		if (profile.status !== PROFILES_STATUS.VALIDATED) {
			allowedActions = allowedActions.filter((action) => action.type !== "shareMissionOrder");
		}
		return allowedActions;
	}, [availableActions, profile, orderData]);

	const memoizedHandlePictoVariant = useCallback(
		(status: keyof typeof PROFILE_COMMUNICATION_DELIVERY_STATUS, type: "missionOrder" | "missionOffer") => {
			return handlePictoVariant(status);
		},
		[offerShareStatus, missionOrderShareStatus]
	);

	const memoizedHandlePopoverContent = useCallback(
		(status: keyof typeof PROFILE_COMMUNICATION_DELIVERY_STATUS, type: "missionOrder" | "missionOffer") => {
			return handlePopoverContent(status, type, t);
		},
		[offerShareStatus, missionOrderShareStatus, t]
	);

	return {
		actions,
		offerShareStatus,
		missionOrderShareStatus,
		memoizedHandlePictoVariant,
		memoizedHandlePopoverContent,
	};
};

export default useDragCard;
