//@ts-nocheck
import { FC, useState } from "react";

import { useTranslation } from "react-i18next";
// Style
import { TAgency } from "src/types/TAgencies";
import { TUser } from "src/types/TUser";

// Store
import useAppContext from "../../../store/useAppContext";

// Constants
import { SUSPICION_TABLE_FIRST_ROW_HEIGHT, SUSPICION_TABLE_ROW_WIDTH } from "../../../constants";
import cn from "../../../utils/cn";
// Utils
import { formatDate } from "../../../utils/formatDate";
import { isDissociated } from "../../../utils/suspiciousSheetUtils";
// Modals
import { ShareDetachedModal } from "../../../views/SuspiciousSheet/Modals/SuspiciousSheetModal";
import { Tag } from "../../atoms/index";
// Composants
import { Button, Picto, Text, Tooltip, useModal } from "@zolteam/react-ras-library";

import style from "./SuspiciousTableFixedHeader.module.css";

interface ISuspiciousSheetHeaderProps {
	top: React.CSSProperties["top"];
	users: TUser[];
	agencies: TAgency[];
}

export const SuspiciousTableFixedHeader: FC<ISuspiciousSheetHeaderProps> = ({ top, users, agencies }) => {
	const [currentUser, setCurrentUser] = useState(null);
	const { t } = useTranslation();
	const [modalDetached, toggleModalDetached] = useModal();
	const [{ suspiciousSheetForm }, appDispatch] = useAppContext();

	const tooltipDissociateStyle = {
		maxWidth: "inherit",
		whiteSpace: "nowrap",
		padding: "var(--padding-s) var(--padding-m)",
	};

	const handleDetachedUser = () => {
		if (!currentUser) {
			return;
		}
		let newDissociatedUsers;
		if (suspiciousSheetForm?.dissociatedUsers?.includes(currentUser)) {
			newDissociatedUsers = suspiciousSheetForm.dissociatedUsers.filter((id) => id !== currentUser);
		} else {
			newDissociatedUsers = suspiciousSheetForm.dissociatedUsers.concat([currentUser]);
		}
		appDispatch({
			type: "SET_SUSPICIOUS_FORM_DISSOCIATED_USERS",
			payload: { dissociated: newDissociatedUsers, all: users },
		});
		setCurrentUser(null);
		toggleModalDetached();
	};

	const handleAssociateUser = (id: number) => {
		appDispatch({
			type: "CLEAR_SUSPICIOUS_FORM",
		});
		appDispatch({
			type: "SET_SUSPICIOUS_FORM_DISSOCIATED_USERS",
			payload: {
				dissociated: suspiciousSheetForm.dissociatedUsers.filter((userId: number) => userId !== id),
				all: users,
			},
		});
	};

	return (
		<>
			<div
				className={style.tableTopHeader}
				style={{
					top,
					left: SUSPICION_TABLE_ROW_WIDTH + 4,
					height: SUSPICION_TABLE_FIRST_ROW_HEIGHT,
				}}
			>
				{users?.map(
					({ agencyId, id, tempoId, lastActivityType, lastActivityDate, mainQualification, isAvailable }) => (
						<div key={id} className="relative d-flex f-column justify-center align-center">
							<div
								className={style.tableHeaderColumnContainer}
								style={{
									width: SUSPICION_TABLE_ROW_WIDTH,
									height: SUSPICION_TABLE_FIRST_ROW_HEIGHT,
								}}
							>
								<div className={style.titleContainer}>
									<Tooltip
										arrow={false}
										offset={[0, -2]}
										placement="bottom-start"
										content={
											<div className={style.content}>
												{mainQualification ? (
													<Text tag="span" size="paragraph02" fontWeight="bold">
														{mainQualification}
													</Text>
												) : null}
												{lastActivityType ? (
													<Text color="grey" tag="span" size="paragraph03">
														<span className="mr-xs">
															{t(`suspiciousSheet.lastActivityType.${lastActivityType}`)}
														</span>
														{formatDate(lastActivityDate)}
													</Text>
												) : null}
												{isAvailable ? (
													<Tag
														size="xs"
														radius="s"
														color="success"
														className={style.tagTooltip}
													>
														{t("suspiciousSheet.available")}
													</Tag>
												) : (
													<Tag size="xs" radius="s" color="grey" className={style.tagTooltip}>
														{t("suspiciousSheet.inactive")}
													</Tag>
												)}
											</div>
										}
									>
										<button type="button" onClick={() => {}} aria-label="info">
											<Picto
												icon="info"
												style={{
													width: "16px",
												}}
											/>
										</button>
									</Tooltip>
									{agencies?.length ? (
										<Text tag="p" size="paragraph02" fontWeight="bold" lineHeight="s">
											{agencies?.find(({ id: agency }) => {
												return parseInt(agency, 10) === parseInt(agencyId, 10);
											})?.name || ""}
										</Text>
									) : null}
									{tempoId ? (
										<Tag size="s" className={style.tag}>
											{tempoId}
										</Tag>
									) : null}
									<Tooltip
										arrow={false}
										offset={[0, -2]}
										placement="bottom-start"
										tooltipBoxStyle={tooltipDissociateStyle}
										content={
											<Text tag="span" size="paragraph02">
												{t("suspiciousSheet.dissociateUser")}
											</Text>
										}
									>
										<button
											data-testid={`${id}-dissociate`}
											type="button"
											className={cn(["noButton", style.buttonIcon])}
											onClick={() => {
												setCurrentUser(id);
												toggleModalDetached();
											}}
											aria-label="dissociate"
										>
											<Picto icon="detached" />
										</button>
									</Tooltip>
								</div>
								{lastActivityType ? (
									<Text tag="span" size="paragraph03">
										<span className="mr-xs">
											{t(`suspiciousSheet.lastActivityType.${lastActivityType}`)}
										</span>
										{formatDate(lastActivityDate, "dd MMM yyyy")}
									</Text>
								) : null}
							</div>
							{isDissociated(id, suspiciousSheetForm) ? (
								<>
									<div className={style.dissociatedDescription}>
										<Text tag="p" size="paragraph01" fontWeight="bold" lineHeight="s">
											{t("suspiciousSheet.dissociatedSheet")}
										</Text>
										<div className="mt-m">
											<Button
												type="button"
												size="s"
												color="primary"
												outline
												onClick={() => handleAssociateUser(id)}
											>
												{t("suspiciousSheet.associateAgain")}
											</Button>
										</div>
									</div>
									<div
										className={style.dissociatedBackground}
										style={{ width: SUSPICION_TABLE_ROW_WIDTH - 4 }}
									/>
								</>
							) : null}
						</div>
					)
				)}
			</div>
			<ShareDetachedModal
				isDisplayed={modalDetached}
				toggleModal={toggleModalDetached}
				handleModalAction={handleDetachedUser}
			/>
		</>
	);
};
