/* eslint-disable react/jsx-props-no-spreading */
import { ReactNode, useState } from "react";

import { upperFirst } from "lodash";
import moment, { Moment } from "moment";
import "moment/locale/fr";
import { ModifiersShape } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import { SelectV2 } from "@zolteam/react-ras-library";
import { DateRangeInput } from "src/components/atoms";
import { DateSinglePicker } from "src/components/molecules";

import { getYears } from "src/utils/dates";

import style from "./DateSinglePickerField.module.css";

type TOption = {
	value: string;
	label: string;
};

interface IDateSinglePickerFieldProps {
	id: string;
	name: string;
	label: string;
	value?: string;
	error?: string;
	info?: string;
	onDateChange: (date: moment.Moment | string | Date | null) => void;
	onClear?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
	onBlur?: () => void;
	onClickOnInput?: () => void;
	containerClassname?: string;
	inputClassName?: string;
	keepOpenOnDateSelect?: boolean;
	isDayBlocked?: (day: moment.Moment) => boolean;
	disabled?: boolean;
	numberOfMonths?: number;
	selectableMonthsYears?: boolean;
	writable?: boolean;
	showErrorMessage?: boolean;
	required?: boolean;
	convertToMoment?: boolean;
	renderDayContents?: (day: Moment, modifiers: ModifiersShape) => ReactNode;
}

const selectableMonths: (props: {
	month: moment.Moment;
	onMonthSelect: (currentMonth: moment.Moment, newMonthVal: string) => void;
	onYearSelect: (currentMonth: moment.Moment, newMonthVal: string) => void;
	isVisible: boolean;
}) => React.ReactNode = ({ month, onMonthSelect, onYearSelect }) => {
	const years = getYears(1900, 2026);

	return (
		<div className="flex flex-col selectable">
			<div className="flex justify-between -translate-y-1">
				<SelectV2
					size="small"
					value={month.month()}
					name="month"
					options={moment.months().map((label, value) => ({
						value: value,
						label: label,
					}))}
					onChange={(item: TOption) => {
						onMonthSelect(month, item.value);
					}}
					getOptionLabel={(option: TOption) => upperFirst(option.label)}
					className={style.selectMonth + " flex-1 me-2"}
				/>
				<SelectV2
					size="small"
					value={month.year()}
					name="year"
					options={years.map((value) => ({
						value: value,
						label: value,
					}))}
					onChange={(item: TOption) => {
						onYearSelect(month, item.value);
					}}
					getOptionLabel={(option: TOption) => upperFirst(option.label)}
					className={style.selectMonth}
				/>
			</div>
		</div>
	);
};

export const DateSinglePickerField: React.FC<IDateSinglePickerFieldProps> = ({
	id,
	label,
	name,
	value = "",
	error = "",
	info = "",
	onDateChange = (date: Date) => {},
	onClear,
	onClickOnInput,
	onBlur,
	containerClassname = "",
	inputClassName = "",
	keepOpenOnDateSelect = false,
	isDayBlocked,
	disabled = false,
	numberOfMonths = 2,
	selectableMonthsYears,
	writable,
	showErrorMessage,
	required,
	convertToMoment = true,
	renderDayContents,
}) => {
	const [focusedInputName, setFocusedInputName] = useState("");

	return (
		<div className={containerClassname}>
			<DateRangeInput
				label={label}
				value={convertToMoment ? (value ? moment(value).format("DD/MM/yyyy") : "") : value ? value : ""}
				onClick={() => {
					setFocusedInputName(name);
					if (onClickOnInput) onClickOnInput();
				}}
				onClear={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
					event.stopPropagation();
					if (onClear) onClear(event);
				}}
				className={inputClassName}
				isActive={focusedInputName === name}
				error={error}
				info={info}
				disabled={disabled}
				writable={writable}
				name={name}
				showErrorMessage={showErrorMessage}
				onDateChange={onDateChange}
				required={required}
				onBlur={onBlur}
			/>
			<DateSinglePicker
				id={id}
				dateString={
					convertToMoment
						? value
						: value && moment(value, "DD/MM/YYYY", true).isValid()
							? moment(value, "DD/MM/YYYY", true)
							: ""
				}
				focusedInput={focusedInputName?.replace("period.", "")}
				setFocusedInput={(inputName) => {
					setFocusedInputName(inputName ? `period.${inputName}` : "");
				}}
				onDateChange={onDateChange}
				isVisible={focusedInputName !== ""}
				setIsVisible={() => setFocusedInputName("")}
				keepOpenOnDateSelect={keepOpenOnDateSelect}
				isDayBlocked={isDayBlocked}
				numberOfMonths={numberOfMonths}
				className={"absolute z-50 " + (selectableMonthsYears && style.datePicker)}
				renderMonthElement={selectableMonthsYears && selectableMonths}
				renderDayContents={renderDayContents}
			/>
		</div>
	);
};
