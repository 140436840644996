import React, { FC, PropsWithChildren } from "react";

import { Tooltip } from "@zolteam/react-ras-library";

import style from "./PopoverListMaxWidth.module.css";

interface IPopoverListMaxWidthProps extends PropsWithChildren {
	click?: () => void;
	content: React.ReactNode;
}

export const PopoverListMaxWidth: FC<IPopoverListMaxWidthProps> = ({ click = () => {}, content, children }) => (
	<Tooltip arrow={false} animation={false} placement="bottom" content={content}>
		<button type="button" className={style.button} onClick={click}>
			{children}
		</button>
	</Tooltip>
);
