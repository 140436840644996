// @ts-nocheck
import { Form, Formik } from "formik";
import { t } from "i18next";
import moment from "moment";
import PropTypes from "prop-types";
import { useQuery } from "react-query";

import advancePayments from "../../../../services/advancePayments";

import { useToastContext } from "../../../../store/ToastContext";

import cn from "../../../../utils/cn";
import { getAtomFromDate } from "../../../../utils/formatDate";
import isDayBlocked from "../../utils/isDayBlocked";
import editAdvancePaymentsFormValidation from "./editAdvancePaymentsFormValidation";
import { Button, InfoMessage, Input, Picto, Textarea } from "@zolteam/react-ras-library";
import { Spinner } from "src/components/atoms";
import { DateSinglePickerField } from "src/components/molecules";

import "./FormEditAdvancePayments.css";
import style from "./FormEditAdvancePayments.module.css";

const FormEditAdvancePayments = ({ advancePaymentId, onSubmitSuccess, onCancel }) => {
	const [, toastDispatch] = useToastContext();
	const handleSubmitForm = (form) => {
		const updatedForm = {
			...form,
			desiredPaymentDate: moment(form.desiredPaymentDate).format("YYYY-MM-DD"),
			requestedAmount: form.requestedAmount?.toString(),
		};
		return advancePayments.patchAdvancePayment(updatedForm, advancePaymentId).then(
			() => {
				onSubmitSuccess();
			},
			(error) => {
				const msgError =
					error.response?.data?.message === "Validation errors"
						? "ERR_BR_ADVANCE_PAYMENT_0000_FORM_ERROR"
						: error.response?.data?.message;

				toastDispatch({
					type: "ERROR",
					payload: {
						icon: "alertCircle",
						msg: t(`global.apiErrors.${msgError}`),
					},
				});
			}
		);
	};

	const getAdvancePaymentById = useQuery({
		queryKey: ["getAdvancePaymentById", advancePaymentId.toString()],
		queryFn: () => advancePayments.getAdvancePaymentById(advancePaymentId),
	});

	const { isLoading: isLoadingGetAdvancePaymentById, data } = getAdvancePaymentById;
	let message = "";

	if (isLoadingGetAdvancePaymentById) return <Spinner text={t("global.loading")} />;

	const handleStatusExportTagLabelChange = (isExported) => {
		const statusLabels = {
			true: t("profile.advancePayments.statusExport.exported"),
			false: t("profile.advancePayments.statusExport.exportFailed"),
			default: t("profile.advancePayments.statusExport.exportInProgress"),
		};

		return statusLabels[isExported] || statusLabels.default;
	};

	const handleStatusExportTagColorChange = (isExported) => {
		const statusColors = {
			true: "success",
			false: "error",
			default: "primary",
		};

		return statusColors[isExported] || statusColors.default;
	};

	return (
		<div>
			{message}
			<Formik
				enableReinitialize
				validateOnMount
				initialValues={{
					...data?.data,
					userIdentity: `${data?.data?.firstName} ${data?.data?.lastName} - ${data?.data?.tempoId}`,
					desiredPaymentDate: data?.data?.desiredPaymentDate,
				}}
				validationSchema={editAdvancePaymentsFormValidation}
				onSubmit={handleSubmitForm}
				validateOnChange
			>
				{({ setFieldValue, setFieldTouched, values, isValid, isSubmitting }) => {
					return (
						<div>
							<Form id="formEdit">
								<Input
									type="string"
									name="agencyName"
									id="agencyName"
									className={style.fieldSpacing}
									label={t("profile.advancePayments.agency")}
									disabled
									value={values?.agencyName}
								/>
								<Input
									type="string"
									name="userIdentity"
									id="userIdentity"
									className={style.fieldSpacing}
									label={t("advancePayments.labelSearchUser")}
									disabled
									value={values?.userIdentity}
								/>
								<div className={style.inputWithPicto}>
									<Input
										type="string"
										name="requestedAmount"
										id="requestedAmount"
										className={style.fieldSpacing}
										label={t("advancePayments.requestedAmount")}
										value={
											values?.requestedAmount === null
												? t("profile.advancePayments.maximum")
												: values?.requestedAmount
										}
										onChange={(event) => {
											setFieldValue("requestedAmount", event.target.value);
										}}
										disabled={values?.isMaximumAdvanceRequested}
									/>
									<Picto icon="euro" className={style.infoPicto} />
								</div>

								<div className={`${style.desiredPaymentDate}`}>
									<DateSinglePickerField
										id="desiredPaymentDate"
										name="desiredPaymentDate"
										label={t("advancePayments.desiredPaymentDate")}
										value={values.desiredPaymentDate}
										onClickOnInput={() => setFieldTouched("desiredPaymentDate", true)}
										onClear={() => {
											setFieldTouched("desiredPaymentDate", true);
											setFieldValue("desiredPaymentDate", null);
										}}
										onDateChange={(date) =>
											setFieldValue("desiredPaymentDate", date ? getAtomFromDate(date) : null)
										}
										isDayBlocked={isDayBlocked}
										error={null}
										keepOpenOnDateSelect={false}
										containerClassname={style.flex80}
										inputClassName={style.desiredPaymentDateInput}
										numberOfMonths={1}
										disabled
									/>
									<div className={style.flex1} />
								</div>

								<div className="contentComment">
									<Textarea
										className="mt-l"
										label={t("advancePayments.comment")}
										placeholder={t("advancePayments.comment")}
										name="comment"
										value={values?.comment || ""}
										autoScale
										onChange={(event) => setFieldValue("comment", event.target.value)}
										shouldDisplayErrorMessage
									/>
								</div>
								<InfoMessage
									color={handleStatusExportTagColorChange(values?.isExported)}
									className="ml-10"
									withIcon={true}
								>
									{handleStatusExportTagLabelChange(values?.isExported)}
								</InfoMessage>
								<hr className={cn([style.separator])} />
								<div className={cn([style.buttonsBlock])}>
									<Button
										className={style.cancelButton}
										id="cancelButton"
										type="button"
										onClick={() => onCancel()}
										color="transparentPrimary"
									>
										{t("advancePayments.cancelAction")}
									</Button>
									<Button
										id="submitButton"
										name="submitButton"
										className={style.validationButtonRight}
										disabled={!isValid}
										color="primary"
										type="submit"
										isLoading={isSubmitting}
									>
										{t("advancePayments.editAction")}
									</Button>
								</div>
							</Form>
						</div>
					);
				}}
			</Formik>
		</div>
	);
};

FormEditAdvancePayments.propTypes = {
	advancePaymentId: PropTypes.number,
	onSubmitSuccess: PropTypes.func,
	onCancel: PropTypes.func,
};

FormEditAdvancePayments.defaultProps = {
	advancePaymentId: 0,
	onSubmitSuccess: () => {},
	onCancel: () => {},
};

export default FormEditAdvancePayments;
