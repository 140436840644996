import { FC } from "react";

import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { StatusUser } from "src/enum/enumStatusUser";
import { TTemporaryWorkerProfile } from "src/types/TTemporaryWorker";

import { TParams } from "../TemporaryWorkerSheet";
import { TAdministrativeFormValues } from "./Administrative";
import { PhoneInput } from "src/components/atoms";
import { CitySelect, CountrySelect, Field } from "src/components/molecules";

import cn from "src/utils/cn";

import { ALLOWED_LETTERS_AND_NUMBERS_REGEX } from "src/constants/CRegex";

import style from "./Administative.module.css";

const ContactInformations: FC = () => {
	const { t } = useTranslation();
	let { userId } = useParams<TParams>();
	const queryClient = useQueryClient();
	const temporaryWorker = queryClient.getQueryData<{ data: TTemporaryWorkerProfile }>(["getUser", userId]);
	const isCandidtate = [StatusUser.CANDIDATE].includes(temporaryWorker?.data?.twStatus);
	const { values, errors, touched, setFieldValue, setFieldTouched } = useFormikContext<TAdministrativeFormValues>();
	const temporaryWorkerProfile: TAdministrativeFormValues = queryClient.getQueryData<any>([
		"getTemporaryWorkerProfile",
		temporaryWorker?.data?.uniqueId,
	])?.data;

	return (
		<div className="flex flex-col w-full gap-2">
			<h2 className="text-xl font-medium ">{t("usersListing.temporaryWorker.administrative.contact")}</h2>
			<div className="flex flex-row gap-4 items-center flex-wrap">
				<CountrySelect
					name="addressCountryId"
					value={values?.addressCountryId}
					label={t("activityLogs.commonFields.wording.addressCountry")}
					required={isCandidtate}
					onBlur={() => {
						setFieldTouched("addressCountryId", true);
					}}
					onChange={(item) => {
						setFieldValue("addressCountryId", item?.id);
					}}
					containerClassname={style.fields}
					className={
						style.fields + " " + (errors.addressCountryId && touched.addressCountryId ? style.error : "")
					}
				/>

				<Field
					label={t("activityLogs.commonFields.wording.addressStreet")}
					type="text"
					name="addressStreet"
					classNameContainer={style.fields}
					required={isCandidtate}
					allowedCharacters={ALLOWED_LETTERS_AND_NUMBERS_REGEX}
					customErrorDisplay
					className={"flex-row flex-nowrap"}
					maxLength={30}
				/>

				<Field
					label={t("activityLogs.commonFields.wording.addressBuilding")}
					type="text"
					name="addressBuilding"
					classNameContainer={style.fields}
					allowedCharacters={ALLOWED_LETTERS_AND_NUMBERS_REGEX}
					customErrorDisplay
					className={"flex-row flex-nowrap"}
					maxLength={30}
				/>
				<CitySelect
					initialCountry={values?.addressCountryId || temporaryWorkerProfile?.addressCountryId}
					required={isCandidtate}
					value={values.location}
					classNameContainer={style.fieldsLg}
					classNamePostalInput={style.fieldSm}
					classNameCityInput={style.fields}
				/>
			</div>
			<div className="flex flex-row gap-4 items-center flex-wrap">
				<Field
					type="email"
					name="email"
					id="email"
					label={t("commands.email")}
					classNameContainer={style.fields}
					className={cn([style.lowercase])}
					maxLength={60}
					customErrorDisplay
					required={!values.phone}
				/>

				<PhoneInput
					name="phone"
					classNameContainer={style.fields}
					label={t("global.mobilePhone")}
					required={!values.email}
				/>

				<PhoneInput name="phone2" classNameContainer={style.fields} label={t("global.landLinePhone")} />
			</div>
		</div>
	);
};

export default ContactInformations;
