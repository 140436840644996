// Style
import { FC } from "react";

import style from "./TitleCount.module.css";

interface ITitleCountProps {
	children: React.ReactNode;
}

export const TitleCount: FC<ITitleCountProps> = ({ children }) => {
	return <div className={style.container}>{children}</div>;
};
