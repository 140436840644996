import { TWorkFilterOption, TJobType } from "src/types";

import client from "./client";

class JobTypesServiceClass {
	getJobTypes = (): Promise<TJobType[]> => {
		return client.get("/api-client-v2/job-types").then((response) => {
			return response.data;
		});
	};

	getWorkEquipment = (): Promise<TWorkFilterOption[]> => {
		return client.get("/api-client-v2/equipments").then((response) => {
			return response.data;
		});
	};

	getWorkConditions = (): Promise<TWorkFilterOption[]> => {
		return client.get("/api-client-v2/work-conditions").then((response) => {
			return response.data;
		});
	};
}

const JobTypesService = new JobTypesServiceClass();
export default JobTypesService;
