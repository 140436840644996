import { FC } from "react";

import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import i18n from "src/i18n";
import { TApiError } from "src/types/TErrors";
import { TUserListing, TUsersListingApiResponse } from "src/types/TUsersListing";

import usersListingService from "src/services/usersListingService";

import { InfoMessage, Text } from "@zolteam/react-ras-library";
import { ConfirmModal } from "src/components/molecules/ConfirmModal/ConfirmModal";

interface ActionUpdateVipProps {
	isOpen: boolean;
	setIsOpen: (value: boolean) => void;
	selectedItem: TUserListing | null;
}

const ActionUpdateVip: FC<ActionUpdateVipProps> = ({ isOpen, setIsOpen, selectedItem }) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();

	const mutationActionUpdateVip = useMutation(
		({ usersIds, isVip }: { usersIds: number[]; isVip: boolean }) =>
			usersListingService.updateVipStatus({ usersIds, isVip }),
		{
			onSuccess: () => {
				//TODO: Les données récupérées via opensearch ne sont pas mises à jour instantanément, solution: mise à jour manuelle coté Front, à améliorer
				queryClient.setQueriesData(
					"postUsersListingSearch",
					(oldData: { data: TUsersListingApiResponse } | undefined) => {
						if (!oldData) return;

						return {
							...oldData,
							data: {
								...oldData.data,
								items: oldData.data.items.map((item) =>
									item.id === selectedItem.id ? { ...selectedItem, isVip: !selectedItem.isVip } : item
								),
							},
						};
					}
				);

				toast.success(t(`usersListing.apiResponse.success.updatedSheet`));
			},
			onError: (error: AxiosError<TApiError>) => {
				const message = error?.response?.data?.message;
				if (i18n.exists(`usersListing.apiResponse.errors.${message}`)) {
					toast.error(t(`usersListing.apiResponse.errors.${message}`, { name: selectedItem?.name }));
				} else {
					toast.error(t(`global.apiErrors.undefined`));
				}
			},
			onSettled: () => {
				setIsOpen(false);
			},
		}
	);

	const handleUpdateVip = (usersIds: number[], isVip: boolean) => {
		return mutationActionUpdateVip.mutateAsync({ usersIds, isVip });
	};

	return (
		<ConfirmModal
			title={t("usersListing.primaryActions.vip.title")}
			isOpen={isOpen}
			onClose={() => setIsOpen(false)}
			onConfirm={() => {
				return handleUpdateVip([selectedItem.id], !selectedItem.isVip);
			}}
			confirmText={t("global.register")}
			size="s"
			className="!w-[26rem]"
		>
			<div className="flex flex-col gap-8">
				<InfoMessage color={"warning"} withIcon>
					{t("usersListing.primaryActions.vip.warnText")}
				</InfoMessage>
				<Text tag="span" size="paragraph01">
					{t("usersListing.primaryActions.vip.text")}
				</Text>
			</div>
		</ConfirmModal>
	);
};

export default ActionUpdateVip;
