import { FC } from "react";

import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import { format } from "date-fns";
import { fr } from "date-fns/locale";

import UserTemporaryWorkerService from "src/services/userTemporaryWorkerService";

import { TParams } from "../TemporaryWorkerSheet";
import { Button, Tag, Text } from "@zolteam/react-ras-library";
import { VipTag } from "src/components/atoms";
import Avatar from "src/components/atoms/Avatar/Avatar";
import { ListMaxWidth } from "src/components/organisms";

import capitalizeFirstCharacter from "src/utils/capitalizeFirstCharacter";
import cn from "src/utils/cn";
import { cntm } from "src/utils/cntm";
import { calculateAge } from "src/utils/formatDate";
import formatPhoneNumber from "src/utils/formatPhoneNumber";

import { UTW_DOC_STATUS } from "src/constants/CUnifiedTemporaryWorker";

import style from "./Summary.module.css";

const HeaderSummary: FC = () => {
	const { t } = useTranslation();
	const userId = useParams<TParams>()?.userId;

	const temporaryWorkerSummary = useQuery({
		queryKey: ["getUserSummary", userId],
		queryFn: ({ signal }) => UserTemporaryWorkerService.getUserTemporaryWorkerSummary(userId, signal),
	});
	const { data: utw, isLoading, isFetching, isError } = temporaryWorkerSummary;
	const identityDocumentStatus = utw?.identityDocument?.status || null;
	const age = utw?.dateOfBirth ? format(utw?.dateOfBirth, "dd/MM/yyyy") : null;

	if (isLoading || isFetching)
		return (
			<div className={`layout ${style.gridContainer}`}>
				{Array.from({ length: 4 }).map((_, i) => (
					<div className={cntm([style.gridItem, " block relative"])} key={i}>
						<Skeleton height={150} width={"100%"} baseColor="#f3f3f3" className="w-full " />
					</div>
				))}
			</div>
		);

	if (isError) {
		return <div className={`layout`}>-</div>;
	}

	return (
		<div className={`layout ${style.gridContainer}`}>
			<div className={cn([style.gridItem, "items-center justify-center relative"])}>
				<div className="absolute top-0 right-4 flex flex-col gap-1.5">
					{utw?.isVip && <VipTag withLabel className="text-xs h-5" />}{" "}
					{utw?.isCdii && (
						<Tag color="primaryLight" className="text-primary-500 text-xs h-5">
							CDII
						</Tag>
					)}
				</div>
				<Avatar className="w-16 h-16" src={utw?.avatar} />
				<div className="flex flex-col gap-1 items-center">
					<h4 className="text-lg font-bold text-center ">
						{utw?.firstName && capitalizeFirstCharacter(utw?.firstName)}{" "}
						<span className="uppercase">{utw?.lastName}</span>
					</h4>
					<ListMaxWidth
						array={utw?.qualifications || []}
						keyListElem="id"
						component={({ data: mainQualification, index }) => {
							return (
								<Text tag="span" size="paragraph02" className="whitespace-nowrap">
									{`${utw?.mainQualification}`}
								</Text>
							);
						}}
						margeError={5}
						oneItem
					/>
					<Button type="button" size="s" color="primary" onClick={() => ""}>
						{t("global.sendMessageAction")}
					</Button>
				</div>
			</div>
			<div className={style.gridItem}>
				<h4 className="text-lg font-bold mb-1">{t("suspiciousSheet.workerInfo.contact")}</h4>
				<div>
					<Text tag="p" size="paragraph02" lineHeight="m" color="grey">
						{t("suspiciousSheet.workerInfo.phone")}
					</Text>

					<Text tag="div" size="paragraph02" lineHeight="m">
						<a href={`tel:${utw?.phone}`} target="_blank" rel="noreferrer" className="text-primary-500 ">
							{formatPhoneNumber(utw?.phone) || "-"}
						</a>
					</Text>
				</div>
				<div>
					<Text tag="p" size="paragraph02" lineHeight="m" color="grey">
						{t("suspiciousSheet.workerInfo.email")}
					</Text>

					<Text tag="div" size="paragraph02" lineHeight="m">
						<a href={`email:${utw?.email}`} target="_blank" rel="noreferrer" className="text-primary-500 ">
							{utw?.email || "-"}
						</a>
					</Text>
				</div>

				<div>
					<Text tag="p" size="paragraph02" lineHeight="m" color="grey">
						{t("suspiciousSheet.workerInfo.addressStreet")}
					</Text>
					<Text tag="p" size="paragraph02" lineHeight="m">
						{utw?.addressStreet}
					</Text>
					<Text tag="p" size="paragraph02" lineHeight="m">
						{utw?.addressPostalCode} {utw?.addressCity}
					</Text>
				</div>
			</div>
			<div className={style.gridItem}>
				<h4 className="text-lg font-bold mb-1">{t("suspiciousSheet.workerInfo.civilStatus")}</h4>
				<div>
					<Text tag="p" size="paragraph02" lineHeight="m" color="grey">
						{t("suspiciousSheet.workerInfo.dateOfBirth")}
					</Text>
					<Text tag="p" size="paragraph02" lineHeight="m">
						{format(new Date(utw?.dateOfBirth), "dd MMMM yyyy", {
							locale: fr,
						}) || "-"}{" "}
						-{" "}
						{!!calculateAge(age) &&
							t(`usersListing.temporaryWorker.summary.age`, { count: calculateAge(age) })}
					</Text>
				</div>
				<div>
					<Text tag="p" size="paragraph02" lineHeight="m" color="grey">
						{t("suspiciousSheet.workerInfo.commonNationality")}
					</Text>
					<Text tag="p" size="paragraph02" lineHeight="m">
						{utw?.nationality || "-"}
					</Text>
				</div>
				<div>
					<Text tag="p" size="paragraph02" lineHeight="m" color="grey">
						{t("suspiciousSheet.workerInfo.identityDocument")}
					</Text>
					<Text tag="p" size="paragraph02" lineHeight="m" className="flex flex-row gap-2 flex-wrap">
						{utw?.identityDocument?.type || "-"}
						{utw?.identityDocument && (
							<Tag
								color={UTW_DOC_STATUS[identityDocumentStatus]?.color || "error"}
								size={"m"}
								className={UTW_DOC_STATUS[identityDocumentStatus]?.className}
							>
								{UTW_DOC_STATUS[identityDocumentStatus]
									? t(
											`usersListing.temporaryWorker.documents.status.${UTW_DOC_STATUS[identityDocumentStatus]?.label}`
										)
									: t("global.validityNotProvided")}
							</Tag>
						)}
					</Text>
				</div>
			</div>
			<div className={style.gridItem}>
				<h4 className="text-lg font-bold mb-1">{t("suspiciousSheet.workerInfo.professionalData")}</h4>
			</div>
		</div>
	);
};

export default HeaderSummary;
