import { TLocationType } from "src/types";

import client from "./client";

export type TOutOfBuisnessReason = {
	value: number;
	name: string;
};

class publicServiceClass {
	getNationalities = () => {
		return client.get(`/api-client-v2/nationalities`);
	};

	getLocations = (type: TLocationType, search: string) => {
		if (search.length < 3) {
			return Promise.resolve([]);
		}
		return client.get(`/api-client-v2/locations?type=${type}&search=${search}`).then((response) => response.data);
	};
}
const PublicService = new publicServiceClass();
export default PublicService;
