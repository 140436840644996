// @ts-nocheck

/* eslint-disable max-len */

/* eslint-disable import/prefer-default-export */
import client from "./client";

const PREFIX = "/api-client-v2/client-relations";

/**
 * @description Gets the list of client relationships for a unified temp worker
 * @param {String} agencyId // Agency id
 * @param {String} id // Unified temp worker id
 * @param {String} search // Search query
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getWorkerRelationClients = (agencyId, id, search) =>
	client.get(`${PREFIX}/unified-temp-workers/${id}/agencies/${agencyId}/clients`, {
		params: {
			search: `${search}`,
		},
	});
