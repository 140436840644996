import moment from "moment";
import { Trans } from "react-i18next";

import TProfileActivity from "../../types/TProfileActivity";
import activityDetailTemplate from "../activityDetailTemplate";
import { PopoverCursor, Picto } from "@zolteam/react-ras-library";

import style from "../../ModalContentProfileCardDetails.module.css";

const renderResponseReceivedActivity = (
	activity: TProfileActivity,
	index: number,
	t: (key: string, options?: any) => string,
	concatenatedActivitiesLength: number
) => {
	const content = (
		<>
			<span className={style.activityText}>
				{activity.isDelegatedBy247 && (
					<PopoverCursor
						placement="top"
						content={<div className={style.popoverBox}>{t("commands.profiles.isDelegatedBy247")}</div>}
					>
						<div className={style.pictoTwentyFour}>
							<Picto icon="timeTwentyFour" />
						</div>
					</PopoverCursor>
				)}
				<Trans
					i18nKey="commands.profiles.modalsContent.profileDetail.activity.templates.statusChange"
					values={{
						status: t(`commands.profiles.modalsContent.profileDetail.statuses.${[activity.profileStatus]}`),
						agentName: `${activity.authorFirstName} ${activity.authorLastName.charAt(0).toUpperCase()}.`,
						orderProfileName: `${activity.tempWorkerFirstName} ${activity.tempWorkerLastName}`,
					}}
				/>
			</span>
			<span className={style.activityTime}>
				{t("commands.profiles.modalsContent.profileDetail.activity.when", {
					date: moment(activity.createdAt).format("DD MMM YYYY"),
					time: moment(activity.createdAt).format("HH[h]mm"),
				})}
			</span>
		</>
	);
	return activityDetailTemplate(index, content, activity.orderMessagingId, concatenatedActivitiesLength);
};

export default renderResponseReceivedActivity;
