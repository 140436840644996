import { useState, useCallback, useEffect } from "react";

import { debounce } from "lodash";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

// Services
import { getAvailableProfiles } from "../../../../../services/commandService";

// Store
import { useToastContext } from "../../../../../store/ToastContext";

//Types
import ProfilesAvailableType from "../../types/ProfilesAvailableType";
// Internals
import getNonSelectableProfileMessage from "./internals/getNonSelectableProfileMessage";
import { Checkbox, Tag, Tooltip } from "@zolteam/react-ras-library";

import cn from "src/utils/cn";

import OUT_BUSINESS_STATUS from "src/constants/outOfBusinessStatus";

// Style
import style from "../Modals.module.css";

const useModalContentAddProfile = (
	defaultSelectedStatus: string,
	onAddClick: (params: { selectedStatus: string; selectedProfiles: number[] }) => void
) => {
	const [selectedStatus, setSelectedStatus] = useState(defaultSelectedStatus || "");
	const [selectedProfiles, setSelectedProfiles] = useState([]);
	const [profileInputValue, setProfileInputValue] = useState("");
	const [profilesLength, setProfilesLength] = useState(null);

	// Hooks
	const { t } = useTranslation();
	const { orderId } = useParams<{ orderId: string }>();
	const [, toastDispatch] = useToastContext();

	// API Calls

	const fetchProfiles = async (inputValue: string) => {
		if (inputValue.length < 2) {
			setProfilesLength(null);
			return [];
		}

		try {
			const res = await getAvailableProfiles(orderId, {
				search: inputValue,
				limit: 20,
			});
			const result = res.data?.sort((a: ProfilesAvailableType, b: ProfilesAvailableType) =>
				a.lastName.localeCompare(b.lastName)
			);
			setProfilesLength(res.data?.length);
			return result;
		} catch (error) {
			setProfilesLength(null);
			return [];
		}
	};

	const debouncedLoadOptions = useCallback(
		debounce((inputValue: string, callback: (options: ProfilesAvailableType[]) => void) => {
			fetchProfiles(inputValue).then((results) => callback(results));
			return;
		}, 250),
		[orderId]
	);

	const handleDeleteProfilClick = (profilId: number) => {
		const filterProfils = selectedProfiles.filter(({ id }) => id !== profilId);
		setSelectedProfiles(filterProfils);
	};

	useEffect(() => {
		return () => {
			debouncedLoadOptions.cancel();
		};
	}, [debouncedLoadOptions]);

	useEffect(() => {
		if (profileInputValue.length <= 2) {
			setProfilesLength(null);
		}
	}, [profileInputValue]);

	const handleAddClick = () => {
		if (!selectedStatus && !selectedProfiles.length) {
			toastDispatch({
				type: "WARN",
				payload: {
					icon: "users",
					msg: "Merci de sélectionner au moins un profil et une étape de validation.",
				},
			});
			return;
		}
		if (!selectedStatus) {
			toastDispatch({
				type: "WARN",
				payload: {
					icon: "users",
					msg: "Merci de sélectionner l'étape de validation pour ce ou ces profils.",
				},
			});
			return;
		}
		if (!selectedProfiles.length) {
			toastDispatch({
				type: "WARN",
				payload: {
					icon: "users",
					msg: "Merci de sélectionner au moins un profil",
				},
			});
			return;
		}
		onAddClick({ selectedStatus, selectedProfiles });
	};

	const renderOptionLabel = useCallback(
		(option: ProfilesAvailableType) => {
			const isSelected =
				selectedProfiles.find(
					({ unifiedTempWorkerUniqueId }) => unifiedTempWorkerUniqueId === option.unifiedTempWorkerUniqueId
				) != null;

			return (
				<div className={cn([style.optionsLabel, isProfileDisabled(option) ? style.labelSelectDisabled : null])}>
					<div className={style.optionCheckbox}>
						<Checkbox
							className={style.checkbox}
							theme="primary"
							id={option.unifiedTempWorkerUniqueId}
							value={isSelected}
						/>
					</div>
					<div className="flex items-center gap-5">
						<p className={cn([style.optionLabel, isSelected ? style.active : ""])}>
							{`${option.firstName} ${option.lastName} ${option.unifiedTempWorkerUniqueId}`}
						</p>
						{isProfileOutOfBusinessOtherThanNonISOorPrefectureValidation(option) ? (
							<Tag color="greyLight" className="px-2.5 py-0.5 leading-4">
								{t("commands.profiles.modalsContent.addProfile.exitOther")}
							</Tag>
						) : null}
					</div>
				</div>
			);
		},
		[selectedProfiles]
	);

	const renderSelectedProfile = ({ firstName, lastName, id, unifiedTempWorkerUniqueId }: ProfilesAvailableType) => (
		<div className={style.addedProfilesItem} key={id}>
			<Tag key={id} color="primaryLight" size="s" showCloseButton onClose={() => handleDeleteProfilClick(id)}>
				<p className={style.profilSelected}>
					<strong>{`${firstName} ${lastName}`}</strong>
					{` ${unifiedTempWorkerUniqueId}`}
				</p>
			</Tag>
		</div>
	);

	const handleProfileSelect = useCallback((item: ProfilesAvailableType) => {
		setSelectedProfiles((prevSelection) => {
			const selectedIndex = prevSelection.findIndex((profile) => profile.id === item.id);
			if (selectedIndex !== -1) return prevSelection.filter((profile) => profile.id !== item.id);
			return prevSelection.concat([item]);
		});
	}, []);

	const profileOptionLabel = useCallback(
		(option: ProfilesAvailableType) =>
			isProfileDisabled(option) ? (
				<Tooltip
					animation={false}
					appendTo={profilesLength > 5 ? "parent" : document.body}
					arrow={false}
					placement="top"
					content={getNonSelectableProfileMessage(option, t)}
					tooltipBoxStyle={{ maxWidth: "300px" }}
				>
					{renderOptionLabel(option)}
				</Tooltip>
			) : (
				renderOptionLabel(option)
			),
		[profilesLength, selectedProfiles]
	);

	const isProfileDisabled = (option: ProfilesAvailableType) => {
		const hasInvalidIdentityDocument = !option.hasValidIdentityDocument;
		const isOutOfBusinessWithReason =
			option.isOutOfBusiness &&
			(option.outOfBusinessReason === OUT_BUSINESS_STATUS.NON_ISO ||
				option.outOfBusinessReason === OUT_BUSINESS_STATUS.PREFECTURE_VALIDATION);
		const isInadequate = option.isInadequate;

		return hasInvalidIdentityDocument || isOutOfBusinessWithReason || isInadequate;
	};

	const isProfileOutOfBusinessOtherThanNonISOorPrefectureValidation = (option: ProfilesAvailableType) => {
		return (
			option.isOutOfBusiness === true &&
			option.outOfBusinessReason !== OUT_BUSINESS_STATUS.NON_ISO &&
			option.outOfBusinessReason !== OUT_BUSINESS_STATUS.PREFECTURE_VALIDATION
		);
	};

	return {
		handleAddClick,
		handleProfileSelect,
		renderSelectedProfile,
		orderProfilesOptions: debouncedLoadOptions,
		profileOptionLabel,
		isProfileDisabled,
		profileInputValue,
		setProfileInputValue,
		selectedProfiles,
		selectedStatus,
		setSelectedStatus,
	};
};

export default useModalContentAddProfile;
