import { t } from "i18next";
import moment from "moment";
import { StatusUser } from "src/enum/enumStatusUser";
import i18n from "src/i18n";
import * as Yup from "yup";

import { uCleanSpaces } from "src/utils/UStrings";
import {
	isBirthdayValidWithSSN,
	isDepartmentValid,
	isGenderValid,
	isValidSSN,
} from "src/utils/UTemporaryWorkerInformations";
import { calculateAge } from "src/utils/formatDate";

import { ALLOWED_NAMES_CHAR_REGEX, EMAIL_REGEX, MOBILE_REGEX, PHONE_REGEX } from "src/constants/CRegex";

const CivilityInformationsValidationSchema = (isCandidtateOrWorker: boolean) => {
	return Yup.object().shape({
		sex: Yup.string()
			.nullable()
			.when("socialSecurityNumber", (ssn, schema) => {
				if (!ssn) return schema;
				return schema.test("ssn-gender-match", t("global.formErrors.ssnMismatch"), function (gender) {
					return isGenderValid(uCleanSpaces(ssn[0]), gender); // Valider la correspondance
				});
			}),
		firstName: Yup.string()
			.required(i18n.t("global.formErrors.firstnameEmpty"))
			.test("is-valid", i18n.t("global.formErrors.invalidNameField"), (value) => {
				return ALLOWED_NAMES_CHAR_REGEX.test(value);
			}),
		lastName: Yup.string()
			.required(i18n.t("global.formErrors.lastnameEmpty"))
			.test("is-valid", i18n.t("global.formErrors.invalidNameField"), (value) => {
				return ALLOWED_NAMES_CHAR_REGEX.test(value);
			}),
		birthName: Yup.string()
			.nullable()
			.test("is-valid", i18n.t("global.formErrors.invalidNameField"), (value) => {
				return ALLOWED_NAMES_CHAR_REGEX.test(value);
			}),

		dateOfBirth: (isCandidtateOrWorker
			? Yup.string().required(i18n.t("global.formErrors.birthdateEmpty"))
			: Yup.string().nullable()
		)
			.test("is-valid", i18n.t("global.formErrors.invalidDate"), (value) => {
				if (!value) return true;
				return moment(value, "DD/MM/YYYY", true).isValid();
			})
			.test("is-old-enough-to-save", i18n.t("global.formErrors.ageUnder16"), (value) => {
				if (!value) return true;
				const age = calculateAge(value);
				return age >= 16;
			})
			.when("socialSecurityNumber", (ssn, schema) => {
				if (ssn) {
					return schema.test(
						"ssn-birthdate-match",
						t("global.formErrors.ssnMismatch"),
						function (dateOfBirth) {
							if (!dateOfBirth) return true;

							const format = "DD/MM/YYYY";
							const isValid = moment(dateOfBirth, format, true).isValid();
							if (isValid) return isBirthdayValidWithSSN(uCleanSpaces(ssn[0]), dateOfBirth); // Valider la correspondance
						}
					);
				}
				return schema; // Si pas de NSS, pas de validation supplémentaire
			}),
		bornDepartment: Yup.string()
			.nullable()
			.when("socialSecurityNumber", (ssn, schema) => {
				if (ssn) {
					return schema.test(
						"ssn-birthdate-match",
						t("global.formErrors.ssnMismatch"),
						function (bornDepartment) {
							if (!bornDepartment) return true;
							return isDepartmentValid(uCleanSpaces(ssn[0]), bornDepartment); // Valider la correspondance
						}
					);
				}
				return schema; // Si pas de NSS, pas de validation supplémentaire
			}),
		bornCity: isCandidtateOrWorker
			? Yup.string().required(i18n.t("global.formErrors.bornCityEmpty"))
			: Yup.string().nullable(),
		nativeCountryId: isCandidtateOrWorker
			? Yup.number().required(i18n.t("global.formErrors.bornCountryEmpty"))
			: Yup.number().nullable(),
		nativeCountry: Yup.object().shape({ name: Yup.string(), id: Yup.string() }),
		nationalityId: isCandidtateOrWorker
			? Yup.number().required(i18n.t("global.formErrors.nationalityEmpty"))
			: Yup.number().nullable(),
	});
};

const SocialSecurityValidationSchema = (isCandidtateOrWorker: boolean) => {
	return Yup.object().shape({
		socialSecurityNumber: isCandidtateOrWorker
			? Yup.string()
					.required(i18n.t("global.formErrors.mandatoryField"))
					.test("test-ssn", t("global.formErrors.ssnInvalid"), isValidSSN)
			: Yup.string().nullable().test("test-ssn", t("global.formErrors.ssnInvalid"), isValidSSN),
	});
};

const ContactInformationsValidationSchema = (isCandidate: boolean) => {
	return Yup.object().shape({
		addressCountryId: isCandidate
			? Yup.number().required(i18n.t("global.formErrors.mandatoryField"))
			: Yup.number().nullable(),
		addressStreet: isCandidate
			? Yup.string().required(i18n.t("global.formErrors.mandatoryField"))
			: Yup.string().nullable(),
		addressBuilding: Yup.string().nullable(),

		addressPostalCode: Yup.string().when("addressCountryId", (addressCountryId: number[], schema) => {
			if (addressCountryId[0] !== 81 && isCandidate) {
				return schema.required(i18n.t("global.formErrors.mandatoryField"));
			} else {
				return schema.nullable();
			}
		}),
		addressCity: Yup.string().when("addressCountryId", (addressCountryId: number[], schema) => {
			if (addressCountryId[0] !== 81 && isCandidate) {
				return schema.required(i18n.t("global.formErrors.mandatoryField"));
			} else {
				return schema.nullable();
			}
		}),
		location: Yup.object().when("addressCountryId", (addressCountryId: number[], schema) => {
			if (addressCountryId[0] === 81 && isCandidate) {
				return schema.required(i18n.t("global.formErrors.mandatoryField"));
			} else {
				return schema.nullable();
			}
		}),

		email: Yup.string()
			.nullable()
			.email(t("global.formErrors.emailInvalid"))
			.matches(EMAIL_REGEX, t("global.formErrors.emailInvalid"))
			.when("$tempWorkerStatus", {
				is: (tempWorkerStatus: StatusUser) => {
					return [StatusUser.CANDIDATE, StatusUser.WAITING, StatusUser.WORKER].includes(tempWorkerStatus);
				},
				then: (schema) =>
					schema.test(
						"is-required-if-no-phone",
						t("global.formErrors.phoneOrEmailRequired"),
						function (value) {
							const { phone } = this.parent;

							return phone || value
								? true
								: this.createError({ message: t("global.formErrors.phoneOrEmailRequired") });
						}
					),
				otherwise: (schema) => schema.nullable(),
			}),
		phone: Yup.string()
			.when(["$tempWorkerStatus", "email"], {
				is: (tempWorkerStatus: StatusUser, email: string) => {
					return (
						[StatusUser.CANDIDATE, StatusUser.WAITING, StatusUser.WORKER].includes(tempWorkerStatus) &&
						!email
					);
				},
				then: (s) => s.required(t("global.formErrors.phoneOrEmailRequired")),
				otherwise: (s) => s.nullable(),
			})
			.test("len", t("global.formErrors.phoneInvalid"), (val) => {
				if (!val) return true;
				const cleanedVal = uCleanSpaces(val);
				return MOBILE_REGEX.test(cleanedVal);
			}),
		phone2: Yup.string()
			.nullable()
			.test("len", t("global.formErrors.phoneInvalid"), (val) => {
				if (!val) return true;
				const cleanedVal = uCleanSpaces(val);
				return PHONE_REGEX.test(cleanedVal);
			}),
	});
};

const EmergencyContactValidationSchema = () => {
	return Yup.object().shape({
		emergencyContactName: Yup.string()
			.nullable()
			.test("is-valid", i18n.t("global.formErrors.invalidNameField"), (value) => {
				return ALLOWED_NAMES_CHAR_REGEX.test(value);
			}),
		emergencyContactPhone: Yup.string()
			.nullable()
			.test("len", t("global.formErrors.phoneInvalid"), (val) => {
				if (!val) return true;
				const cleanedVal = uCleanSpaces(val);
				const regex = /^(01|02|03|04|05|06|07|09)\d{8}$/;
				return regex.test(cleanedVal);
			}),
	});
};

export const AdministrativeFormValidationSchema = (isCandidtateOrWorker: boolean, isCandidtate: boolean) => {
	return Yup.object().shape({
		...CivilityInformationsValidationSchema(isCandidtateOrWorker).fields,
		...SocialSecurityValidationSchema(isCandidtateOrWorker).fields,
		...ContactInformationsValidationSchema(isCandidtate).fields,
		...EmergencyContactValidationSchema().fields,
	});
};
