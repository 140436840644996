import { useContext, useEffect } from "react";

import { TFilters } from "src/types";

import { FiltersContext } from "./FiltersContext";

export const useFiltersContext = <T>(filtersKey: keyof TFilters<T>, countCallback?: (filters: T) => number) => {
	const context = useContext(FiltersContext);
	if (!context) {
		throw new Error("useFilters must be used within a FiltersProvider");
	}

	if (!filtersKey) {
		throw new Error("filtersKey must be provided");
	}

	const getFilters = () => {
		return (context.Filters[filtersKey] || {}) as TFilters<T>[keyof TFilters<T>];
	};

	// setFilters peut recevoir un objet direct ou une fonction qui modifie l'état précédent
	const setFilters = (
		newFilterValue:
			| TFilters<T>[keyof TFilters<T>]
			| ((prevFilter: TFilters<T>[keyof TFilters<T>]) => TFilters<T>[keyof TFilters<T>])
	) => {
		context.setFilters((prevFilters) => {
			// Si newFilterValue est une fonction, on l'appelle avec l'état actuel
			const updatedFilterValue =
				typeof newFilterValue === "function"
					? (newFilterValue as (prev: TFilters<T>[keyof TFilters<T>]) => TFilters<T>[keyof TFilters<T>])(
							prevFilters[filtersKey] as TFilters<T>[keyof TFilters<T>]
						)
					: newFilterValue;

			if (countCallback) updatedFilterValue.count = countCallback(updatedFilterValue);

			// On met à jour uniquement la clé spécifique dans les filtres
			return {
				...prevFilters,
				[filtersKey]: updatedFilterValue,
			};
		});
	};

	const setFilter = (key: keyof TFilters<T>[keyof TFilters<T>], value: any) => {
		setFilters((prev) => ({
			...prev,
			[key]: value,
		}));
	};

	const setCount = (newCount: number) => {
		setFilters((prev) => ({
			...prev,
			count: newCount,
		}));
	};

	useEffect(() => {
		if (countCallback) setCount(countCallback(getFilters()));
	}, []);

	return {
		filters: getFilters(),
		setFilters,
		setFilter,
		count: getFilters()?.count ?? 0,
		setCount,
	};
};
