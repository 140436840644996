import React from "react";

interface ITableFiltersProps {
	rightContent?: React.ReactNode;
	leftContent?: React.ReactNode;
}

export const TableFilters: React.FC<ITableFiltersProps> = ({ rightContent = null, leftContent = null }) => (
	<div className={"row flex-nowrap justify-between grid-rows-[1/1] pb-5"}>
		<div className={"row flex-nowrap gap-5 items-center"}>{leftContent}</div>
		<div className={"row flex-nowrap gap-5 items-center"}>{rightContent}</div>
	</div>
);
