export const DATE_FORMAT_STRING = "DD/MM/yyyy";
export const START_DATE_INPUT_NAME = "period.startDate";
export const END_DATE_INPUT_NAME = "period.endDate";

export const DEFAULT_DAY_WORKING_TIME = {
	workingDays: {
		monday: false,
		tuesday: false,
		wednesday: false,
		thursday: false,
		friday: false,
		saturday: false,
		sunday: false,
	},
};

export const DEFAULT_NIGHT_WORKING_TIME = {
	workingNights: {
		mondayNight: false,
		tuesdayNight: false,
		wednesdayNight: false,
		thursdayNight: false,
		fridayNight: false,
		saturdayNight: false,
		sundayNight: false,
	},
};
