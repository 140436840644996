// @ts-nocheck
import client from "./client";

/**
 * @description Get agencies for logged user
 * @returns {Promise<AxiosResponse<any>>}
 */
const getAgencies = () => client.get("/api-client-v2/agencies");

const AgenciesService = {
	getAgencies,
};

export default AgenciesService;
