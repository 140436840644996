import React, { PropsWithChildren, useRef } from "react";

import { NavLink, matchPath, useLocation } from "react-router-dom";

import cn from "../../../utils/cn";

// Style
import { cntm } from "src/utils/cntm";

import style from "./SideMenuSecondaryItem.module.css";

interface ISideMenuSecondaryItemProps extends PropsWithChildren {
	path: string;
	className?: string;
}

export const SideMenuSecondaryItem: React.FC<ISideMenuSecondaryItemProps> = ({ path, children, className }) => {
	const { pathname } = useLocation();
	const isActive = matchPath(pathname, { path: path, exact: true });

	//Added a scroll effect when clicking on a sidemenu item and the item is not completely visible
	const itemRef = useRef(null);
	const handleScroll = (ref: React.RefObject<HTMLElement>) => {
		ref.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
	};

	return (
		<div
			ref={itemRef}
			className={cntm([style.btn, "snap-center w-full", className])}
			onClick={() => handleScroll(itemRef)}
		>
			<NavLink className={cn([style.sidemenuItem, isActive && style.active])} to={path}>
				{children}
			</NavLink>
		</div>
	);
};
