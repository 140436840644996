import TProfileActivityRender from "../../types/TProfileActivityRender";

import style from "../../ModalContentProfileCardDetails.module.css";

const renderCommunicationContent = (
	activity: TProfileActivityRender,
	index: number,
	primaryCommunicationMedium: string
) => {
	if (activity.isOpen && !activity.content) {
		return (
			<div className={style.loaderBox}>
				<img className={style.loaderIcon} src="/circle-loader.png" alt="circle-loader" />
			</div>
		);
	}

	if (primaryCommunicationMedium === "sms") {
		return (
			<div
				className={activity.content && activity.isOpen ? style.sms : style.hidden}
			>{`${activity.content}`}</div>
		);
	}
	return (
		<iframe
			id={index.toString()}
			title={`content${index}`}
			className={activity.content && activity.isOpen ? style.iframe : style.hidden}
			srcDoc={activity.content}
		/>
	);
};

export default renderCommunicationContent;
