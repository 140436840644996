// @ts-nocheck
import { useState } from "react";

import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";

// Style
import cn from "../../../../utils/cn";
import { Button, Title, ToggleSwitch } from "@zolteam/react-ras-library";

import style from "./Modals.module.css";

const ModalContentNotifyUser = ({ onConfirmClick, onCancelClick }) => {
	// States
	const [shouldNotifyUsers, setShouldNotifyUsers] = useState(false);
	// Hooks
	const { t } = useTranslation();

	return (
		<div className={style.content}>
			<Title tag="h1" size="heading01" lineHeight="l" className={cn([style.title])}>
				{t("commands.profiles.modalsContent.notifyUsers.title")}
			</Title>

			<div className={style.message}>
				<p className={style.text}>
					<Trans i18nKey="commands.profiles.modalsContent.notifyUsers.infoMessage" />
				</p>
				<p className={style.text}>
					<Trans i18nKey="commands.profiles.modalsContent.notifyUsers.infoMessageBis" />
				</p>
				<div className={style.toggle}>
					<ToggleSwitch
						id="shouldNotifyUsers"
						name="shouldNotifyUsers"
						onChange={() => setShouldNotifyUsers((prevState) => !prevState)}
						toggled={shouldNotifyUsers}
					>
						<label htmlFor="shouldNotifyUsers" className={style["toggle-label"]}>
							{t("commands.profiles.modalsContent.notifyUsers.toggleLabel")}
						</label>
					</ToggleSwitch>
				</div>
				<span>
					<p className={style.confirmationText}>
						{t("commands.profiles.modalsContent.notifyUsers.warningMessage")}
					</p>
				</span>
			</div>

			<hr className={cn([style.separator])} />
			<div className={cn([style.buttonsBlock])}>
				<Button
					className={style.validationButtonRight}
					type="button"
					color="primary"
					onClick={onCancelClick}
					outline
				>
					{t("commands.profiles.modalsContent.notifyUsers.cancelAction")}
				</Button>
				<Button
					className={style.validationButtonRight}
					type="button"
					color="primary"
					onClick={() => onConfirmClick(shouldNotifyUsers)}
				>
					{t("commands.profiles.modalsContent.notifyUsers.confirmAction")}
				</Button>
			</div>
		</div>
	);
};

ModalContentNotifyUser.propTypes = {
	onConfirmClick: PropTypes.func.isRequired,
	onCancelClick: PropTypes.func.isRequired,
};

export default ModalContentNotifyUser;
