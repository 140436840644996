import React from "react";

import { TFunction } from "i18next";

// Hooks
import useCommandsMoreOptions from "./useCommandsMoreOptions";
import { Picto, Popover, Text, Tooltip } from "@zolteam/react-ras-library";
// Components
import { PopoverMenuItem } from "src/components/atoms";

type Props = {
	id: string;
	isDuplicable: boolean;
	t: TFunction<"translation", undefined>;
};

const CommandsMoreOptions: React.FC<Props> = ({ id, isDuplicable, t }) => {
	const { handleEditCommand, handleDuplicateCommand } = useCommandsMoreOptions();

	return (
		<Popover
			minWidth
			clickInside
			placement="left-start"
			animation="perspective"
			offset={[-20, 10]}
			content={
				<div className="flex flex-col">
					<div className="flex min-h-10 items-center">
						<PopoverMenuItem click={(e) => handleEditCommand(e, id)}>
							<Text tag="div" size="paragraph01" data-testid={`edit-command-${id}`}>
								{t("commands.edit")}
							</Text>
						</PopoverMenuItem>
					</div>
					{isDuplicable ? (
						<div className="flex min-h-10 items-center">
							<PopoverMenuItem click={(e) => handleDuplicateCommand(e, id)}>
								<Text tag="div" size="paragraph01" data-testid={`edit-command-${id}`}>
									{t("commands.listing.duplication.option")}
								</Text>
							</PopoverMenuItem>
						</div>
					) : (
						<PopoverMenuItem
							click={(e) => {
								e.stopPropagation();
								e.preventDefault();
							}}
							disabled
						>
							<Tooltip
								animation={false}
								arrow={false}
								content={<span>{t("commands.listing.duplication.blocked")}</span>}
							>
								<div className="flex min-h-10 items-center">
									<Text color="grey" tag="div" size="paragraph01" data-testid={`edit-command-${id}`}>
										{t("commands.listing.duplication.option")}
									</Text>
								</div>
							</Tooltip>
						</PopoverMenuItem>
					)}
				</div>
			}
		>
			<button type="button" onClick={(e) => e.preventDefault()} aria-label="more">
				<Picto
					icon="more"
					style={{
						width: "18px",
						transform: "rotate(90deg)",
						color: "var(--color-neutral-500)",
					}}
				/>
			</button>
		</Popover>
	);
};

export default CommandsMoreOptions;
