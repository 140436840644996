import moment from "moment";
import { Trans } from "react-i18next";

import TProfileActivity from "../../types/TProfileActivity";
import activityDetailTemplate from "../activityDetailTemplate";

import style from "../../ModalContentProfileCardDetails.module.css";

const renderProfileResponseActivity = (
	activity: TProfileActivity,
	index: number,
	t: (key: string, options?: any) => string,
	concatenatedActivitiesLength: number
) => {
	const content = (
		<>
			<span className={style.activityText}>
				<Trans
					i18nKey="commands.profiles.modalsContent.profileDetail.activity.templates.offerResponse"
					values={{
						response: t(
							`commands.profiles.modalsContent.profileDetail.profileResponse.${[activity.profileStatus]}`
						),
						orderProfileName: `${activity.tempWorkerFirstName} ${activity.tempWorkerLastName}`,
						communicationMedium: t(
							`commands.profiles.modalsContent.profileDetail.communicationMedium.${activity.communicationMediumForResponseReceived}`
						),
					}}
				/>
			</span>
			<span className={style.activityTime}>
				{t("commands.profiles.modalsContent.profileDetail.activity.whenAndHow", {
					date: moment(activity.createdAt).format("DD MMM YYYY"),
					time: moment(activity.createdAt).format("HH[h]mm"),
					communicationMedium: t(
						`commands.profiles.modalsContent.profileDetail.communicationMediumShort.${activity.communicationMediumForResponseReceived}`
					),
				})}
			</span>
		</>
	);

	return activityDetailTemplate(index, content, activity.orderMessagingId, concatenatedActivitiesLength);
};

export default renderProfileResponseActivity;
