import { FC } from "react";

import { useTranslation } from "react-i18next";
import { TTemporaryWorker } from "src/types";

import { SUSPICION_TABLE_FIRST_ROW_HEIGHT, SUSPICION_TABLE_ROW_WIDTH } from "../../../constants";
import { formatDate } from "../../../utils/formatDate";
import { Tag } from "../Tag/Tag";
import { Text } from "@zolteam/react-ras-library";

import style from "./SuspiciousTableHeaderCorner.module.css";

interface ISuspiciousTableHeaderCornerProps {
	top: number;
	left: number;
	temporaryWorker: Partial<TTemporaryWorker>;
}

export const SuspiciousTableHeaderCorner: FC<ISuspiciousTableHeaderCornerProps> = ({ top, left, temporaryWorker }) => {
	const { t } = useTranslation();
	return (
		<div
			className={style.tableCornerHeader}
			style={{
				top,
				left,
				height: SUSPICION_TABLE_FIRST_ROW_HEIGHT,
				width: SUSPICION_TABLE_ROW_WIDTH + 4,
			}}
		>
			<div
				className="d-flex justify-between align-center"
				style={{
					minWidth: SUSPICION_TABLE_ROW_WIDTH,
					height: SUSPICION_TABLE_FIRST_ROW_HEIGHT,
				}}
			>
				<div className="mh-l">
					<Text tag="p" size="paragraph03" lineHeight="m" color="grey">
						{t("suspiciousSheet.id")}
					</Text>
					<Text tag="p" fontWeight="bold">
						{temporaryWorker.uniqueId}
					</Text>
				</div>
				<div className="mh-l">
					<Tag size="s">{t("suspiciousSheet.uniqueSheet")}</Tag>
					<Text tag="span" size="paragraph03" className="mt-xs d-flex">
						<span className="mr-xs">{t("suspiciousSheet.createdAt")}</span>
						{formatDate(temporaryWorker.createdAt, "dd MMM yyyy")}
					</Text>
				</div>
			</div>
		</div>
	);
};
