import { isNil } from "lodash";
import { OrderWorkingTimeInterface } from "src/types/OrderWorkingTimeInterface";

export const areAllDaySelected = (isNightMode: boolean, workingTime: OrderWorkingTimeInterface): boolean => {
	if (isNightMode && !isNil(workingTime.workingNights)) {
		return Object.values(workingTime.workingNights)?.every((day: boolean) => !!day);
	} else if (workingTime.workingDays) {
		return Object.values(workingTime.workingDays)?.every((day: boolean) => !!day);
	}

	return false;
};
