// @ts-nocheck
import { useLayoutEffect, useMemo, useState } from "react";

const defaultState = {
	x: 0,
	y: 0,
	width: 0,
	height: 0,
	top: 0,
	left: 0,
	bottom: 0,
	right: 0,
};

const useMeasure = () => {
	const [element, ref] = useState(null);
	const [rect, setRect] = useState(defaultState);

	const observer = useMemo(
		() =>
			new window.ResizeObserver((entries) => {
				if (entries[0]) {
					const { x, y, width, height, top, left, bottom, right } = entries[0].target.getBoundingClientRect();
					setRect({ x, y, width, height, top, left, bottom, right });
				}
			}),
		[]
	);

	useLayoutEffect(() => {
		if (!element) return;
		observer.observe(element);
		// eslint-disable-next-line consistent-return
		return () => {
			observer.disconnect();
		};
	}, [element, observer]);

	return [ref, rect];
};

export default useMeasure;
