import { FC, PropsWithChildren } from "react";

import cn from "../../../utils/cn";
import { InfoMessage, Tooltip } from "@zolteam/react-ras-library";

import style from "./TooltipMessage.module.css";

interface ITooltipMessageProps extends PropsWithChildren {
	color?: string;
	className?: string;
	arrow?: boolean;
	placement?: "top" | "bottom" | "left" | "right";
}

export const TooltipMessage: FC<ITooltipMessageProps> = ({
	className = "",
	color = "primary",
	children = "",
	arrow = true,
	placement = "top",
}) => (
	<Tooltip placement={placement} animation={false} arrow={arrow} content={<span>{children}</span>}>
		<div className={cn([style.infoMessage, className])}>
			<InfoMessage color={color} withIcon className={style.infoMessage} />
		</div>
	</Tooltip>
);
