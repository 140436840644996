import client from "src/services/client";

// Constants
import { ORDER_EQUIPMENTS } from "src/constants";

type EquipmentKey = keyof typeof ORDER_EQUIPMENTS;

export type OrderEquipmentsItem = {
	id: number;
	name: EquipmentKey;
};

export type OrderEquipmentsApiResponse = OrderEquipmentsItem[];

export const getOrdersEquipments = (): Promise<AxiosResponse<OrderEquipmentsApiResponse>> =>
	client.get("/api-client-v2/orders/matching/filters/equipments");
