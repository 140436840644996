type TOptions = {
	label: string;
	value: string | number;
};

export const familySituations = [
	"SINGLE",
	"MARRIED",
	"WIDOWER",
	"DIVORCED",
	"CONCUBINAGE",
	"CIVIL_PARTNER",
	"SEPARATE",
];

export const familySituationsArray: TOptions[] = familySituations.map((value, index) => ({
	label: value,
	value: index + 1,
}));

export const UTW_DOC_STATUS: { [key: string]: { label: string; color: string; className?: string } } = {
	VALID: {
		label: "VALID",
		color: "success",
	},
	EXPIRED: {
		label: "EXPIRED",
		color: "error",
	},
	UNCONFIRMED: {
		label: "UNCONFIRMED",
		color: "primaryLight",
		className: "text-primary-500",
	},
	NEAR_EXPIRY: {
		label: "NEAR_EXPIRY",
		color: "warning",
	},
};
