import PROFILE_COMMUNICATION_DELIVERY_STATUS from "src/constants/ProfileCommunicationStatus";

const handlePopoverContent = (
	status: keyof typeof PROFILE_COMMUNICATION_DELIVERY_STATUS,
	type: "missionOrder" | "missionOffer",
	t: (key: string) => string
): string => {
	const tooltipTranslationKeys = {
		missionOffer: {
			FAILURE: t("commands.profiles.communicationStatus.missionOffer.nonDelivered"),
			UNDELIVERED: t("commands.profiles.communicationStatus.missionOffer.nonDelivered"),
			SUCCESS: t("commands.profiles.communicationStatus.missionOffer.delivered"),
			IN_PROGRESS: t("commands.profiles.communicationStatus.missionOffer.inProcess"),
		},
		missionOrder: {
			FAILURE: t("commands.profiles.communicationStatus.missionOrder.nonDelivered"),
			UNDELIVERED: t("commands.profiles.communicationStatus.missionOrder.nonDelivered"),
			SUCCESS: t("commands.profiles.communicationStatus.missionOrder.delivered"),
			IN_PROGRESS: t("commands.profiles.communicationStatus.missionOrder.inProcess"),
		},
	};

	return tooltipTranslationKeys[type][status] || "";
};

export default handlePopoverContent;
