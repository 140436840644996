import { FC, useRef, useState } from "react";

import { t } from "i18next";
import { isArray } from "lodash";
import { QueryFunctionContext, QueryKey, QueryObserverOptions, QueryOptions, useQuery } from "react-query";
import { Props } from "react-select";

import { InfoMessage, Picto, SelectMulti, SelectV2 } from "@zolteam/react-ras-library";
import { Tag } from "src/components/atoms";
import { FilterCardHeader } from "src/components/molecules";
import OptionMultiSelect from "src/components/molecules/OptionMultiSelect";
import { ListMaxWidth } from "src/components/organisms";

import cn from "src/utils/cn";

type TCustomQueryArgs = {
	search: string;
};

type TCustomQueryFn = (args: TCustomQueryArgs, ctx?: QueryFunctionContext<QueryKey, any>) => Promise<any>;

type TCustomQueryOptions = Omit<QueryOptions, "queryFn"> & {
	queryFn: TCustomQueryFn;
	options?: QueryObserverOptions;
};

interface ISelectFilterProps<T> extends Props {
	title: string;
	handleReset: () => void;
	query: TCustomQueryOptions;
	value: T | T[];
	onChange: (value: T) => void;
	selectAllOption?: boolean;
	selectAllLabel?: string;
	getItems?: (query: unknown) => T[];
	debounce?: number;
	isSearchable?: boolean;
	minSearchLength?: number;
}

export const SelectFilter: FC<ISelectFilterProps<unknown>> = ({
	title,
	handleReset,
	value,
	query,
	debounce = 500,
	getItems,
	isSearchable = false,
	minSearchLength = 3,
	...props
}) => {
	const [SearchTerm, setSearchTerm] = useState("");
	const [active, setActive] = useState(false);

	const debounceTM = useRef<NodeJS.Timeout | null>(null);

	const handleFetch = (queryCtx: QueryFunctionContext<QueryKey, any>): Promise<any> => {
		if (debounceTM.current) {
			clearTimeout(debounceTM.current);
		}

		if (isSearchable && SearchTerm.length && SearchTerm.length < minSearchLength) {
			return Promise.resolve([]);
		}

		return new Promise((resolve, reject) => {
			debounceTM.current = setTimeout(() => {
				query
					.queryFn(
						{
							search: SearchTerm,
						},
						queryCtx
					)
					.then(resolve, reject);
			}, debounce);
		});
	};

	const handleDelete = (id: number | string) => {
		if (!isArray(value)) return;
		const filteredOptions = value.filter((option) => option.id.toString() !== id.toString());
		props.onChange(filteredOptions);
	};

	const buildQueryKey = (queryKey: QueryKey): QueryKey => {
		if (isArray(queryKey)) {
			if (isSearchable && SearchTerm) return [...queryKey, SearchTerm];
			return queryKey;
		}

		if (isSearchable) return [queryKey, SearchTerm];
		return queryKey;
	};

	const { isLoading, data, error } = useQuery({
		queryKey: buildQueryKey(query.queryKey),
		queryFn: handleFetch,
		cacheTime: 0, // Disable cache by default to avoid stale data on search
		...query.options,
	});

	const options = getItems ? getItems(data) : data;

	return (
		<div>
			<FilterCardHeader title={title} handleReset={handleReset} />

			{props.isMulti ? (
				<div className="gap-2 col">
					<ListMaxWidth
						array={
							isArray(value)
								? props.getOptionLabel || props.getOptionValue
									? value.map((v) => ({
											...v,
											name: props.getOptionLabel ? props.getOptionLabel(v) : v.name,
											id: props.getOptionValue ? props.getOptionValue(v) : v.id,
										}))
									: value
								: []
						}
						keyListElem="id"
						maxWidthMarge={16}
						margeError={30}
						component={({ data: { name, id } }) => (
							<Tag color="primary" size="xs">
								<span>{name}</span>
								<button
									type="button"
									className="noButton"
									aria-label="Delete"
									onClick={() => handleDelete(id)}
								>
									<Picto icon="close" />
								</button>
							</Tag>
						)}
					/>
					<SelectMulti
						className={cn(["selectFilter", active && "activeSelect"])}
						onInputChange={(inputValue: string, action: any) => {
							if (isSearchable && action.action === "set-value")
								return setSearchTerm(action.prevInputValue);
							setSearchTerm(inputValue);
						}}
						onBlur={() => {
							setActive(false);
						}}
						onFocus={() => {
							setActive(true);
						}}
						inputValue={SearchTerm}
						noOptionsMessage={() => {
							if (isSearchable && SearchTerm.length < minSearchLength)
								return t("global.filters.minSearchLength", { minSearchLength });
							return t("global.filters.noResults", { searchText: SearchTerm });
						}}
						loadingMessage={() => t("global.loading")}
						blurInputOnSelect={false}
						closeMenuOnSelect={false}
						hideSelectedOptions={false}
						options={options ?? []}
						value={value ?? []}
						disabled={isLoading || !!error}
						isLoading={isLoading}
						useFormikStyle={false}
						components={{
							Option: OptionMultiSelect,
						}}
						activeSelectAllOption={props.selectAllOption}
						selectAllLabel={props.selectAllLabel}
						label={isLoading ? t("global.loading") : t("global.filters.selectOptionsText")}
						isClearable={false}
						{...props}
					/>
				</div>
			) : (
				<SelectV2 options={options ?? []} isLoading={isLoading} {...props} />
			)}
			{!!error && (
				<div className="[&>div]:w-full [&>div]:box-border">
					<InfoMessage withIcon color="error">
						{t("global.error")}
					</InfoMessage>
				</div>
			)}
		</div>
	);
};
