// @ts-nocheck
/**
 * This function takes a date as input and returns a string based on the date and time.
 *
 * @param {Date} date
 * @returns {string} - "night", "1214", "weekend" or "none"
 *
 * @example
 * prefillPackage(new Date('2021-06-14T00:00:00.000Z')) // returns "night"
 * prefillPackage(new Date('2021-06-14T12:27:00.000Z')) // returns "1214"
 * prefillPackage(new Date('2021-06-13T00:00:00.000Z')) // returns "weekend"
 * prefillPackage(new Date('2021-06-15T00:00:00.000Z')) // returns "none"
 */
//eslint-disable-next-line
export default (date) => {
	const day = date.getDay();
	const hours = date.getHours();
	const minutes = date.getMinutes();

	// Check if it is the weekend
	if (day === 6 || day === 0) {
		return "weekend";
	}

	// Check if it is night time
	if (hours > 17 || (hours === 17 && minutes >= 59) || hours < 8 || (hours === 8 && minutes <= 30)) {
		return "night";
	}

	// Check if it is lunch time (weekdays only)
	if (day >= 1 && day <= 5) {
		if (hours === 12 || hours === 13) {
			return "1214";
		}
	}

	return "none";
};
