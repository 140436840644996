import { FC, useMemo, useState } from "react";

import { AxiosError } from "axios";
import { sortBy } from "lodash";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import i18n from "src/i18n";
import { TApiError } from "src/types/TErrors";
import { TUserListing, TUsersListingApiResponse } from "src/types/TUsersListing";

import usersListingService from "src/services/usersListingService";

import { InfoMessage } from "@zolteam/react-ras-library";
import { ConfirmModal } from "src/components/molecules/ConfirmModal/ConfirmModal";
import { CheckboxListFilter } from "src/components/organisms";

import { OUT_BUSINESS_REASONS_MOCKED } from "src/constants/CUsersListing";

interface ActionOutOfBuisnessProps {
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
	selectedItem: TUserListing | null;
}

const DEFAULT_OUT_BUSINESS_REASONS = "OUT_BUSINESS_OTHER";

const ActionOutOfBuisness: FC<ActionOutOfBuisnessProps> = ({ isOpen, setIsOpen, selectedItem }) => {
	const { t } = useTranslation();
	const [outOfBuisnessReason, setOutOfBuisnessReason] = useState(DEFAULT_OUT_BUSINESS_REASONS);
	const queryClient = useQueryClient();

	const { data, isLoading, isFetching, error } = useQuery({
		queryKey: "getOutOfBuisnessReasons",
		queryFn: () => usersListingService.getOutOfBuisnessReasons(),
		staleTime: Infinity,
		enabled: isOpen,
	});

	const sortedData = useMemo(() => {
		return data ? sortBy(data, (item) => item.value !== 10) : [];
	}, [data]);

	const mutationActionUpdateOutOfBuisnessReason = useMutation(
		({ userId, data }: { userId: number; data: string }) =>
			usersListingService.updateOutOfBuisnessReason([userId], data),
		{
			onSuccess: () => {
				//TODO: Les données récupérées via opensearch ne sont pas mises à jour instantanément, solution: mise à jour manuelle coté Front, à améliorer
				queryClient.setQueriesData(
					["postUsersListingSearch"],
					(oldData: { data: TUsersListingApiResponse } | undefined) => {
						if (!oldData) return;

						return {
							...oldData,
							data: {
								...oldData.data,
								items: oldData.data.items.map((item) =>
									item.id === selectedItem.id
										? { ...selectedItem, isOutOfBusiness: !selectedItem.isOutOfBusiness }
										: item
								),
							},
						};
					}
				);
				toast.success(t(`usersListing.apiResponse.success.updatedSheet`));
			},
			onError: (error: AxiosError<TApiError>) => {
				const message = error?.response?.data?.message;
				if (i18n.exists(`usersListing.apiResponse.errors.${message}`)) {
					toast.error(t(`usersListing.apiResponse.errors.${message}`, { name: selectedItem?.name }));
				} else {
					toast.error(t(`global.apiErrors.undefined`));
				}
			},
			onSettled: () => {
				setOutOfBuisnessReason(DEFAULT_OUT_BUSINESS_REASONS);
				setIsOpen(false);
			},
		}
	);

	const handleUpdateOutOfBuisnessReason = (userId: number, outOfBusinessReasonName: string) => {
		return mutationActionUpdateOutOfBuisnessReason.mutateAsync({
			userId,
			data: data?.find((d) => d?.name?.toString() === outOfBusinessReasonName)?.value?.toString(),
		});
	};

	return (
		<ConfirmModal
			title={t("usersListing.primaryActions.status.title")}
			isOpen={isOpen}
			onClose={() => {
				setIsOpen(false);
				setOutOfBuisnessReason(DEFAULT_OUT_BUSINESS_REASONS);
			}}
			onConfirm={() => {
				return handleUpdateOutOfBuisnessReason(selectedItem?.id || null, outOfBuisnessReason);
			}}
			confirmText={t("usersListing.primaryActions.status.confirm")}
			size="s"
			className="!w-max"
			disableConfirm={!data}
		>
			{isLoading || isFetching ? (
				<div className="flex flex-col gap-1">
					{OUT_BUSINESS_REASONS_MOCKED?.map((obr) => <Skeleton key={obr.value} height={45} width={"100%"} />)}
				</div>
			) : error ? (
				<div className="[&>div]:w-full [&>div]:box-border">
					<InfoMessage withIcon color="error">
						{t("global.error")}
					</InfoMessage>
				</div>
			) : (
				<div className="flex flex-col gap-8">
					<CheckboxListFilter
						items={sortedData?.reduce<Record<string, boolean>>((acc, reason) => {
							acc[String(reason?.name)] = false; // Convertir la clé en chaîne
							return acc;
						}, {})}
						type="radio"
						onChange={(item) => setOutOfBuisnessReason(item)}
						getLabel={(item) => {
							return t(`usersListing.primaryActions.status.OUT_BUSINESS_REASONS.${item}`);
						}}
						getValue={(type) => {
							return type === outOfBuisnessReason;
						}}
						listStyle={{ gap: "0.75rem" }}
						labelClassName="gap-3 py-3 pl-2 pr-0 rounded-lg w-full cursor-pointer"
						selectedLabelClassName="bg-primary-50 text-primary-500"
					/>
				</div>
			)}
		</ConfirmModal>
	);
};

export default ActionOutOfBuisness;
