/* eslint-disable react/jsx-props-no-spreading */
import moment from "moment";
import "moment/locale/fr";
import { DayPickerSingleDateController, DayPickerSingleDateControllerShape } from "react-dates";
// Style
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

// Utils
import cn from "../../../utils/cn";
// Components
import { Picto } from "@zolteam/react-ras-library";

import { formatMonthText } from "src/utils/dates";

import style from "./DateSinglePicker.module.css";

type TDateSinglePickerProps = {
	id: string | number;
	focusedInput: string | undefined;
	setFocusedInput: (focused: string) => void;
	onDateChange: (date: moment.Moment | null) => void;
	dateString?: string | moment.Moment | null;
	className?: string;
	isVisible?: boolean;
	setIsVisible: (visible: string) => void;
	numberOfMonths?: number;
	keepOpenOnDateSelect?: boolean;
};

interface IDateSinglePickerPropsExtended
	extends TDateSinglePickerProps,
		Omit<DayPickerSingleDateControllerShape, "onDateChange"> {}

export const DateSinglePicker: React.FC<Partial<IDateSinglePickerPropsExtended>> = ({
	id,
	dateString = "",
	focusedInput,
	setFocusedInput,
	onDateChange,
	className = "",
	isVisible = true,
	setIsVisible = undefined,
	numberOfMonths = 2,
	keepOpenOnDateSelect = false,
	...props
}) => {
	const handleOnClickOutside = () => {
		setIsVisible("");
		setFocusedInput("");
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const widthCalendar = numberOfMonths * 270;

	const {
		renderMonthText, // Exclure ce prop de restProps
		...restProps // Toutes les autres propriétés, sauf renderMonthText car s'il y a renderMonthElement alors il doit être = à undefined
	} = props;

	return isVisible ? (
		<div data-testid="period-date-range-picker" className={className} id={String(id)}>
			<DayPickerSingleDateController
				date={dateString ? moment(dateString).startOf("day").add(12, "hours") : null}
				isDayHighlighted={() => false}
				onDateChange={(date) => onDateChange(date)}
				focused={!!focusedInput}
				onFocusChange={(focusStatus: { focused: boolean }) => {
					setFocusedInput(focusStatus?.focused ? focusedInput : "");
				}}
				dayPickerNavigationInlineStyles={{}}
				numberOfMonths={numberOfMonths}
				monthFormat="MMMM Y"
				onOutsideClick={handleOnClickOutside}
				renderMonthText={undefined}
				renderMonthElement={({ month }) => <>{formatMonthText(month)}</>}
				hideKeyboardShortcutsPanel
				renderDayContents={(dayMoment) => <div className={"dayWrapper"}>{dayMoment.format("D")}</div>}
				navPrev={
					<div tabIndex={0} className={cn([style.chevronWrapper, style.chevronLeftWrapper])}>
						<Picto icon="chevronLeft" className={style.picto} />
					</div>
				}
				navNext={
					<div tabIndex={0} className={cn([style.chevronWrapper, style.chevronRightWrapper])}>
						<Picto icon="chevronRight" className={style.picto} />
					</div>
				}
				initialVisibleMonth={() => moment(dateString || new Date()).startOf("month")}
				keepOpenOnDateSelect={keepOpenOnDateSelect}
				{...restProps}
			/>
		</div>
	) : null;
};
