import { useContext } from "react";

import { AppDispatchContext, AppStateContext, IAppStateContext } from "./AppContext";

const useAppContext = () => {
	const appState = useContext(AppStateContext) ?? ({} as IAppStateContext);
	const appDispatch = useContext(AppDispatchContext);
	return [appState, appDispatch] as const;
};

export default useAppContext;
