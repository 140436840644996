import client from "./client";

/**
 * @description Get logged user info
 * @returns {Promise<AxiosResponse<any>>}
 */
const getUserMe = () => client.get("api-client-v2/user/me");

const getUserAgencies = () => client.get("/api-client-v2/agencies");

/**
 * @description Get availables features
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getAvailableFeatures = () => client.get("api-client-v2/user/available-features");

const UserService = {
	getUserMe,
	getUserAgencies,
};

export default UserService;
