const ORDER_NOT_PROVIDED_REASONS = {
	COMPETITION: "Concurrence",
	TOO_EXPENSIVE: "Trop cher",
	NO_CREDIT: "Pas de crédit",
	NO_CANDIDATE: "Pas de candidat",
	WEATHER_CONDITIONS: "Intempéries",
	ORDER_CANCELLED: "Commande annulée",
	ORDER_ABANDONED: "Commande abandonnée",
	OTHER: "Autre",
};

export default ORDER_NOT_PROVIDED_REASONS;
