import { FC, useCallback, useState } from "react";

import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { TTemporaryWorkerDocuments } from "src/types";

import UserTemporaryWorkerService from "src/services/userTemporaryWorkerService";

import { TParams } from "../TemporaryWorkerSheet";
import DocumentsColumns from "./DocumentsColumns";
import { Badge, Title } from "@zolteam/react-ras-library";
import { PictoCommonUTWData } from "src/components/atoms";
import { InputSearch, WideLoader } from "src/components/molecules";
import { TableListing } from "src/components/organisms";

import { uNormalizeAndCleanString } from "src/utils/UStrings";

import { UTW_DOC_STATUS } from "src/constants/CUnifiedTemporaryWorker";

import style from "./Documents.module.css";

interface IDocumentsProps {
	uniqueId: string;
}

const SEARCHEABLE_FIELDS = [
	{ label: "name" },
	{ label: "type", translationKey: "usersListing.temporaryWorker.documents.types" },
	{ label: "reference" },
];

const compareValue = (value: string, fields: string[]) => {
	const searchLowerAndNormalize = uNormalizeAndCleanString(value);

	return fields.some((field) => {
		return uNormalizeAndCleanString(field)?.includes(searchLowerAndNormalize);
	});
};

const Documents: FC<IDocumentsProps> = ({ uniqueId }) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const userId = useParams<TParams>()?.userId;
	const temporaryWorkerQueryState: any = queryClient.getQueryState<any>(["getUser", userId]);
	const [filteredData, setFilteredData] = useState<TTemporaryWorkerDocuments[]>([]);
	const statusOrder = ["EXPIRED", "NEAR_EXPIRY", "UNCONFIRMED"];

	//Query
	const utwDocuments = useQuery({
		queryKey: ["getUTWDocuments"],
		queryFn: ({ signal }) => {
			return UserTemporaryWorkerService.getDocuments(uniqueId, signal);
		},
		enabled: !!uniqueId,
		onSuccess: (data) => {
			setFilteredData(data);
		},
	});

	const { isError, isLoading, isFetching, data } = utwDocuments;

	const getInvalidDocuments = useCallback(() => {
		return filteredData
			?.filter((item) => item.status !== UTW_DOC_STATUS.VALID.label)
			.sort((a, b) => {
				return statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status);
			});
	}, [filteredData]);

	const getValidDocuments = useCallback(() => {
		return filteredData?.filter((item) => item.status === UTW_DOC_STATUS.VALID.label);
	}, [filteredData]);

	const handleSearch = (searchText: string) => {
		if (!searchText) return setFilteredData(data);
		const _filteredData = data?.filter((item) =>
			compareValue(
				searchText,
				SEARCHEABLE_FIELDS.map((field) => {
					return !!field.translationKey
						? t(`${field.translationKey}.${item[field.label]}`)
						: (item[field.label] as string);
				})
			)
		);
		return setFilteredData(_filteredData || []);
	};

	return (
		<div className="flex flex-col flex-1 overflow-hidden">
			<div className={`${style.filterHeader} flex flex-row items-center justify-start gap-12 p-6 pb-4 `}>
				<Title
					tag="h2"
					size="heading02"
					fontWeight="bold"
					lineHeight="s"
					className="flex flex-row gap-3 items-center"
				>
					{t("usersListing.temporaryWorker.documents.title")} <PictoCommonUTWData />
				</Title>

				<InputSearch
					label={t("usersListing.temporaryWorker.documents.searchText")}
					onChange={(value) => handleSearch(value)}
					resetValueProps={() => setFilteredData(data)}
					minLength={0}
					name="search"
					className="w-auto min-w-80  h-[41px]"
				/>
			</div>
			<div className={`${style.table} pt-6 shadow-lg pb-10 overflow-auto`}>
				{isLoading || isFetching || temporaryWorkerQueryState.status === "idle" ? (
					<div className="relative h-full w-full flex-1 flex">
						<WideLoader message={t("global.loading")} />
					</div>
				) : //Info erreur dans le toast
				data?.length === 0 || isError ? (
					<div className="flex flex-1 justify-center items-center mb-20">
						{t("usersListing.temporaryWorker.documents.noDocumentFound")}
					</div>
				) : (
					<div className="flex flex-row gap-4">
						<div className="flex flex-col gap-20 flex-[3] min-w-[850px]">
							{getInvalidDocuments()?.length > 0 && (
								<div>
									<Title
										tag="h3"
										size="heading03"
										fontWeight="bold"
										lineHeight="s"
										className="ml-6 mb-4 flex flex-row"
									>
										{t("usersListing.temporaryWorker.documents.invalid")}{" "}
										<Badge
											content={getInvalidDocuments()?.length}
											className="ml-s"
											color="accent"
										/>
									</Title>
									<TableListing
										loading={isLoading || isFetching}
										error={isError}
										data={getInvalidDocuments()}
										initialColumnSort={{
											field: "expirationDate",
											orderBy: "ASC",
										}}
										tableContainerClassName="rounded-none overflow-visible"
										canBeChecked={false}
										fullHeight
										showHeader={false}
										showCustomMessage={false}
										genericEmptyCellsMessage={<div className="text-neutral-300">-</div>}
										tableClassName={style.tableClass}
									>
										{DocumentsColumns()}
									</TableListing>
								</div>
							)}

							{getValidDocuments()?.length > 0 && (
								<div>
									<Title
										tag="h3"
										size="heading03"
										fontWeight="bold"
										lineHeight="s"
										className="ml-6 mb-4"
									>
										{t("usersListing.temporaryWorker.documents.valid")}
									</Title>
									<TableListing
										loading={isLoading || isFetching}
										error={isError}
										data={getValidDocuments()}
										initialColumnSort={{
											field: "expirationDate",
											orderBy: "ASC",
										}}
										tableContainerClassName="rounded-none overflow-visible"
										canBeChecked={false}
										fullHeight
										showHeader={false}
										showCustomMessage={false}
										genericEmptyCellsMessage={<div className="text-neutral-300">-</div>}
										tableClassName={style.tableClass}
									>
										{DocumentsColumns()}
									</TableListing>
								</div>
							)}
						</div>
						<div className="flex-1"></div>
					</div>
				)}
			</div>
		</div>
	);
};

export default Documents;
