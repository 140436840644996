// Style
import { FC } from "react";

import { Text } from "@zolteam/react-ras-library";

import style from "./TableInfo.module.css";

interface ITableInfoProps {
	info: string;
	subInfo?: string | null;
	className?: string;
}

export const TableInfo: FC<ITableInfoProps> = ({ info, subInfo = "", className = "" }) => (
	<div className={`${style.container} ${className}`}>
		<Text tag="div" fontWeight="light" lineHeight="m" size="paragraph02">
			{info}
		</Text>
		{subInfo ? (
			<Text tag="div" color="grey" fontWeight="light" lineHeight="m" size="paragraph03">
				{subInfo}
			</Text>
		) : null}
	</div>
);
