import { FC, PropsWithChildren } from "react";

import { NavLink } from "react-router-dom";

import cn from "../../../utils/cn";

import styles from "./ProfileNav.module.css";

interface IProfileNavProps extends PropsWithChildren {
	path: string;
	isActive?: boolean;
}

export const ProfileNav: FC<IProfileNavProps> = ({ children, isActive = false, path }) => (
	<button type="button" className={cn([styles.btn, isActive ? styles.active : null])}>
		<NavLink to={path}>{children}</NavLink>
	</button>
);
