// Style
import { FC } from "react";

import { Picto, Text, Tooltip } from "@zolteam/react-ras-library";

import style from "./TableLockedSheet.module.css";

interface ITableLockedSheetProps {
	icon: string;
	msg: string;
	children: React.ReactNode;
	isLocked?: boolean;
}

export const TableLockedSheet: FC<ITableLockedSheetProps> = ({ icon, isLocked = false, msg, children }) =>
	isLocked ? (
		<Tooltip
			animation={false}
			arrow={false}
			placement="right"
			content={
				<Text tag="span" size="paragraph02" color="grey">
					{msg}
				</Text>
			}
		>
			<div className={style.container}>
				<Picto icon={icon} />
				{children}
			</div>
		</Tooltip>
	) : (
		<div className={style.container}>{children}</div>
	);
