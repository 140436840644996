import { useMemo, useState } from "react";

import { Form, Formik, useFormikContext } from "formik";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

// Services
import { getAvailableFilters } from "src/services/availableFiltersService";
import { getCommonDocuments, getOutOfBusinessReasons, getVehiculesList } from "src/services/commandService";
import { getCommonQualifications } from "src/services/commonQualificationsService";
import { OrderEquipmentsItem, getOrdersEquipments } from "src/services/ordersEquipmentsService";
// Types
import { OrderEquipmentsApiResponse } from "src/services/ordersEquipmentsService";

// Hooks
import useOrderData from "src/hooks/useOrderData";

import formatCommonQualifications from "./internal/formatCommonQualifications";
import BusinessInformationFilterProps from "./types/BusinessInformationFilterPropsType";
import BusinessInformationFilterValuesType from "./types/BusinessInformationFilterValuesType";
import useBusinessInformationFilter from "./useBusinessInformationFilter";
import { Checkbox, InfoMessage, Picto, SelectAsync, Tag, Title, ToggleSwitch } from "@zolteam/react-ras-library";
import { DateRangeInput, Slider } from "src/components/atoms";
import { DateRangePicker } from "src/components/molecules";

// Style
import cn from "src/utils/cn";
// Utils
import { getAtomFromDate } from "src/utils/formatDate";

// Components
// Style
// Utils
// Utils
import { ORDER_EQUIPMENTS } from "src/constants";
import { CURRENT_AVAILABLE_FILTERS } from "src/constants/order/currentAvailableFilters";

import style from "./BusinessInformationFilter.module.css";

const BusinessInformationFilter: React.FC<BusinessInformationFilterProps> = ({
	onRadiusChange,
	onStatusChange,
	onQualificationSelect,
	onQualificationDelete,
	onCommonDocumentSelect,
	onCommonDocumentDelete,
	onVehiclesChange,
	onOutOfBusinessReasons,
	onEquipmentSelect,
	onEquipmentDelete,
	onAvailabilityElementChange,
	onPeriodChange,
}) => {
	const { t } = useTranslation();
	const { orderId } = useParams<{ orderId: string }>();
	const { values, setFieldValue, touched, errors, setFieldTouched } =
		useFormikContext<BusinessInformationFilterValuesType>();
	const {
		distance,
		statuses,
		equipments,
		qualifications,
		commonDocuments,
		isMedicalCheckupValid,
		isAdequateForClient,
		hasAlreadyWorkedForClient,
		vehicles,
		isOutOfBusiness,
		outOfBusinessReasonsIds,
		period,
		isTempWorkerAvailabilitiesFilterEnabled,
		isTempWorkerTimeOffFilterEnabled,
		isTempWorkerOrderFilterEnabled,
		isTempWorkerContractFilterEnabled,
	}: BusinessInformationFilterValuesType = values;

	const [documentInputValue, setDocumentInputValue] = useState("");
	const [qualificationInputValue, setQualificationInputValue] = useState("");
	const [equipmentInputValue, setEquipmentInputValue] = useState("");

	const { startDate, endDate } = period;

	const DATE_FORMAT_STRING = "DD/MM/yyyy";
	const [focusedInputName, setFocusedInputName] = useState("");

	const { orderData } = useOrderData(orderId);

	const { data: availableFilters } = useQuery(["availableFilters"], () => getAvailableFilters());

	const { data: outOfBusinessReasonsList, isLoading: isoutOfBusinessReasonsListLoading } = useQuery(
		"getOutOfBusinessReasons",
		getOutOfBusinessReasons
	);

	const { data: vehiclesList, isLoading: isVehiclesListLoading } = useQuery("getVehiculesList", getVehiculesList);

	const { data: ordersEquipmentsData, isFetched: ordersEquipmentsFetched } = useQuery(
		"ordersEquipments",
		getOrdersEquipments,
		{
			staleTime: Infinity,
			placeholderData: { data: [] } as AxiosResponse<OrderEquipmentsApiResponse>,
		}
	);

	const {
		isLocationBeingCalculated,
		locationCouldNotBeCalculated,
		showAvailabilityPeriodFilter,
		showClientHistoryFilter,
		showClientAdequateFilter,
		showClientWorkedForClientFilter,
		showAvailabilityUnifiedTempWorkerFilter,
		showUnavailableOrderPeriodsFilter,
		showUnavailabilityContractPeriodsFilter,
		shouldShowSingleFilter,
	} = useBusinessInformationFilter(orderData, availableFilters);

	let timeoutId: NodeJS.Timeout;

	const orderEquipmentOptions = useMemo(() => {
		if (!ordersEquipmentsData) return [];
		return ordersEquipmentsData.data.map(({ id, name }: OrderEquipmentsItem) => ({
			id,
			name: ORDER_EQUIPMENTS[name],
		}));
	}, [ordersEquipmentsData]);

	const qualificationOptions = async (inputValue: string | undefined | null) => {
		clearTimeout(timeoutId);

		if (inputValue.length < 3) return null;

		return new Promise((resolve) => {
			timeoutId = setTimeout(async () => {
				if (inputValue.length < 3) {
					resolve([]);
				} else {
					try {
						const res = await getCommonQualifications({
							search: inputValue,
							limit: 200,
						});
						resolve(formatCommonQualifications(res));
					} catch (error) {
						resolve([]);
					}
				}
			}, 200);
		});
	};

	const commonDocumentsOptions = async (inputValue: string | undefined | null) => {
		clearTimeout(timeoutId);

		if (inputValue.length < 3) return null;

		return new Promise((resolve) => {
			timeoutId = setTimeout(async () => {
				if (inputValue.length < 3) {
					resolve([]);
				} else {
					try {
						const res = await getCommonDocuments({
							search: inputValue,
							limit: 200,
						});
						resolve(formatCommonQualifications(res));
					} catch (error) {
						resolve([]);
					}
				}
			}, 200);
		});
	};

	const renderSliderLabel = (value: number) => {
		if (value === 10) return t("commands.profiles.modalsContent.matchingFilters.lessThan", { kmDistance: "10" });
		if (value === 100 || value === null)
			return t("commands.profiles.modalsContent.matchingFilters.moreThan", { kmDistance: "100" });
		return `${value} ${t("commands.profiles.modalsContent.matchingFilters.km")}`;
	};

	const handleStatusCheckboxChange = (statusType: string, checked: boolean) => {
		const newStatuses = checked ? [...statuses, statusType] : statuses.filter((status) => status !== statusType);
		onStatusChange("statuses", newStatuses);
	};

	const handleVehiclesCheckboxChange = (vehicleId: number, checked: boolean) => {
		const newVehicles = checked ? [...vehicles, vehicleId] : vehicles.filter((status) => status !== vehicleId);
		onVehiclesChange("vehicles", newVehicles);
	};

	const handleOutOfBusinessReasonsCheckboxChange = (reason: number, checked: boolean) => {
		const newReasons = checked
			? [...outOfBusinessReasonsIds, reason]
			: outOfBusinessReasonsIds.filter((reasonId) => reasonId !== reason);
		onStatusChange("outOfBusinessReasonsIds", newReasons);
	};

	const handleDateRangeUpdate = (start: string, end: string) => {
		const newPeriod = {
			startDate: start ? getAtomFromDate(start) : null,
			endDate: end ? getAtomFromDate(end) : null,
		};

		if (startDate) {
			setFieldTouched("startDate", true);
		}
		if (endDate) {
			setFieldTouched("endDate", true);
		}

		onPeriodChange("period", newPeriod);
	};

	const handleClearPeriodDate = (periodSlot: string, event: React.SyntheticEvent) => {
		event.stopPropagation();
		const newValues: { startDate: string | null; endDate: string | null } = {
			...values.period,
			[periodSlot]: null,
		};

		setFieldTouched(periodSlot, true);
		onPeriodChange("period", newValues);
	};

	const renderSelectedQualifications = ({ name, id }: { name: string; id: string }) => (
		<div className={style.addedItem} key={id}>
			<Tag key={id} color="primaryLight" size="s" showCloseButton onClose={() => onQualificationDelete(id)}>
				<p className={style.itemSelected}>{`${name}`}</p>
			</Tag>
		</div>
	);

	const renderSelectedCommonDocuments = ({ name, id }: { name: string; id: string }) => (
		<div className={style.addedItem} key={id}>
			<Tag
				key={id}
				color="primaryLight"
				size="s"
				className={style.tag}
				showCloseButton
				onClose={() => onCommonDocumentDelete(id)}
			>
				<p className={style.documentSelected}>{`${name}`}</p>
			</Tag>
		</div>
	);

	const renderSelectedEquipments = (id: number) => {
		const qualificationName = orderEquipmentOptions.find(
			(option: { id: number; name: string }) => option.id === id
		)?.name;
		return (
			<div className={style.addedItem} key={id}>
				<Tag key={id} color="primaryLight" size="s" showCloseButton onClose={() => onEquipmentDelete(id)}>
					<p className={style.itemSelected}>{`${qualificationName}`}</p>
				</Tag>
			</div>
		);
	};

	const renderOptionLabel = (option: { name: string; unifiedTempWorkerUniqueId: string }, isSelected: boolean) => (
		<div className={style.optionsLabel}>
			<div className={style.optionCheckbox}>
				<Checkbox
					className={style.checkbox}
					onChange={(e: React.SyntheticEvent) => {
						e.stopPropagation();
					}}
					theme="primary"
					id={option.unifiedTempWorkerUniqueId}
					value={isSelected}
				/>
			</div>
			<p className={cn([style.optionLabel, isSelected ? style.active : ""])}>{`${option.name}`}</p>
		</div>
	);

	return (
		<>
			<Title tag="h1" size="heading01" lineHeight="l" className={cn([style.title])}>
				{t("commands.profiles.modalsContent.matchingFilters.title")}
			</Title>
			<div className="mt-3 mb-10 flex flex-col gap-2.5">
				{shouldShowSingleFilter(CURRENT_AVAILABLE_FILTERS.GEO_DISTANCE) && (
					<>
						{locationCouldNotBeCalculated && (
							<InfoMessage color="warning" withIcon>
								{t("commands.profiles.modalsContent.matchingFilters.locationCouldNotBeCalculated")}
							</InfoMessage>
						)}

						{isLocationBeingCalculated && (
							<InfoMessage color="warning">
								<div className={style.infoMessageContent}>
									<Picto icon="refresh" className={style.refreshPicto} />
									{t("commands.profiles.modalsContent.matchingFilters.geolocationInProgress")}
								</div>
							</InfoMessage>
						)}
					</>
				)}

				{qualifications.length === 0 && (
					<InfoMessage color="warning">
						<div className={style.infoMessageContent}>
							<Picto icon="info" className={style.refreshPicto} />
							{t("commands.profiles.modalsContent.matchingFilters.noQualificationsSelected")}
						</div>
					</InfoMessage>
				)}
			</div>

			<div className={style.section}>
				<h3 className={style.sectionTitle}>{t("commands.profiles.modalsContent.matchingFilters.subTitle")}</h3>

				{/* QUALIFICATIONS */}
				{shouldShowSingleFilter(CURRENT_AVAILABLE_FILTERS.QUALIFICATION) && (
					<div className={style.subSection}>
						<div className={style.subtitle}>
							{t("commands.profiles.modalsContent.matchingFilters.sectionTitleQualification")}
						</div>
						<div className={style.addedItemBox}>
							{qualifications?.map((qualification: { name: string; id: string }) =>
								renderSelectedQualifications(qualification)
							) || []}
						</div>
						<div className={style.searchInput}>
							<Formik initialValues={null} onSubmit={() => null}>
								{() => (
									<Form className={style.addProfileForm}>
										<SelectAsync
											className={cn([
												style.inputs,
												errors?.qualifications ? "errorSelect" : null,
											])}
											isSearchable
											searchIcon
											promiseOptions={qualificationOptions}
											onChange={(item: { id: string; name: string }) => {
												onQualificationSelect(item);
											}}
											inputPersistOnOptionSelect
											name="qualifications"
											label={t("commands.profiles.modalsContent.matchingFilters.selectLabel")}
											getOptionLabel={(option: {
												name: string;
												unifiedTempWorkerUniqueId: string;
											}) =>
												renderOptionLabel(
													option,
													qualifications?.find((opt) => opt.name === option.name) != null
												)
											}
											getOptionValue={(option: {
												name: string;
												unifiedTempWorkerUniqueId: string;
											}) => option.unifiedTempWorkerUniqueId}
											value=""
											handleInputChange={(
												value: string | undefined | null,
												{ action, prevInputValue }: { action: string; prevInputValue: string }
											) => {
												if (action === "set-value") {
													setQualificationInputValue(prevInputValue);
													return prevInputValue;
												}
												if (action !== "menu-close") setQualificationInputValue(value);
												return value;
											}}
											inputValue={qualificationInputValue}
											noOptionsMessage={(): string | null => null}
											loadingMessage={() =>
												t(
													"commands.profiles.modalsContent.matchingFilters.qualifications.loadingMessage"
												)
											}
										/>
										{errors.qualifications && (
											<InfoMessage color="error" withIcon>
												{errors.qualifications}
											</InfoMessage>
										)}
									</Form>
								)}
							</Formik>
						</div>
					</div>
				)}

				{/* DOCUMENTS */}
				{shouldShowSingleFilter(CURRENT_AVAILABLE_FILTERS.DOCUMENTS) && (
					<div className={style.subSection}>
						<div className={style.subtitle}>
							{t("commands.profiles.modalsContent.matchingFilters.sectionDocuments.title")}
						</div>
						{shouldShowSingleFilter(CURRENT_AVAILABLE_FILTERS.MEDICAL_CHECKUP) && (
							<div className={style.switch}>
								<ToggleSwitch
									id="isMedicalCheckupValid"
									name="isMedicalCheckupValid"
									onChange={() => setFieldValue("isMedicalCheckupValid", !isMedicalCheckupValid)}
									toggled={isMedicalCheckupValid}
								>
									<label htmlFor="isMedicalCheckupValid" className={style["toggle-label"]}>
										{t(
											"commands.profiles.modalsContent.matchingFilters.sectionDocuments.medicalVisit"
										)}
									</label>
								</ToggleSwitch>
							</div>
						)}
						<div className={style.addedItemBox}>
							{commonDocuments?.map((commonDocument) => renderSelectedCommonDocuments(commonDocument)) ||
								[]}
						</div>
						<div className={style.searchInput}>
							<Formik initialValues={null} onSubmit={() => null}>
								{() => (
									<Form className={style.addProfileForm}>
										<SelectAsync
											className={cn([style.inputs])}
											isSearchable
											searchIcon
											inputPersistOnOptionSelect
											promiseOptions={commonDocumentsOptions}
											onChange={(item: { id: string }) => {
												onCommonDocumentSelect(item);
											}}
											name="commonDocuments"
											label={t(
												"commands.profiles.modalsContent.matchingFilters.documents.selectCommonDocumentLabel"
											)}
											getOptionLabel={(option: {
												name: string;
												unifiedTempWorkerUniqueId: string;
											}) =>
												renderOptionLabel(
													option,
													commonDocuments?.find((opt) => opt.name === option.name) != null
												)
											}
											getOptionValue={(option: { id: string }) => option.id}
											value=""
											handleInputChange={(
												value: string | undefined | null,
												{ action, prevInputValue }: { action: string; prevInputValue: string }
											) => {
												if (action === "set-value") {
													setDocumentInputValue(prevInputValue);
													return prevInputValue;
												}
												if (action !== "menu-close") setDocumentInputValue(value);
												return value;
											}}
											inputValue={documentInputValue}
											noOptionsMessage={(): string | null => null}
											loadingMessage={() =>
												t(
													"commands.profiles.modalsContent.matchingFilters.documents.loadingMessage"
												)
											}
										/>
									</Form>
								)}
							</Formik>
						</div>
					</div>
				)}

				{/* EQUIPMENTS */}
				{shouldShowSingleFilter(CURRENT_AVAILABLE_FILTERS.EQUIPMENTS) && (
					<div>
						{ordersEquipmentsFetched ? (
							<div className={style.searchInput}>
								<div className={style.subSection}>
									<div className={style.subtitle}>
										{t("commands.profiles.modalsContent.matchingFilters.sectionEquipments.title")}
									</div>
									<div className={style.addedItemBox}>
										{equipments.map((equipmentId: number) =>
											renderSelectedEquipments(Number(equipmentId))
										) || []}
									</div>
									<Formik initialValues={null} onSubmit={() => null}>
										{() => (
											<Form className={style.addProfileForm}>
												<SelectAsync
													className={cn([style.inputs])}
													defaultOptions={orderEquipmentOptions}
													promiseOptions={async (
														input: string | undefined | null
													): Promise<{ id: number; name: string }[]> => {
														if (input.length < 3) return orderEquipmentOptions;
														return orderEquipmentOptions.filter(
															(option: { name: string }) =>
																option.name.toLowerCase().includes(input.toLowerCase())
														);
													}}
													onChange={(item: { id: number }) => {
														onEquipmentSelect(item);
													}}
													inputPersistOnOptionSelect
													name="profile"
													label={t(
														"commands.profiles.modalsContent.matchingFilters.sectionEquipments.selectLabel"
													)}
													getOptionLabel={(option: {
														id: number;
														name: string;
														unifiedTempWorkerUniqueId: string;
													}) =>
														renderOptionLabel(
															option,
															equipments?.find((id) => option.id === id) != null
														)
													}
													getOptionValue={(option: { id: number }) => option.id}
													value=""
													handleInputChange={(
														value: string,
														{
															action,
															prevInputValue,
														}: { action: string; prevInputValue: string }
													) => {
														if (action === "set-value") {
															setEquipmentInputValue(prevInputValue);
															return prevInputValue;
														}
														if (action !== "menu-close") setEquipmentInputValue(value);
														return value;
													}}
													inputValue={equipmentInputValue}
													noOptionsMessage={(): string | null => null}
													loadingMessage={(): string | null => null}
												/>
											</Form>
										)}
									</Formik>
								</div>
							</div>
						) : (
							<div>Chargement des équipements...</div>
						)}
					</div>
				)}
			</div>
			{/* STATUS */}
			{shouldShowSingleFilter(CURRENT_AVAILABLE_FILTERS.STATUS) && (
				<div className={style.section}>
					<h3 className={style.sectionTitle}>
						{t("commands.profiles.modalsContent.matchingFilters.sectionTitleStatus")}
					</h3>
					<div className={cn([style.statusSection, style.checkbox, style.statusBox])}>
						<Checkbox
							id="candidate"
							value={statuses.includes("candidate")}
							onChange={() => handleStatusCheckboxChange("candidate", !statuses.includes("candidate"))}
							theme="primary"
							label={t("commands.profiles.modalsContent.matchingFilters.candidate")}
							name="statuses"
						/>
						<Checkbox
							id="worker"
							value={statuses.includes("worker")}
							onChange={() => handleStatusCheckboxChange("worker", !statuses.includes("worker"))}
							theme="primary"
							label={t("commands.profiles.modalsContent.matchingFilters.worker")}
							name="statuses"
						/>
					</div>
				</div>
			)}

			{/* OUT OF BUSINESS */}
			{!isoutOfBusinessReasonsListLoading &&
				shouldShowSingleFilter(CURRENT_AVAILABLE_FILTERS.OUT_OF_BUSINESS) && (
					<div className={style.section}>
						<div className={cn([style.switch, style.toggleSwitchBox])}>
							<ToggleSwitch
								id="isOutOfBusiness"
								name="isOutOfBusiness"
								toggled={isOutOfBusiness}
								onChange={() => {
									onOutOfBusinessReasons("isOutOfBusiness", !isOutOfBusiness);
								}}
							/>
							<label htmlFor="isOutOfBusiness" className={style["toggle-label"]}>
								{t("commands.profiles.modalsContent.matchingFilters.outProfiles")}
							</label>
						</div>
						{isOutOfBusiness && (
							<div className={cn([style.checkbox, style.statusBox, style.outOfBusinessReasons])}>
								{outOfBusinessReasonsList?.data?.map((reason: { id: number; keyName: string }) => (
									<div className={style.outOfBusinessCheckBox}>
										<Checkbox
											id={reason.keyName}
											value={outOfBusinessReasonsIds.includes(reason.id)}
											onChange={() =>
												handleOutOfBusinessReasonsCheckboxChange(
													reason.id,
													!outOfBusinessReasonsIds.includes(reason.id)
												)
											}
											theme="primary"
											label={t(`outOfBusinessReasons.${reason.keyName}`)}
											name="outOfBusinessReasonsIds"
										/>
									</div>
								))}
							</div>
						)}
					</div>
				)}
			{/* PREVIOUS EXPERIENCES, Change condition if more filters are added in this section */}
			{showClientHistoryFilter && (
				<div className={style.section}>
					<h3 className={style.sectionTitle}>
						{t("commands.profiles.modalsContent.matchingFilters.sectionPreviousExperiences.title")}
					</h3>

					{/* CLIENT HISTORY */}
					<p className={style.subtitle}>
						{t(
							"commands.profiles.modalsContent.matchingFilters.sectionPreviousExperiences.clientHistory.title"
						)}
					</p>
					<div className={cn([style.checkbox])}>
						{showClientAdequateFilter && (
							<Checkbox
								id="isAdequateForClientCheckbox"
								value={isAdequateForClient}
								onChange={() => setFieldValue("isAdequateForClient", !isAdequateForClient)}
								theme="primary"
								label={t(
									"commands.profiles.modalsContent.matchingFilters.sectionPreviousExperiences.clientHistory.isAdequateForClient"
								)}
								name="isAdequateForClient"
							/>
						)}
						{showClientWorkedForClientFilter && (
							<Checkbox
								id="hasAlreadyWorkedForClientCheckbox"
								value={hasAlreadyWorkedForClient}
								onChange={() => setFieldValue("hasAlreadyWorkedForClient", !hasAlreadyWorkedForClient)}
								theme="primary"
								label={t(
									"commands.profiles.modalsContent.matchingFilters.sectionPreviousExperiences.clientHistory.hasAlreadyWorkedForClient"
								)}
								name="isAdequateForClient"
							/>
						)}
					</div>
				</div>
			)}

			{/* AVAILABILITY */}
			<div className={style.section}>
				<h3 className={style.sectionTitle}>
					{t("commands.profiles.modalsContent.matchingFilters.sectionAvailability.title")}
				</h3>
				<p className={style.subtitle}>
					{t("commands.profiles.modalsContent.matchingFilters.sectionAvailability.availability")}
				</p>
				{showAvailabilityPeriodFilter && (
					<div className={style.dateRange}>
						<div className={style.dateRangeWrapper}>
							<DateRangeInput
								label={t("commands.start")}
								disabled={false}
								value={startDate ? moment(startDate).format(DATE_FORMAT_STRING) : ""}
								onClick={() => {
									setFieldTouched("startDate", true);
									setFocusedInputName("startDate");
								}}
								onClear={(e) => handleClearPeriodDate("startDate", e)}
								className={style.fieldStartDate}
								isActive={focusedInputName === "startDate"}
								error={
									(touched?.startDate && errors?.period?.startDate
										? t("commands.error.mandatoryStartDateField")
										: null) ||
									(typeof errors.period === "string"
										? t("commands.error.mandatoryEndDateField")
										: null)
								}
							/>
							<DateRangeInput
								label={t("commands.end")}
								disabled={false}
								value={endDate ? moment(endDate).format(DATE_FORMAT_STRING) : ""}
								onClick={() => {
									setFieldTouched("endDate", true);
									setFocusedInputName("endDate");
								}}
								onClear={(e) => handleClearPeriodDate("endDate", e)}
								className={cn([style.dateRangeField, style.fieldEndDate])}
								isActive={focusedInputName === "endDate"}
								error={
									touched?.endDate && errors?.period?.endDate
										? t("commands.error.mandatoryEndDateField")
										: null ||
											(typeof errors.period === "string"
												? t("commands.error.mandatoryEndDateField")
												: null)
								}
							/>

							<DateRangePicker
								className={style.datePickerWrapper}
								// @ts-ignore
								dateFrom={moment(startDate || new Date())
									.startOf("day")
									.add(12, "hours")}
								// @ts-ignore
								dateTo={endDate ? moment(endDate) : null}
								focusedInput={focusedInputName.replace("period.", "")}
								setFocusedInput={(reactDatesInputName) =>
									setFocusedInputName(reactDatesInputName ? `period.${reactDatesInputName}` : "")
								}
								onDatesChange={handleDateRangeUpdate}
								keepOpenOnDateSelect
								isVisible={focusedInputName !== ""}
								isDayBlocked={() => false}
								setIsVisible={() => setFocusedInputName("")}
							/>
						</div>
						{typeof errors.period === "string" ? (
							<InfoMessage color="error" withIcon>
								{errors.period}
							</InfoMessage>
						) : null}
					</div>
				)}

				<p className={style.subtitle}>
					{t("commands.profiles.modalsContent.matchingFilters.sectionAvailability.availabilityReasons")}
				</p>
				<div className={cn([style.checkbox, style.outOfBusinessReasons])}>
					<div className={style.outOfBusinessCheckBox}>
						<Checkbox
							id="isTempWorkerAvailabilitiesFilterEnabled"
							value={isTempWorkerAvailabilitiesFilterEnabled}
							onChange={() =>
								onAvailabilityElementChange(
									"isTempWorkerAvailabilitiesFilterEnabled",
									!isTempWorkerAvailabilitiesFilterEnabled
								)
							}
							theme="primary"
							label={t(
								"commands.profiles.modalsContent.matchingFilters.sectionAvailability.availabilityPlanning"
							)}
							name="availability"
						/>
					</div>
					{showAvailabilityUnifiedTempWorkerFilter && (
						<div className={style.outOfBusinessCheckBox}>
							<Checkbox
								id="isTempWorkerOrderFilterEnabled"
								value={isTempWorkerOrderFilterEnabled}
								onChange={() =>
									onAvailabilityElementChange(
										"isTempWorkerOrderFilterEnabled",
										!isTempWorkerOrderFilterEnabled
									)
								}
								theme="primary"
								label={t(
									"commands.profiles.modalsContent.matchingFilters.sectionAvailability.tempWorkerOrder"
								)}
								name="availability"
							/>
						</div>
					)}
					{showUnavailableOrderPeriodsFilter && (
						<div className={style.outOfBusinessCheckBox}>
							<Checkbox
								id="isTempWorkerTimeOffFilterEnabled"
								value={isTempWorkerTimeOffFilterEnabled}
								onChange={() =>
									onAvailabilityElementChange(
										"isTempWorkerTimeOffFilterEnabled",
										!isTempWorkerTimeOffFilterEnabled
									)
								}
								theme="primary"
								label={t(
									"commands.profiles.modalsContent.matchingFilters.sectionAvailability.absences"
								)}
								name="availability"
							/>
						</div>
					)}
					{showUnavailabilityContractPeriodsFilter && (
						<div className={style.outOfBusinessCheckBox}>
							<Checkbox
								id="isTempWorkerContractFilterEnabled"
								value={isTempWorkerContractFilterEnabled}
								onChange={() =>
									onAvailabilityElementChange(
										"isTempWorkerContractFilterEnabled",
										!isTempWorkerContractFilterEnabled
									)
								}
								theme="primary"
								label={t(
									"commands.profiles.modalsContent.matchingFilters.sectionAvailability.contractPeriods"
								)}
								name="availability"
							/>
						</div>
					)}
				</div>
			</div>

			{/* GEO */}
			{!isVehiclesListLoading && shouldShowSingleFilter(CURRENT_AVAILABLE_FILTERS.GEO_DISTANCE) && (
				<div className={style.section}>
					<h3 className={style.sectionTitle}>
						{t("commands.profiles.modalsContent.matchingFilters.sectionTitleProfilPreferences")}
					</h3>
					<div
						className={
							locationCouldNotBeCalculated || isLocationBeingCalculated ? style.disabledFieldArea : null
						}
					>
						<p className={style.specialSubtitle}>
							{t("commands.profiles.modalsContent.matchingFilters.missionRadius")}
						</p>
						<Slider
							onChange={onRadiusChange}
							value={distance}
							min={10}
							max={100}
							minLabel="10"
							maxLabel="100"
							minLabelDetails={t("commands.profiles.modalsContent.matchingFilters.km")}
							maxLabelDetails={t("commands.profiles.modalsContent.matchingFilters.kmMore")}
							renderSliderLabel={renderSliderLabel}
							className={
								distance === 10 || distance === 100 || distance === null
									? "tooltipEdge"
									: "tooltipDefault"
							}
						/>
					</div>
				</div>
			)}

			{/* VEHICLES */}
			{!isVehiclesListLoading && (
				<div className={cn([style.section, style.lastSection])}>
					<p className={style.subSectionTitle}>
						{t("commands.profiles.modalsContent.matchingFilters.sectionTitleVehicles")}
					</p>
					<div className={cn([style.checkbox, style.statusBox, style.outOfBusinessReasons])}>
						{vehiclesList?.data?.map((reason: { id: number; name: string }) => (
							<div className={style.outOfBusinessCheckBox} key={reason.id}>
								<Checkbox
									id={reason.name}
									value={vehicles.includes(reason.id)}
									onChange={() =>
										handleVehiclesCheckboxChange(reason.id, !vehicles.includes(reason.id))
									}
									theme="primary"
									label={reason.name}
									name="vehicles"
								/>
							</div>
						))}
					</div>
				</div>
			)}
		</>
	);
};

export default BusinessInformationFilter;
