import { FC } from "react";

import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { TUserListing } from "src/types/TUsersListing";

import usersListingService from "src/services/usersListingService";

import { InfoMessage, Text } from "@zolteam/react-ras-library";
import { ConfirmModal } from "src/components/molecules/ConfirmModal/ConfirmModal";

interface ActionDeleteUserProps {
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
	selectedItem: TUserListing | null;
}

const ActionDeleteUser: FC<ActionDeleteUserProps> = ({ isOpen, setIsOpen, selectedItem }) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();

	const mutationActionUpdateOutOfBuisnessReason = useMutation(
		({ userId }: { userId: number }) => usersListingService.deleteUser(userId),
		{
			onSuccess: () => {
				queryClient.invalidateQueries({ queryKey: ["postUsersListingSearch"] });
				toast.success(t(`usersListing.apiResponse.success.deleteUser`));
			},
			onSettled: () => {
				setIsOpen(false);
			},
		}
	);

	const handleUpdateOutOfBuisnessReason = (userId: number) => {
		return mutationActionUpdateOutOfBuisnessReason.mutateAsync({ userId });
	};

	return (
		<ConfirmModal
			title={t("global.delete")}
			isOpen={isOpen}
			onClose={() => setIsOpen(false)}
			onConfirm={() => {
				return handleUpdateOutOfBuisnessReason(selectedItem.id);
			}}
			confirmText={t("global.delete")}
			size="s"
			className="modal xs"
			closeOnConfirm={false}
			closeOnClickOutside={false}
		>
			<div className="flex flex-col gap-8">
				<InfoMessage color={"warning"} withIcon>
					{t("usersListing.primaryActions.delete.warnText")}
				</InfoMessage>
				<div className="flex flex-col gap-2">
					<Text tag="span" size="paragraph01">
						{t("usersListing.primaryActions.delete.confirmDelete")}
					</Text>
					<Text tag="span" size="paragraph01">
						{t("usersListing.primaryActions.delete.definitiveDelete")}
					</Text>
				</div>
			</div>
		</ConfirmModal>
	);
};

export default ActionDeleteUser;
