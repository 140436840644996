import { useMemo } from "react";

import { useLocation } from "react-router-dom";

// Constants
import { ORDER_CREATION_MODE } from "src/constants";

type LocationState = {
	mode?: NullableString;
	duplicatedOrderId?: NullableString;
};

const useDuplication = () => {
	const location = useLocation<LocationState>();

	const { mode, duplicatedOrderId } = useMemo(
		() => location.state || { mode: "default", duplicatedOrderId: null },
		[location]
	);
	const isInDuplicationMode = useMemo(() => mode === ORDER_CREATION_MODE.DUPLICATE, [mode]);

	return {
		isInDuplicationMode,
		duplicatedOrderId,
	};
};

export default useDuplication;
