import { FC } from "react";

import { useTranslation } from "react-i18next";

import DocumentsTypes from "./DocumentsTypes/DocumentsTypes";
import { SideMenuSecondary, WithSideMenu, WithTopBarre } from "src/components/templates";

const Integration: FC = () => {
	const { t } = useTranslation();

	const routes = [
		{
			path: `/integration`,
			routeName: "documents",
			label: t("integration.menu.documentTypes"),
			component: <DocumentsTypes />,
			permissions: ["digital.division"],
		},
	];

	return (
		<WithSideMenu>
			<WithTopBarre quickEntryContent={<></>} leftContent={<></>} containerClassname="flex flex-row">
				<SideMenuSecondary routes={routes} className="w-auto" classNameItemContainer="py-2 px-3" />
			</WithTopBarre>
		</WithSideMenu>
	);
};

export default Integration;
