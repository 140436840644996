// @ts-nocheck

/* eslint-disable no-nested-ternary */
import { useState } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useMedia } from "react-use";

import cn from "../../../utils/cn";
// Utils
import { Picto, Tooltip } from "@zolteam/react-ras-library";

// Style
import style from "./OrderProfilesActiveUsers.module.css";

const MAX_USERS_DISPLAYED = 9;
const MAX_USER_DISPLAYED_TOOLTIP = 10;

const MoreUserList = ({ list }) => {
	const { t } = useTranslation();

	return (
		<Tooltip
			placement="bottom"
			animation={false}
			arrow={false}
			content={
				<ul>
					{list
						.filter((_, index) => index < MAX_USER_DISPLAYED_TOOLTIP + 1)
						.map((user, index) => {
							const knownUser = !!(user.firstName && user.lastName);

							if (index === MAX_USER_DISPLAYED_TOOLTIP)
								return (
									<li className={style.tooltipUser} key={user.socketId}>
										{list.length - MAX_USER_DISPLAYED_TOOLTIP > 1
											? t("commands.profiles.activeUsers.overflowingAgents", {
													count: list.length - MAX_USER_DISPLAYED_TOOLTIP,
												})
											: t("commands.profiles.activeUsers.overflowingAgent")}
									</li>
								);

							return (
								<li className={style.tooltipUser} key={user.socketId}>
									<div className={cn([style.avatar, !knownUser && style.unknownAvatar])}>
										{knownUser ? (
											user.avatarUrl ? (
												<img src={user.avatarUrl} alt="avatar" aria-hidden="true" />
											) : (
												<span className={cn([style.text, style.initials])}>
													{`${user?.firstName.charAt(0).toUpperCase()}`}
													{`${user?.lastName.charAt(0).toUpperCase()}`}
												</span>
											)
										) : (
											<Picto className={style.tooltipPicto} icon="userDelete" />
										)}
									</div>
									<div>
										{knownUser
											? `${user.firstName} ${user.lastName}`
											: t("commands.profiles.activeUsers.rasAgent")}
									</div>
								</li>
							);
						})}
				</ul>
			}
		>
			<div className={cn([style.user, style.more])}>
				<div className={cn([style.avatar, style.moreAvatar])}>
					<span className={cn([style.text, style.moreUsers])}>{`+${list.length}`}</span>
				</div>
			</div>
		</Tooltip>
	);
};
MoreUserList.propTypes = {
	list: PropTypes.arrayOf(
		PropTypes.shape({
			socketId: PropTypes.string.isRequired,
			avatarUrl: PropTypes.string,
			lastName: PropTypes.string,
			firstName: PropTypes.string,
		}).isRequired
	).isRequired,
};

const OrderProfilesActiveUsers = ({ userList }) => {
	const [hoveredUser, setHoveredUser] = useState(null);
	const { t } = useTranslation();
	const isSmall = useMedia("(max-width: 1175px)");
	const isMediumInterval = useMedia("(min-width: 1280px) and (max-width: 1420px)");
	const shouldShowMinimalDisplay = isSmall || isMediumInterval;

	const displayedUsers = userList.slice(0, MAX_USERS_DISPLAYED);
	const remainingUsers = userList.slice(MAX_USERS_DISPLAYED);

	return (
		<div className={style.list}>
			{shouldShowMinimalDisplay ? (
				<MoreUserList list={userList} />
			) : (
				<>
					{displayedUsers.map((user) => {
						const knownUser = user.firstName && user.lastName;

						return (
							<Tooltip
								visible={false}
								placement="bottom"
								animation={false}
								arrow={false}
								key={user.socketId}
								content={
									<div>
										{knownUser
											? `${user.firstName} ${user.lastName}`
											: t("commands.profiles.activeUsers.rasAgent")}
									</div>
								}
							>
								<div
									className={cn([style.user, hoveredUser === user.socketId && style.hovered])}
									key={user.socketId}
									onMouseEnter={() => setHoveredUser(user.socketId)}
									onMouseLeave={() => setHoveredUser(null)}
								>
									<div className={cn([style.avatar, !knownUser && style.unknownAvatar])}>
										{knownUser ? (
											user.avatarUrl ? (
												<img src={user.avatarUrl} alt="avatar" aria-hidden="true" />
											) : (
												<span className={cn([style.text, style.initials])}>
													{`${user?.firstName.charAt(0).toUpperCase()}`}
													{`${user?.lastName.charAt(0).toUpperCase()}`}
												</span>
											)
										) : (
											<Picto className={style.tooltipPicto} icon="userDelete" />
										)}
									</div>
								</div>
							</Tooltip>
						);
					}) || null}
					{remainingUsers.length > 0 && <MoreUserList list={remainingUsers} />}
				</>
			)}
		</div>
	);
};

OrderProfilesActiveUsers.propTypes = {
	userList: PropTypes.arrayOf(
		PropTypes.shape({
			socketId: PropTypes.string.isRequired,
			avatarUrl: PropTypes.string,
			lastName: PropTypes.string,
			firstName: PropTypes.string,
		}).isRequired
	).isRequired,
};

export default OrderProfilesActiveUsers;
