// @ts-nocheck
const formatAgencyListing = (options) => {
	const processedOptions = options.map((option) => {
		if (option.includes("RAS ")) {
			return option.replace("RAS ", "");
		}
		return option;
	});

	const sortedOptions = [...processedOptions].sort();

	return sortedOptions;
};

export const getAgencyListing = (agencies, agencyKey = "name") => {
	const processedOptions = agencies.map((agency) => ({
		...agency,
		[agencyKey]: agency[agencyKey].includes("RAS ") ? agency[agencyKey].replace("RAS ", "") : agency[agencyKey],
	}));

	// Sort by agencyKey alphabetically
	const sortedOptions = [...processedOptions].sort((a, b) => {
		if (a[agencyKey] < b[agencyKey]) {
			return -1;
		}
		if (a[agencyKey] > b[agencyKey]) {
			return 1;
		}
		return 0;
	});

	return sortedOptions;
};

export default formatAgencyListing;
